import * as React from "react"
import { Formik, Field as RField, FormikProps, useFormikContext} from "formik";
import * as Yup from "yup";
import "./form.scss"
import { useDropzone } from "react-dropzone";
import * as uuidv4 from 'uuid/v4';
import { Select as RSelect, Input} from 'antd';
import {
    TextField,
    Card,
    CardContent,
    CardHeader,
    Button,  
    Checkbox,
    FormControlLabel, 
    FormControl,
    Link,
    Select,
    InputLabel,
    MenuItem,
    TextFieldProps
} from '@material-ui/core';
const { Option, OptGroup } = RSelect;

type TForm = {
    onSubmit: (data: any) => void,
    noSubmitBtn?: boolean,
    initialValues?: object, 
    validation?: any, 
    children: React.ReactNode, 
    id?: string, 
    getState?: (e: any)=> void,
    buttonProps?:{
        style: object,
        position: "left" | "center" | "right",
        className: string;
    }
}
type TRenderField = {
    field: {name: string, value: string, onChange: () => void, onBlur: () => void}
    form: FormikProps<object>
    meta: {
        error: string
        initialError: string
        initialTouched: boolean
        initialValue: string
        touched: boolean
        value: string
    };
}
type TFieldProps = {placeholder?: string, title:string, name: string, children?: React.ReactNode, disabled?: boolean, type?: string, required?: boolean, error?: any, variant?: any, helperText?: string }
type DesignProps = {style?: object, className?: string}
    

export function Form (props: TForm){
    const  {onSubmit, initialValues = {}, validation, children, id, getState, buttonProps, noSubmitBtn} = props
    return(
        <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            enableReinitialize
            validate={validation}
            innerRef={(e) => getState && getState(e?.values)}
        >
        {props => {
        const { handleSubmit, values } = props;
        // const {position = "right"} = buttonProps
        return (
            <form onSubmit={handleSubmit} id={id}>
                {children}
                <div className={`form-submit-btn-container ${buttonProps?.className} ${buttonProps?.position || "right"}`} style={buttonProps?.style}>
                {!noSubmitBtn && (<Button type="submit" variant={"outlined"}>Submit</Button>)}
                </div>
            </form>
        );
        }}
    </Formik>
)};


export namespace Form{
    export const Select = ({ 
        placeholder, 
        title, 
        name, 
        multiple, 
        options, 
        children, 
        labelKey, 
        className,
        disabled,
        valueKey}:TFieldProps & DesignProps & {multiple?: boolean; options?: any, valueKey?: string; labelKey?: string}) => {
        const renderOptions = () => {
            if (children) {
                return children;
            }
            return options.map((item:  string | number, index: number) => {
                if (valueKey && labelKey) {
                    return (
                        <Option className="select-menu__option" key={index} value={item[valueKey]}>
                            {item[labelKey]}
                        </Option>
                    );
                }
                return (
                    <Option className="select-menu__option" key={index} value={item}>
                        {item}
                    </Option>
                );
            });
        };
        return( 
        <RField name={name} type={"text"}>
            {({field, form}: TRenderField) => {
                const handleChange = (value: any) => form.setFieldValue(name, value);
                const handleBlur = () => form.setFieldTouched(name, true);
                return(
                    <RSelect 
                        
                        {...field}
                        mode={multiple ? "multiple" : "default"}
                        onChange={handleChange}
                        placeholder={placeholder}
                        className={`form-select ${className || ""}`}
                        onBlur={handleBlur}
                        style={{ width: "100%", marginTop: 24 }}
                        getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
                        disabled={disabled}
                    >
                        {renderOptions()}
                    </RSelect>
                    )}}
        </RField>
    )}
    export const Text = (props: TFieldProps & DesignProps & TextFieldProps ) => {
        return (
            <RField name={props.name}>
                {(form: TRenderField, data) => {
                    return (<TextField {...form.field} {...props} value={form.field.value || ""} label={props.title} fullWidth autoComplete="new-password" />)
                }}
            </RField>
        );
    };
    export const File = (props:TFieldProps & DesignProps & {customOpen?: (e:Function) => void}) => {
        return (
            <RField name={props.name}>
                {(form: TRenderField) => (<UploadComponent  {...form.field} {...form} {...props} />)}
            </RField>
        );
    };
}
const UploadComponent = props => {
    const { form, children, customOpen } = props;
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        accept: "image/*",
        noClick: true,
        noKeyboard: true,
        onDrop: acceptedFiles => {
            form.setFieldValue("files", acceptedFiles.map(file => Object.assign(file, {
              preview: URL.createObjectURL(file),
              id: uuidv4()
            })));
        },
        multiple: false
    });
    customOpen && customOpen(open)
    return (
      <div>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          
          {children ? children : isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <div onClick={open} className="media-library-load-btn">
                {"drop here"}
            </div>
            
          )}
        </div>
      </div>
    );
  };

  export const FormGroup = ({children}) => (<div className="form-group-custom">{children}</div>)
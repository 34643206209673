import * as React from 'react';
import './Company.scss';
import useReactRouter from 'use-react-router';
import {
    TextField,
    Card,
    CardContent,
    CardHeader,
    Button,
    Checkbox,
    FormControlLabel,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Link,
    Dialog as MaterialDialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    FormHelperText
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { companyDelete, getCompany, getUsersBySiteId, updateSite, updateCompanySite, TCompany, actions as companyActions, updateCompanyProperty, updateCompanySiteList, updateCompany, actions, getActiveAds, getInactiveAds } from '../../redux/reducers/company/companyReducer';
import { IStore } from '../../redux/reducers';
import { Dialog } from '../../components/Modal/Dialog';
import { searchCustomers, clearCustomers } from '../../redux/reducers/customerSearchReducer';
import { userDelete } from '../../redux/reducers/user/userReducer';
import { actions as siteActions } from '../../redux/reducers/sitesReducer';
import { createSite } from '../../redux/reducers/company/siteCreateReducer';
import { FormGroup, Form } from '../../components/Form/form';
import { ApiService } from '../../services/api/apiService';
import { JwtService } from '../../services/jwt/jwtService';
import { CustomerModeldefault } from '../../services/api/defaults';
import * as uuidv4 from 'uuid/v4';
import { CustomerModel, IHaveSiteModel, JobAdModel, SiteEntity } from '../../services/api/autorest/models';
import { enqueueSnackbar } from '../../redux/reducers/snackBarReducer';
import { Autocomplete } from '@material-ui/lab';
import { faCalendarAlt, faClock, faFileLines, faFileSignature } from '@fortawesome/free-solid-svg-icons'
import * as moment from 'moment';
import { FaCommonIcon } from '../../components/FaCommonIcon';
import { ActiveJobAdModel } from '../../Models/ActiveJobAdModel';
import { simpleEmailRegex } from '../../constants/regex';
import { Utils } from '../../services/utils';

const endpoints = require('../../../config/api/endpoints.json');

const counrties = require('..\\..\\..\\config\\countries.json');
const countriesDK = counrties['da-DK'];

enum UserValidationStatus {
    NotValidated,
    UserExist,
    UserNotExist
}

export const Company = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useMappedState((s: IStore) => s);
    const company = state.company;
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const isAsyncRunning = state.metadata.isAsyncRunning;
    const { match, history } = useReactRouter<{ id: string; siteId: string }>();
    const isEdit = !!match.params.id && !!match.params.siteId;
    const isDeleted = company?.isDeleted;
    const isDisabled = company?.isDisabled;
    const [roles, setRolse] = React.useState([]);
    const [deleteUser, setDeleteUser] = React.useState({ user: {} as any, isDialogDelete: false });
    const [siteTypeTitle, setSiteTypeTitle] = React.useState('');
    const firstUser = company?.userList && company?.userList[0];
    const [formState, setFormState] = React.useState({} as any);
    const [initialValuesState, setInitialValuesState] = React.useState({} as any);
    const [isCreateAdvertiser, setIsCreateAdvertiser] = React.useState(false);
    const [userErrorMsg, setUserErrorMsg] = React.useState('');
    const [validation, setValidation] = React.useState<{ [key: string]: { error: string | null } }>({
        roles: null,
        email: null
    });
    const [showListOfCustomersModalWindow, setShowListOfCustomersModalWindow] = React.useState<boolean>(false);
    const [listOfFoundCustomers, setListOfFoundCustomers] = React.useState<CustomerModel[]>([]);
    const [userValidationStatus, setUserValidationStatus] = React.useState<UserValidationStatus>(UserValidationStatus.NotValidated);
    const [showOrderUpdate, setShowOrderUpdate] = React.useState<boolean>(false);
    const [showOrderButton, setShowOrderButton] = React.useState<boolean>(false);

    React.useEffect(() => {
        ApiService.getCompany(match.params.siteId, match.params.id).then(companies => {
            const c = companies[0];
            dispatch(companyActions.replaceCompany({ company: c }));

            if (c && c.siteId) {
                dispatch(getActiveAds(c.siteId));
                dispatch(getInactiveAds(c.siteId));
            }

        });
    }, []);

    const isShowOrderButton = () => {
        var result = [...(company.activeAds || []), ...(company.inactiveAds || [])].filter(item => (item.orderInformation && (!item.orderInformation.buyerCustomerCrmId || item.orderInformation.buyerCustomerCrmId === '')));
        setShowOrderButton(result.length > 0);
    }


    React.useEffect(() => {
        isShowOrderButton();
    }, [company.activeAds, company.inactiveAds]);

    React.useEffect(() => {
        if (company && company.id) {
            if (!company.siteList) {
                ApiService.getSiteListForCompany(company.id).then(siteList => {
                    if (siteList && siteList.length > 0) {
                        const currentSite = siteList[0];
                        dispatch(updateCompanySiteList(siteList));
                        setSiteTypeTitle(currentSite.siteType === 5 ? 'Advertiser' : 'Site');
                    }
                });
            }

            dispatch(companyActions.replaceCompany({ company }));
        }
    }, [company]);

    React.useEffect(() => {
        if (company.siteList && !company.userList) {
            ApiService.getUsersBySiteId(company.siteId)
                .then(userList => dispatch(companyActions.replaceUserlist({ userList: userList as any[] })))
                .catch(error => dispatch(companyActions.replaceUserlist({ userList: [] })));
        }
    }, [company.siteList]);

    React.useEffect(() => {
        getUserRoles();
        const customerId = state?.customers[0]?.id;
        if (customerId) {
            history.push(`/customer/edit/${customerId}`);
            dispatch(clearCustomers());
        }
    }, [state?.customers]);

    const handlePropertyChanged = (e: any) => {
        dispatch(updateCompanyProperty(e.target.value, e.target.name));

        if (e.target.name === 'name')
            dispatch(updateCompanySiteList(company.siteList.map(site => ({ ...site, name: e.target.value }))));
    };

    const handleCheckboxChecked = (e: any) => {
        dispatch(updateCompanyProperty(e.target.checked, e.target.name));
    };

    const deleteCustomerClicked = () => {
        dispatch(companyDelete(match.params.id, match.params.siteId));
        setDeleteModalOpen(false);
        dispatch(getCompany({ companyId: match.params.id, siteId: match.params.siteId }));
        dispatch(getUsersBySiteId(match.params.siteId));
    };

    const changeCountry = (e: object, value: any) => {
        if (value)
            dispatch(companyActions.replaceCompany({ company: { ...company, countryCode: value['key'], country: value['value'] } }))
    };

    const handleSaveClicked = async () => {
        try {
            await ApiService.updateCompany({ company, siteId: company.siteId, id: company.id });
            await ApiService.updateSite(company.siteList.find(site => site.id === company.siteId));
            dispatch(enqueueSnackbar('Company successfully updated', 'success'));
        } catch (error) {
            dispatch(enqueueSnackbar(`Company update failed ${error}`, 'error'));
        }
    };

    const handleGotoCustomer = (customerId) => {
        dispatch(searchCustomers(customerId));
    };

    const handleCreateCustomer = async () => {
        if (company) {
            let customerList;
            try {
                customerList = await ApiService.getCustomersByCvrAndPnumber(company.vatnumber, company.pnumber);
            } catch {
                customerList = null;
            }
            let updateCompanyData: TCompany = { ...company };

            if (customerList && customerList.length > 0) {
                if (customerList.length === 1) {
                    updateCompanyData.externalId = customerList[0].customerId.toString();
                } else if (customerList.length > 1) {
                    setListOfFoundCustomers(customerList);
                    setShowListOfCustomersModalWindow(true);
                    return;
                }
            } else {
                const newCustomerData: CustomerModel = {
                    ...CustomerModeldefault,
                    id: uuidv4(),
                    customerId: 0,
                    name: company.name,
                    cvr: company.vatnumber,
                    ean: company.eannumber,
                    pNumber: company.pnumber,
                    address: company.address,
                    city: company.city,
                    zipCode: company.zipCode,
                    country: company.country,
                    webAddress: company.websiteUrl,
                    email: company.email,
                    phone1: company.phone,
                    createdBy: JwtService.getUsername(),
                };

                const customerResponse = await ApiService.createCustomer(newCustomerData);
                updateCompanyData = { ...company, externalId: customerResponse.customerId?.toString() || null };
            }

            dispatch(companyActions.replaceCompany({ company: updateCompanyData }));
            await ApiService.updateCompany({ company: updateCompanyData, siteId: company.siteId, id: company.id });
        }
    }

    async function getUserRoles() {
        let rolesData = null;

        try {
            rolesData = await ApiService.getUserRoles(match.params.siteId);
        } catch {
            rolesData = [];
        }

        setRolse(rolesData);
    }

    const createUser = async () => {
        // validation role
        if (formState.roles) {
            let rv = await ApiService.getUserByName(formState?.email ? formState?.email : '');
            setUserErrorMsg('');

            await ApiService.createUser({ ...formState, roles: [formState.roles] }, match.params.siteId);
            setUserValidationStatus(UserValidationStatus.NotValidated);
            setInitialValuesState({});
            setIsCreateAdvertiser(false);
            dispatch(getCompany({ companyId: match.params.id, siteId: match.params.siteId }));
            dispatch(getUsersBySiteId(match.params.siteId));
        } else {
            setValidation({ roles: { error: t('Select Role') } })
        }
    };

    const delateUser = () => {
        dispatch(userDelete(deleteUser.user.id, match.params.siteId));
        setDeleteUser((c) => ({ user: {}, isDialogDelete: false }));
        dispatch(getCompany({ companyId: match.params.id, siteId: match.params.siteId }));
        // dispatch(getUsersBySiteId(match.params.siteId));
    }

    const site = company?.siteList?.find(site => site.id === company.siteId);

    if (site?.id && !company?.name) {
        return (
            <div>
                Your Advertiser ({site.name}) will be present soon!!
            </div>
        )
    }
    if (!site?.id && !company?.name) {
        return (
            <div>
                Your Advertiser will be present soon!!
            </div>
        )
    }
    const makeClone = () => {
        const user = firstUser ? {
            userEmail: firstUser?.email,
            userLastName: firstUser?.lastName,
            userFirstName: firstUser?.firstName,
            userId: firstUser?.id,
            userPhone: firstUser?.phoneNumber
        } : {
            userEmail: JwtService.email(),
            userLastName: JwtService.getLastName(),
            userId: JwtService.getUserId(),
            userFirstName: JwtService.getFirstName()
        }
        dispatch(createSite({
            ...company,
            companyName: `${company.name}-clone`,
            ofirCRMNumber: company.externalId,
            vatNumber: company.vatnumber,
            ...user
        }));
    }

    const emailValidate = async () => {
        if (!formState?.email || !formState.email.match(simpleEmailRegex)) {
            setValidation({ email: { error: "Please enter valid email" } });
        } else {
            const rv = await ApiService.getUserByName(formState.email);

            if (rv && rv.userName) {
                setInitialValuesState((c) => ({
                    ...c,
                    ...formState,
                    firstName: rv.firstName,
                    lastName: rv.lastName,
                    phoneNumber: rv.phone,
                    password: '0000000000',
                    passwordConfirm: '0000000000',

                }));

                setUserValidationStatus(UserValidationStatus.UserExist);
                dispatch(enqueueSnackbar("User exists", "info"));
                return;
            } else if (formState?.userEmail) {
                setInitialValuesState((c) => ({
                    ...c,
                    ...formState,
                    userFirstName: '',
                    userLastName: '',
                    userPhone: '',
                    password: '',
                    passwordConfirm: ''
                }));
            } else {
                setInitialValuesState((c) => ({
                    ...c,
                    ...formState,
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    password: "",
                    passwordConfirm: "",

                }));
            }

            dispatch(enqueueSnackbar("User doesn't exist, enter user data", "info"));
            setUserValidationStatus(UserValidationStatus.UserNotExist);
        }
    };

    const updateStatus = () => {
        const listOfSites = [...company.siteList];
        let updatedSite = listOfSites.find(site => site.id === company.siteId);
        updatedSite.siteType = updatedSite.siteType === 0 ? 5 : 0;
        dispatch(updateCompanySiteList(listOfSites));
        dispatch(updateSite(updatedSite.id));
        //need to update all active jobAds on this site (change Description of jobAd)
        ApiService.updateDescription(updatedSite.id, updatedSite.siteType);
    }

    const getTitle = (company: TCompany) => {
        const companyExternalId: string = company?.externalId ? `(${company?.externalId})` : '';
        return `${siteTypeTitle} Data: ${company?.name}${companyExternalId}`;
    }

    const closeCreateUserModal = () => {
        setUserValidationStatus(UserValidationStatus.NotValidated);
        setInitialValuesState({});
        setIsCreateAdvertiser(false);
    }

    const AdsStyledRow = (ad: ActiveJobAdModel, key: number) => {
        return (
            (ad.orderInformation && (!ad.orderInformation.buyerCustomerCrmId || ad.orderInformation.buyerCustomerCrmId === '')) && (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginTop: '5px'}}>
                    <TextField
                        disabled={true}
                        fullWidth
                        size="small"
                        name="name"
                        value={`order: ${ad.orderInformation.id} (${dateStringFormat(ad.expiryDate)})`}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ minWidth: 165, marginLeft: 12, whiteSpace: 'nowrap' }}
                        onClick={async () => {
                            try {
                                const response = await ApiService.updateBuyerCustomerOrderData(ad.orderInformation.id, {
                                    crmId: company.externalId,
                                    name: ad.orderInformation.buyerCustomer,
                                    valueAddedTaxId: ad.orderInformation.valueAddedTaxIdentification,
                                    globalLocationNumber: ad.orderInformation.globalLocationNumber,
                                    contact: { name: ad.orderInformation.contactName, emailAddress: ad.orderInformation.contactEmail }
                                });

                                response.success
                                    ? dispatch(enqueueSnackbar(`Order successfully updated`, 'success'))
                                    : dispatch(enqueueSnackbar(
                                        `Unable to update order! ${response.statusDescription ? `Reason : ${response.statusDescription}` : ""}`,
                                        'error'
                                    ));
                            } catch {
                                dispatch(enqueueSnackbar(`Unable to update order!`, 'error'));
                            }
                        }}
                    >
                        Update
                    </Button>
                </div>
                )
            );
    }

    const ActiveAdsStyledTableRow = (ad: ActiveJobAdModel, key: number) => {
        return (
            <TableRow className='activeAdsStyledTableRow' key={key}>
                <TableCell className='activeAdsTableCell'>
                    <Link
                        style={{ cursor: 'pointer' }}
                        href="javascript:void(0)"
                        onClick={(e) => {
                            e.preventDefault();
                            const url = siteTypeTitle === "Site"
                                ? `//${site.host}/job/${ad.url}`
                                : `${endpoints['ofir']}/resultat/jobad.aspx?syndicationid=${ad.externalID}`;
                            window.open(url, '_blank');
                        }}
                    >
                        {ad.title}
                    </Link>
                </TableCell>
                <TableCell className='activeAdsTableCell'>{dateStringFormat(ad.activationDate)}</TableCell>
                <TableCell className='activeAdsTableCell'>{dateStringFormat(ad.expiryDate)}</TableCell>
                <TableCell className='activeAdsTableCell'>{ad.orderInformation?.id ?? ''}</TableCell>
                <TableCell className='activeAdsTableCell'>
                    {
                        (ad.orderInformation && (!ad.orderInformation.buyerCustomerCrmId || ad.orderInformation.buyerCustomerCrmId === '')) &&
                        <Button
                            variant="text"
                            color="primary"
                            onClick={async () => {
                                try {
                                    const response = await ApiService.updateBuyerCustomerOrderData(ad.orderInformation.id, {
                                        crmId: company.externalId,
                                        name: ad.orderInformation.buyerCustomer,
                                        valueAddedTaxId: ad.orderInformation.valueAddedTaxIdentification,
                                        globalLocationNumber: ad.orderInformation.globalLocationNumber,
                                        contact: { name: ad.orderInformation.contactName, emailAddress: ad.orderInformation.contactEmail }
                                    });

                                    response.success
                                        ? dispatch(enqueueSnackbar(`Order successfully updated`, 'success'))
                                        : dispatch(enqueueSnackbar(
                                            `Unable to update order! ${response.statusDescription ? `Reason : ${response.statusDescription}` : ""}`,
                                            'error'
                                        ));
                                } catch {
                                    dispatch(enqueueSnackbar(`Unable to update order!`, 'error'));
                                }
                            }}
                        >
                            Update
                        </Button>
                    }
                </TableCell>
            </TableRow>
        )
    }

    const dateStringFormat = (date: Date): string => moment(date).format('L');

    return (
        <div className="container">
            <div className="inputs">
                {company?.isDeleted && (
                    <Card classes={{ root: 'card' }}>
                        <CardHeader classes={{ root: 'cardheader' }} title="Delete reason" />
                        <CardContent>
                            <div>{company?.deleteReason}</div>
                        </CardContent>
                    </Card>
                )}

                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <div>
                        <CardHeader classes={{ root: 'cardheader' }} title={getTitle(company)} />
                        <Card classes={{ root: 'card company-info' }}>
                            <CardContent>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                                    <TextField disabled={true} fullWidth name="type" label={t('Type')} value={siteTypeTitle} />
                                    <Button
                                        onClick={() => updateStatus()}
                                        variant="contained"
                                        color="primary"
                                        title=""
                                    >
                                        {siteTypeTitle === "Site" ? t("Downgrade") : t("Upgrade")}
                                    </Button>
                                </div>
                                <FormControlLabel
                                    style={{ marginTop: -6 }}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name="disablePublishAd"
                                            disabled={isAsyncRunning || isDeleted || isDisabled}
                                            onChange={handleCheckboxChecked}
                                            checked={company?.disablePublishAd || false}
                                        />
                                    }
                                    label="Disable Publish Ad"
                                />
                                <Button
                                    onClick={makeClone}
                                    variant="contained"
                                    color="primary"
                                    title=""
                                >
                                    Clone advertiser
                                </Button>
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="name"
                                    onChange={handlePropertyChanged}
                                    label={t('Name')}
                                    value={company?.name || ''}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="address"
                                    onChange={handlePropertyChanged}
                                    label={t('Address')}
                                    value={company?.address || ''}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="zipCode"
                                    onChange={handlePropertyChanged}
                                    label={t('Postal code')}
                                    value={company?.zipCode || ''}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="city"
                                    onChange={handlePropertyChanged}
                                    label={t('City')}
                                    value={company?.city || ''}
                                />
                                <Autocomplete
                                    options={countriesDK}
                                    getOptionLabel={(option) => option['value']}
                                    onChange={changeCountry}
                                    value={{ value: company?.country || '', key: company?.countryCode || '' }}
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    renderInput={(params) => <TextField{...params} label="Country" />}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="websiteUrl"
                                    onChange={handlePropertyChanged}
                                    label={t('Web address')}
                                    value={company?.websiteUrl || ''}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="email"
                                    onChange={handlePropertyChanged}
                                    label={t('E-mail')}
                                    value={company?.email || ''}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="commercialContact"
                                    onChange={handlePropertyChanged}
                                    label={t('commercial E-mail')}
                                    value={company?.commercialContact || ''}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="phone"
                                    onChange={handlePropertyChanged}
                                    label={t('Phone')}
                                    value={company?.phone || ''}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="vatnumber"
                                    onChange={handlePropertyChanged}
                                    label={t('cvr')}
                                    value={company?.vatnumber || ''}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="commercialExternalId"
                                    onChange={handlePropertyChanged}
                                    label={t('Commercial ExternalId')}
                                    value={company?.commercialExternalId || ''}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="commercialVatNumber"
                                    onChange={handlePropertyChanged}
                                    label={t('Commercial Cvr')}
                                    value={company?.commercialVatNumber || ''}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="commercialContactNameExtended"
                                    onChange={handlePropertyChanged}
                                    label={t('Commercial Contact Name')}
                                    value={company?.commercialContactNameExtended || ''}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="commercialContactExtended"
                                    onChange={handlePropertyChanged}
                                    label={t('Commercial Contact')}
                                    value={company?.commercialContactExtended || ''}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="pnumber"
                                    onChange={handlePropertyChanged}
                                    label={t('PNumber')}
                                    value={company?.pnumber || ''}
                                />
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth
                                    name="eannumber"
                                    onChange={handlePropertyChanged}
                                    label={t('EanNumber')}
                                    value={company?.eannumber || ''}
                                />
                                {company?.externalId ?
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                                        <TextField
                                            disabled={isAsyncRunning || isDeleted || isDisabled}
                                            fullWidth
                                            name="externalId"
                                            onChange={handlePropertyChanged}
                                            label={t('External Id')}
                                            value={company?.externalId || ''}
                                        />
                                        <Button
                                            onClick={() => {
                                                handleGotoCustomer(company?.externalId);
                                            }}
                                            variant="contained"
                                            color="primary"
                                            style={{ minWidth: 165, marginLeft: 12 }}
                                        >
                                            customer card
                                        </Button>
                                    </div> :
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                                        <TextField
                                            disabled={isAsyncRunning || isDeleted || isDisabled}
                                            fullWidth
                                            name="externalId"
                                            onChange={handlePropertyChanged}
                                            label={t('External Id')}
                                            value={company?.externalId || ''}
                                        />
                                        <Button
                                            onClick={handleCreateCustomer}
                                            variant="contained"
                                            color="primary"
                                            style={{ minWidth: 165, marginLeft: 12, whiteSpace: 'nowrap' }}
                                        >
                                            Create customer
                                        </Button>
                                    </div>}
                                {showOrderButton && (
                                    <Button
                                        onClick={() => { setShowOrderUpdate(true); }}
                                        variant="contained"
                                        color="primary"
                                        title=""
                                        fullWidth
                                        style={{ marginTop: '5px' }}
                                    >
                                        Pending orders for customer
                                    </Button>
                                )}
                                <MaterialDialog
                                    open={showOrderUpdate}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    maxWidth="sm"
                                    fullWidth
                                >
                                    <DialogTitle id="alert-dialog-title">Pending orders for customer</DialogTitle>
                                    <DialogContent>
                                        {company.activeAds?.map((ad: ActiveJobAdModel, index: number) => AdsStyledRow(ad, index))}
                                        {company.inactiveAds?.map((ad: ActiveJobAdModel, index: number) => AdsStyledRow(ad, index))}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setShowOrderUpdate(false)} variant="contained" color="primary" autoFocus>
                                            Close
                                        </Button>
                                    </DialogActions>
                                </MaterialDialog>
                                <MaterialDialog
                                    open={showListOfCustomersModalWindow}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">Customers</DialogTitle>
                                    <DialogContent>
                                        <ul style={{ listStyle: 'none', margin: 0, padding: 0, width: 500 }}>
                                            {
                                                listOfFoundCustomers && listOfFoundCustomers.length > 0
                                                    ? (
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Name</TableCell>
                                                                    <TableCell>CVR</TableCell>
                                                                    <TableCell>PNumber</TableCell>
                                                                    <TableCell></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {listOfFoundCustomers.map((customer, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell>
                                                                            {customer.name}
                                                                        </TableCell>
                                                                        <TableCell>{customer.cvr}</TableCell>
                                                                        <TableCell>{customer.pNumber}</TableCell>
                                                                        <TableCell>
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={async () => {
                                                                                    const companyToSave = { ...company, externalId: customer.customerId.toString() };
                                                                                    dispatch(companyActions.replaceCompany({ company: companyToSave }));
                                                                                    await ApiService.updateCompany({
                                                                                        company: companyToSave,
                                                                                        siteId: company.siteId,
                                                                                        id: company.id
                                                                                    });
                                                                                    setShowListOfCustomersModalWindow(false);
                                                                                }}
                                                                            >
                                                                                Select
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    )
                                                    : null
                                            }
                                        </ul>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setShowListOfCustomersModalWindow(false)} variant="contained" color="primary" autoFocus>
                                            Close
                                        </Button>
                                    </DialogActions>
                                </MaterialDialog>

                                {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                            <TextField
                                disabled={isAsyncRunning || isDeleted || isDisabled}
                                fullWidth
                                name="host"
                                onChange={(e)=> setHost(e.target.value as any)}
                                label={t('Host url')}
                                value={host ||(company as any).host || "" }
                                error={hostValidateMsg === "Valid host url" || hostValidateMsg === "" ? false : true}
                                helperText={!!hostValidateMsg  || hostValidateMsg === ""  ? hostValidateMsg : ' '}                              
                            />
                            <Button
                                onClick={() => { validateHost() }}
                                variant="contained"
                                color="primary"
                                style={{ minWidth: 165, marginLeft: 12 }}
                                title=""
                            >

                                Validate
                            </Button>
                        </div> */}
                                {(site && !!site.host) && (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginTop: 12 }}>
                                        <Link
                                            className={isAsyncRunning ? 'disabled' : ''}
                                            style={{ cursor: 'pointer' }}
                                            href="javascript:void(0)"
                                            onClick={() => {
                                                window.open(`//${Utils.transformHost(site?.host) }`, '_blank');
                                            }}
                                            title={Utils.transformHost(site?.host)}
                                        >
                                            {Utils.transformHost(site?.host) || ''}
                                        </Link>
                                        <Button
                                            onClick={() => {
                                                window.open(`//${Utils.transformHost(site?.host)}/Account/LoginAdvertiser`, '_blank');
                                            }}
                                            variant="contained"
                                            color="primary"
                                            style={{ minWidth: 165, marginLeft: 12 }}
                                            title={`${Utils.transformHost(site?.host)}/Account/LoginAdvertiser`}
                                        >
                                            admin page
                                        </Button>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </div>
                    {
                        company && company.activeAds && company.activeAds.length > 0 ? (
                            <div>
                                <CardHeader classes={{ root: 'cardheader' }} title={'Active ads'} />
                                <Card className='activeAdsCard'>
                                    <CardContent>
                                        <Table className='activeAdsTable'>
                                            <TableHead className='activeAdsTableHeader'>
                                                <TableRow className='activeAdsStyledTableRow'>
                                                    <TableCell className='activeAdsTableCell'><FaCommonIcon icon={faFileSignature} /> {t('Heading')}</TableCell>
                                                    <TableCell className='activeAdsTableCell'><FaCommonIcon icon={faCalendarAlt} /> {t('Activation')}</TableCell>
                                                    <TableCell className='activeAdsTableCell'><FaCommonIcon icon={faClock} /> {t('Expiry date')}</TableCell>
                                                    <TableCell className='activeAdsTableCell'><FaCommonIcon icon={faFileLines} /> {t('Order number')}</TableCell>
                                                    <TableCell className='activeAdsTableCell'></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className='activeAdsTableBody'>
                                                {company.activeAds.map((ad: ActiveJobAdModel, index: number) => ActiveAdsStyledTableRow(ad, index))}
                                            </TableBody>
                                        </Table>
                                    </CardContent>
                                </Card>
                            </div>
                        ) : null
                    }
                </div>
                
                {/* {company?.siteList?.length ? <CardHeader classes={{ root: 'cardheader' }} title={`Site List`} /> : ""}
                <Card classes={{ root: 'card company-info' }}>
                    <CardContent>
                        {company?.siteList?.map((s: any, i:any) => (
                            <Link href={`http://${s.host}/admin`} key={i} target="_blank" title={s.host}>
                                {s.name}
                            </Link>))}
                    </CardContent>
                </Card> */}
                {company?.userList?.length ? <CardHeader classes={{ root: 'cardheader' }} title={`User List`} /> : ''}
                <Card>
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Name')}</TableCell>
                                    <TableCell>{t('E-mail')}</TableCell>
                                    <TableCell>{t('Role')}</TableCell>
                                    <TableCell>{t('Actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {company?.userList?.map((c: any, i: any) => (
                                    <TableRow key={i} style={c.isDeleted ? { textDecoration: 'line-through' } : {}}>
                                        <TableCell>
                                            <Link
                                                className={isAsyncRunning ? 'disabled' : ''}
                                                style={{ cursor: 'pointer' }}
                                                href="javascript:void(0)"
                                                onClick={() => history.push(`/user/edit/${c.id}`)}
                                            >
                                                {`${c.firstName} ${c.lastName}`}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{c.email}</TableCell>
                                        <TableCell>{c.roles.find((r) => r.role.siteId === match.params.siteId)?.role.name}</TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={() => {
                                                    setDeleteUser({
                                                        user: { ...c, roles: c.roles.find((r) => r.role.siteId === match.params.siteId) },
                                                        isDialogDelete: true
                                                    });

                                                }}
                                            >
                                                <DeleteIcon color={'error'} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Button
                            disabled={isAsyncRunning || isDisabled}
                            onClick={() => setIsCreateAdvertiser(true)}
                            variant="contained"
                            color="primary"
                            style={{ float: 'right', width: 100, margin: 10 }}
                            title=""
                        >
                            {t('Add')}
                        </Button>
                    </CardContent>
                </Card>
                {!isDeleted && (
                    <Card>
                        <Button
                            disabled={isAsyncRunning || isDisabled}
                            onClick={handleSaveClicked}
                            variant="contained"
                            color="primary"
                            style={{ float: 'right', width: 100, margin: 10 }}
                            title=""
                        >
                            {match.params.id ? t('Update') : t('Save')}
                        </Button>
                        {isEdit && (
                            <Button
                                disabled={isAsyncRunning}
                                onClick={() => setDeleteModalOpen(true)}
                                variant="text"
                                color="secondary"
                                style={{ float: 'right', width: 100, margin: 10 }}
                                title=""
                            >
                                {t('Delete')}
                            </Button>
                        )}
                    </Card>
                )}
                <MaterialDialog
                    open={isCreateAdvertiser}
                    onClose={closeCreateUserModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {initialValuesState.userExhist ?

                        <DialogActions>
                            <Typography className="exhistUserMessage">
                                {t("The user will be added to this site, but with the original information, which has been created on another site. If the user needs to get a new password, please use the forgot password functionality on the login page.")}
                            </Typography>
                            {/* added close button */}
                            <Button onClick={() => setIsCreateAdvertiser(false)} color="primary">
                                {t('Close')}
                            </Button>

                        </DialogActions> : <>
                            <DialogTitle id="alert-dialog-title">{t('Add new user for site')}</DialogTitle>
                            <DialogContent>
                                <div>{userErrorMsg}</div>
                                <Form onSubmit={createUser} initialValues={{ ...initialValuesState }} noSubmitBtn getState={(e) => setFormState(e)}>
                                    <FormGroup>
                                        <Form.Text
                                            name="email"
                                            title="Email"
                                            required
                                            style={{ flex: 3 }}
                                            onFocus={() => {
                                                if (!!validation.email)
                                                    delete validation.email
                                            }}
                                        />
                                        <Button onClick={emailValidate} variant="contained" color="primary" title="">
                                            Validate
                                        </Button>
                                    </FormGroup>
                                    {
                                        !!validation.email?.error && (
                                            <FormHelperText error={!!validation.email.error}>{validation.email.error}</FormHelperText>
                                        )
                                    }
                                    <FormGroup>
                                        <Form.Text
                                            name="phoneNumber"
                                            title="Phone"
                                            disabled={userValidationStatus !== UserValidationStatus.UserNotExist}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Form.Text
                                            name="firstName"
                                            title="First name"
                                            required
                                            disabled={userValidationStatus !== UserValidationStatus.UserNotExist}
                                        />
                                        <Form.Text
                                            name="lastName"
                                            title="Last name"
                                            required
                                            disabled={userValidationStatus !== UserValidationStatus.UserNotExist}
                                        />
                                    </FormGroup>
                                    <Form.Select
                                        required
                                        options={roles}
                                        valueKey="roleId"
                                        labelKey="name"
                                        name={'roles'}
                                        title="Roles"
                                        placeholder="Roles*"
                                        disabled={userValidationStatus === UserValidationStatus.NotValidated}
                                    />
                                    {!!validation.roles?.error ? <FormHelperText error={!!validation.roles.error}>{validation.roles.error}</FormHelperText> : <></>}
                                    <FormGroup>
                                        <Form.Text
                                            name="password"
                                            title="Password"
                                            type="password"
                                            required
                                            disabled={userValidationStatus !== UserValidationStatus.UserNotExist}
                                        />
                                        <Form.Text
                                            name="passwordConfirm"
                                            title="Password Confirm"
                                            type="password"
                                            required
                                            disabled={userValidationStatus !== UserValidationStatus.UserNotExist}
                                        />
                                    </FormGroup>
                                </Form>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={createUser} color="primary" disabled={userValidationStatus === UserValidationStatus.NotValidated}>
                                    {t('Create User')}
                                </Button>
                                {/* added close button */}
                                <Button onClick={closeCreateUserModal} color="primary">
                                    {t('Close')}
                                </Button>

                            </DialogActions>
                        </>}
                </MaterialDialog>
                <Dialog
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onSubmit={deleteCustomerClicked}
                    dialogContentText={t(`Are you sure you want to delete ${company?.name}?`)}
                    title={t('Delete')}
                />
                <Dialog
                    isOpen={deleteUser.isDialogDelete}
                    onClose={() => setDeleteUser((c) => ({ ...c, isDialogDelete: false }))}
                    onSubmit={() => {
                        dispatch(userDelete(deleteUser.user.id, match.params.siteId));
                        setDeleteUser((c) => ({ user: {}, isDialogDelete: false }));
                        dispatch(getCompany({ companyId: match.params.id, siteId: match.params.siteId }));
                    }}
                    dialogContentText={t(`Are you sure you want to delete user ${deleteUser.user?.firstName} ${deleteUser.user?.lastName}?`)}
                    title={t('Delete')}
                />
            </div>
        </div>
    );
};

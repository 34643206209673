import * as React from 'react';
import { useDispatch } from 'redux-react-hook';
import { ModalData, hideModal, showPipedriveSuccess } from '../../../../redux/reducers/modalsReducer';
import { DialogTitle, DialogContent, DialogActions, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { updatePipedriveOrg, createPipedriveOrg } from '../../../../redux/reducers/customerReducer';

export default function PipeDriveOrgSelection({ data }: { data: ModalData }) {
    const dispatch = useDispatch();
    const { customer, orgs } = data;

    const [selectedOrg, setSelectedOrg] = React.useState('');

    const handleChange = (e) => {
        setSelectedOrg(e.target.value);
    };

    const handleUpdate = () => {
        dispatch(updatePipedriveOrg(customer, parseInt(selectedOrg)));
        dispatch(hideModal());
        // dispatch(showPipedriveSuccess())
    };

    const renderOptions = () => {
        return orgs.map((org) => {
            return <FormControlLabel key={org.id} value={org.id.toString()} control={<Radio />} label={`${org.name} since ${org.add_time} | ID: (${org.id})`} />;
        });
    };
    const handleCreate = () => {
        dispatch(createPipedriveOrg(customer));
        dispatch(hideModal());
    };
    return (
        <>
            <DialogTitle>Select organization to update</DialogTitle>
            <DialogContent>
                <FormControl>
                    <FormLabel>Organizations:</FormLabel>
                    <RadioGroup aria-label="gender" name="org" value={selectedOrg} onChange={handleChange}>
                        {renderOptions()}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
       
                <Button type="button" color="primary" variant="contained" onClick={handleCreate}>
                    Create new
                </Button>
                <Button type="button" color="secondary" variant="contained" onClick={handleUpdate} disabled={!selectedOrg}>
                    Update
                </Button>
            </DialogActions>
        </>
    );
}

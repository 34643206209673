import * as base64 from "base-64";
import * as utf8 from "utf8";


export const Base64 = (data: string) => {
    const encode = () => base64.encode(utf8.encode(data));
    const decode = () => utf8.decode(base64.decode(data));
    return {
        decode, 
        encode
    }
}
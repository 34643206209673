import { actionCreator, Action, isType } from '../Action';
import { SnackbarProvider, OptionsObject } from 'notistack';
import { Snackbar } from '@material-ui/core';

export const actions = {
    enqueueSnackbar: actionCreator<Snackbar>('ENQUEUE_SNACKBAR'),
    removeSnackbar: actionCreator<{ key: string | number }>('REMOVE_SNACKBAR'),
};

export default function reducer(state: Snackbar[] = [], action: Action<any>) {
    if (!state) {
        return null;
    } else if (isType(action, actions.enqueueSnackbar)) {
        return [...state, action.payload];
    } else if (isType(action, actions.removeSnackbar)) {
        return {
            ...state,
            notifications: state.filter((notification: any) => notification.key !== action.payload.key),
        };
    }
    return state;
}
export const enqueueSnackbar = (message: string, variant?: 'default' | 'error' | 'success' | 'warning' | 'info', reloadOnSuccess: boolean = true) => {
    return async (dispatch: any, getState: any) => {
        dispatch(actions.enqueueSnackbar({ message, options: { variant: variant || 'default', key: new Date().getTime() + Math.random() } }));
        variant === "success" && reloadOnSuccess ? window.location.reload() : null;
    };
};
export interface Snackbar {
    message: string | React.ReactNode;
    options?: OptionsObject;
    hasBeenDisplayed?: boolean;
}

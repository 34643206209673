import * as React from 'react';
import { DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useDispatch } from 'redux-react-hook';
import { ModalData, hideModal } from '../../../../redux/reducers/modalsReducer';
import {PIPEDRIVE_FIELDS_CODES} from "../../../../constants/pipedrive"
import "../../Modal.scss"
const HeaderStyle = {
    display:"flex",
    justifyContent: "space-between",
    padding: 12,
    border: "1px solid",
    "& > div":{
        fontSize: 24
    }
}
export default function PipeDriveSuccessModal({ data }: { data: any }) {
    return (
        <>
            <DialogTitle>{data.update ? "Organisation Updated" : "Organisation Created"}</DialogTitle>
            <DialogContent className={"modal-table-header"}>
                <div>
                    <h4>Company name:</h4>
                    <div>{data.name}</div>
                </div>
                <div>
                    <h4>Cvr:</h4>
                    <div>{data[PIPEDRIVE_FIELDS_CODES.CVR]}</div>
                </div>
                <div>
                    <h4>P_Number:</h4>
                    <div>{data[PIPEDRIVE_FIELDS_CODES.P_NUMBER]}</div>
                </div>
                <div>
                    <h4>Crm ID:</h4>
                    <div>{data[PIPEDRIVE_FIELDS_CODES.CUSTOMER_ID]}</div>
                </div>
                <div>
                    <h4>Organisation ID:</h4>
                    <div>{data.id}</div>
                </div>
            </DialogContent>
        </>
    );
}

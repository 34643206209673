import { actionCreator, Action, isType } from '../Action';
import { CustomerModel } from '../../services/api/autorest/models';

export interface ModalData {
    [key: string]: any;
};

export interface ModalsState {
    template: string;
    data?: ModalData;
}

const defaultState: ModalsState = {
    template: ''
};

export const TEMPLATE_TYPES = {
    PIPEDRIVE_ACTION_SELECTION: 'PIPEDRIVE_ACTION_SELECTION',
    PIPEDRIVE_ORG_SELECTION: 'PIPEDRIVE_ORG_SELECTION',
    PIPEDRIVE_SUCCESS: 'PIPEDRIVE_SUCCESS'
};

export const actions = {
    showModal: actionCreator<{ templateType: string; data?: ModalData }>('MODAL_SHOW'),
    hideModal: actionCreator('HIDE_MODAL'),
};

export default function reducer(state: ModalsState = defaultState, action: Action<any>) {
    if (!state) {
        return null;
    }

    if (isType(action, actions.showModal)) {
        return {
            ...state,
            template: action.payload.templateType,
            data: action.payload.data || {}
        };
    }

    if (isType(action, actions.hideModal)) {
        return { ...state, template: '' };
    }

    return state;
}

function showModal(template: string, data?: ModalData) {
    return async (dispatch: Function) => {
        dispatch(actions.showModal({ templateType: template, data }));
    };
}

export function hideModal(): Action<any> {
    return actions.hideModal();
}

export function showPipedriveActionSelection(customer: CustomerModel, orgId: number) {
    return (dispatch: Function) => {
        const data = {
            customer,
            orgId
        };

        dispatch(showModal(TEMPLATE_TYPES.PIPEDRIVE_ACTION_SELECTION, data));
    };
}

export function showPipedriveOrgSelection(customer: CustomerModel, orgs: any[]) {
    return (dispatch: Function) => {
        const data = {
            customer,
            orgs
        };

        dispatch(showModal(TEMPLATE_TYPES.PIPEDRIVE_ORG_SELECTION, data));
    };
}

export function showPipedriveSuccess(data) {
    return (dispatch: Function) => {
        dispatch(showModal(TEMPLATE_TYPES.PIPEDRIVE_SUCCESS, data));
    };
}
import * as React from 'react';
import { DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useDispatch } from 'redux-react-hook';
import { ModalData, hideModal } from '../../../../redux/reducers/modalsReducer';
import { updatePipedriveOrg, createPipedriveOrg } from '../../../../redux/reducers/customerReducer';

export default function PipeDriveActionSelectionModal({ data }: { data: ModalData }) {
    const dispatch = useDispatch();
    const { customer, orgId } = data;

    const handleCreate = () => {
        dispatch(createPipedriveOrg(customer));
        dispatch(hideModal());
    };

    const handleUpdate = () => {
        dispatch(updatePipedriveOrg(customer, orgId));
        dispatch(hideModal());
    };

    return (
        <>
            <DialogTitle>Choice action type</DialogTitle>
            <DialogContent>
                <p>Update an existing organization in pipedrive or create a new one?</p>
            </DialogContent>
            <DialogActions>
                <Button type="button" color="primary" variant="contained" onClick={handleCreate}>
                    Create new
                </Button>
                <Button type="button" color="secondary" variant="contained" onClick={handleUpdate}>
                    Update
                </Button>
            </DialogActions>
        </>
    );
}

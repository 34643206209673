const endpoints = require('../../config/api/endpoints.json');
export const PIPEDRIVE_API_DOMAIN = endpoints["pipedriver"];
export const PIPEDRIVE_API_TOKEN = endpoints["pipedrivetoken"];
// export const PIPEDRIVE_API_DOMAIN = "https://ofir-sandbox-0fb274.pipedrive.com/v1";
// export const PIPEDRIVE_API_TOKEN = '41a1d3195eed54727d5cf0a763546e36023de461';
// console.log({PIPEDRIVE_API_DOMAIN, PIPEDRIVE_API_TOKEN});
export const PIPEDRIVE_FIELDS_CODES = (() => {    
    if (endpoints["pipedrivetoken"] === '202c519377f00e4976ab0e98e2b65b6009b3cdf1') {
        return {
            WEB_ADDRESS: 'e9d6fc985be355b27f044d75b5c83f4522989d6b',
            CVR: 'afd1f0cda24fe12cc9d4437f6116db1beda38f77',
            P_NUMBER:  '1b833b45d98975671a2f0a2ddd45789616cc629d',
            EAN: '1265c449ce5fb2b87c2856bd11ad78b9bd910889',
            INDUSTRY_CODE: 'c101f381367db1d8d264adbe23f60a751ca45488',
            CUSTOMER_ID: '965c035748d8f796bdfd2aad20847a23a383a6cd',
            PHONE1: 'b85c1006ebce6154566d2a265017d8dcb74ff24a',
        };
    }

    return {
        CVR: '7d89606440c3ffbc6d1ad8a53fd58ab7a8f65bac',
        P_NUMBER: 'f842292d38600569b67b0ecc45868cfcd6a94677',
        EAN: '445fa986123499809c77b3eb3091f9cfbe3249bc',
        INDUSTRY_CODE: '2fcbf0c6309f5390a0246cfd6f2fdad336629516',
        CUSTOMER_ID: 'abd3f450ff9d0d40fb6ee93c03842f43ae4f96a5',
        PHONE1: '770f59f68651cf2d025865cb42176a1fab4b8b1a',
    };
})();

import { actionCreator, Action, isType } from '../../Action';
import { ApiService } from '../../../services/api/apiService';
import { enqueueSnackbar } from '../snackBarReducer';
import { AsyncHandler } from '../../helpers';
export const actions = {
    search: actionCreator<{ user: any[] }>('SEARCH_USER')
};
export default function reducer(state: any[] = [], action: Action<any>) {
    if (!state) return null;
    if (isType(action, actions.search)) return action.payload.user;
    return state;
}
export const searchUser = (text: string, size: number) => {
    return async (dispatch: any, getState: any) => {
        AsyncHandler(
            async () => {
                try{
                    const c = await ApiService.searchUser(text, size) as any;
                    dispatch(actions.search({ user: c.slice(0,25) }));
                }catch(err){
                    // dispatch(enqueueSnackbar(`User ${text} not Found`, 'error'));
                    dispatch(actions.search({ user: [] }));
                }
            },
            dispatch,
            'searchUser'
        );
    };
};

import * as React from 'react';
import useReactRouter from 'use-react-router';
import './User.scss';
import {
    TextField,
    Card,
    CardContent,
    CardHeader,
    Button,
    Link,
    Select,
    InputLabel,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconLink from '@material-ui/icons/Link';
import { Dialog } from '../../components/Modal/Dialog';
import { useTranslation } from 'react-i18next';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { updateUserProperty, getUser, updateUser, userDelete, createUser, changeUserPassword } from '../../redux/reducers/user/userReducer';
import { getSitesByHost } from '../../redux/reducers/sitesReducer';
import { getRoles } from '../../redux/reducers/rolesReducer';
import { IStore } from '../../redux/reducers';
import { ApiService } from '../../services/api/apiService';
import { ChangePasswordViewModeldefault } from '../../services/api/defaults';
import { EmptyGuid } from '../../services/utils';
import { Utils } from '../../services/utils';

export const User = () => {
    const { t } = useTranslation();
    const state = useMappedState((state: IStore) => state);
    const dispatch = useDispatch();
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const user = state?.user;
    const [errors, setErrors] = React.useState(false);
    const isAsyncRunning = state.metadata.isAsyncRunning;
    const { match, history } = useReactRouter<{ userId: string }>();
    const isEdit = !!match.params.userId;
    const isDeleted = user.isDeleted;
    const isDisabled = user.isLocked;
    const [userSiteId, setUserSiteId] = React.useState(EmptyGuid);
    const [role, setRole] = React.useState([]);
    const sites = state.sites;
    const roles = state.roles;
    const [showChangePasswordForm, setShowChangePasswordForm] = React.useState(false);
    const [changePassword, setChangePassword] = React.useState(ChangePasswordViewModeldefault);
    const [isUserValidated, setIsUserValidated] = React.useState<boolean>(false);
    const [validatePressedForCurrentUser, setValidatePressedForCurrentUser] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (userSiteId) {
            dispatch(getRoles(userSiteId));
            setErrors(false);
        }
    }, [userSiteId]);

    React.useEffect(() => {
        if (isEdit) {
            dispatch(getUser({ userId: match.params.userId }));
            setIsUserValidated(true);
        }
    }, []);

    const changeText = (e: any) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        if (fieldName === "password" && fieldValue.slice(0,5) === "00000") {
            dispatch(updateUserProperty(fieldValue.slice(5), fieldName));
        } else {
            if (fieldName === "email") {
                setIsUserValidated(false);
                setValidatePressedForCurrentUser(false);
            }

            dispatch(updateUserProperty(fieldValue, fieldName));
        }
    };

    const saveClicked = () => {
        if (isEdit || isUserValidated) {
            dispatch(updateUser(role));
        } else {
            dispatch(createUser(userSiteId, role));
        }
    };

    const deleteCustomerClicked = (id: string) => {
        if (!!!userSiteId) return setErrors(true);
        dispatch(userDelete(id, userSiteId));
        setDeleteModalOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, value: any) => {
        if (event.target.value === EmptyGuid) {
            setUserSiteId(event.target.value);
        } else {
            if (isEdit) {
                dispatch(getSitesByHost(value.props.value[0].logoUrl));
                // logoUrl contained host data
                setUserSiteId(value.props.value[0].siteId);
            } else {
                var hostPath = value.split('/');
                var mainHost = hostPath[0].split('.');
                var findingValue = `${hostPath[hostPath.length - 1]}`;
                var hostValue = `${hostPath[hostPath.length - 1]}.${mainHost[1]}.${mainHost[2]}`;
                dispatch(getSitesByHost(findingValue));
                const id = sites?.find((x) => x.host === hostValue).id;
                if (isEdit) return setUserSiteId(id);
                setUserSiteId(id);
            }
        }
    };

    const getSiteDataByRoles = (id: string) => {
        return user?.siteList?.find((s) => s.id == id);
    };

    const emailValidate = async () => {
        const email = user?.email;

        if (email) {
            setValidatePressedForCurrentUser(true);
            const rv = await ApiService.getUserByName(email);
            if (rv) {
                setIsUserValidated(true);
                const setUser = {
                    id: rv.userId,
                    firstName: rv.firstName,
                    lastName: rv.lastName,
                    phoneNumber: rv.phone
                }

                for (const el in setUser)
                    changeText({ target: { name: el, value: setUser[el] } });
            }
        }
    };

    const onChangePassword = (e) => {
        setChangePassword({ ...changePassword, [e.target.name]: e.target.value });
    }

    const onChangePasswordButtonClick = () => {
        if (changePassword.newPassword?.length > 0 && changePassword.confirmPassword?.length > 0)
            dispatch(changeUserPassword(changePassword, user.siteList[0].id));
    }

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            if (active) {
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);
    return (
        <div className="container">
            <div className="inputs">
                {isDeleted && (
                    <Card classes={{ root: 'card' }}>
                        <CardHeader classes={{ root: 'cardheader' }} title="Delete reason" />
                        <CardContent>
                            <div>{user.deleteReason}</div>
                        </CardContent>
                    </Card>
                )}
                <CardHeader classes={{ root: 'cardheader' }} title={`User Data${user?.id && isEdit ? `: ${user?.id}` : ''}`} />
                <Card classes={{ root: 'card company-info' }}>
                    <CardContent>
                        <div className="emailValidation">
                            <TextField
                                disabled={isEdit}
                                fullWidth={true}
                                name="email"
                                onChange={changeText}
                                style={{ float: 'left', width: `75%` }}
                                label={t('E-mail')}
                                value={user?.email || ''}
                                required
                            />
                            <Button
                                onClick={() => emailValidate()}
                                variant="contained"
                                color="primary"
                                style={{ float: 'right', width: 10, margin: 10, paddingLeft: 60, paddingRight: 60 }}
                            >
                                {t('Validate')}
                            </Button>
                        </div>
                    </CardContent>
                </Card>

                <Card classes={{ root: 'card company-info' }}>
                    <CardContent>
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled || (!validatePressedForCurrentUser && !isEdit)}
                            fullWidth={true}
                            name="firstName"
                            onChange={changeText}
                            label={t('First Name')}
                            value={user?.firstName || ''}
                            required
                        />
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled || (!validatePressedForCurrentUser && !isEdit)}
                            fullWidth={true}
                            name="lastName"
                            onChange={changeText}
                            label={t('Last Name')}
                            value={user?.lastName || ''}
                            required
                        />
                        {!isEdit && (validatePressedForCurrentUser && !isUserValidated) && (
                            < TextField
                                name="password"
                                label={t('New Password')}
                                type="password"
                                fullWidth={true}
                                onChange={changeText}
                                required
                            />
                        )}
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled || (!validatePressedForCurrentUser && !isEdit)}
                            fullWidth={true}
                            type="number"
                            name="phoneNumber"
                            onChange={changeText}
                            label={t('Phone')}
                            value={user?.phoneNumber || ''}
                        />

                        {!isEdit && (
                            <>
                                <Autocomplete
                                    open={open}
                                    disabled={isAsyncRunning || isDeleted || isDisabled || (!validatePressedForCurrentUser && !isEdit)}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    getOptionSelected={(option, value) => option?.name === value?.name}
                                    getOptionLabel={(sites) => Utils.transformHost(sites?.host) || ''}
                                    options={sites || []}
                                    loading={loading}
                                    onInputChange={handleChange}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Type your site host *"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                                <Autocomplete
                                    options={roles}
                                    disabled={isAsyncRunning || isDeleted || isDisabled || (!validatePressedForCurrentUser && !isEdit)}
                                    multiple
                                    getOptionLabel={(roles) => roles?.name}
                                    onChange={(e, v) => {
                                        const newRole = [] as any;
                                        v.map((c) => newRole.push(c.roleId));
                                        setRole(newRole);
                                    }}
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} label={t('Please choose role')} required />}
                                />
                            </>
                        )}
                    </CardContent>
                </Card>
                {isEdit && (
                    <>
                        <CardHeader classes={{ root: 'cardheader' }} title={`User Role and Site`} />
                        <Card classes={{ root: 'card company-info' }}>
                            <CardContent>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('Type')}</TableCell>
                                            <TableCell>{t('Name')}</TableCell>
                                            <TableCell>{t('Role')}</TableCell>
                                            <TableCell>{t('Actions')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {user?.roles?.map((r) => {
                                            const site = r.role?.siteId && getSiteDataByRoles(r.role.siteId);
                                            return (
                                                <>
                                                    {(site as any)?.companies?.map((c) => {
                                                        return (
                                                            <TableRow key={c.id}>
                                                                <TableCell>{site?.siteType === 5 ? 'Advertiser' : 'Career Site'}</TableCell>
                                                                <TableCell>
                                                                    <Link onClick={() => history.push(`/site/edit/${c.id}/${c.siteId}`)}>{c?.name || ''}</Link>
                                                                </TableCell>

                                                                <TableCell>{r.role.name}</TableCell>
                                                                <TableCell>
                                                                    <Button onClick={() => window.open(`http://${Utils.transformHost(site.host)}/Account/LoginAdvertiser`, '_blank')} title={`http://${Utils.transformHost(site.host)}/Account/LoginAdvertiser`}>
                                                                        <IconLink color="primary" />
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </>
                )}

                {!isDeleted && (
                    <Card classes={{ root: 'card company-info' }}>
                        {isUserValidated && (
                            <Button
                                disabled={isAsyncRunning || isDisabled}
                                variant="contained"
                                color="primary"
                                style={{ float: 'left', width: 180, margin: 10 }}
                                onClick={() => setShowChangePasswordForm(!showChangePasswordForm)}
                            >
                                {t('Change Password')}
                            </Button>
                        )}

                        <Button
                            disabled={isAsyncRunning || isDisabled || (!validatePressedForCurrentUser && !isEdit)}
                            onClick={saveClicked}
                            variant="contained"
                            color="primary"
                            style={{ float: 'right', width: 100, margin: 10 }}
                            title=""
                        >
                            {match.params.userId ? t('Update') : t('Save')}
                        </Button>
                        {isEdit && (
                            <Button
                                disabled={isAsyncRunning}
                                onClick={() => setDeleteModalOpen(true)}
                                variant="text"
                                color="secondary"
                                style={{ float: 'right', width: 100, margin: 10 }}
                                title=""
                            >
                                {t('Delete')}
                            </Button>
                        )}
                    </Card>
                )}

                {showChangePasswordForm && (
                    <Card classes={{ root: 'card company-info' }}>
                        <CardContent>
                            <TextField
                                name="newPassword"
                                label={t('New Password')}
                                type="password"
                                fullWidth={true}
                                onChange={onChangePassword}
                            />
                            <TextField
                                name="confirmPassword"
                                label={t('Confirm Password')}
                                type="password"
                                fullWidth={true}
                                onChange={onChangePassword}
                            />
                            <Button
                                disabled={isAsyncRunning}
                                variant="contained"
                                color="primary"
                                style={{ float: 'right', width: 100, margin: 10 }}
                                onClick={onChangePasswordButtonClick}
                            >
                                {t('Confirm')}
                            </Button>
                        </CardContent>
                    </Card>
                )}
                
                <Dialog
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    dialogContentText={t(`Are you sure you want to delete ${user?.firstName}?`)}
                    title={t('Delete')}
                    onSubmit={() => deleteCustomerClicked(user.id)}
                >
                    <div style={{ margin: '12px 0' }}>
                        <InputLabel style={{ color: errors ? 'red' : 'black' }}>{t('Please choose for which site you want to delete this user')}</InputLabel>
                    </div>
                    <Select
                        fullWidth
                        defaultValue={EmptyGuid}
                        onChange={handleChange}
                    >
                        <option key={0} value={EmptyGuid}>Delete user completely</option>
                        {user?.siteList?.map(
                            (site: any, i) =>
                                site && (
                                    <option key={i + 1} value={site?.companies}>{site.name || Utils.transformHost(site.host)}</option> 
                                )
                        )}
                    </Select>
                </Dialog>
            </div>
        </div>
    );
};

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './src/App';
import './styles/style.scss';
ReactDOM.render(<App />, document.getElementById('app'));

// Hot Module Replacement
declare let module: { hot: any };

if (module.hot) {
    module.hot.accept('./src/App', () => {
        const NewApp = require('./src/App').default;
        ReactDOM.render(<NewApp />, document.getElementById('app'));
    });
}

import * as React from 'react';
import './CompanySearch.scss';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Divider, Link, Checkbox, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMappedState, useDispatch } from 'redux-react-hook';
import * as site from '../../redux/reducers/company/companySearchReducer';
import { IStore } from '../../redux/reducers';
import useReactRouter from 'use-react-router';
import { Search } from "../../components/Search/Search"

export const CompanySearch = () => {
    let timer: any;
    const { t } = useTranslation();
    const { history } = useReactRouter();
    const state = useMappedState((s: IStore) => s);
    const companies = state.companies;
    const dispatch = useDispatch();
    const [search, setSearch] = React.useState<string>('');
    const [withNoCrmIdOnlyChecked, setWithNoCrmIdOnlyChecked] = React.useState<boolean>(false);
    const isAsyncRunning = state.metadata.isAsyncRunning;
    React.useEffect(() => searchCompany(search), [withNoCrmIdOnlyChecked, search]);

    const searchCompany = (searchValue: string) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            dispatch(site.searchCompany(searchValue, 100, withNoCrmIdOnlyChecked));
        }, 300);
    };

    return (
        <div className="container">
            <Paper>
                <FormControlLabel
                    style={{ marginLeft: 0 }}
                    control={
                        <Checkbox
                            color="primary"
                            name="noCrmIdOnly"
                            checked={withNoCrmIdOnlyChecked}
                            onChange={(e, checked) => setWithNoCrmIdOnlyChecked(checked)}
                        />
                    }
                    label="Show advertiser with empty CRM_id"
                />
                <Search onSearch={(e) => setSearch(e.target.value)}/>
                <Divider />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('CRM_ID')}</TableCell>
                            <TableCell>{t('Name')}</TableCell>
                            <TableCell>{t('Email')}</TableCell>
                            <TableCell>{t('Zip-code / City')}</TableCell>
                            <TableCell>{t('Address')}</TableCell>
                            <TableCell>{t('Phone')}</TableCell>
                            <TableCell>{t('CVR')}</TableCell>
                            <TableCell>{t('PNumber')}</TableCell>
                            <TableCell>{t('EAN_Number')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {companies?.map((c) => (
                            <TableRow key={c.id}>
                                <TableCell>{c.externalId}</TableCell>
                                <TableCell>
                                    <Link
                                        className={isAsyncRunning ? 'disabled' : ''}
                                        style={{ cursor: 'pointer' }}
                                        href="javascript:void(0)"
                                        onClick={() => history.push(`/site/edit/${c.id}/${c.siteId}`)}
                                    >
                                        {c.name}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link
                                        className={isAsyncRunning ? 'disabled' : ''}
                                        style={{ cursor: 'pointer' }}
                                        href={`mailto:${c.email}`}
                                    >
                                        {c.email}
                                    </Link>
                                </TableCell>
                                <TableCell>{c.zipCode + ' / ' + c.city}</TableCell>
                                <TableCell>{c.address}</TableCell>
                                <TableCell>{c.phone}</TableCell>
                                <TableCell>{c.vatnumber}</TableCell>
                                <TableCell>{c.pnumber}</TableCell>
                                <TableCell>{c.eannumber}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
};


import { Errors } from './errors';
import { useTranslation } from 'react-i18next';
const { t } = useTranslation();

export interface Metadata {
    isAsyncRunning: boolean;
    errors: Errors;
    loading?: boolean;
    name?: string;
}
export const errorsDefault: Errors = {
    cvr: false,
    cvrErrorTextResource: 'cvrTextError',
};

import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Form, FormGroup } from '../../components/Form/form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { ISiteCreate } from './interfaces';
import { createSite } from '../../redux/reducers/company/siteCreateReducer';
import Button from '@material-ui/core/Button';
import { ApiService } from '../../services/api/apiService';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

export const SiteCreate = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        dispatch(createSite({...e, pNumber: Array.isArray(e.pNumber) ? e.pNumber[0] : e.pNumber, country: country.value, countryCode: country.key}));
    };

    const changeCountry = (e: object, country: any) => {
        const { key, value } = country;

        setCountry({
            key,
            value,
        });
    };

    const counrties = require('..\\..\\..\\config\\countries.json');
    const countriesDK = counrties['da-DK'];

    const [formState, setFormState] = React.useState({} as ISiteCreate & { vatNumbers: any[]; cvrOrg: string });
    const [country, setCountry] = React.useState<any>({});
    const [initialValuesState, setInitialValuesState] = React.useState({notValid: true} as ISiteCreate & { notValid: boolean; vatNumbers: any[]; cvrOrg });
    //if cvr found more 1 org make a select for chosing.
    // React.useEffect(() => {
    //     const orgData = formState?.vatNumbers?.find((c) => c.productionUnitIdentifier === formState.cvrOrg[0]);
    // }, [formState.cvrOrg]);
    console.log(country);
    //check cvr data if exist
    const searchCvr = async (e: any) => {
        if (!e.charCode || e.charCode === 13) {
            if (formState.vatNumber.length < 8) {
            } else {
                const cvr = await ApiService.searchCVR(formState.vatNumber);
                if (cvr.productionUnits.length > 1) {
                    setInitialValuesState((c) => ({
                        ...formState,
                        ...c,
                        vatNumber: cvr.valueAddedTaxId,
                        vatNumbers: cvr.productionUnits,
                        address: cvr.address,
                        city: cvr.postalCity,
                        zipCode: cvr.postalCode,
                        companyName: cvr.name,
                    }));
                } else {
                    setInitialValuesState((c) => ({
                        ...formState,
                        ...c,
                        address: cvr.address,
                        city: cvr.postalCity,
                        zipCode: cvr.postalCode,
                        companyName: cvr.name,
                        vatNumber: cvr.valueAddedTaxId,
                        pNumber: cvr.productionUnits[0].productionUnitIdentifier,
                    }));
                }
            }
        }
    };
    //check email and insert data if exist
    const emailValidate = async () => {
        const rv = await ApiService.getUserByName(formState?.userEmail ? formState.userEmail : '');
        if (rv && rv.userName) {
            setInitialValuesState((c) => ({
                ...c,
                ...formState,
                userFirstName: rv.firstName,
                userLastName: rv.lastName,
                userPhone: rv.phoneNumber,
                password: '0000000000',
                passwordConfirm: '0000000000',
                notValid: false,

            }));
        } else if (formState?.userEmail) {
            setInitialValuesState((c) => ({ 
                ...c,
                ...formState,
                userFirstName: '',
                userLastName: '', 
                userPhone: '', 
                password: '',
                passwordConfirm: '',
                notValid: false,
             }));
        }
    };
    return (
        <div className="container">
            <div className="inputs">
                <Card classes={{ root: 'card company-info' }}>
                    <CardContent>
                        <Form onSubmit={handleSubmit} initialValues={initialValuesState} getState={(e) => setFormState(e)}>
                            <FormGroup>
                                <Form.Text name="vatNumber" title="CVR-number" required style={{ flex: 3 }} />
                                <Button onClick={searchCvr} variant="contained" color="primary" title="">
                                    Check CVR
                                </Button>
                            </FormGroup>
                            <Form.Text name="companyName" title="Company Name" required />
                            <Form.Text required name="address" title="Address" />
                            <Form.Text name="addressExtended" title="Additional Address " />
                            <Form.Text name="ofirCRMNumber" title="CRM Id*" />
                            <Form.Select required options={['Denmark', 'United Kingdom']} name={'siteLanguage'} title="Language" placeholder="Language" />
                            <FormGroup>
                                <Form.Text required name="zipCode" title="Zip-code" />
                                <Form.Text required name="city" title="City" />
                                <TextField name="country" value={country.value} hidden />
                                <TextField name="countryCode" value={ country.key} hidden/>
                                <Autocomplete
                                    options={countriesDK}
                                    getOptionLabel={(option) => option['value']}
                                    onChange={ changeCountry }
                                    renderInput={(params) => <TextField{...params} label="Country" required/>}
                                />

                            </FormGroup>
                            <FormGroup>
                                {initialValuesState?.vatNumbers ? (
                                    <Form.Select
                                        required
                                        options={initialValuesState?.vatNumbers.map((c) => [c.productionUnitIdentifier])}
                                        name={'pNumber'}
                                        title="P-Number"
                                        placeholder="Select your p-number"
                                        style={{ flex: 3 }}
                                    />
                                ) : (
                                    <Form.Text name="pNumber" title="P-number" />
                                )}
                                <Form.Text name="eanNumber" title="EAN-number" />
                                <Form.Text name="userPhone" title="Phone" />
                            </FormGroup>
                            <FormGroup>
                                <Form.Text name="userEmail" title="Email" required style={{ flex: 3 }} />
                                <Button onClick={() => emailValidate()} variant="contained" color="primary" title="">
                                    Validate
                                </Button>
                            </FormGroup>
                            <FormGroup>
                                <Form.Text name="userFirstName" title="First name" required  disabled={!!initialValuesState.notValid} />
                                <Form.Text name="userLastName" title="Last name" required disabled={!!initialValuesState.notValid} />
                            </FormGroup>
                            <FormGroup>
                                <Form.Text name="password" title="Password" type="password" required disabled={!!initialValuesState.notValid} />
                                <Form.Text name="passwordConfirm" title="Password Confirm" type="password" required  disabled={!!initialValuesState.notValid} />
                            </FormGroup>
                        </Form>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

import * as React from 'react';
import { Dialog as RDialog, DialogActions,DialogTitle, DialogContentText, DialogContent, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
type TDialog = {isOpen: boolean, onClose: () => void, onSubmit?: ()=>void, title?: string, children?: React.ReactNode, dialogContentText?: string }

export function Dialog(props:  TDialog) {
    const { t } = useTranslation()
    const { children, onClose, onSubmit, isOpen, title, dialogContentText } = props
    return (
        <RDialog
        open={isOpen}
        onClose={onClose}
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{dialogContentText}</DialogContentText>
            {children}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} autoFocus variant="contained" color="primary">
                {t('No')}
            </Button>
            <Button onClick={onSubmit} color="primary">
                {t('Yes')}
            </Button>
        </DialogActions>
    </RDialog>
    );
}

import { actionCreator, Action, isType } from '../Action';
import { ApiService } from '../../services/api/apiService';
import { CustomerWebAPISharedRolesCreateGlobalRoleOptionalParams } from '../../services/api/autorest/models';

export const actions = {
    getRoles: actionCreator<{ roles: any }>('GET_ROLES'),
};
export default function reducer(state: any[] = [], action: Action<any>) {
    if (isType(action, actions.getRoles)) {
        return action.payload.roles
    }
    return state;
}


export const getRoles = (siteId: string) => {
    return async (dispatch:any, getState: any) =>{
        const rv = await ApiService.getRoleLists(siteId) as any; 
        dispatch(actions.getRoles({roles: rv}))
    }
}

import { actionCreator, Action, isType } from '../../Action';
import { ApiService } from '../../../services/api/apiService';
import { AsyncHandler } from '../../helpers';
import { enqueueSnackbar } from '../snackBarReducer';
import {TCompany} from "./companyReducer"

export const actions = {
    search: actionCreator<{ company: TCompany[] }>('SEARCH_COMPANY'),
};
export default function reducer(state: any[] = [], action: Action<any>) {
    if (!state) return null;
    if (isType(action, actions.search)) return action.payload.company;
    return state;
}
export const searchCompany = (text: string, size: number, noCrmOnly: boolean) => {
    return async (dispatch: any, getState: any) => {
        AsyncHandler(
            async () => {
                try {
                    const c = await ApiService.searchCompany(text, size, noCrmOnly) as any;
                    dispatch(actions.search({ company: c.slice(0, size) }));
                } catch (error) {
                    dispatch(actions.search({ company: [] }));
                }
            },
            dispatch,
            'searchCompany'
        );
    };
    
};


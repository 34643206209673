import * as React from 'react';
import './UserSearch.scss';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Divider, Link} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMappedState, useDispatch } from 'redux-react-hook';
import * as reducer  from '../../redux/reducers/user/userSearchReducer';
import { IStore } from '../../redux/reducers';
import useReactRouter from 'use-react-router';
import { Search } from "../../components/Search/Search"
export const UserSearch = () => {
    const { t } = useTranslation();
    const { history } = useReactRouter();

    const state = useMappedState((s: IStore) => s);
    const users = state.users;
    const dispatch = useDispatch();
    let timer: any;
    const isAsyncRunning = state.metadata.isAsyncRunning;
    const searchUser = (e: any) => {
        const val = e.target.value;
        if (!val) return;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            dispatch(reducer.searchUser(val, 100));
        }, 300);
    };
    
    return (
        <div className="container">
            <Paper>
                <Search onSearch={searchUser}/>
                <Divider />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Id')}</TableCell>
                            <TableCell>{t('Name')}</TableCell>
                            <TableCell>{t('Email')}</TableCell>
                            <TableCell>{t('Telephone')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users?.map((c, i) => (
                            <TableRow key={c.id}>
                                <TableCell>{c.id}</TableCell>
                                <TableCell>
                                    <Link
                                        className={isAsyncRunning ? 'disabled' : ''}
                                        style={{ cursor: 'pointer' }}
                                        href="javascript:void(0)"
                                        onClick={() => history.push(`/user/edit/${c.id}`)}
                                    >
                                        {c.firstName} {c.lastName}
                                    </Link>
                                </TableCell>
                                <TableCell>{c.email}</TableCell>
                                <TableCell>{c.phoneNumber}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
};


import { actionCreator, Action, isType } from '../Action';
import { ApiService } from '../../services/api/apiService';
import { IHaveSiteModel } from '../../services/api/autorest/models';

export const actions = {
    getSites: actionCreator<{ sites: IHaveSiteModel }>('GET_SITES'),
    updateSite: actionCreator<{ site: IHaveSiteModel }>('UPDATE_SITE')
};
export default function reducer(state: IHaveSiteModel[] = [], action: Action<any>) {
    if (isType(action, actions.getSites)) {
        return action.payload.sites;
    } else if (isType(action, actions.updateSite)) {
        return action.payload.site;
    }
    return state;
}


export const getSites = () => {
    return async (dispatch:any, getState: any) =>{
        const rv = await ApiService.getSiteLists(); 
        dispatch(actions.getSites({sites: rv}))
    }
}

export const getSitesByHost = (host:string) => {
    return async (dispatch:any, getState: any) =>{
        const rv = !!host.length ? await ApiService.getSitesByHost(host) as any : null;  
        dispatch(actions.getSites({sites: rv}))
    }
}

export const getSiteById = (siteId: string) => {
    return async (dispatch: any, getState: any) => {
        const site = await ApiService.getSiteById(siteId) as any;
        dispatch(actions.getSites({sites: site}))
    };
};

export const updateSite = (siteData: any) => {
    return async (dispatch: any, getState: any) => {
        const site = await ApiService.updateSite(siteData) as any;
        dispatch(actions.getSites({sites: site}))
    }
}

export function validator<T>(object: any, validation: T){
    let rv = {errors: {} as T, isError: false};
    for (const objKey in object){
        for (const validKey  in validation){
            if (objKey == validKey ){
                if (!object[objKey]){
                    rv["errors"] = {...validation, ...rv.errors, [objKey]: {...validation[objKey], error: true}}  
                } else {
                    rv["errors"] = {...validation, ...rv.errors, [objKey]: {...validation[objKey], error: false} }
                }
            }
        }
    }
    for (const key in rv.errors){
        if (rv.errors[key]["error"] === true){
            return {...rv, isError: true}
        } 
    }
    return {...rv, isError: false};    
}
import { Api } from './api';
import { Cvr } from '../../redux/models/cvr';
import { CustomerModeldefault } from './defaults';
import { Dawa } from '../../redux/models/dawa';
import { Company } from '../../pages/company';
import { TUser } from '../../redux/reducers/user/userReducer';
import { JwtService } from '../jwt/jwtService';
import { Base64 } from "../../services/base64"
import { IHaveSiteModel } from './autorest/models/mappers';
import { enqueueSnackbar } from '../../redux/reducers/snackBarReducer';
import { ChangePasswordViewModel } from './autorest/models';
// tslint:disable-next-line:no-var-requires
type CustomerModel = typeof CustomerModeldefault;
export interface UserRole {
    name: string;
    roleId: string;
    siteId: string;
}

const endpoints = require('../../../config/api/endpoints.json');
class PrivateChanges {
    public static async handler(callback) {
        try {
            return await callback
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    JwtService.removeToken();
                    window.open("/", "_self")
                }
            }
        }
    }
}
export class ApiService extends PrivateChanges {
    public static async getToken(userName: string, password: string) {
        const token = await new Api(endpoints['auth-user']).getToken({
            body: { password, userName }
        });
        //generateToken for autoLogin to carerSiteSolution

        let _local = window.location.hostname === "localhost";
        let b = _local ? "localhost" : window.location.hostname.split(".");
        if (!_local) {
            (b as any).splice(0, 1);
            b = (b as any).join(".")
        }
        //convert json key and convert to base64
        const tokenForCookie = Base64((JSON.stringify(token) as any).replaceAll(`"`, `'`).replace("accessToken", "access_token").replace("expiresIn", "expires_in")).encode()
        document.cookie = `AuthToken=${tokenForCookie};expires=${token.expiresIn};path=/;domain=${!_local ? `.${b}` : b}`;
        return token
    }

    //CUSTOMER
    public static async searchCustomer(searchText: string) {
        return this.handler(new Api(endpoints['queries-customer']).searchCustomer(searchText));
    }

    public static async searchCompanyByCustomerId(customerId: string) {
        let rv;
        try {
            rv = await fetch(`${endpoints['queries-company']}/api/companies/by-customer/${customerId}`)
        } catch (error) {
        }
        return await rv.json();
    }
    public static async createCustomer(customer: CustomerModel) {
        return new Api(endpoints['commands-customer']).createCustomer(customer);
    }
    public static async deleteCustomer(customer: CustomerModel) {
        return new Api(endpoints['commands-customer']).deleteCustomer(customer);
    }
    public static async sendToNavision(navisionData: any, method: string) {
        const rv = await fetch(`${endpoints['navision']}/api/customers`, {
            method: method,
            body: JSON.stringify(navisionData),
            headers: {
                'Content-Type': 'application/json',
                "Accept": '*/*',
                'Access-Control-Allow-Origin': '*'
            }
        });
        if (!rv.ok) throw new Error(rv.statusText);
        return rv.ok;
    }
    public static async updateCustomer(customer: CustomerModel) {
        console.log(customer)
        for (const t in CustomerModeldefault) CustomerModeldefault[t] = customer[t];

        const rv = await fetch(`${endpoints['commands-customer']}/api/Updatecustomer`, {
            method: "PUT",
            body: JSON.stringify(CustomerModeldefault),
            headers: {
                Authorization: `Bearer ${JwtService.getToken()}`,
                'Content-Type': 'application/json',
                "Accept": 'application/json'
            }
        })
        if (!rv.ok) throw new Error(rv.statusText);
        return rv.ok;
        //await rv.json();
        // return new Api(endpoints['commands-customer']).updateCustomer({...customer});
    }
    public static async getCustomer(id: string) {
        return new Api(endpoints['queries-customer']).getCustomer(id);
    }
    public static async getCustomerByPNumber(pNumber: string) {
        return new Api(endpoints['queries-customer']).getCustomerByPnumber(pNumber);
    }
    public static async getCustomerExists(pNumber: string) {
        return new Api(endpoints['queries-customer']).getCustomerExists(pNumber);
    }
    public static async getCustomersByCvrAndPnumber(cvr: string, pnumber: string) {
        return new Api(endpoints['queries-customer']).getCustomersByCvrAndPnumber(cvr, pnumber);
    }
    //--recommendation--//
    public static async getCustomerRecommendation(id: string | number, place: string) {
        const SUBSCRIBER_ID = '6a7e2428-004a-443a-a833-e2c4439dee8f';
        const rv = await fetch(`${endpoints['ofir-api']}/commerce/recommendations/${id}?subscriber=${SUBSCRIBER_ID}&customerType=${place}`);
        if (!rv.ok) throw new Error(rv.statusText);
        return await rv.json();
    }
    //OTHER
    public static async searchCVR(searchText: string): Promise<Cvr> {
        const resp = await fetch(endpoints['cvr'].replace('{0}', searchText), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!resp.ok) {
            throw Error(resp.statusText);
        }
        const json = await resp.json();
        return json;
    }
    public static async searchDawa(model: CustomerModel): Promise<Dawa[]> {
        const searchText = encodeURI(`${model.address} ${model.zipCode} ${model.city}`);
        const resp = await fetch(`https://dawa.aws.dk/adresser?q=${searchText}`, {
            mode: 'cors'
        });
        if (!resp.ok) {
            throw Error(resp.statusText);
        }
        const json = await resp.json();
        return json;
    }
    //Company
    public static async searchCompany(searchText: string, size: number, noCrmOnly: boolean) {
        return new Api(endpoints['queries-company']).companiesFindCompanies({ searchValue: searchText, noCrmId: noCrmOnly });
    }
    public static async getCompaniesByVatNumber(vatNumber: string, noCrmId: boolean) {
        return new Api(endpoints['queries-company']).companiesFindCompaniesByVatNumber(vatNumber, { emptyCrmId: noCrmId });
    }
    public static async getUsersBySiteId(id: string) {
        return new Api(endpoints['queries-user']).usersFindUserBySiteId(id) as any;
    }
    public static async getCompany(siteId: string, companyId?: string) {
        try {
            const rv = await new Api(endpoints['queries-company']).companiesGet1(siteId) as any;
            return rv.results

        } catch (error) {
            //console.clear();
        }
        //return new Api(endpoints['queries-company']).companiesGet2(siteId, companyId);
    }
    public static async getSiteListForCompany(companyId: string) {
        try {
            const response = await fetch(`${endpoints['queries-site']}/api/sites/ByCompanyId/${companyId}`, {
                headers: { 'Content-Type': 'application/json' }
            });

            return response.ok ? await response.json() : null;
        } catch (error) {
            return null;
        }
    }
    public static async hostValidate(host: string): Promise<any> {
        try {
            return await new Api(endpoints["queries-site"]).getSiteByHost(host)
        } catch (error) {
            return false
        }
    }
    public static async updateCompany(props: { company: any, siteId: string; id: string }) {
        return new Api(endpoints['commands-company']).companiesPutAsync(props.id, props.siteId, { body: props.company });
    }
    public static async deleteCompany(companyId: string, siteId: string) {
        return new Api(endpoints['commands-company']).companiesDeleteAsync(companyId, siteId);
    }
    //USER
    public static async searchUser(searchText: string, size: number) {
        return this.handler(new Api(endpoints['queries-user']).usersFindUsers({ body: { searchValue: searchText } }));
    }
    public static async getUser(userId: string) {
        return new Api(endpoints['queries-user']).usersFindUserById(userId);
    }
    public static async getUserRoles(SITE_ID): Promise<UserRole[]> {
        const result = await new Api(endpoints['queries-user']).rolesGetRolesAsync(SITE_ID) as any;

        return result;
    }
    public static async getUserSiteList(id: string) {

        // const rv = await new Api(endpoints['queries-site']).getSiteById(id)
        //console.log(rv);

        return await (await fetch(`${endpoints['queries-site']}/api/sites/ById/${id}`)).json();
    }
    public static async deleteUser(siteId: string, userId: string) {
        return new Api(endpoints['commands-user']).usersDeleteUser(siteId, userId);
    }
    public static async updateUser(user: any) {
        return new Api(endpoints['commands-user']).updateUserByCustomerTool(user.id, { body: user });
    }
    public static async changeUserPassword(siteId: string, userId: string, changePasswordViewModel: ChangePasswordViewModel) {
        return new Api(endpoints['commands-user']).usersChangeUserPassword(siteId, userId, { body: changePasswordViewModel });
    }
    public static async createUser(user: any, siteId: string) {
        return new Api(endpoints['commands-user']).usersCreateUser(siteId, { body: { ...user, userName: user.email } });
    }
    public static async userExist(userName: string, siteId: string) {
        return new Api(endpoints['queries-user']).usersGetUserAsync1(userName, { siteId })
    }

    //JOB
    public static async getActiveAds(siteId: string) {
        return await new Api(endpoints["queries-jobad"]).getJobAdsFiltered(siteId, { pageSize: 25 });
    }

    public static async getInactiveAds(siteId: string) {
        return await new Api(endpoints["queries-jobad"]).getJobAdsFiltered(siteId, {pageSize: 25, status: 2})
    }

    public static async getActiveAdOrderNumber(jobId: string) {
        const SUBSCRIBER_ID = '6a7e2428-004a-443a-a833-e2c4439dee8f';
        const rv = await fetch(`${endpoints['ofir-api']}/commerce/order/${jobId}?subscriber=${SUBSCRIBER_ID}`);
        return rv.ok ? await rv.json() : null;
    }

    public static async updateBuyerCustomerOrderData(
        orderId: string,
        body: {
            crmId: string,
            name: string,
            valueAddedTaxId: string,
            globalLocationNumber: string,
            contact: { name: string, emailAddress: string }
        }
    ) {
        const SUBSCRIBER_ID = '6a7e2428-004a-443a-a833-e2c4439dee8f';
        const requestOptions = {
            method: 'POST',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify(body)
        };

        const response = await fetch(
            `${endpoints['ofir-api']}/commerce/buyercustomerorderdata/${orderId}?subscriber=${SUBSCRIBER_ID}`,
            requestOptions
        );
        const json = response.ok ? await response.json() : null;
        return {
            success: parseInt(json?.StatusCode) === 200,
            statusDescription: json.StatusDescription
        };
    }

    public static async updateAccountCunstomerOrderData(
        orderId: string,
        body: {
            crmId: string,
            name: string,
            valueAddedTaxId: string,
            globalLocationNumber: string,
            contact: { name: string, emailAddress: string }
        }
    ) {
        const SUBSCRIBER_ID = '6a7e2428-004a-443a-a833-e2c4439dee8f';
        const requestOptions = {
            method: 'POST',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify(body)
        };

        const response = await fetch(
            `${endpoints['ofir-api']}/commerce/buyercustomerorderdata/${orderId}?subscriber=${SUBSCRIBER_ID}`,
            requestOptions
        );
        const json = response.ok ? await response.json() : null;
        return {
            success: parseInt(json?.StatusCode) === 200,
            statusDescription: json.StatusDescription
        };
    }

    //SITE
    public static async createSite(site) {
        const data = await new Api(endpoints["commands-sitecreation"]).internalSiteCreationV2(site);
    }

    public static async getSiteByCompanyId(companyId) {
        return await new Api(endpoints["queries-site"]).sitesGetSiteByCompanyId(companyId);
    }

    public static async uploadImageFileOnlyForSiteCreation(newSiteId: string, images: any[]): Promise<any> {
        var formData = new FormData();
        formData.append("ImageType", "Logo");
        formData.append("Images", images[0]);
        const rv = (await (await fetch(`${endpoints["commands-image"]}/api/sites/${newSiteId}/imagesonlyforsitescreation`, {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            body: formData,
        })).json());
        return rv;

    }

    public static async generateCssAndSaveItToSharedFolder(
        bodyFont: string,
        color: string,
        cssFileName: string,
        newSiteId: string
    ): Promise<any> {
        return await new Api(endpoints["commands-cssgenerator"]).generateCssAndSaveItToSharedFolder(newSiteId, {
            bodyFont,
            color,
            cssFileName
        });
    }

    public static async getSiteById(id: string) {
        return await new Api(endpoints["queries-site"]).getSiteById(id);
    }

    public static async getSiteLists() {
        const data = await new Api(endpoints["queries-site"]).getSitesWithHttpOperationResponse({ pageSize: 5000 });
        return JSON.parse(data.bodyAsText)
    }

    public static async getSitesByHost(host: string) {
        const result = await fetch(`${endpoints["queries-site"]}/api/sites/ByList/ByHost/${host}`);
        const data = await result.json();
        return Array.isArray(data) ? data : [data];
    }

    public static async getRoleLists(siteId: string) {
        return new Api(endpoints["queries-user"]).rolesGetRolesAsync(siteId);
    }

    public static async updateSite(site) {
        const md = JSON.parse(site.masterData);
        md.LogoUrl = site.host;
        md.CssUrl = site.host;
        site.masterData = JSON.stringify(md);
        return new Api(endpoints["commands-site"]).updateSite(site.id, { ...site, host: site.host }, { isValidateHost: true })
    }

    public static async deleteSite(siteId: string) {
        new Api(endpoints["commands-site"]).sitesDeleteSite(siteId);
    }

    public static async mergeSite(siteId: string, targetSiteId: string, targetCompanyId: string) {
        new Api(endpoints["commands-site"]).sitesMergeSite(siteId, { targetSiteId, targetCompanyId });
    }

    public static async getUserByName(email: string): Promise<TUser> {
        const result = await fetch(`${endpoints['queries-user']}/api/users/ByName/${email}`);
        return result.ok ? await result.json() as TUser : null;
    }

    public static async updateDescription(siteId: string, siteType: number) {
        new Api(endpoints["commands-jobad"]).jobAdPatchDescription1WithHttpOperationResponse(siteId, siteType);
    }
}

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type FaCommonIconProps = {
    color?: string;
    icon: IconProp;
}

export const FaCommonIcon = ({ icon, color }: FaCommonIconProps) => (
    <FontAwesomeIcon icon={icon} color={color ? color : '#0f446d'} />
)
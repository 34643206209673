// import is an example of JavaScript syntax new version
import * as React from 'react';

import { HashRouter, Route, Redirect } from 'react-router-dom';
import Login from './pages/login/Login';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { ThemeProvider, jssPreset, StylesProvider } from '@material-ui/styles';
import Master from './pages/master/Master';
// tslint:disable-next-line:no-var-requires
import rootReducer from './redux/reducers';
import { StoreContext } from 'redux-react-hook';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import Modal from './components/Modal/Modal';
import { CustomerSearch, Customer } from './pages/customer';
import { User, UserSearch} from './pages/user';
import { Company, CompanySearch } from './pages/company';
import { SiteCreate } from './pages/site/sitecreate';
import { JwtService } from './services/jwt/jwtService';
import { SnackbarProvider } from 'notistack';
import { Notifier } from './redux/reducers/components/Notifier';
import { create } from 'jss';

// tslint:disable-next-line:no-var-requires
const resources = require('../config/i18n/resources.json');

// @ts-ignore
const jss = create({
    ...jssPreset()
    // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
}) as any;

const store = createStore(rootReducer, applyMiddleware(thunk));
i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'da',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });
const theme = createMuiTheme({
    typography: {
        // useNextVariants: true
    },
    palette: {
        primary: {
            main: '#0099e5'
        },
        secondary: {
            main: '#f44336'
        }
    },
    overrides: {
        MuiFormControl: {
            root: {
                marginTop: '3px'
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 12
            }
        }
    }
});

const Routes = () => (
    <StylesProvider jss={jss}>
        <StoreContext.Provider value={store}>
            <SnackbarProvider hideIconVariant={true}>
                <ThemeProvider theme={theme}>
                    <MuiThemeProvider theme={theme}>
                        <HashRouter>
                            <Master>
                                <Route exact={true} path="/" component={Login} />
                                <PrivateRoute exact={true} path="/customer/create" component={Customer} />
                                <PrivateRoute exact={true} path="/customer/search" component={CustomerSearch} />
                                <PrivateRoute exact={true} path="/customer/edit/:customerId" component={Customer} />
                                <PrivateRoute exact={true} path="/site/search" component={CompanySearch} />
                                <PrivateRoute exact={true} path="/site/create" component={SiteCreate} />
                                <PrivateRoute exact={true} path="/site/edit/:id/:siteId" component={Company} />
                                <PrivateRoute exact={true} path="/user/search" component={UserSearch} />
                                <PrivateRoute exact={true} path="/user/create" component={User} />
                                <PrivateRoute exact={true} path="/user/edit/:userId" component={User} />
                                <Notifier />
                                <Modal />
                            </Master>
                        </HashRouter>
                    </MuiThemeProvider>
                </ThemeProvider>
            </SnackbarProvider>
        </StoreContext.Provider>
    </StylesProvider>
);
const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={(props) => (authenticate() ? <Component {...props} /> : <Redirect to="/" />)} />
    );
};
const authenticate = () => {
    return JwtService.getToken();
};
const App = () => {
    return <Routes />;
};
export default App;

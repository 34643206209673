import { LoginRequest, IHaveUserResponse, TokenResponse, CustomerModel, DeleteCustomerMessage, CreateCompanyViewModel, UpdateCompanyViewModel, ResetPasswordViewModel, NewPasswordViewModel, CreateRoleViewModel, CreateUserViewModel, UpdateUserViewModel, AddSiteCreationUserViewModel, ChangePasswordViewModel, PostSiteViewModel, PutSiteViewModel, InternalSiteCreationRequest, SiteCreateResult, PersistingCssRequestModel, CssGeneratingResponseModel, CssGeneratingRequestModel, ProductItemModelRequest, JobAdLocationsModel, ExtendedJobAdModel, JobAdResponce, MsgJobAd, SetSuperAdminDeleteStatusResponce, UpdateStatusResponce, PatchProductModelRequest, EncryptedData, PartnerJobAdResponce, CompanyWithSiteTypeModel, SearchParams, IHaveSiteModel, SiteEntity, IHaveEmailSiteModel, JobAdListItem, JobAdListItemSearchResult, ProductItemModel, JobAdModel, JobAdModelSearchResult } from "./autorest/models";
export const LoginRequestdefault: LoginRequest = {
    password: '',
    userName: '',
    siteId: '',
    otpToken: ''
};
export const IHaveUserResponsedefault: IHaveUserResponse = {
    userId: '',
    userName: '',
    firstName: '',
    lastName: '',
    phoneNumber: ''
};
export const TokenResponsedefault: TokenResponse = {
    accessToken: '',
    expiresIn: 0,
    user: { }
};
export const CustomerModeldefault: CustomerModel = {
    id: '',
    customerId: 0,
    name: '',
    cvr: '',
    ean: '',
    pNumber: '',
    address: '',
    address2: '',
    city: '',
    zipCode: '',
    country: '',
    webAddress: '',
    email: '',
    phone1: '',
    phone2: '',
    phone3: '',
    industryCode: '',
    created: new Date(0),
    modified: new Date(0),
    modifiedBy: '',
    createdBy: '',
    packageProperty: '',
    deleteReason: ''
};
export const DeleteCustomerMessagedefault: DeleteCustomerMessage = {
    id: '',
    deleteReason: ''
};
export const CreateCompanyViewModeldefault: CreateCompanyViewModel = {
    name: '',
    address: '',
    addressExtended: '',
    zipCode: '',
    city: '',
    stateCounty: '',
    country: '',
    countryCode: '',
    email: '',
    websiteUrl: '',
    vatnumber: '',
    pnumber: '',
    eannumber: '',
    phone: '',
    logoUrl: '',
    logoPath: '',
    industry: '',
    numEmployees: 0,
    facebookUrl: '',
    linkedInUrl: '',
    twitterUrl: '',
    otherUrl: '',
    externalId: '',
    displayName: '',
    externalApplyUrl: '',
    externalApplyMethod: 0,
    contactPerson: '',
    externalApplyEmail: '',
    subIndustry: '',
    youtubeUrl: '',
    instagramUrl: '',
    discountCode: '',
    salesPerson: '',
    commercialContactName: '',
    commercialContact: '',
    commercialContactNameExtended: '',
    commercialContactExtended: '',
    commercialVatNumber: '',
    commercialExternalId: ''
};
export const UpdateCompanyViewModeldefault: UpdateCompanyViewModel = {
    name: '',
    address: '',
    addressExtended: '',
    zipCode: '',
    city: '',
    stateCounty: '',
    country: '',
    countryCode: '',
    email: '',
    websiteUrl: '',
    vatnumber: '',
    pnumber: '',
    eannumber: '',
    phone: '',
    logoUrl: '',
    logoPath: '',
    industry: '',
    numEmployees: 0,
    facebookUrl: '',
    linkedInUrl: '',
    twitterUrl: '',
    otherUrl: '',
    externalId: '',
    displayName: '',
    externalApplyUrl: '',
    externalApplyMethod: 0,
    contactPerson: '',
    externalApplyEmail: '',
    subIndustry: '',
    youtubeUrl: '',
    instagramUrl: '',
    discountCode: '',
    salesPerson: '',
    commercialContactName: '',
    commercialContact: '',
    commercialContactNameExtended: '',
    commercialContactExtended: '',
    commercialVatNumber: '',
    commercialExternalId: ''
};
export const ResetPasswordViewModeldefault: ResetPasswordViewModel = {
    resetPasswordLink: '',
    cultureCode: '',
    subject: ''
};
export const NewPasswordViewModeldefault: NewPasswordViewModel = {
    password: '',
    resetPasswordToken: ''
};
export const CreateRoleViewModeldefault: CreateRoleViewModel = {
    roleName: ''
};
export const CreateUserViewModeldefault: CreateUserViewModel = {
    userName: '',
    password: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    notes: '',
    deliveryType2FA: '',
    roles: []
};
export const UpdateUserViewModeldefault: UpdateUserViewModel = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    notes: '',
    deliveryType2FA: '',
    roles: []
};
export const AddSiteCreationUserViewModeldefault: AddSiteCreationUserViewModel = {
    userName: '',
    password: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    notes: ''
};
export const ChangePasswordViewModeldefault: ChangePasswordViewModel = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
};
export const PostSiteViewModeldefault: PostSiteViewModel = {
    host: '',
    name: '',
    masterData: '',
    cultureCode: ''
};
export const PutSiteViewModeldefault: PutSiteViewModel = {
    name: '',
    host: '',
    masterData: '',
    cultureCode: '',
    siteType: 0,
    metaTitle: '',
    metaDescription: ''
};
export const InternalSiteCreationRequestdefault: InternalSiteCreationRequest = {
    siteId: '',
    fontName: '',
    primaryColor: '',
    companyId: '',
    userId: '',
    cssFileName: '',
    logoFileName: '',
    companyName: '',
    address: '',
    addressExtended: '',
    zipCode: '',
    city: '',
    countyState: '',
    country: '',
    countryCode: '',
    ofirCRMNumber: '',
    pNumber: '',
    vatNumber: '',
    eanNumber: '',
    industry: '',
    subIndustry: '',
    companyWebsite: '',
    companyPhone: '',
    companyEmail: '',
    companyFacebookUrl: '',
    companyTwitterUrl: '',
    companyLinkedInUrl: '',
    companyInstagramUrl: '',
    companyYoutubeUrl: '',
    otherUrl: '',
    userEmail: '',
    userFirstName: '',
    userLastName: '',
    userPhone: '',
    password: '',
    salesPerson: '',
    siteName: '',
    siteLanguage: '',
    discountCode: '',
    discountCodeNotes: '',
    siteHost: '',
    urlSchema: '',
    siteState: 0,
    siteType: 0,
    industryId: 0,
    subIndustryId: 0
};
export const SiteCreateResultdefault: SiteCreateResult = {
    siteId: '',
    companyId: '',
    userId: ''
};
export const PersistingCssRequestModeldefault: PersistingCssRequestModel = {
    cssFileName: '',
    color: '',
    bodyFont: ''
};
export const CssGeneratingResponseModeldefault: CssGeneratingResponseModel = {
    fileName: ''
};
export const CssGeneratingRequestModeldefault: CssGeneratingRequestModel = {
    color: '',
    bodyFont: ''
};
export const ProductItemModelRequestdefault: ProductItemModelRequest = {
    productNumber: '',
    productName: '',
    productPrice: ''
};
export const JobAdLocationsModeldefault: JobAdLocationsModel = {
    id: '',
    jobAdId: '',
    locationAddress: '',
    locationZipCode: '',
    locationCity: '',
    locationCountry: '',
    area: '',
    areaId: 0
};
export const ExtendedJobAdModeldefault: ExtendedJobAdModel = {
    regionCode: '',
    customerName: '',
    customerRelationshipManagementId: '',
    companyVatNumber: '',
    fullDescription: '',
    customerType: '',
    logoUrl: '',
    originalSource: '',
    siteURL: '',
    siteLanguage: '',
    couponCode: '',
    username: '',
    userEmail: '',
    oldStatus: 0,
    applicationMethodLocal: 0,
    requestedPackageOverridePrice: '',
    requestedPackageOverrideProducts: '',
    orderReference: '',
    ogImageWidth: 0,
    ogImageHeight: 0,
    authorUserEmail: '',
    updateFlag: 0,
    backgroundImage: '',
    title: '',
    createDate: new Date(0),
    expiryDate: new Date(0),
    id: '',
    externalID: '',
    externalSource: '',
    category: [],
    jobType: [],
    position: [],
    condition: [],
    salaryFrom: 0,
    salaryTo: 0,
    salaryType: '',
    salaryCurrency: '',
    description: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    commercialContactName: '',
    commercialContact: '',
    locationAddress: '',
    locationZipCode: '',
    locationCity: '',
    locationCounty: '',
    locationCountry: '',
    internalReference: '',
    applicationMethod: [],
    exportOptions: [],
    companyID: '',
    companyName: '',
    companyAddress: '',
    companyCity: '',
    companyZipCode: '',
    companyCounty: '',
    companyCountry: '',
    companyEmail: '',
    companyPhone: '',
    companyWWW: '',
    companyContactPerson: '',
    companyCommercialContactName: '',
    companyCommercialContact: '',
    companyCommercialVatNumber: '',
    companyCommercialExternalId: '',
    siteId: '',
    status: 0,
    url: '',
    urlHash: '',
    applicationUrl: '',
    companyVATIdentifier: '',
    productionUnitIdentifier: '',
    globalLocationNumber: '',
    requestedPackageId: '',
    requestedPackageName: '',
    requestedPackagePrice: '',
    requestedPackageDurationDays: 0,
    requestedPackageProducts: [],
    countryCode: '',
    activationDate: new Date(0),
    correlationId: '',
    pNumber: '',
    statisticsRegisterFeedDisplayCountLink: '',
    statisticsRegisterFeedEntrySearchedCountLink: '',
    statisticsRegisterFeedEntryDisplayCountLink: '',
    statisticsRegisterJobApplicationInitiatedCountLink: '',
    statisticsRegisterJobApplicationAppliedCountLink: '',
    numberOfVacancies: 0,
    applyMethod: 0,
    author: '',
    modifyDate: new Date(0),
    logoId: '',
    ogimage: '',
    orderByName: '',
    orderByEmail: '',
    alternateTextAnonymousLocation: '',
    locations: [],
    locationString: '',
    locationMapUri: ''
};
export const JobAdResponcedefault: JobAdResponce = {
    siteid: '',
    id: ''
};
export const MsgJobAddefault: MsgJobAd = {
    updateFlag: 0,
    backgroundImage: '',
    title: '',
    createDate: new Date(0),
    expiryDate: new Date(0),
    id: '',
    externalID: '',
    externalSource: '',
    category: [],
    jobType: [],
    position: [],
    condition: [],
    salaryFrom: 0,
    salaryTo: 0,
    salaryType: '',
    salaryCurrency: '',
    description: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    commercialContactName: '',
    commercialContact: '',
    locationAddress: '',
    locationZipCode: '',
    locationCity: '',
    locationCounty: '',
    locationCountry: '',
    internalReference: '',
    applicationMethod: [],
    exportOptions: [],
    companyID: '',
    companyName: '',
    companyAddress: '',
    companyCity: '',
    companyZipCode: '',
    companyCounty: '',
    companyCountry: '',
    companyEmail: '',
    companyPhone: '',
    companyWWW: '',
    companyContactPerson: '',
    companyCommercialContactName: '',
    companyCommercialContact: '',
    companyCommercialVatNumber: '',
    companyCommercialExternalId: '',
    siteId: '',
    status: 0,
    url: '',
    urlHash: '',
    applicationUrl: '',
    companyVATIdentifier: '',
    productionUnitIdentifier: '',
    globalLocationNumber: '',
    requestedPackageId: '',
    requestedPackageName: '',
    requestedPackagePrice: '',
    requestedPackageDurationDays: 0,
    requestedPackageProducts: [],
    countryCode: '',
    activationDate: new Date(0),
    correlationId: '',
    pNumber: '',
    statisticsRegisterFeedDisplayCountLink: '',
    statisticsRegisterFeedEntrySearchedCountLink: '',
    statisticsRegisterFeedEntryDisplayCountLink: '',
    statisticsRegisterJobApplicationInitiatedCountLink: '',
    statisticsRegisterJobApplicationAppliedCountLink: '',
    numberOfVacancies: 0,
    applyMethod: 0,
    author: '',
    modifyDate: new Date(0),
    logoId: '',
    ogimage: '',
    orderByName: '',
    orderByEmail: '',
    alternateTextAnonymousLocation: '',
    locations: [],
    locationString: '',
    locationMapUri: '',
    customerName: ''
};
export const SetSuperAdminDeleteStatusResponcedefault: SetSuperAdminDeleteStatusResponce = {
    siteId: '',
    jobAdId: '',
    status: 0
};
export const UpdateStatusResponcedefault: UpdateStatusResponce = {
    siteid: '',
    id: '',
    status: 0,
    url: '',
    urlHash: ''
};
export const PatchProductModelRequestdefault: PatchProductModelRequest = {
    requestedPackageId: '',
    requestedPackageName: '',
    requestedPackagePrice: '',
    requestedPackageDurationDays: 0,
    items: []
};
export const EncryptedDatadefault: EncryptedData = {
    publicKey: '',
    data: ''
};
export const PartnerJobAdResponcedefault: PartnerJobAdResponce = {
    siteid: '',
    id: '',
    url: ''
};
export const CompanyWithSiteTypeModeldefault: CompanyWithSiteTypeModel = {
    siteType: 0,
    id: '',
    name: '',
    address: '',
    addressExtended: '',
    zipCode: '',
    city: '',
    stateCounty: '',
    country: '',
    countryCode: '',
    email: '',
    commercialContactName: '',
    commercialContact: '',
    commercialContactNameExtended: '',
    commercialContactExtended: '',
    commercialVatNumber: '',
    commercialExternalId: '',
    websiteUrl: '',
    vatnumber: '',
    pnumber: '',
    eannumber: '',
    phone: '',
    logoUrl: '',
    logoPath: '',
    industry: '',
    subIndustry: '',
    numEmployees: 0,
    facebookUrl: '',
    linkedInUrl: '',
    twitterUrl: '',
    otherUrl: '',
    dateCreated: new Date(0),
    dateModified: new Date(0),
    correlationId: '',
    siteId: '',
    externalId: '',
    displayName: '',
    externalApplyUrl: '',
    externalApplyMethod: 0,
    contactPerson: '',
    externalApplyEmail: '',
    youtubeUrl: '',
    instagramUrl: ''
};
export const SearchParamsdefault: SearchParams = {
    searchValue: ''
};
export const IHaveSiteModeldefault: IHaveSiteModel = {
    id: '',
    name: '',
    host: '',
    masterData: '',
    cultureCode: '',
    createdAt: new Date(0)
};
export const SiteEntitydefault: SiteEntity = {
    id: '',
    name: '',
    host: '',
    createdAt: new Date(0),
    modifiedAt: new Date(0),
    correlationId: '',
    masterData: '',
    cultureCode: '',
    companyId: '',
    siteState: 0,
    siteType: 0,
    metaTitle: '',
    metaDescription: ''
};
export const IHaveEmailSiteModeldefault: IHaveEmailSiteModel = {
    id: '',
    createdAt: new Date(0),
    cultureCode: ''
};
export const JobAdListItemdefault: JobAdListItem = {
    id: '',
    siteID: '',
    title: '',
    createDate: new Date(0),
    expiryDate: new Date(0),
    status: 0,
    url: '',
    condition: [],
    activationDate: new Date(0),
    jobType: [],
    locationCity: '',
    statisticsRegisterFeedEntrySearchedCountLink: ''
};
export const JobAdListItemSearchResultdefault: JobAdListItemSearchResult = {
    total: 0,
    page: 0,
    pageSize: 0,
    count: 0,
    results: []
};
export const ProductItemModeldefault: ProductItemModel = {
    productNumber: '',
    productName: '',
    productPrice: ''
};
export const JobAdModeldefault: JobAdModel = {
    backgroundImage: '',
    title: '',
    createDate: new Date(0),
    expiryDate: new Date(0),
    id: '',
    externalID: '',
    externalSource: '',
    category: [],
    jobType: [],
    position: [],
    condition: [],
    salaryFrom: 0,
    salaryTo: 0,
    salaryType: '',
    salaryCurrency: '',
    description: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    commercialContactName: '',
    commercialContact: '',
    locationAddress: '',
    locationZipCode: '',
    locationCity: '',
    locationCounty: '',
    locationCountry: '',
    internalReference: '',
    applicationMethod: [],
    exportOptions: [],
    companyID: '',
    companyName: '',
    companyAddress: '',
    companyCity: '',
    companyZipCode: '',
    companyCounty: '',
    companyCountry: '',
    companyEmail: '',
    companyPhone: '',
    companyWWW: '',
    companyCommercialContactName: '',
    companyCommercialContact: '',
    companyCommercialVatNumber: '',
    companyCommercialExternalId: '',
    companyContactPerson: '',
    siteId: '',
    status: 0,
    url: '',
    urlHash: '',
    applicationUrl: '',
    companyVATIdentifier: '',
    productionUnitIdentifier: '',
    globalLocationNumber: '',
    requestedPackageId: '',
    requestedPackageName: '',
    requestedPackagePrice: '',
    requestedPackageDurationDays: 0,
    requestedPackageProducts: [],
    countryCode: '',
    activationDate: new Date(0),
    updateFlag: 0,
    correlationId: '',
    pNumber: '',
    statisticsRegisterFeedDisplayCountLink: '',
    statisticsRegisterFeedEntrySearchedCountLink: '',
    statisticsRegisterFeedEntryDisplayCountLink: '',
    statisticsRegisterJobApplicationInitiatedCountLink: '',
    statisticsRegisterJobApplicationAppliedCountLink: '',
    candidateCount: 0,
    numberOfVacancies: 0,
    applyMethod: 0,
    author: '',
    modifyDate: new Date(0),
    logoId: '',
    ogimage: '',
    orderByName: '',
    orderByEmail: '',
    alternateTextAnonymousLocation: '',
    locations: [],
    locationString: '',
    locationMapUri: '',
    customerName: ''
};
export const JobAdModelSearchResultdefault: JobAdModelSearchResult = {
    total: 0,
    page: 0,
    pageSize: 0,
    count: 0,
    results: []
};

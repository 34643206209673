const decode = require('jwt-decode') as any;
export class JwtService {
    private static getJwtProp(porp: string) {
        try {
            const token = this.getToken();
            if (!token) return null;
            return decode(token)[porp];
        } catch (error) {
            console.log(error);
        }
        return null;
    }
    public static getToken() {
        var keyValue = document.cookie.match('(^|;) ?jwttoken=([^;]*)(;|$)');
        return keyValue ? keyValue[2] : null;
    }

    public static setToken(accessToken: string, expiresIn) {
        var now = new Date();
        now.setTime(now.getTime() + 1 * 3600 * 1000); //you can change 3600s to expiresIn time
        document.cookie = `jwttoken=${accessToken};expires=${now.toUTCString()}; path=/`;
    }
    public static removeToken() {
        document.cookie = "jwttoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
    }
    public static getFirstName() {
        return this.getJwtProp('given_name');
    }
    public static getLastName() {
        return this.getJwtProp('family_name');
    }
    public static getUserId() {
        return this.getJwtProp("jti");
    }
    public static getName() {
        return this.getJwtProp('given_name') + ' ' + this.getJwtProp('family_name');
    }
    public static email() {
        return this.getJwtProp('email');
    }
    public static getUsername():string {
        return this.getJwtProp('http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name');
    }
    public static hasRole(role: string) {
        const roles = this.getJwtProp('http://schemas.microsoft.com/ws/2008/06/identity/claims/role');
        if (typeof roles === 'string') {
            return roles === role;
        }

        return roles && roles.includes(role);
    }
    public static hasCustomerCMSRole() {
        const roles = this.getJwtProp('http://schemas.microsoft.com/ws/2008/06/identity/claims/role');
        if (typeof roles == "string"){
            return roles.toLowerCase() === 'customercms_3ca5ef31-887f-4096-a980-870a76fef7d8'
        } else {
           return roles?.filter((x:any) => {
                return x.toLowerCase() === 'customercms_nodelete_3ca5ef31-887f-4096-a980-870a76fef7d8';
            }).length > 0
        }
    }
    public static hasCustomerCMSNoDeleteRole() {
        const roles = JwtService.getJwtProp('http://schemas.microsoft.com/ws/2008/06/identity/claims/role');
        if (typeof roles == "string"){
            return roles.toLowerCase() === 'customercms_3ca5ef31-887f-4096-a980-870a76fef7d8'
        } else {
           return roles?.filter((x:any) => {
                return x.toLowerCase() === 'customercms_nodelete_3ca5ef31-887f-4096-a980-870a76fef7d8';
            }).length > 0
        }
    }
    

    private static getJwt() {
        try {
            const token = this.getToken();
            if (!token) return null;
            return decode(token);
        } catch (error) {
            console.log(error);
        }
        return null;
    }
}

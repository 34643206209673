import { combineReducers } from 'redux';
import customer, { CustomerModelEx } from './customerReducer';
import metadata from './metadataReducer';
import customers from './customerSearchReducer';
import users from './user/userSearchReducer';
import user, {TUser} from './user/userReducer';

import sites from './sitesReducer';
import roles from './rolesReducer';

import companies from './company/companySearchReducer';
import company, {TCompany} from './company/companyReducer';
import snackbars from './snackBarReducer';
import modal, { ModalsState } from './modalsReducer';
import { Snackbar } from './snackBarReducer';
import { Metadata } from '../models/meta';
import { IHaveSiteModel } from '../../services/api/autorest/models';

export default combineReducers({
    customers,
    customer,
    users,
    user,
    sites,
    roles,
    companies,
    company,
    metadata,
    snackbars,
    modal
});
export interface IStore {
    customer: CustomerModelEx;
    customers: CustomerModelEx[];
    users: TUser[];
    user: TUser,
    sites: IHaveSiteModel[],
    companies:TCompany[],
    company:TCompany,
    metadata: Metadata;
    snackbars: Snackbar[];
    modal: ModalsState;
    roles: any[];
}

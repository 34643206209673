import { PIPEDRIVE_API_DOMAIN, PIPEDRIVE_API_TOKEN, PIPEDRIVE_FIELDS_CODES } from '../../constants/pipedrive';
import { CustomerModel } from './autorest/models';

export class PipederiveApi {
    public static async createOrg(customer: CustomerModel) {
        const { name, address, zipCode, city, country, phone1, cvr, pNumber, ean, industryCode, customerId } = customer;

        const requestData = {
            name,
            address: `${address}, ${zipCode} ${city} ${country}`,
            address_postal_code: zipCode,
            address_country: country,
            [PIPEDRIVE_FIELDS_CODES.PHONE1]: phone1,
            [PIPEDRIVE_FIELDS_CODES.CVR]: cvr,
            [PIPEDRIVE_FIELDS_CODES.P_NUMBER]: pNumber,
            [PIPEDRIVE_FIELDS_CODES.EAN]: ean,
            [PIPEDRIVE_FIELDS_CODES.INDUSTRY_CODE]: +industryCode,
            [PIPEDRIVE_FIELDS_CODES.CUSTOMER_ID]: customerId
        };

        const requestParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        };
        const url = `${PIPEDRIVE_API_DOMAIN}/organizations?api_token=${PIPEDRIVE_API_TOKEN}`;

        const result = await fetch(url, requestParams);

        return result.json();
    }

    public static async updadeOrg(customer: CustomerModel, orgId: number) {
        const { name, address, zipCode, city, country, phone1, cvr, pNumber, ean, industryCode, customerId } = customer;

        this.getOrgById = this.getOrgById.bind(this)
        
        const requestData = {
            name,
            address: `${address}, ${zipCode} ${city} ${country}`,
            address_postal_code: zipCode,
            address_country: country,
            [PIPEDRIVE_FIELDS_CODES.PHONE1]: phone1,
            [PIPEDRIVE_FIELDS_CODES.CVR]: cvr,
            [PIPEDRIVE_FIELDS_CODES.P_NUMBER]: pNumber,
            [PIPEDRIVE_FIELDS_CODES.EAN]: ean,
            [PIPEDRIVE_FIELDS_CODES.INDUSTRY_CODE]: +industryCode,
            [PIPEDRIVE_FIELDS_CODES.CUSTOMER_ID]: customerId
        };

        const requestParams = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        };
        const url = `${PIPEDRIVE_API_DOMAIN}/organizations/${orgId}?api_token=${PIPEDRIVE_API_TOKEN}`;

        const result = await fetch(url, requestParams);

        return result.json();
    }

    public static async searchOrgsByFieldValue(value: string) {
        const requestParams = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const url = `${PIPEDRIVE_API_DOMAIN}/organizations/search?api_token=${PIPEDRIVE_API_TOKEN}&term=${value}`;

        const result = await fetch(url, requestParams);
        const response = await result.json();

        if (!response?.data) return [];
        return response.data?.items;

        
    }
    //only test 
    public static async getDealById(id?: string) {
        const requestParams = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        };
        //prod
        //const url = `https://matchworkas-a83faf.pipedrive.com/v1/deals/4451?api_token=202c519377f00e4976ab0e98e2b65b6009b3cdf1`;
        const url = `${PIPEDRIVE_API_DOMAIN}/deals/${id}?api_token=${PIPEDRIVE_API_TOKEN}`;
        //test
        //const url = `https://ofir-sandbox-0fb274.pipedrive.com/v1/deals/4451?api_token=41a1d3195eed54727d5cf0a763546e36023de461`;

        const result = await fetch(url, requestParams);
        const response = await result.json();

        const org = await this.getOrgById(response.data.org_id.value)
        return response.data;
    }
    public static async getOrgById(id: string) {
        const requestParams = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const url = `${PIPEDRIVE_API_DOMAIN}/organizations/${id}?api_token=${PIPEDRIVE_API_TOKEN}`;

        const result = await fetch(url, requestParams);
        const response = await result.json();

        return response.data;
    }
    public static async getAllOrg() {
        const requestParams = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const url = `${PIPEDRIVE_API_DOMAIN}/organizations?api_token=${PIPEDRIVE_API_TOKEN}`;

        const result = await fetch(url, requestParams);
        const response = await result.json();

        return response;
    }
}

import * as React from 'react';
import { AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import PersonAdd from '@material-ui/icons/PersonAdd';
import SearchIcon from '@material-ui/icons/Search';
import LinearProgress from '@material-ui/core/LinearProgress';

import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/styles';
import './Master.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { clearCustomer } from '../../redux/reducers/customerReducer';
import { clearUser } from '../../redux/reducers/user/userReducer';
import { IStore } from '../../redux/reducers';
import { JwtService } from '../../services/jwt/jwtService';
const Master = ({ children }: { children: any }) => {
    const drawerWidth = 260;

    const classes = makeStyles((theme) => {
        return {
            root: {
                display: 'flex',
                flexGrow: 1
            },
            appBar: {
                zIndex: theme.zIndex.drawer + 1
            },
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
                zIndex: 0
            },
            drawerPaper: {
                width: drawerWidth
            },
            content: {
                flexGrow: 1,
                display: 'flex',
                padding: theme.spacing(3)
            },
            toolbar: theme.mixins.toolbar,
            inputInput: {
                paddingTop: theme.spacing(),
                paddingRight: theme.spacing(),
                paddingBottom: theme.spacing(),
                paddingLeft: theme.spacing(10),
                transition: theme.transitions.create('width'),
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                    width: 120,
                    '&:focus': {
                        width: 200
                    }
                }
            },
            inputRoot: {
                color: 'inherit',
                width: '100%'
            },
            search: {
                position: 'relative',
                borderRadius: theme.shape.borderRadius,
                backgroundColor: fade(theme.palette.common.white, 0.15),
                '&:hover': {
                    backgroundColor: fade(theme.palette.common.white, 0.25)
                },
                marginLeft: 0,
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                    marginLeft: theme.spacing(),
                    width: 'auto'
                }
            },
            searchIcon: {
                width: theme.spacing(9),
                height: '100%',
                position: 'absolute',
                pointerEvents: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            grow: {
                flexGrow: 1
            }
        };
    })({});
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useMappedState((s: any) => s) as IStore;
    const isAsyncRunning = state.metadata.isAsyncRunning;
    const { history, location, match } = useReactRouter();
    const showAppbar = location.pathname !== '/';
    const navigateCreateCustomer = () => {
        dispatch(clearCustomer());
        history.push('/customer/create');
    };
    const navigateCreateUser = () => {
        dispatch(clearUser());
        history.push('/user/create');
    };
    const [showLogout, setShowLogout] = React.useState(false);

    return (
        <div className={classes.root}>
            {showAppbar && (
                <>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h5" color="inherit" noWrap>
                                Customer Web
                            </Typography>
                            <div
                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                onMouseOver={(e) => setShowLogout(true)}
                                onMouseLeave={() => {
                                    setShowLogout(false);
                                }}
                            >
                                {showLogout && (
                                    <Typography
                                        variant="body1"
                                        color="inherit"
                                        noWrap
                                        style={{ marginRight: 12, cursor: 'pointer' }}
                                        onClick={() => {
                                            JwtService.removeToken();
                                            history.push('/');
                                        }}
                                    >
                                        LogOut
                                    </Typography>
                                )}
                                <Typography variant="h6" color="inherit" noWrap>
                                    {JwtService.getName()}
                                </Typography>
                            </div>
                        </Toolbar>
                        <LinearProgress style={{ visibility: isAsyncRunning ? 'visible' : 'hidden' }} />
                    </AppBar>

                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        style={{ zIndex: 2 }}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                    >
                        <div className={classes.toolbar} />
                        <List>
                            <ListItem selected={location.pathname === '/customer/search'} button onClick={() => history.push('/customer/search')}>
                                <ListItemIcon>
                                    <SearchIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('Search Customer')} />
                            </ListItem>
                        </List>
                        <ListItem selected={location.pathname === '/customer/create'} button onClick={navigateCreateCustomer}>
                            <ListItemIcon>
                                <PersonAdd />
                            </ListItemIcon>
                            <ListItemText primary={t('Create Customer')} />
                        </ListItem>
                        <ListItem selected={location.pathname === '/site/search'} button onClick={() => history.push('/site/search')}>
                            <ListItemIcon>
                                <SearchIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('Search Site / Advertiser')} />
                        </ListItem>
                        <ListItem selected={location.pathname === '/site/create'} button onClick={() => history.push('/site/create')}>
                            <ListItemIcon>
                                <PersonAdd />
                            </ListItemIcon>
                            <ListItemText primary={t('Create Advertiser')} />
                        </ListItem>
                        <ListItem selected={location.pathname === '/user/search'} button onClick={() => history.push('/user/search')}>
                            <ListItemIcon>
                                <SearchIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('Search User')} />
                        </ListItem>
                        <ListItem selected={location.pathname === '/user/create'} button onClick={navigateCreateUser}>
                            <ListItemIcon>
                                <PersonAdd />
                            </ListItemIcon>
                            <ListItemText primary={t('Create User')} />
                        </ListItem>
                        {/* <Divider />
                        <List>
                            {['All mail', 'Trash', 'Spam'].map((text, index) => (
                                <ListItem button key={text}>
                                    <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </List> */}
                        {/* <ListItem selected={location.pathname === '/user/create'} button onClick={() => window.open("http://localhost:18896/Account/LoginAdvertiser")}>
                            <ListItemText primary={t('Create User')} />
                        </ListItem> */}
                    </Drawer>
                </>
            )}
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div className="children">{children}</div>
            </main>
        </div>
        // <div className="main">
        //     <div className="appBar">
        //         {showAppbar && (
        //             <div>
        //                 <AppBar position="static">
        //                     <Toolbar>
        //                         <Typography variant="h6" color="inherit">
        //                             Document Web
        //                         </Typography>
        //                     </Toolbar>
        //                 </AppBar>
        //                 <Drawer variant="permanent" />
        //             </div>
        //         )}
        //     </div>
        //     <div className="children">{children}</div>
        // </div>
    );
};
export default Master;

/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */


export const LoginRequest = {
  serializedName: "LoginRequest",
  type: {
    name: "Composite",
    className: "LoginRequest",
    modelProperties: {
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      },
      userName: {
        serializedName: "userName",
        type: {
          name: "String"
        }
      },
      siteId: {
        serializedName: "siteId",
        type: {
          name: "Uuid"
        }
      },
      otpToken: {
        serializedName: "otpToken",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const IHaveUserResponse = {
  serializedName: "IHaveUserResponse",
  type: {
    name: "Composite",
    className: "IHaveUserResponse",
    modelProperties: {
      userId: {
        readOnly: true,
        serializedName: "userId",
        type: {
          name: "Uuid"
        }
      },
      userName: {
        readOnly: true,
        serializedName: "userName",
        type: {
          name: "String"
        }
      },
      firstName: {
        readOnly: true,
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        readOnly: true,
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        readOnly: true,
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TokenResponse = {
  serializedName: "TokenResponse",
  type: {
    name: "Composite",
    className: "TokenResponse",
    modelProperties: {
      accessToken: {
        readOnly: true,
        serializedName: "access_token",
        type: {
          name: "String"
        }
      },
      expiresIn: {
        readOnly: true,
        serializedName: "expires_in",
        type: {
          name: "Number"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "IHaveUserResponse"
        }
      }
    }
  }
};

export const CustomerModel = {
  serializedName: "CustomerModel",
  type: {
    name: "Composite",
    className: "CustomerModel",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      customerId: {
        serializedName: "customerId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      cvr: {
        serializedName: "cvr",
        type: {
          name: "String"
        }
      },
      ean: {
        serializedName: "ean",
        type: {
          name: "String"
        }
      },
      pNumber: {
        serializedName: "pNumber",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "String"
        }
      },
      address2: {
        serializedName: "address2",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      zipCode: {
        serializedName: "zipCode",
        type: {
          name: "String"
        }
      },
      country: {
        serializedName: "country",
        type: {
          name: "String"
        }
      },
      webAddress: {
        serializedName: "webAddress",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      phone1: {
        serializedName: "phone1",
        type: {
          name: "String"
        }
      },
      phone2: {
        serializedName: "phone2",
        type: {
          name: "String"
        }
      },
      phone3: {
        serializedName: "phone3",
        type: {
          name: "String"
        }
      },
      industryCode: {
        serializedName: "industryCode",
        type: {
          name: "String"
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      modified: {
        serializedName: "modified",
        type: {
          name: "DateTime"
        }
      },
      modifiedBy: {
        serializedName: "modifiedBy",
        type: {
          name: "String"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      isDeleted: {
        serializedName: "isDeleted",
        type: {
          name: "Boolean"
        }
      },
      packageProperty: {
        serializedName: "packageProperty",
        type: {
          name: "String"
        }
      },
      isHq: {
        serializedName: "isHq",
        type: {
          name: "Boolean"
        }
      },
      deleteReason: {
        serializedName: "deleteReason",
        type: {
          name: "String"
        }
      },
      correlationId: {
        serializedName: "correlationId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const DeleteCustomerMessage = {
  serializedName: "DeleteCustomerMessage",
  type: {
    name: "Composite",
    className: "DeleteCustomerMessage",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      deleteReason: {
        serializedName: "deleteReason",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateCompanyViewModel = {
  serializedName: "CreateCompanyViewModel",
  type: {
    name: "Composite",
    className: "CreateCompanyViewModel",
    modelProperties: {
      name: {
        required: true,
        serializedName: "name",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      address: {
        required: true,
        serializedName: "address",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      addressExtended: {
        serializedName: "addressExtended",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      zipCode: {
        required: true,
        serializedName: "zipCode",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      city: {
        required: true,
        serializedName: "city",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      stateCounty: {
        serializedName: "stateCounty",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      country: {
        serializedName: "country",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        constraints: {
          MaxLength: 2,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      websiteUrl: {
        serializedName: "websiteUrl",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      vatnumber: {
        required: true,
        serializedName: "vatnumber",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      pnumber: {
        serializedName: "pnumber",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      eannumber: {
        serializedName: "eannumber",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      logoUrl: {
        required: true,
        serializedName: "logoUrl",
        type: {
          name: "String"
        }
      },
      logoPath: {
        required: true,
        serializedName: "logoPath",
        type: {
          name: "String"
        }
      },
      industry: {
        required: true,
        serializedName: "industry",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      numEmployees: {
        serializedName: "numEmployees",
        type: {
          name: "Number"
        }
      },
      facebookUrl: {
        serializedName: "facebookUrl",
        type: {
          name: "String"
        }
      },
      linkedInUrl: {
        serializedName: "linkedInUrl",
        type: {
          name: "String"
        }
      },
      twitterUrl: {
        serializedName: "twitterUrl",
        type: {
          name: "String"
        }
      },
      otherUrl: {
        serializedName: "otherUrl",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      externalApplyUrl: {
        serializedName: "externalApplyUrl",
        type: {
          name: "String"
        }
      },
      externalApplyMethod: {
        required: true,
        serializedName: "externalApplyMethod",
        constraints: {
          InclusiveMaximum: 3,
          InclusiveMinimum: 0
        },
        type: {
          name: "Number"
        }
      },
      contactPerson: {
        serializedName: "contactPerson",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      externalApplyEmail: {
        serializedName: "externalApplyEmail",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      subIndustry: {
        required: true,
        serializedName: "subIndustry",
        constraints: {
          MaxLength: 300,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      youtubeUrl: {
        serializedName: "youtubeUrl",
        type: {
          name: "String"
        }
      },
      instagramUrl: {
        serializedName: "instagramUrl",
        type: {
          name: "String"
        }
      },
      discountCode: {
        serializedName: "discountCode",
        type: {
          name: "String"
        }
      },
      salesPerson: {
        required: true,
        serializedName: "salesPerson",
        type: {
          name: "String"
        }
      },
      commercialContactName: {
        serializedName: "commercialContactName",
        type: {
          name: "String"
        }
      },
      commercialContact: {
        serializedName: "commercialContact",
        type: {
          name: "String"
        }
      },
      commercialContactNameExtended: {
        serializedName: "commercialContactNameExtended",
        type: {
          name: "String"
        }
      },
      commercialContactExtended: {
        serializedName: "commercialContactExtended",
        type: {
          name: "String"
        }
      },
      commercialVatNumber: {
        serializedName: "commercialVatNumber",
        type: {
          name: "String"
        }
      },
      commercialExternalId: {
        serializedName: "commercialExternalId",
        type: {
          name: "String"
        }
      },
      disablePublishAd: {
        serializedName: "disablePublishAd",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UpdateCompanyViewModel = {
  serializedName: "UpdateCompanyViewModel",
  type: {
    name: "Composite",
    className: "UpdateCompanyViewModel",
    modelProperties: {
      name: {
        required: true,
        serializedName: "name",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      address: {
        required: true,
        serializedName: "address",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      addressExtended: {
        serializedName: "addressExtended",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      zipCode: {
        required: true,
        serializedName: "zipCode",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      city: {
        required: true,
        serializedName: "city",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      stateCounty: {
        serializedName: "stateCounty",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      country: {
        serializedName: "country",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        constraints: {
          MaxLength: 2,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      websiteUrl: {
        serializedName: "websiteUrl",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      vatnumber: {
        required: true,
        serializedName: "vatnumber",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      pnumber: {
        serializedName: "pnumber",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      eannumber: {
        serializedName: "eannumber",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        constraints: {
          MaxLength: 50,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      logoUrl: {
        required: true,
        serializedName: "logoUrl",
        type: {
          name: "String"
        }
      },
      logoPath: {
        required: true,
        serializedName: "logoPath",
        type: {
          name: "String"
        }
      },
      industry: {
        required: true,
        serializedName: "industry",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      numEmployees: {
        serializedName: "numEmployees",
        type: {
          name: "Number"
        }
      },
      facebookUrl: {
        serializedName: "facebookUrl",
        type: {
          name: "String"
        }
      },
      linkedInUrl: {
        serializedName: "linkedInUrl",
        type: {
          name: "String"
        }
      },
      twitterUrl: {
        serializedName: "twitterUrl",
        type: {
          name: "String"
        }
      },
      otherUrl: {
        serializedName: "otherUrl",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      externalApplyUrl: {
        serializedName: "externalApplyUrl",
        type: {
          name: "String"
        }
      },
      externalApplyMethod: {
        required: true,
        serializedName: "externalApplyMethod",
        constraints: {
          InclusiveMaximum: 3,
          InclusiveMinimum: 0
        },
        type: {
          name: "Number"
        }
      },
      contactPerson: {
        serializedName: "contactPerson",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      externalApplyEmail: {
        serializedName: "externalApplyEmail",
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      subIndustry: {
        required: true,
        serializedName: "subIndustry",
        constraints: {
          MaxLength: 300,
          MinLength: 0
        },
        type: {
          name: "String"
        }
      },
      youtubeUrl: {
        serializedName: "youtubeUrl",
        type: {
          name: "String"
        }
      },
      instagramUrl: {
        serializedName: "instagramUrl",
        type: {
          name: "String"
        }
      },
      discountCode: {
        serializedName: "discountCode",
        type: {
          name: "String"
        }
      },
      salesPerson: {
        serializedName: "salesPerson",
        type: {
          name: "String"
        }
      },
      commercialContactName: {
        serializedName: "commercialContactName",
        type: {
          name: "String"
        }
      },
      commercialContact: {
        serializedName: "commercialContact",
        type: {
          name: "String"
        }
      },
      commercialContactNameExtended: {
        serializedName: "commercialContactNameExtended",
        type: {
          name: "String"
        }
      },
      commercialContactExtended: {
        serializedName: "commercialContactExtended",
        type: {
          name: "String"
        }
      },
      commercialVatNumber: {
        serializedName: "commercialVatNumber",
        type: {
          name: "String"
        }
      },
      commercialExternalId: {
        serializedName: "commercialExternalId",
        type: {
          name: "String"
        }
      },
      disablePublishAd: {
        serializedName: "disablePublishAd",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ResetPasswordViewModel = {
  serializedName: "ResetPasswordViewModel",
  type: {
    name: "Composite",
    className: "ResetPasswordViewModel",
    modelProperties: {
      resetPasswordLink: {
        serializedName: "resetPasswordLink",
        type: {
          name: "String"
        }
      },
      cultureCode: {
        serializedName: "cultureCode",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      advertiser: {
        serializedName: "advertiser",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const NewPasswordViewModel = {
  serializedName: "NewPasswordViewModel",
  type: {
    name: "Composite",
    className: "NewPasswordViewModel",
    modelProperties: {
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      },
      resetPasswordToken: {
        serializedName: "resetPasswordToken",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const CreateRoleViewModel = {
  serializedName: "CreateRoleViewModel",
  type: {
    name: "Composite",
    className: "CreateRoleViewModel",
    modelProperties: {
      roleName: {
        serializedName: "roleName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateUserViewModel = {
  serializedName: "CreateUserViewModel",
  type: {
    name: "Composite",
    className: "CreateUserViewModel",
    modelProperties: {
      userName: {
        required: true,
        serializedName: "userName",
        type: {
          name: "String"
        }
      },
      password: {
        required: true,
        serializedName: "password",
        type: {
          name: "String"
        }
      },
      firstName: {
        required: true,
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        required: true,
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      notes: {
        serializedName: "notes",
        type: {
          name: "String"
        }
      },
      is2FA: {
        serializedName: "is2FA",
        type: {
          name: "Boolean"
        }
      },
      deliveryType2FA: {
        serializedName: "deliveryType2FA",
        type: {
          name: "String"
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const UpdateUserViewModel = {
  serializedName: "UpdateUserViewModel",
  type: {
    name: "Composite",
    className: "UpdateUserViewModel",
    modelProperties: {
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      notes: {
        serializedName: "notes",
        type: {
          name: "String"
        }
      },
      is2FA: {
        serializedName: "is2FA",
        type: {
          name: "Boolean"
        }
      },
      deliveryType2FA: {
        serializedName: "deliveryType2FA",
        type: {
          name: "String"
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const AddSiteCreationUserViewModel = {
  serializedName: "AddSiteCreationUserViewModel",
  type: {
    name: "Composite",
    className: "AddSiteCreationUserViewModel",
    modelProperties: {
      userName: {
        required: true,
        serializedName: "userName",
        type: {
          name: "String"
        }
      },
      password: {
        required: true,
        serializedName: "password",
        type: {
          name: "String"
        }
      },
      firstName: {
        required: true,
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        required: true,
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      notes: {
        serializedName: "notes",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ChangePasswordViewModel = {
  serializedName: "ChangePasswordViewModel",
  type: {
    name: "Composite",
    className: "ChangePasswordViewModel",
    modelProperties: {
      oldPassword: {
        serializedName: "oldPassword",
        type: {
          name: "String"
        }
      },
      newPassword: {
        serializedName: "newPassword",
        type: {
          name: "String"
        }
      },
      confirmPassword: {
        serializedName: "confirmPassword",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PostSiteViewModel = {
  serializedName: "PostSiteViewModel",
  type: {
    name: "Composite",
    className: "PostSiteViewModel",
    modelProperties: {
      host: {
        serializedName: "host",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      masterData: {
        serializedName: "masterData",
        type: {
          name: "String"
        }
      },
      cultureCode: {
        serializedName: "cultureCode",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PutSiteViewModel = {
  serializedName: "PutSiteViewModel",
  type: {
    name: "Composite",
    className: "PutSiteViewModel",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      host: {
        serializedName: "host",
        type: {
          name: "String"
        }
      },
      masterData: {
        serializedName: "masterData",
        type: {
          name: "String"
        }
      },
      cultureCode: {
        serializedName: "cultureCode",
        type: {
          name: "String"
        }
      },
      siteType: {
        serializedName: "siteType",
        type: {
          name: "Number"
        }
      },
      metaTitle: {
        serializedName: "metaTitle",
        type: {
          name: "String"
        }
      },
      metaDescription: {
        serializedName: "metaDescription",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InternalSiteCreationRequest = {
  serializedName: "InternalSiteCreationRequest",
  type: {
    name: "Composite",
    className: "InternalSiteCreationRequest",
    modelProperties: {
      siteId: {
        serializedName: "siteId",
        type: {
          name: "Uuid"
        }
      },
      fontName: {
        serializedName: "fontName",
        type: {
          name: "String"
        }
      },
      primaryColor: {
        serializedName: "primaryColor",
        type: {
          name: "String"
        }
      },
      companyId: {
        serializedName: "companyId",
        type: {
          name: "Uuid"
        }
      },
      userId: {
        serializedName: "userId",
        type: {
          name: "Uuid"
        }
      },
      cssFileName: {
        serializedName: "cssFileName",
        type: {
          name: "String"
        }
      },
      logoFileName: {
        serializedName: "logoFileName",
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "String"
        }
      },
      addressExtended: {
        serializedName: "addressExtended",
        type: {
          name: "String"
        }
      },
      zipCode: {
        serializedName: "zipCode",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      countyState: {
        serializedName: "countyState",
        type: {
          name: "String"
        }
      },
      country: {
        serializedName: "country",
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      ofirCRMNumber: {
        serializedName: "ofirCRMNumber",
        type: {
          name: "String"
        }
      },
      pNumber: {
        serializedName: "pNumber",
        type: {
          name: "String"
        }
      },
      vatNumber: {
        serializedName: "vatNumber",
        type: {
          name: "String"
        }
      },
      eanNumber: {
        serializedName: "eanNumber",
        type: {
          name: "String"
        }
      },
      industry: {
        serializedName: "industry",
        type: {
          name: "String"
        }
      },
      subIndustry: {
        serializedName: "subIndustry",
        type: {
          name: "String"
        }
      },
      companyWebsite: {
        serializedName: "companyWebsite",
        type: {
          name: "String"
        }
      },
      companyPhone: {
        serializedName: "companyPhone",
        type: {
          name: "String"
        }
      },
      companyEmail: {
        serializedName: "companyEmail",
        type: {
          name: "String"
        }
      },
      companyFacebookUrl: {
        serializedName: "companyFacebookUrl",
        type: {
          name: "String"
        }
      },
      companyTwitterUrl: {
        serializedName: "companyTwitterUrl",
        type: {
          name: "String"
        }
      },
      companyLinkedInUrl: {
        serializedName: "companyLinkedInUrl",
        type: {
          name: "String"
        }
      },
      companyInstagramUrl: {
        serializedName: "companyInstagramUrl",
        type: {
          name: "String"
        }
      },
      companyYoutubeUrl: {
        serializedName: "companyYoutubeUrl",
        type: {
          name: "String"
        }
      },
      otherUrl: {
        serializedName: "otherUrl",
        type: {
          name: "String"
        }
      },
      userEmail: {
        serializedName: "userEmail",
        type: {
          name: "String"
        }
      },
      userFirstName: {
        serializedName: "userFirstName",
        type: {
          name: "String"
        }
      },
      userLastName: {
        serializedName: "userLastName",
        type: {
          name: "String"
        }
      },
      userPhone: {
        serializedName: "userPhone",
        type: {
          name: "String"
        }
      },
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      },
      salesPerson: {
        serializedName: "salesPerson",
        type: {
          name: "String"
        }
      },
      siteName: {
        serializedName: "siteName",
        type: {
          name: "String"
        }
      },
      siteLanguage: {
        serializedName: "siteLanguage",
        type: {
          name: "String"
        }
      },
      discountCode: {
        serializedName: "discountCode",
        type: {
          name: "String"
        }
      },
      discountCodeNotes: {
        serializedName: "discountCodeNotes",
        type: {
          name: "String"
        }
      },
      siteHost: {
        serializedName: "siteHost",
        type: {
          name: "String"
        }
      },
      urlSchema: {
        serializedName: "urlSchema",
        type: {
          name: "String"
        }
      },
      siteState: {
        serializedName: "siteState",
        type: {
          name: "Number"
        }
      },
      siteType: {
        serializedName: "siteType",
        type: {
          name: "Number"
        }
      },
      industryId: {
        serializedName: "industryId",
        type: {
          name: "Number"
        }
      },
      subIndustryId: {
        serializedName: "subIndustryId",
        type: {
          name: "Number"
        }
      },
      sendDPA: {
        serializedName: "sendDPA",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const SiteCreateResult = {
  serializedName: "SiteCreateResult",
  type: {
    name: "Composite",
    className: "SiteCreateResult",
    modelProperties: {
      siteId: {
        serializedName: "siteId",
        type: {
          name: "String"
        }
      },
      companyId: {
        serializedName: "companyId",
        type: {
          name: "String"
        }
      },
      userId: {
        serializedName: "userId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PersistingCssRequestModel = {
  serializedName: "PersistingCssRequestModel",
  type: {
    name: "Composite",
    className: "PersistingCssRequestModel",
    modelProperties: {
      cssFileName: {
        serializedName: "cssFileName",
        type: {
          name: "String"
        }
      },
      color: {
        serializedName: "color",
        type: {
          name: "String"
        }
      },
      bodyFont: {
        serializedName: "bodyFont",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CssGeneratingResponseModel = {
  serializedName: "CssGeneratingResponseModel",
  type: {
    name: "Composite",
    className: "CssGeneratingResponseModel",
    modelProperties: {
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CssGeneratingRequestModel = {
  serializedName: "CssGeneratingRequestModel",
  type: {
    name: "Composite",
    className: "CssGeneratingRequestModel",
    modelProperties: {
      color: {
        serializedName: "color",
        type: {
          name: "String"
        }
      },
      bodyFont: {
        serializedName: "bodyFont",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ProductItemModelRequest = {
  serializedName: "ProductItemModelRequest",
  type: {
    name: "Composite",
    className: "ProductItemModelRequest",
    modelProperties: {
      productNumber: {
        required: true,
        serializedName: "productNumber",
        type: {
          name: "String"
        }
      },
      productName: {
        required: true,
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      productPrice: {
        serializedName: "productPrice",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const JobAdLocationsModel = {
  serializedName: "JobAdLocationsModel",
  type: {
    name: "Composite",
    className: "JobAdLocationsModel",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      jobAdId: {
        serializedName: "jobAdId",
        type: {
          name: "Uuid"
        }
      },
      locationAddress: {
        serializedName: "locationAddress",
        type: {
          name: "String"
        }
      },
      locationZipCode: {
        serializedName: "locationZipCode",
        type: {
          name: "String"
        }
      },
      locationCity: {
        serializedName: "locationCity",
        type: {
          name: "String"
        }
      },
      locationCountry: {
        serializedName: "locationCountry",
        type: {
          name: "String"
        }
      },
      area: {
        serializedName: "area",
        type: {
          name: "String"
        }
      },
      areaId: {
        serializedName: "areaId",
        type: {
          name: "Number"
        }
      },
      primary: {
        serializedName: "primary",
        type: {
          name: "Boolean"
        }
      },
      useArea: {
        serializedName: "useArea",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ExtendedJobAdModel = {
  serializedName: "ExtendedJobAdModel",
  type: {
    name: "Composite",
    className: "ExtendedJobAdModel",
    modelProperties: {
      regionCode: {
        serializedName: "regionCode",
        type: {
          name: "String"
        }
      },
      customerName: {
        serializedName: "customerName",
        type: {
          name: "String"
        }
      },
      customerRelationshipManagementId: {
        serializedName: "customerRelationshipManagementId",
        type: {
          name: "String"
        }
      },
      companyVatNumber: {
        serializedName: "companyVatNumber",
        type: {
          name: "String"
        }
      },
      fullDescription: {
        serializedName: "fullDescription",
        type: {
          name: "String"
        }
      },
      customerType: {
        serializedName: "customerType",
        type: {
          name: "String"
        }
      },
      logoUrl: {
        serializedName: "logoUrl",
        type: {
          name: "String"
        }
      },
      originalSource: {
        serializedName: "originalSource",
        type: {
          name: "String"
        }
      },
      siteURL: {
        serializedName: "siteURL",
        type: {
          name: "String"
        }
      },
      siteLanguage: {
        serializedName: "siteLanguage",
        type: {
          name: "String"
        }
      },
      couponCode: {
        serializedName: "couponCode",
        type: {
          name: "String"
        }
      },
      username: {
        serializedName: "username",
        type: {
          name: "String"
        }
      },
      userEmail: {
        serializedName: "userEmail",
        type: {
          name: "String"
        }
      },
      oldStatus: {
        serializedName: "oldStatus",
        type: {
          name: "Number"
        }
      },
      applicationMethodLocal: {
        serializedName: "applicationMethodLocal",
        type: {
          name: "Number"
        }
      },
      requestedPackageOverridePrice: {
        serializedName: "requestedPackageOverridePrice",
        type: {
          name: "String"
        }
      },
      requestedPackageOverrideProducts: {
        serializedName: "requestedPackageOverrideProducts",
        type: {
          name: "String"
        }
      },
      orderReference: {
        serializedName: "orderReference",
        type: {
          name: "String"
        }
      },
      publishToOfir: {
        serializedName: "publishToOfir",
        type: {
          name: "Boolean"
        }
      },
      ogImageWidth: {
        serializedName: "ogImageWidth",
        type: {
          name: "Number"
        }
      },
      ogImageHeight: {
        serializedName: "ogImageHeight",
        type: {
          name: "Number"
        }
      },
      authorUserEmail: {
        serializedName: "authorUserEmail",
        type: {
          name: "String"
        }
      },
      authorUserIsSuperAdmin: {
        serializedName: "authorUserIsSuperAdmin",
        type: {
          name: "Boolean"
        }
      },
      updateFlag: {
        serializedName: "updateFlag",
        type: {
          name: "Number"
        }
      },
      backgroundImage: {
        serializedName: "backgroundImage",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      createDate: {
        serializedName: "createDate",
        type: {
          name: "DateTime"
        }
      },
      expiryDate: {
        serializedName: "expiryDate",
        type: {
          name: "DateTime"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      externalID: {
        serializedName: "externalID",
        type: {
          name: "String"
        }
      },
      externalSource: {
        serializedName: "externalSource",
        type: {
          name: "String"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      jobType: {
        serializedName: "jobType",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      salaryFrom: {
        serializedName: "salaryFrom",
        type: {
          name: "Number"
        }
      },
      salaryTo: {
        serializedName: "salaryTo",
        type: {
          name: "Number"
        }
      },
      salaryType: {
        serializedName: "salaryType",
        type: {
          name: "String"
        }
      },
      salaryCurrency: {
        serializedName: "salaryCurrency",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      contactName: {
        serializedName: "contactName",
        type: {
          name: "String"
        }
      },
      contactPhone: {
        serializedName: "contactPhone",
        type: {
          name: "String"
        }
      },
      contactEmail: {
        serializedName: "contactEmail",
        type: {
          name: "String"
        }
      },
      commercialContactName: {
        serializedName: "commercialContactName",
        type: {
          name: "String"
        }
      },
      commercialContact: {
        serializedName: "commercialContact",
        type: {
          name: "String"
        }
      },
      locationAddress: {
        serializedName: "locationAddress",
        type: {
          name: "String"
        }
      },
      locationZipCode: {
        serializedName: "locationZipCode",
        type: {
          name: "String"
        }
      },
      locationCity: {
        serializedName: "locationCity",
        type: {
          name: "String"
        }
      },
      locationCounty: {
        serializedName: "locationCounty",
        type: {
          name: "String"
        }
      },
      locationCountry: {
        serializedName: "locationCountry",
        type: {
          name: "String"
        }
      },
      internalReference: {
        serializedName: "internalReference",
        type: {
          name: "String"
        }
      },
      applicationMethod: {
        serializedName: "applicationMethod",
        type: {
          name: "Sequence",
          element: {
            serializedName: "numberElementType",
            type: {
              name: "Number"
            }
          }
        }
      },
      exportOptions: {
        serializedName: "exportOptions",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      companyID: {
        serializedName: "companyID",
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      companyAddress: {
        serializedName: "companyAddress",
        type: {
          name: "String"
        }
      },
      companyCity: {
        serializedName: "companyCity",
        type: {
          name: "String"
        }
      },
      companyZipCode: {
        serializedName: "companyZipCode",
        type: {
          name: "String"
        }
      },
      companyCounty: {
        serializedName: "companyCounty",
        type: {
          name: "String"
        }
      },
      companyCountry: {
        serializedName: "companyCountry",
        type: {
          name: "String"
        }
      },
      companyEmail: {
        serializedName: "companyEmail",
        type: {
          name: "String"
        }
      },
      companyPhone: {
        serializedName: "companyPhone",
        type: {
          name: "String"
        }
      },
      companyWWW: {
        serializedName: "companyWWW",
        type: {
          name: "String"
        }
      },
      companyContactPerson: {
        serializedName: "companyContactPerson",
        type: {
          name: "String"
        }
      },
      companyCommercialContactName: {
        serializedName: "companyCommercialContactName",
        type: {
          name: "String"
        }
      },
      companyCommercialContact: {
        serializedName: "companyCommercialContact",
        type: {
          name: "String"
        }
      },
      companyCommercialVatNumber: {
        serializedName: "companyCommercialVatNumber",
        type: {
          name: "String"
        }
      },
      companyCommercialExternalId: {
        serializedName: "companyCommercialExternalId",
        type: {
          name: "String"
        }
      },
      siteId: {
        required: true,
        serializedName: "siteId",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Number"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      urlHash: {
        serializedName: "urlHash",
        type: {
          name: "String"
        }
      },
      applicationUrl: {
        serializedName: "applicationUrl",
        type: {
          name: "String"
        }
      },
      companyVATIdentifier: {
        serializedName: "companyVATIdentifier",
        type: {
          name: "String"
        }
      },
      productionUnitIdentifier: {
        serializedName: "productionUnitIdentifier",
        type: {
          name: "String"
        }
      },
      globalLocationNumber: {
        serializedName: "globalLocationNumber",
        type: {
          name: "String"
        }
      },
      requestedPackageId: {
        serializedName: "requestedPackageId",
        type: {
          name: "String"
        }
      },
      requestedPackageName: {
        serializedName: "requestedPackageName",
        type: {
          name: "String"
        }
      },
      requestedPackagePrice: {
        serializedName: "requestedPackagePrice",
        type: {
          name: "String"
        }
      },
      requestedPackageDurationDays: {
        serializedName: "requestedPackageDurationDays",
        type: {
          name: "Number"
        }
      },
      requestedPackageProducts: {
        serializedName: "requestedPackageProducts",
        type: {
          name: "Sequence",
          element: {
            serializedName: "ProductItemModelRequestElementType",
            type: {
              name: "Composite",
              className: "ProductItemModelRequest"
            }
          }
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      activationDate: {
        serializedName: "activationDate",
        type: {
          name: "DateTime"
        }
      },
      correlationId: {
        serializedName: "correlationId",
        type: {
          name: "String"
        }
      },
      pNumber: {
        serializedName: "pNumber",
        type: {
          name: "String"
        }
      },
      statisticsRegisterFeedDisplayCountLink: {
        serializedName: "statisticsRegisterFeedDisplayCountLink",
        type: {
          name: "String"
        }
      },
      statisticsRegisterFeedEntrySearchedCountLink: {
        serializedName: "statisticsRegisterFeedEntrySearchedCountLink",
        type: {
          name: "String"
        }
      },
      statisticsRegisterFeedEntryDisplayCountLink: {
        serializedName: "statisticsRegisterFeedEntryDisplayCountLink",
        type: {
          name: "String"
        }
      },
      statisticsRegisterJobApplicationInitiatedCountLink: {
        serializedName: "statisticsRegisterJobApplicationInitiatedCountLink",
        type: {
          name: "String"
        }
      },
      statisticsRegisterJobApplicationAppliedCountLink: {
        serializedName: "statisticsRegisterJobApplicationAppliedCountLink",
        type: {
          name: "String"
        }
      },
      numberOfVacancies: {
        serializedName: "numberOfVacancies",
        type: {
          name: "Number"
        }
      },
      applyMethod: {
        serializedName: "applyMethod",
        type: {
          name: "Number"
        }
      },
      author: {
        serializedName: "author",
        type: {
          name: "String"
        }
      },
      modifyDate: {
        readOnly: true,
        serializedName: "modifyDate",
        type: {
          name: "DateTime"
        }
      },
      logoId: {
        serializedName: "logoId",
        type: {
          name: "Uuid"
        }
      },
      ogimage: {
        serializedName: "ogimage",
        type: {
          name: "String"
        }
      },
      orderByName: {
        serializedName: "orderByName",
        type: {
          name: "String"
        }
      },
      orderByEmail: {
        serializedName: "orderByEmail",
        type: {
          name: "String"
        }
      },
      anonymousLocation: {
        serializedName: "anonymousLocation",
        type: {
          name: "Boolean"
        }
      },
      alternateTextAnonymousLocation: {
        serializedName: "alternateTextAnonymousLocation",
        type: {
          name: "String"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            serializedName: "JobAdLocationsModelElementType",
            type: {
              name: "Composite",
              className: "JobAdLocationsModel"
            }
          }
        }
      },
      locationString: {
        serializedName: "locationString",
        type: {
          name: "String"
        }
      },
      locationMapUri: {
        serializedName: "locationMapUri",
        type: {
          name: "String"
        }
      },
      isPublished: {
        serializedName: "isPublished",
        type: {
          name: "Boolean"
        }
      },
      useEAN: {
        serializedName: "useEAN",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const JobAdResponce = {
  serializedName: "JobAdResponce",
  type: {
    name: "Composite",
    className: "JobAdResponce",
    modelProperties: {
      siteid: {
        serializedName: "siteid",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const MsgJobAd = {
  serializedName: "MsgJobAd",
  type: {
    name: "Composite",
    className: "MsgJobAd",
    modelProperties: {
      updateFlag: {
        serializedName: "updateFlag",
        type: {
          name: "Number"
        }
      },
      backgroundImage: {
        serializedName: "backgroundImage",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      createDate: {
        serializedName: "createDate",
        type: {
          name: "DateTime"
        }
      },
      expiryDate: {
        serializedName: "expiryDate",
        type: {
          name: "DateTime"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      externalID: {
        serializedName: "externalID",
        type: {
          name: "String"
        }
      },
      externalSource: {
        serializedName: "externalSource",
        type: {
          name: "String"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      jobType: {
        serializedName: "jobType",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      salaryFrom: {
        serializedName: "salaryFrom",
        type: {
          name: "Number"
        }
      },
      salaryTo: {
        serializedName: "salaryTo",
        type: {
          name: "Number"
        }
      },
      salaryType: {
        serializedName: "salaryType",
        type: {
          name: "String"
        }
      },
      salaryCurrency: {
        serializedName: "salaryCurrency",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      contactName: {
        serializedName: "contactName",
        type: {
          name: "String"
        }
      },
      contactPhone: {
        serializedName: "contactPhone",
        type: {
          name: "String"
        }
      },
      contactEmail: {
        serializedName: "contactEmail",
        type: {
          name: "String"
        }
      },
      commercialContactName: {
        serializedName: "commercialContactName",
        type: {
          name: "String"
        }
      },
      commercialContact: {
        serializedName: "commercialContact",
        type: {
          name: "String"
        }
      },
      locationAddress: {
        serializedName: "locationAddress",
        type: {
          name: "String"
        }
      },
      locationZipCode: {
        serializedName: "locationZipCode",
        type: {
          name: "String"
        }
      },
      locationCity: {
        serializedName: "locationCity",
        type: {
          name: "String"
        }
      },
      locationCounty: {
        serializedName: "locationCounty",
        type: {
          name: "String"
        }
      },
      locationCountry: {
        serializedName: "locationCountry",
        type: {
          name: "String"
        }
      },
      internalReference: {
        serializedName: "internalReference",
        type: {
          name: "String"
        }
      },
      applicationMethod: {
        serializedName: "applicationMethod",
        type: {
          name: "Sequence",
          element: {
            serializedName: "numberElementType",
            type: {
              name: "Number"
            }
          }
        }
      },
      exportOptions: {
        serializedName: "exportOptions",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      companyID: {
        serializedName: "companyID",
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      companyAddress: {
        serializedName: "companyAddress",
        type: {
          name: "String"
        }
      },
      companyCity: {
        serializedName: "companyCity",
        type: {
          name: "String"
        }
      },
      companyZipCode: {
        serializedName: "companyZipCode",
        type: {
          name: "String"
        }
      },
      companyCounty: {
        serializedName: "companyCounty",
        type: {
          name: "String"
        }
      },
      companyCountry: {
        serializedName: "companyCountry",
        type: {
          name: "String"
        }
      },
      companyEmail: {
        serializedName: "companyEmail",
        type: {
          name: "String"
        }
      },
      companyPhone: {
        serializedName: "companyPhone",
        type: {
          name: "String"
        }
      },
      companyWWW: {
        serializedName: "companyWWW",
        type: {
          name: "String"
        }
      },
      companyContactPerson: {
        serializedName: "companyContactPerson",
        type: {
          name: "String"
        }
      },
      companyCommercialContactName: {
        serializedName: "companyCommercialContactName",
        type: {
          name: "String"
        }
      },
      companyCommercialContact: {
        serializedName: "companyCommercialContact",
        type: {
          name: "String"
        }
      },
      companyCommercialVatNumber: {
        serializedName: "companyCommercialVatNumber",
        type: {
          name: "String"
        }
      },
      companyCommercialExternalId: {
        serializedName: "companyCommercialExternalId",
        type: {
          name: "String"
        }
      },
      siteId: {
        required: true,
        serializedName: "siteId",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Number"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      urlHash: {
        serializedName: "urlHash",
        type: {
          name: "String"
        }
      },
      applicationUrl: {
        serializedName: "applicationUrl",
        type: {
          name: "String"
        }
      },
      companyVATIdentifier: {
        serializedName: "companyVATIdentifier",
        type: {
          name: "String"
        }
      },
      productionUnitIdentifier: {
        serializedName: "productionUnitIdentifier",
        type: {
          name: "String"
        }
      },
      globalLocationNumber: {
        serializedName: "globalLocationNumber",
        type: {
          name: "String"
        }
      },
      requestedPackageId: {
        serializedName: "requestedPackageId",
        type: {
          name: "String"
        }
      },
      requestedPackageName: {
        serializedName: "requestedPackageName",
        type: {
          name: "String"
        }
      },
      requestedPackagePrice: {
        serializedName: "requestedPackagePrice",
        type: {
          name: "String"
        }
      },
      requestedPackageDurationDays: {
        serializedName: "requestedPackageDurationDays",
        type: {
          name: "Number"
        }
      },
      requestedPackageProducts: {
        serializedName: "requestedPackageProducts",
        type: {
          name: "Sequence",
          element: {
            serializedName: "ProductItemModelRequestElementType",
            type: {
              name: "Composite",
              className: "ProductItemModelRequest"
            }
          }
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      activationDate: {
        serializedName: "activationDate",
        type: {
          name: "DateTime"
        }
      },
      correlationId: {
        serializedName: "correlationId",
        type: {
          name: "String"
        }
      },
      pNumber: {
        serializedName: "pNumber",
        type: {
          name: "String"
        }
      },
      statisticsRegisterFeedDisplayCountLink: {
        serializedName: "statisticsRegisterFeedDisplayCountLink",
        type: {
          name: "String"
        }
      },
      statisticsRegisterFeedEntrySearchedCountLink: {
        serializedName: "statisticsRegisterFeedEntrySearchedCountLink",
        type: {
          name: "String"
        }
      },
      statisticsRegisterFeedEntryDisplayCountLink: {
        serializedName: "statisticsRegisterFeedEntryDisplayCountLink",
        type: {
          name: "String"
        }
      },
      statisticsRegisterJobApplicationInitiatedCountLink: {
        serializedName: "statisticsRegisterJobApplicationInitiatedCountLink",
        type: {
          name: "String"
        }
      },
      statisticsRegisterJobApplicationAppliedCountLink: {
        serializedName: "statisticsRegisterJobApplicationAppliedCountLink",
        type: {
          name: "String"
        }
      },
      numberOfVacancies: {
        serializedName: "numberOfVacancies",
        type: {
          name: "Number"
        }
      },
      applyMethod: {
        serializedName: "applyMethod",
        type: {
          name: "Number"
        }
      },
      author: {
        serializedName: "author",
        type: {
          name: "String"
        }
      },
      modifyDate: {
        readOnly: true,
        serializedName: "modifyDate",
        type: {
          name: "DateTime"
        }
      },
      logoId: {
        serializedName: "logoId",
        type: {
          name: "Uuid"
        }
      },
      ogimage: {
        serializedName: "ogimage",
        type: {
          name: "String"
        }
      },
      orderByName: {
        serializedName: "orderByName",
        type: {
          name: "String"
        }
      },
      orderByEmail: {
        serializedName: "orderByEmail",
        type: {
          name: "String"
        }
      },
      anonymousLocation: {
        serializedName: "anonymousLocation",
        type: {
          name: "Boolean"
        }
      },
      alternateTextAnonymousLocation: {
        serializedName: "alternateTextAnonymousLocation",
        type: {
          name: "String"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            serializedName: "JobAdLocationsModelElementType",
            type: {
              name: "Composite",
              className: "JobAdLocationsModel"
            }
          }
        }
      },
      locationString: {
        serializedName: "locationString",
        type: {
          name: "String"
        }
      },
      locationMapUri: {
        serializedName: "locationMapUri",
        type: {
          name: "String"
        }
      },
      isPublished: {
        serializedName: "isPublished",
        type: {
          name: "Boolean"
        }
      },
      useEAN: {
        serializedName: "useEAN",
        type: {
          name: "Boolean"
        }
      },
      customerName: {
        serializedName: "customerName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SetSuperAdminDeleteStatusResponce = {
  serializedName: "SetSuperAdminDeleteStatusResponce",
  type: {
    name: "Composite",
    className: "SetSuperAdminDeleteStatusResponce",
    modelProperties: {
      siteId: {
        serializedName: "siteId",
        type: {
          name: "String"
        }
      },
      jobAdId: {
        serializedName: "jobAdId",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const UpdateStatusResponce = {
  serializedName: "UpdateStatusResponce",
  type: {
    name: "Composite",
    className: "UpdateStatusResponce",
    modelProperties: {
      siteid: {
        serializedName: "siteid",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Number"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      urlHash: {
        serializedName: "urlHash",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PatchProductModelRequest = {
  serializedName: "PatchProductModelRequest",
  type: {
    name: "Composite",
    className: "PatchProductModelRequest",
    modelProperties: {
      requestedPackageId: {
        serializedName: "requestedPackageId",
        type: {
          name: "String"
        }
      },
      requestedPackageName: {
        serializedName: "requestedPackageName",
        type: {
          name: "String"
        }
      },
      requestedPackagePrice: {
        serializedName: "requestedPackagePrice",
        type: {
          name: "String"
        }
      },
      requestedPackageDurationDays: {
        serializedName: "requestedPackageDurationDays",
        type: {
          name: "Number"
        }
      },
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            serializedName: "ProductItemModelRequestElementType",
            type: {
              name: "Composite",
              className: "ProductItemModelRequest"
            }
          }
        }
      }
    }
  }
};

export const EncryptedData = {
  serializedName: "EncryptedData",
  type: {
    name: "Composite",
    className: "EncryptedData",
    modelProperties: {
      publicKey: {
        serializedName: "publicKey",
        type: {
          name: "Uuid"
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PartnerJobAdResponce = {
  serializedName: "PartnerJobAdResponce",
  type: {
    name: "Composite",
    className: "PartnerJobAdResponce",
    modelProperties: {
      siteid: {
        serializedName: "siteid",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CompanyWithSiteTypeModel = {
  serializedName: "CompanyWithSiteTypeModel",
  type: {
    name: "Composite",
    className: "CompanyWithSiteTypeModel",
    modelProperties: {
      siteType: {
        serializedName: "siteType",
        type: {
          name: "Number"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "String"
        }
      },
      addressExtended: {
        serializedName: "addressExtended",
        type: {
          name: "String"
        }
      },
      zipCode: {
        serializedName: "zipCode",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      stateCounty: {
        serializedName: "stateCounty",
        type: {
          name: "String"
        }
      },
      country: {
        serializedName: "country",
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      commercialContactName: {
        serializedName: "commercialContactName",
        type: {
          name: "String"
        }
      },
      commercialContact: {
        serializedName: "commercialContact",
        type: {
          name: "String"
        }
      },
      commercialContactNameExtended: {
        serializedName: "commercialContactNameExtended",
        type: {
          name: "String"
        }
      },
      commercialContactExtended: {
        serializedName: "commercialContactExtended",
        type: {
          name: "String"
        }
      },
      commercialVatNumber: {
        serializedName: "commercialVatNumber",
        type: {
          name: "String"
        }
      },
      commercialExternalId: {
        serializedName: "commercialExternalId",
        type: {
          name: "String"
        }
      },
      websiteUrl: {
        serializedName: "websiteUrl",
        type: {
          name: "String"
        }
      },
      vatnumber: {
        serializedName: "vatnumber",
        type: {
          name: "String"
        }
      },
      pnumber: {
        serializedName: "pnumber",
        type: {
          name: "String"
        }
      },
      eannumber: {
        serializedName: "eannumber",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      logoUrl: {
        serializedName: "logoUrl",
        type: {
          name: "String"
        }
      },
      logoPath: {
        serializedName: "logoPath",
        type: {
          name: "String"
        }
      },
      industry: {
        serializedName: "industry",
        type: {
          name: "String"
        }
      },
      subIndustry: {
        serializedName: "subIndustry",
        type: {
          name: "String"
        }
      },
      numEmployees: {
        serializedName: "numEmployees",
        type: {
          name: "Number"
        }
      },
      facebookUrl: {
        serializedName: "facebookUrl",
        type: {
          name: "String"
        }
      },
      linkedInUrl: {
        serializedName: "linkedInUrl",
        type: {
          name: "String"
        }
      },
      twitterUrl: {
        serializedName: "twitterUrl",
        type: {
          name: "String"
        }
      },
      otherUrl: {
        serializedName: "otherUrl",
        type: {
          name: "String"
        }
      },
      dateCreated: {
        serializedName: "dateCreated",
        type: {
          name: "DateTime"
        }
      },
      dateModified: {
        serializedName: "dateModified",
        type: {
          name: "DateTime"
        }
      },
      correlationId: {
        serializedName: "correlationId",
        type: {
          name: "Uuid"
        }
      },
      siteId: {
        serializedName: "siteId",
        type: {
          name: "Uuid"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      externalApplyUrl: {
        serializedName: "externalApplyUrl",
        type: {
          name: "String"
        }
      },
      externalApplyMethod: {
        serializedName: "externalApplyMethod",
        type: {
          name: "Number"
        }
      },
      contactPerson: {
        serializedName: "contactPerson",
        type: {
          name: "String"
        }
      },
      externalApplyEmail: {
        serializedName: "externalApplyEmail",
        type: {
          name: "String"
        }
      },
      youtubeUrl: {
        serializedName: "youtubeUrl",
        type: {
          name: "String"
        }
      },
      instagramUrl: {
        serializedName: "instagramUrl",
        type: {
          name: "String"
        }
      },
      disablePublishAd: {
        serializedName: "disablePublishAd",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const SearchParams = {
  serializedName: "SearchParams",
  type: {
    name: "Composite",
    className: "SearchParams",
    modelProperties: {
      searchValue: {
        serializedName: "searchValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const IHaveSiteModel = {
  serializedName: "IHaveSiteModel",
  type: {
    name: "Composite",
    className: "IHaveSiteModel",
    modelProperties: {
      id: {
        readOnly: true,
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        readOnly: true,
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      host: {
        readOnly: true,
        serializedName: "host",
        type: {
          name: "String"
        }
      },
      masterData: {
        readOnly: true,
        serializedName: "masterData",
        type: {
          name: "String"
        }
      },
      cultureCode: {
        readOnly: true,
        serializedName: "cultureCode",
        type: {
          name: "String"
        }
      },
      createdAt: {
        readOnly: true,
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SiteEntity = {
  serializedName: "SiteEntity",
  type: {
    name: "Composite",
    className: "SiteEntity",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      host: {
        serializedName: "host",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      modifiedAt: {
        serializedName: "modifiedAt",
        type: {
          name: "DateTime"
        }
      },
      correlationId: {
        serializedName: "correlationId",
        type: {
          name: "Uuid"
        }
      },
      masterData: {
        serializedName: "masterData",
        type: {
          name: "String"
        }
      },
      cultureCode: {
        serializedName: "cultureCode",
        type: {
          name: "String"
        }
      },
      companyId: {
        serializedName: "companyId",
        type: {
          name: "Uuid"
        }
      },
      siteState: {
        serializedName: "siteState",
        type: {
          name: "Number"
        }
      },
      siteType: {
        serializedName: "siteType",
        type: {
          name: "Number"
        }
      },
      metaTitle: {
        serializedName: "metaTitle",
        type: {
          name: "String"
        }
      },
      metaDescription: {
        serializedName: "metaDescription",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const IHaveEmailSiteModel = {
  serializedName: "IHaveEmailSiteModel",
  type: {
    name: "Composite",
    className: "IHaveEmailSiteModel",
    modelProperties: {
      id: {
        readOnly: true,
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      createdAt: {
        readOnly: true,
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      cultureCode: {
        readOnly: true,
        serializedName: "cultureCode",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const JobAdListItem = {
  serializedName: "JobAdListItem",
  type: {
    name: "Composite",
    className: "JobAdListItem",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      siteID: {
        serializedName: "siteID",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      createDate: {
        serializedName: "createDate",
        type: {
          name: "DateTime"
        }
      },
      expiryDate: {
        serializedName: "expiryDate",
        type: {
          name: "DateTime"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Number"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      activationDate: {
        serializedName: "activationDate",
        type: {
          name: "DateTime"
        }
      },
      jobType: {
        serializedName: "jobType",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      locationCity: {
        serializedName: "locationCity",
        type: {
          name: "String"
        }
      },
      statisticsRegisterFeedEntrySearchedCountLink: {
        serializedName: "statisticsRegisterFeedEntrySearchedCountLink",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const JobAdListItemSearchResult = {
  serializedName: "JobAdListItemSearchResult",
  type: {
    name: "Composite",
    className: "JobAdListItemSearchResult",
    modelProperties: {
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      page: {
        serializedName: "page",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      count: {
        serializedName: "count",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            serializedName: "JobAdListItemElementType",
            type: {
              name: "Composite",
              className: "JobAdListItem"
            }
          }
        }
      }
    }
  }
};

export const ProductItemModel = {
  serializedName: "ProductItemModel",
  type: {
    name: "Composite",
    className: "ProductItemModel",
    modelProperties: {
      productNumber: {
        serializedName: "productNumber",
        type: {
          name: "String"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      productPrice: {
        serializedName: "productPrice",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const JobAdModel = {
  serializedName: "JobAdModel",
  type: {
    name: "Composite",
    className: "JobAdModel",
    modelProperties: {
      backgroundImage: {
        serializedName: "backgroundImage",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      createDate: {
        serializedName: "createDate",
        type: {
          name: "DateTime"
        }
      },
      expiryDate: {
        serializedName: "expiryDate",
        type: {
          name: "DateTime"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      externalID: {
        serializedName: "externalID",
        type: {
          name: "String"
        }
      },
      externalSource: {
        serializedName: "externalSource",
        type: {
          name: "String"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      jobType: {
        serializedName: "jobType",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      salaryFrom: {
        serializedName: "salaryFrom",
        type: {
          name: "Number"
        }
      },
      salaryTo: {
        serializedName: "salaryTo",
        type: {
          name: "Number"
        }
      },
      salaryType: {
        serializedName: "salaryType",
        type: {
          name: "String"
        }
      },
      salaryCurrency: {
        serializedName: "salaryCurrency",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      contactName: {
        serializedName: "contactName",
        type: {
          name: "String"
        }
      },
      contactPhone: {
        serializedName: "contactPhone",
        type: {
          name: "String"
        }
      },
      contactEmail: {
        serializedName: "contactEmail",
        type: {
          name: "String"
        }
      },
      commercialContactName: {
        serializedName: "commercialContactName",
        type: {
          name: "String"
        }
      },
      commercialContact: {
        serializedName: "commercialContact",
        type: {
          name: "String"
        }
      },
      locationAddress: {
        serializedName: "locationAddress",
        type: {
          name: "String"
        }
      },
      locationZipCode: {
        serializedName: "locationZipCode",
        type: {
          name: "String"
        }
      },
      locationCity: {
        serializedName: "locationCity",
        type: {
          name: "String"
        }
      },
      locationCounty: {
        serializedName: "locationCounty",
        type: {
          name: "String"
        }
      },
      locationCountry: {
        serializedName: "locationCountry",
        type: {
          name: "String"
        }
      },
      internalReference: {
        serializedName: "internalReference",
        type: {
          name: "String"
        }
      },
      applicationMethod: {
        serializedName: "applicationMethod",
        type: {
          name: "Sequence",
          element: {
            serializedName: "numberElementType",
            type: {
              name: "Number"
            }
          }
        }
      },
      exportOptions: {
        serializedName: "exportOptions",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "String"
            }
          }
        }
      },
      companyID: {
        serializedName: "companyID",
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      companyAddress: {
        serializedName: "companyAddress",
        type: {
          name: "String"
        }
      },
      companyCity: {
        serializedName: "companyCity",
        type: {
          name: "String"
        }
      },
      companyZipCode: {
        serializedName: "companyZipCode",
        type: {
          name: "String"
        }
      },
      companyCounty: {
        serializedName: "companyCounty",
        type: {
          name: "String"
        }
      },
      companyCountry: {
        serializedName: "companyCountry",
        type: {
          name: "String"
        }
      },
      companyEmail: {
        serializedName: "companyEmail",
        type: {
          name: "String"
        }
      },
      companyPhone: {
        serializedName: "companyPhone",
        type: {
          name: "String"
        }
      },
      companyWWW: {
        serializedName: "companyWWW",
        type: {
          name: "String"
        }
      },
      companyCommercialContactName: {
        serializedName: "companyCommercialContactName",
        type: {
          name: "String"
        }
      },
      companyCommercialContact: {
        serializedName: "companyCommercialContact",
        type: {
          name: "String"
        }
      },
      companyCommercialVatNumber: {
        serializedName: "companyCommercialVatNumber",
        type: {
          name: "String"
        }
      },
      companyCommercialExternalId: {
        serializedName: "companyCommercialExternalId",
        type: {
          name: "String"
        }
      },
      companyContactPerson: {
        serializedName: "companyContactPerson",
        type: {
          name: "String"
        }
      },
      siteId: {
        serializedName: "siteId",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Number"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      urlHash: {
        serializedName: "urlHash",
        type: {
          name: "String"
        }
      },
      applicationUrl: {
        serializedName: "applicationUrl",
        type: {
          name: "String"
        }
      },
      companyVATIdentifier: {
        serializedName: "companyVATIdentifier",
        type: {
          name: "String"
        }
      },
      productionUnitIdentifier: {
        serializedName: "productionUnitIdentifier",
        type: {
          name: "String"
        }
      },
      globalLocationNumber: {
        serializedName: "globalLocationNumber",
        type: {
          name: "String"
        }
      },
      requestedPackageId: {
        serializedName: "requestedPackageId",
        type: {
          name: "String"
        }
      },
      requestedPackageName: {
        serializedName: "requestedPackageName",
        type: {
          name: "String"
        }
      },
      requestedPackagePrice: {
        serializedName: "requestedPackagePrice",
        type: {
          name: "String"
        }
      },
      requestedPackageDurationDays: {
        serializedName: "requestedPackageDurationDays",
        type: {
          name: "Number"
        }
      },
      requestedPackageProducts: {
        serializedName: "requestedPackageProducts",
        type: {
          name: "Sequence",
          element: {
            serializedName: "ProductItemModelElementType",
            type: {
              name: "Composite",
              className: "ProductItemModel"
            }
          }
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      activationDate: {
        serializedName: "activationDate",
        type: {
          name: "DateTime"
        }
      },
      updateFlag: {
        serializedName: "updateFlag",
        type: {
          name: "Number"
        }
      },
      correlationId: {
        serializedName: "correlationId",
        type: {
          name: "String"
        }
      },
      pNumber: {
        serializedName: "pNumber",
        type: {
          name: "String"
        }
      },
      statisticsRegisterFeedDisplayCountLink: {
        serializedName: "statisticsRegisterFeedDisplayCountLink",
        type: {
          name: "String"
        }
      },
      statisticsRegisterFeedEntrySearchedCountLink: {
        serializedName: "statisticsRegisterFeedEntrySearchedCountLink",
        type: {
          name: "String"
        }
      },
      statisticsRegisterFeedEntryDisplayCountLink: {
        serializedName: "statisticsRegisterFeedEntryDisplayCountLink",
        type: {
          name: "String"
        }
      },
      statisticsRegisterJobApplicationInitiatedCountLink: {
        serializedName: "statisticsRegisterJobApplicationInitiatedCountLink",
        type: {
          name: "String"
        }
      },
      statisticsRegisterJobApplicationAppliedCountLink: {
        serializedName: "statisticsRegisterJobApplicationAppliedCountLink",
        type: {
          name: "String"
        }
      },
      candidateCount: {
        serializedName: "candidateCount",
        type: {
          name: "Number"
        }
      },
      numberOfVacancies: {
        serializedName: "numberOfVacancies",
        type: {
          name: "Number"
        }
      },
      applyMethod: {
        serializedName: "applyMethod",
        type: {
          name: "Number"
        }
      },
      author: {
        serializedName: "author",
        type: {
          name: "String"
        }
      },
      modifyDate: {
        serializedName: "modifyDate",
        type: {
          name: "DateTime"
        }
      },
      logoId: {
        serializedName: "logoId",
        type: {
          name: "Uuid"
        }
      },
      ogimage: {
        serializedName: "ogimage",
        type: {
          name: "String"
        }
      },
      orderByName: {
        serializedName: "orderByName",
        type: {
          name: "String"
        }
      },
      orderByEmail: {
        serializedName: "orderByEmail",
        type: {
          name: "String"
        }
      },
      anonymousLocation: {
        serializedName: "anonymousLocation",
        type: {
          name: "Boolean"
        }
      },
      alternateTextAnonymousLocation: {
        serializedName: "alternateTextAnonymousLocation",
        type: {
          name: "String"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            serializedName: "JobAdLocationsModelElementType",
            type: {
              name: "Composite",
              className: "JobAdLocationsModel"
            }
          }
        }
      },
      locationString: {
        serializedName: "locationString",
        type: {
          name: "String"
        }
      },
      locationMapUri: {
        serializedName: "locationMapUri",
        type: {
          name: "String"
        }
      },
      isPublished: {
        serializedName: "isPublished",
        type: {
          name: "Boolean"
        }
      },
      useEAN: {
        serializedName: "useEAN",
        type: {
          name: "Boolean"
        }
      },
      customerName: {
        serializedName: "customerName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const JobAdModelSearchResult = {
  serializedName: "JobAdModelSearchResult",
  type: {
    name: "Composite",
    className: "JobAdModelSearchResult",
    modelProperties: {
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      page: {
        serializedName: "page",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      count: {
        serializedName: "count",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            serializedName: "JobAdModelElementType",
            type: {
              name: "Composite",
              className: "JobAdModel"
            }
          }
        }
      }
    }
  }
};

export const CustomerWebAPIGetTokenOptionalParams = {
  serializedName: "GetTokenOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIGetTokenOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "LoginRequest"
        }
      }
    }
  }
};

export const CustomerWebAPICompaniesPostAsyncOptionalParams = {
  serializedName: "CompaniesPostAsyncOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPICompaniesPostAsyncOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "CreateCompanyViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPICompaniesPutAsyncOptionalParams = {
  serializedName: "CompaniesPutAsyncOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPICompaniesPutAsyncOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "UpdateCompanyViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPIPasswordResetUserPasswordOptionalParams = {
  serializedName: "PasswordResetUserPasswordOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIPasswordResetUserPasswordOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "ResetPasswordViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPIPasswordSetNewPasswordOptionalParams = {
  serializedName: "PasswordSetNewPasswordOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIPasswordSetNewPasswordOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "NewPasswordViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPIRolesCreateRoleOptionalParams = {
  serializedName: "RolesCreateRoleOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIRolesCreateRoleOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "CreateRoleViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPISharedRolesCreateGlobalRoleOptionalParams = {
  serializedName: "SharedRolesCreateGlobalRoleOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPISharedRolesCreateGlobalRoleOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "CreateRoleViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPISharedUsersCreateGlobalUserOptionalParams = {
  serializedName: "SharedUsersCreateGlobalUserOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPISharedUsersCreateGlobalUserOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "CreateUserViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPISharedUsersUpdateGlobalUserOptionalParams = {
  serializedName: "SharedUsersUpdateGlobalUserOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPISharedUsersUpdateGlobalUserOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "UpdateUserViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPIUsersCreateUserOptionalParams = {
  serializedName: "UsersCreateUserOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIUsersCreateUserOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "CreateUserViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPIUsersInitUserForNewSiteOptionalParams = {
  serializedName: "UsersInitUserForNewSiteOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIUsersInitUserForNewSiteOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "AddSiteCreationUserViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPIUsersChangeUserPasswordOptionalParams = {
  serializedName: "UsersChangeUserPasswordOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIUsersChangeUserPasswordOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "ChangePasswordViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPIUsersUpdateUserOptionalParams = {
  serializedName: "UsersUpdateUserOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIUsersUpdateUserOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "UpdateUserViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPIUpdateUserByCustomerToolOptionalParams = {
  serializedName: "UpdateUserByCustomerToolOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIUpdateUserByCustomerToolOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "UpdateUserViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPICreateSiteOptionalParams = {
  serializedName: "CreateSiteOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPICreateSiteOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "PostSiteViewModel"
        }
      }
    }
  }
};

export const CustomerWebAPIUpdateSiteOptionalParams = {
  serializedName: "UpdateSiteOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIUpdateSiteOptionalParams",
    modelProperties: {
      isValidateHost: {
        serializedName: "isValidateHost",
        defaultValue: true,
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CustomerWebAPISitesMergeSiteOptionalParams = {
  serializedName: "SitesMergeSiteOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPISitesMergeSiteOptionalParams",
    modelProperties: {
      targetSiteId: {
        serializedName: "targetSiteId",
        type: {
          name: "Uuid"
        }
      },
      targetCompanyId: {
        serializedName: "targetCompanyId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const CustomerWebAPICssGeneratorGenerateCssAsyncOptionalParams = {
  serializedName: "CssGeneratorGenerateCssAsyncOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPICssGeneratorGenerateCssAsyncOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "CssGeneratingRequestModel"
        }
      }
    }
  }
};

export const CustomerWebAPIJobAdPatchDescription1OptionalParams = {
  serializedName: "JobAdPatchDescription1Options",
  type: {
    name: "Composite",
    className: "CustomerWebAPIJobAdPatchDescription1OptionalParams",
    modelProperties: {
      jobadid: {
        serializedName: "jobadid",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomerWebAPICreatePartnerJobAdOptionalParams = {
  serializedName: "CreatePartnerJobAdOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPICreatePartnerJobAdOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "EncryptedData"
        }
      }
    }
  }
};

export const CustomerWebAPICompaniesGet1OptionalParams = {
  serializedName: "CompaniesGet1Options",
  type: {
    name: "Composite",
    className: "CustomerWebAPICompaniesGet1OptionalParams",
    modelProperties: {
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      page: {
        serializedName: "page",
        defaultValue: 1,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomerWebAPICompaniesFindCompaniesOptionalParams = {
  serializedName: "CompaniesFindCompaniesOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPICompaniesFindCompaniesOptionalParams",
    modelProperties: {
      searchValue: {
        serializedName: "searchValue",
        type: {
          name: "String"
        }
      },
      noCrmId: {
        serializedName: "noCrmId",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CustomerWebAPICompaniesFindCompaniesByVatNumberOptionalParams = {
  serializedName: "CompaniesFindCompaniesByVatNumberOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPICompaniesFindCompaniesByVatNumberOptionalParams",
    modelProperties: {
      emptyCrmId: {
        serializedName: "emptyCrmId",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CustomerWebAPIPartnerCompaniesFindCompanyByVatIdOptionalParams = {
  serializedName: "PartnerCompaniesFindCompanyByVatIdOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIPartnerCompaniesFindCompanyByVatIdOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "EncryptedData"
        }
      }
    }
  }
};

export const CustomerWebAPIPartnerUsersFindBySiteIdOptionalParams = {
  serializedName: "PartnerUsersFindBySiteIdOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIPartnerUsersFindBySiteIdOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "EncryptedData"
        }
      }
    }
  }
};

export const CustomerWebAPIUsersGetUsersAsyncOptionalParams = {
  serializedName: "UsersGetUsersAsyncOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIUsersGetUsersAsyncOptionalParams",
    modelProperties: {
      searchIn: {
        serializedName: "SearchIn",
        type: {
          name: "String"
        }
      },
      searchFor: {
        serializedName: "SearchFor",
        type: {
          name: "String"
        }
      },
      includeRoles: {
        serializedName: "IncludeRoles",
        type: {
          name: "Boolean"
        }
      },
      includePassword: {
        serializedName: "IncludePassword",
        type: {
          name: "Boolean"
        }
      },
      page: {
        serializedName: "Page",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "PageSize",
        type: {
          name: "Number"
        }
      },
      siteId: {
        serializedName: "SiteId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const CustomerWebAPIUsersGetUserAsyncOptionalParams = {
  serializedName: "UsersGetUserAsyncOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIUsersGetUserAsyncOptionalParams",
    modelProperties: {
      includeRoles: {
        serializedName: "IncludeRoles",
        type: {
          name: "Boolean"
        }
      },
      siteId: {
        serializedName: "SiteId",
        type: {
          name: "Uuid"
        }
      },
      includePassword: {
        serializedName: "IncludePassword",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CustomerWebAPIUsersGetUserAsync1OptionalParams = {
  serializedName: "UsersGetUserAsync1Options",
  type: {
    name: "Composite",
    className: "CustomerWebAPIUsersGetUserAsync1OptionalParams",
    modelProperties: {
      includeRoles: {
        serializedName: "IncludeRoles",
        type: {
          name: "Boolean"
        }
      },
      siteId: {
        serializedName: "SiteId",
        type: {
          name: "Uuid"
        }
      },
      includePassword: {
        serializedName: "IncludePassword",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CustomerWebAPIUsersFindUsersOptionalParams = {
  serializedName: "UsersFindUsersOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIUsersFindUsersOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "SearchParams"
        }
      }
    }
  }
};

export const CustomerWebAPIGetSitesOptionalParams = {
  serializedName: "GetSitesOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIGetSitesOptionalParams",
    modelProperties: {
      searchIn: {
        serializedName: "SearchIn",
        type: {
          name: "String"
        }
      },
      searchFor: {
        serializedName: "SearchFor",
        type: {
          name: "String"
        }
      },
      pageSize: {
        serializedName: "PageSize",
        type: {
          name: "Number"
        }
      },
      page: {
        serializedName: "Page",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomerWebAPISitesGetSiteByIdsOptionalParams = {
  serializedName: "SitesGetSiteByIdsOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPISitesGetSiteByIdsOptionalParams",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Sequence",
          element: {
            serializedName: "stringElementType",
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const CustomerWebAPIGetJobsContentListOptionalParams = {
  serializedName: "GetJobsContentListOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIGetJobsContentListOptionalParams",
    modelProperties: {
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      page: {
        serializedName: "page",
        defaultValue: 1,
        type: {
          name: "Number"
        }
      },
      status: {
        serializedName: "status",
        defaultValue: 1,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomerWebAPIGetJobsListOptionalParams = {
  serializedName: "GetJobsListOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIGetJobsListOptionalParams",
    modelProperties: {
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      page: {
        serializedName: "page",
        defaultValue: 1,
        type: {
          name: "Number"
        }
      },
      status: {
        serializedName: "status",
        defaultValue: 1,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomerWebAPIJobAdsGetJAEOptionalParams = {
  serializedName: "JobAdsGetJAEOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIJobAdsGetJAEOptionalParams",
    modelProperties: {
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      page: {
        serializedName: "page",
        defaultValue: 1,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomerWebAPIGetJobAdsFilteredOptionalParams = {
  serializedName: "GetJobAdsFilteredOptions",
  type: {
    name: "Composite",
    className: "CustomerWebAPIGetJobAdsFilteredOptionalParams",
    modelProperties: {
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      page: {
        serializedName: "page",
        defaultValue: 1,
        type: {
          name: "Number"
        }
      },
      status: {
        serializedName: "status",
        defaultValue: 1,
        type: {
          name: "Number"
        }
      },
      sortField: {
        serializedName: "sortField",
        defaultValue: 'createDate',
        type: {
          name: "String"
        }
      },
      sortOrder: {
        serializedName: "sortOrder",
        defaultValue: 'DESC',
        type: {
          name: "String"
        }
      }
    }
  }
};

import * as React from 'react';
import { CardContent, Card, CardActions, Button, TextField, CardHeader } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import './Login.scss';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ApiService } from '../../services/api/apiService';
import { JwtService } from '../../services/jwt/jwtService';
const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { history } = useReactRouter();

    const [validation, setValidation] = useState({
        username: {
            error: false,
            errorText: ''
        },
        password: {
            error: false,
            errorText: ''
        }
    });

    const { t } = useTranslation();
    const login = async () => {
        const errorMessage = t('Wrong username or password');
        try {
            if (!checkforError()) {
                const token = await ApiService.getToken(username, password);
                JwtService.setToken(token.accessToken ? token.accessToken : '', token.expiresIn);
                if (JwtService.hasRole('SuperAdmin')) {
                    history.push('/customer/search');
                } else {
                    setValidation({
                        ...validation,
                        password: { error: true, errorText: errorMessage },
                        username: { error: true, errorText: errorMessage }
                    });
                }
            }
        } catch (error) {
            setValidation({ ...validation, password: { error: true, errorText: errorMessage }, username: { error: true, errorText: errorMessage } });
        }
    };
    const checkforError = () => {
        const p = { ...validation.password, error: !password };
        const u = { ...validation.username, error: !username };
        setValidation({ ...validation, password: p, username: u });
        return p.error || u.error;
    };
    return (
        <div className="login-main">
            <Card classes={{ root: 'login-card' }}>
                <CardHeader title={t('Login')} />
                <CardContent>
                    <TextField
                        error={validation.username.error}
                        helperText={validation.username.errorText}
                        fullWidth={true}
                        label={t('Username')}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        error={validation.password.error}
                        helperText={validation.password.errorText}
                        type="password"
                        fullWidth={true}
                        label={t('Password')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        margin="normal"
                    />
                </CardContent>
                <CardActions>
                    <Button
                        fullWidth={true}
                        size="medium"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            login();
                        }}
                    >
                        {t('Login')}
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
};
export default Login;

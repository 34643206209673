export const customerObjectValidation = {
    name: {
        error: false,
        errorText: '',
    },
    cvr: {
        error: false,
        errorText: '',
    },
    address: {
        error: false,
        errorText: '',
    },
    zipCode: {
        error: false,
        errorText: '',
    },
    city: {
        error: false,
        errorText: '',
    }
}
import * as React from 'react';
import ModalLayout from './ModalLayout';
import { TEMPLATE_TYPES } from '../../redux/reducers/modalsReducer';
import { useMappedState } from 'redux-react-hook';
import { IStore } from '../../redux/reducers';

import PipeDriveActionSelectionModal from './templates/PipeDriveActionSelectionModal/PipeDriveActionSelectionModal';
import PipeDriveOrgSelection from './templates/PipeDriveOrgSelection/PipeDriveOrgSelection';
import PipeDriveSuccessModal from './templates/PipeDriveSuccessModal/PipeDriveSuccessModal';

import './Modal.scss';

export default function Modal() {
    const modalState = useMappedState((s: IStore) => s.modal);
    const templateType = modalState.template;
    const data = modalState.data;

    const [currentTemplate, setCurrentTemplate] = React.useState(null);

    React.useEffect(() => {
        switch (templateType) {
            case TEMPLATE_TYPES.PIPEDRIVE_ACTION_SELECTION: {
                setCurrentTemplate(<PipeDriveActionSelectionModal data={data} />);

                break;
            }
            case TEMPLATE_TYPES.PIPEDRIVE_ORG_SELECTION: {
                setCurrentTemplate(<PipeDriveOrgSelection data={data} />);

                break;
            }
            case TEMPLATE_TYPES.PIPEDRIVE_SUCCESS: {
                setCurrentTemplate(<PipeDriveSuccessModal data={data} />);

                break;
            }
            default: {
                setCurrentTemplate(null);
            }
        }
    }, [templateType]);

    return <ModalLayout isOpen={!!templateType}>{currentTemplate}</ModalLayout>;
}

export const CreateLogo = (FONT_NAME: string, PRIMARY_COLOR: string, title: string): Promise<any> => {
    const getLuminocity = (color) => {
        const c = color.substring(1);
        const rgb = parseInt(c, 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;

        // tslint:disable-next-line:binary-expression-operand-order
        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    };

    const shadeColor = (color, percent) => {
        const f = parseInt(color.slice(1), 16);
        const t = percent < 0 ? 0 : 255;
        const p = percent < 0 ? percent * -1 : percent;
        const R = f >> 16;
        const G = (f >> 8) & 0x00ff;
        const B = f & 0x0000ff;
        return (
            '#' +
            // tslint:disable-next-line:binary-expression-operand-order
            (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1)
        );
    };

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    let fontSize = 100;
    let textDimensions;
    const i = 0;
    const textAsImage = new Image();
    const font = FONT_NAME;
    const bgColor = PRIMARY_COLOR;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const grd = ctx.createLinearGradient(canvas.width / 2, 0, canvas.width / 2, canvas.height);
    grd.addColorStop(0, bgColor);
    grd.addColorStop(1, shadeColor(bgColor, 0.3));
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    if (getLuminocity(bgColor) > 220) {
        ctx.fillStyle = '#000000';
    } else {
        ctx.fillStyle = '#ffffff';
    }
    ctx.textBaseline = 'middle';
    ctx.font = fontSize + 'px ' + font;
    textDimensions = ctx.measureText(title);
    fontSize = 100;
    while (textDimensions.width >= canvas.width - 20) {
        fontSize--;
        ctx.font = fontSize + 'px ' + font;
        textDimensions = ctx.measureText(title);
    }
    ctx.fillText(title, 10, canvas.height / 2 - 1);
    textAsImage.src = canvas.toDataURL();
    // $('#canvasImage').append(textAsImage);
    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            // blob.lastModifiedDate = new Date();
            // blob.name = 'LogoFromText.png';
            resolve(blob);
        });
    });
};

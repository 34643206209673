import * as metadataReducer from './reducers/metadataReducer';
import { useDispatch } from 'redux-react-hook';

export const AsyncHandler = async (callback: () => Promise<void>, dispatch: any, errorProperty: string = '', errorCallback?: (e: any) => void) => {
    try {
        dispatch(metadataReducer.setIsAsync(true, errorProperty));
        //dispatch(metadataReducer.loading(true, errorProperty));
        await callback();
        dispatch(metadataReducer.setIsAsync(false));
      //dispatch(metadataReducer.loading(false));
        if (errorProperty) {
            dispatch(metadataReducer.updateErrors(errorProperty, false));
        }
    } catch (error) {
        if (errorProperty) {
            dispatch(metadataReducer.updateErrors(errorProperty, true));
        }
        dispatch(metadataReducer.setIsAsync(false));
        //dispatch(metadataReducer.loading(false));
        if (errorCallback) {
            errorCallback(error);
        }
        throw error;
    }
};

import * as React from 'react';
import { Dialog, IconButton, Button } from '@material-ui/core';
import { hideModal } from '../../redux/reducers/modalsReducer';
import { useDispatch } from 'redux-react-hook';

export default function ModalLayout({ children, isOpen }: { children: React.ReactNode; isOpen: boolean }) {
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(hideModal());
    };

    return (
        <Dialog open={isOpen} onBackdropClick={closeModal}>
            <div className="modal-close-wrap">
                <Button type="button" color="primary" onClick={closeModal}>
                    Close
                </Button>
            </div>
            <div className="modal">{children}</div>
        </Dialog>
    );
}

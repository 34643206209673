import * as React from 'react';
import "./Search.scss";
import {InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export const Search = (props: {onSearch: (e:any) => void}) => {
    return (
        <div className={"search"}>
            <div className={"search__container"}>
                <div className={"search__icon"}>
                    <SearchIcon />
                </div>
                <InputBase className="search__input" placeholder="Search…" onChange={props.onSearch} />
            </div>
        </div>
    );
}

/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "ms-rest-js";


/**
 * @interface
 * An interface representing LoginRequest.
 */
export interface LoginRequest {
  /**
   * @member {string} [password]
   */
  password?: string;
  /**
   * @member {string} [userName]
   */
  userName?: string;
  /**
   * @member {string} [siteId]
   */
  siteId?: string;
  /**
   * @member {string} [otpToken]
   */
  otpToken?: string;
}

/**
 * @interface
 * An interface representing IHaveUserResponse.
 */
export interface IHaveUserResponse {
  /**
   * @member {string} [userId]
   */
  readonly userId?: string;
  /**
   * @member {string} [userName]
   */
  readonly userName?: string;
  /**
   * @member {string} [firstName]
   */
  readonly firstName?: string;
  /**
   * @member {string} [lastName]
   */
  readonly lastName?: string;
  /**
   * @member {string} [phoneNumber]
   */
  readonly phoneNumber?: string;
}

/**
 * @interface
 * An interface representing TokenResponse.
 */
export interface TokenResponse {
  /**
   * @member {string} [accessToken]
   */
  readonly accessToken?: string;
  /**
   * @member {number} [expiresIn]
   */
  readonly expiresIn?: number;
  /**
   * @member {IHaveUserResponse} [user]
   */
  user?: IHaveUserResponse;
}

/**
 * @interface
 * An interface representing CustomerModel.
 */
export interface CustomerModel {
  /**
   * @member {string} [id]
   */
  id?: string;
  /**
   * @member {number} [customerId]
   */
  customerId?: number;
  /**
   * @member {string} [name]
   */
  name?: string;
  /**
   * @member {string} [cvr]
   */
  cvr?: string;
  /**
   * @member {string} [ean]
   */
  ean?: string;
  /**
   * @member {string} [pNumber]
   */
  pNumber?: string;
  /**
   * @member {string} [address]
   */
  address?: string;
  /**
   * @member {string} [address2]
   */
  address2?: string;
  /**
   * @member {string} [city]
   */
  city?: string;
  /**
   * @member {string} [zipCode]
   */
  zipCode?: string;
  /**
   * @member {string} [country]
   */
  country?: string;
  /**
   * @member {string} [webAddress]
   */
  webAddress?: string;
  /**
   * @member {string} [email]
   */
  email?: string;
  /**
   * @member {string} [phone1]
   */
  phone1?: string;
  /**
   * @member {string} [phone2]
   */
  phone2?: string;
  /**
   * @member {string} [phone3]
   */
  phone3?: string;
  /**
   * @member {string} [industryCode]
   */
  industryCode?: string;
  /**
   * @member {Date} [created]
   */
  created?: Date;
  /**
   * @member {Date} [modified]
   */
  modified?: Date;
  /**
   * @member {string} [modifiedBy]
   */
  modifiedBy?: string;
  /**
   * @member {string} [createdBy]
   */
  createdBy?: string;
  /**
   * @member {boolean} [isDeleted]
   */
  isDeleted?: boolean;
  /**
   * @member {string} [packageProperty]
   */
  packageProperty?: string;
  /**
   * @member {boolean} [isHq]
   */
  isHq?: boolean;
  /**
   * @member {string} [deleteReason]
   */
  deleteReason?: string;
  /**
   * @member {string} [correlationId]
   */
  correlationId?: string;
}

/**
 * @interface
 * An interface representing DeleteCustomerMessage.
 */
export interface DeleteCustomerMessage {
  /**
   * @member {string} [id]
   */
  id?: string;
  /**
   * @member {string} [deleteReason]
   */
  deleteReason?: string;
}

/**
 * @interface
 * An interface representing CreateCompanyViewModel.
 */
export interface CreateCompanyViewModel {
  /**
   * @member {string} name
   */
  name: string;
  /**
   * @member {string} address
   */
  address: string;
  /**
   * @member {string} [addressExtended]
   */
  addressExtended?: string;
  /**
   * @member {string} zipCode
   */
  zipCode: string;
  /**
   * @member {string} city
   */
  city: string;
  /**
   * @member {string} [stateCounty]
   */
  stateCounty?: string;
  /**
   * @member {string} [country]
   */
  country?: string;
  /**
   * @member {string} [countryCode]
   */
  countryCode?: string;
  /**
   * @member {string} [email]
   */
  email?: string;
  /**
   * @member {string} [websiteUrl]
   */
  websiteUrl?: string;
  /**
   * @member {string} vatnumber
   */
  vatnumber: string;
  /**
   * @member {string} [pnumber]
   */
  pnumber?: string;
  /**
   * @member {string} [eannumber]
   */
  eannumber?: string;
  /**
   * @member {string} [phone]
   */
  phone?: string;
  /**
   * @member {string} logoUrl
   */
  logoUrl: string;
  /**
   * @member {string} logoPath
   */
  logoPath: string;
  /**
   * @member {string} industry
   */
  industry: string;
  /**
   * @member {number} [numEmployees]
   */
  numEmployees?: number;
  /**
   * @member {string} [facebookUrl]
   */
  facebookUrl?: string;
  /**
   * @member {string} [linkedInUrl]
   */
  linkedInUrl?: string;
  /**
   * @member {string} [twitterUrl]
   */
  twitterUrl?: string;
  /**
   * @member {string} [otherUrl]
   */
  otherUrl?: string;
  /**
   * @member {string} [externalId]
   */
  externalId?: string;
  /**
   * @member {string} [displayName]
   */
  displayName?: string;
  /**
   * @member {string} [externalApplyUrl]
   */
  externalApplyUrl?: string;
  /**
   * @member {number} externalApplyMethod
   */
  externalApplyMethod: number;
  /**
   * @member {string} [contactPerson]
   */
  contactPerson?: string;
  /**
   * @member {string} [externalApplyEmail]
   */
  externalApplyEmail?: string;
  /**
   * @member {string} subIndustry
   */
  subIndustry: string;
  /**
   * @member {string} [youtubeUrl]
   */
  youtubeUrl?: string;
  /**
   * @member {string} [instagramUrl]
   */
  instagramUrl?: string;
  /**
   * @member {string} [discountCode]
   */
  discountCode?: string;
  /**
   * @member {string} salesPerson
   */
  salesPerson: string;
  /**
   * @member {string} [commercialContactName]
   */
  commercialContactName?: string;
  /**
   * @member {string} [commercialContact]
   */
  commercialContact?: string;
  /**
   * @member {string} [commercialContactNameExtended]
   */
  commercialContactNameExtended?: string;
  /**
   * @member {string} [commercialContactExtended]
   */
  commercialContactExtended?: string;
  /**
   * @member {string} [commercialVatNumber]
   */
  commercialVatNumber?: string;
  /**
   * @member {string} [commercialExternalId]
   */
  commercialExternalId?: string;
  /**
   * @member {boolean} [disablePublishAd]
   */
  disablePublishAd?: boolean;
}

/**
 * @interface
 * An interface representing UpdateCompanyViewModel.
 */
export interface UpdateCompanyViewModel {
  /**
   * @member {string} name
   */
  name: string;
  /**
   * @member {string} address
   */
  address: string;
  /**
   * @member {string} [addressExtended]
   */
  addressExtended?: string;
  /**
   * @member {string} zipCode
   */
  zipCode: string;
  /**
   * @member {string} city
   */
  city: string;
  /**
   * @member {string} [stateCounty]
   */
  stateCounty?: string;
  /**
   * @member {string} [country]
   */
  country?: string;
  /**
   * @member {string} [countryCode]
   */
  countryCode?: string;
  /**
   * @member {string} [email]
   */
  email?: string;
  /**
   * @member {string} [websiteUrl]
   */
  websiteUrl?: string;
  /**
   * @member {string} vatnumber
   */
  vatnumber: string;
  /**
   * @member {string} [pnumber]
   */
  pnumber?: string;
  /**
   * @member {string} [eannumber]
   */
  eannumber?: string;
  /**
   * @member {string} [phone]
   */
  phone?: string;
  /**
   * @member {string} logoUrl
   */
  logoUrl: string;
  /**
   * @member {string} logoPath
   */
  logoPath: string;
  /**
   * @member {string} industry
   */
  industry: string;
  /**
   * @member {number} [numEmployees]
   */
  numEmployees?: number;
  /**
   * @member {string} [facebookUrl]
   */
  facebookUrl?: string;
  /**
   * @member {string} [linkedInUrl]
   */
  linkedInUrl?: string;
  /**
   * @member {string} [twitterUrl]
   */
  twitterUrl?: string;
  /**
   * @member {string} [otherUrl]
   */
  otherUrl?: string;
  /**
   * @member {string} [externalId]
   */
  externalId?: string;
  /**
   * @member {string} [displayName]
   */
  displayName?: string;
  /**
   * @member {string} [externalApplyUrl]
   */
  externalApplyUrl?: string;
  /**
   * @member {number} externalApplyMethod
   */
  externalApplyMethod: number;
  /**
   * @member {string} [contactPerson]
   */
  contactPerson?: string;
  /**
   * @member {string} [externalApplyEmail]
   */
  externalApplyEmail?: string;
  /**
   * @member {string} subIndustry
   */
  subIndustry: string;
  /**
   * @member {string} [youtubeUrl]
   */
  youtubeUrl?: string;
  /**
   * @member {string} [instagramUrl]
   */
  instagramUrl?: string;
  /**
   * @member {string} [discountCode]
   */
  discountCode?: string;
  /**
   * @member {string} [salesPerson]
   */
  salesPerson?: string;
  /**
   * @member {string} [commercialContactName]
   */
  commercialContactName?: string;
  /**
   * @member {string} [commercialContact]
   */
  commercialContact?: string;
  /**
   * @member {string} [commercialContactNameExtended]
   */
  commercialContactNameExtended?: string;
  /**
   * @member {string} [commercialContactExtended]
   */
  commercialContactExtended?: string;
  /**
   * @member {string} [commercialVatNumber]
   */
  commercialVatNumber?: string;
  /**
   * @member {string} [commercialExternalId]
   */
  commercialExternalId?: string;
  /**
   * @member {boolean} [disablePublishAd]
   */
  disablePublishAd?: boolean;
}

/**
 * @interface
 * An interface representing ResetPasswordViewModel.
 */
export interface ResetPasswordViewModel {
  /**
   * @member {string} [resetPasswordLink]
   */
  resetPasswordLink?: string;
  /**
   * @member {string} [cultureCode]
   */
  cultureCode?: string;
  /**
   * @member {string} [subject]
   */
  subject?: string;
  /**
   * @member {boolean} [advertiser]
   */
  advertiser?: boolean;
}

/**
 * @interface
 * An interface representing NewPasswordViewModel.
 */
export interface NewPasswordViewModel {
  /**
   * @member {string} [password]
   */
  password?: string;
  /**
   * @member {string} [resetPasswordToken]
   */
  resetPasswordToken?: string;
}

/**
 * @interface
 * An interface representing CreateRoleViewModel.
 */
export interface CreateRoleViewModel {
  /**
   * @member {string} [roleName]
   */
  roleName?: string;
}

/**
 * @interface
 * An interface representing CreateUserViewModel.
 */
export interface CreateUserViewModel {
  /**
   * @member {string} userName
   */
  userName: string;
  /**
   * @member {string} password
   */
  password: string;
  /**
   * @member {string} firstName
   */
  firstName: string;
  /**
   * @member {string} lastName
   */
  lastName: string;
  /**
   * @member {string} [phoneNumber]
   */
  phoneNumber?: string;
  /**
   * @member {string} [notes]
   */
  notes?: string;
  /**
   * @member {boolean} [is2FA]
   */
  is2FA?: boolean;
  /**
   * @member {string} [deliveryType2FA]
   */
  deliveryType2FA?: string;
  /**
   * @member {string[]} [roles]
   */
  roles?: string[];
}

/**
 * @interface
 * An interface representing UpdateUserViewModel.
 */
export interface UpdateUserViewModel {
  /**
   * @member {string} [firstName]
   */
  firstName?: string;
  /**
   * @member {string} [lastName]
   */
  lastName?: string;
  /**
   * @member {string} [phoneNumber]
   */
  phoneNumber?: string;
  /**
   * @member {string} [notes]
   */
  notes?: string;
  /**
   * @member {boolean} [is2FA]
   */
  is2FA?: boolean;
  /**
   * @member {string} [deliveryType2FA]
   */
  deliveryType2FA?: string;
  /**
   * @member {string[]} [roles]
   */
  roles?: string[];
}

/**
 * @interface
 * An interface representing AddSiteCreationUserViewModel.
 */
export interface AddSiteCreationUserViewModel {
  /**
   * @member {string} userName
   */
  userName: string;
  /**
   * @member {string} password
   */
  password: string;
  /**
   * @member {string} firstName
   */
  firstName: string;
  /**
   * @member {string} lastName
   */
  lastName: string;
  /**
   * @member {string} [phoneNumber]
   */
  phoneNumber?: string;
  /**
   * @member {string} [notes]
   */
  notes?: string;
}

/**
 * @interface
 * An interface representing ChangePasswordViewModel.
 */
export interface ChangePasswordViewModel {
  /**
   * @member {string} [oldPassword]
   */
  oldPassword?: string;
  /**
   * @member {string} [newPassword]
   */
  newPassword?: string;
  /**
   * @member {string} [confirmPassword]
   */
  confirmPassword?: string;
}

/**
 * @interface
 * An interface representing PostSiteViewModel.
 */
export interface PostSiteViewModel {
  /**
   * @member {string} [host]
   */
  host?: string;
  /**
   * @member {string} [name]
   */
  name?: string;
  /**
   * @member {string} [masterData]
   */
  masterData?: string;
  /**
   * @member {string} [cultureCode]
   */
  cultureCode?: string;
}

/**
 * @interface
 * An interface representing PutSiteViewModel.
 */
export interface PutSiteViewModel {
  /**
   * @member {string} [name]
   */
  name?: string;
  /**
   * @member {string} [host]
   */
  host?: string;
  /**
   * @member {string} [masterData]
   */
  masterData?: string;
  /**
   * @member {string} [cultureCode]
   */
  cultureCode?: string;
  /**
   * @member {number} [siteType]
   */
  siteType?: number;
  /**
   * @member {string} [metaTitle]
   */
  metaTitle?: string;
  /**
   * @member {string} [metaDescription]
   */
  metaDescription?: string;
}

/**
 * @interface
 * An interface representing InternalSiteCreationRequest.
 */
export interface InternalSiteCreationRequest {
  /**
   * @member {string} [siteId]
   */
  siteId?: string;
  /**
   * @member {string} [fontName]
   */
  fontName?: string;
  /**
   * @member {string} [primaryColor]
   */
  primaryColor?: string;
  /**
   * @member {string} [companyId]
   */
  companyId?: string;
  /**
   * @member {string} [userId]
   */
  userId?: string;
  /**
   * @member {string} [cssFileName]
   */
  cssFileName?: string;
  /**
   * @member {string} [logoFileName]
   */
  logoFileName?: string;
  /**
   * @member {string} [companyName]
   */
  companyName?: string;
  /**
   * @member {string} [address]
   */
  address?: string;
  /**
   * @member {string} [addressExtended]
   */
  addressExtended?: string;
  /**
   * @member {string} [zipCode]
   */
  zipCode?: string;
  /**
   * @member {string} [city]
   */
  city?: string;
  /**
   * @member {string} [countyState]
   */
  countyState?: string;
  /**
   * @member {string} [country]
   */
  country?: string;
  /**
   * @member {string} [countryCode]
   */
  countryCode?: string;
  /**
   * @member {string} [ofirCRMNumber]
   */
  ofirCRMNumber?: string;
  /**
   * @member {string} [pNumber]
   */
  pNumber?: string;
  /**
   * @member {string} [vatNumber]
   */
  vatNumber?: string;
  /**
   * @member {string} [eanNumber]
   */
  eanNumber?: string;
  /**
   * @member {string} [industry]
   */
  industry?: string;
  /**
   * @member {string} [subIndustry]
   */
  subIndustry?: string;
  /**
   * @member {string} [companyWebsite]
   */
  companyWebsite?: string;
  /**
   * @member {string} [companyPhone]
   */
  companyPhone?: string;
  /**
   * @member {string} [companyEmail]
   */
  companyEmail?: string;
  /**
   * @member {string} [companyFacebookUrl]
   */
  companyFacebookUrl?: string;
  /**
   * @member {string} [companyTwitterUrl]
   */
  companyTwitterUrl?: string;
  /**
   * @member {string} [companyLinkedInUrl]
   */
  companyLinkedInUrl?: string;
  /**
   * @member {string} [companyInstagramUrl]
   */
  companyInstagramUrl?: string;
  /**
   * @member {string} [companyYoutubeUrl]
   */
  companyYoutubeUrl?: string;
  /**
   * @member {string} [otherUrl]
   */
  otherUrl?: string;
  /**
   * @member {string} [userEmail]
   */
  userEmail?: string;
  /**
   * @member {string} [userFirstName]
   */
  userFirstName?: string;
  /**
   * @member {string} [userLastName]
   */
  userLastName?: string;
  /**
   * @member {string} [userPhone]
   */
  userPhone?: string;
  /**
   * @member {string} [password]
   */
  password?: string;
  /**
   * @member {string} [salesPerson]
   */
  salesPerson?: string;
  /**
   * @member {string} [siteName]
   */
  siteName?: string;
  /**
   * @member {string} [siteLanguage]
   */
  siteLanguage?: string;
  /**
   * @member {string} [discountCode]
   */
  discountCode?: string;
  /**
   * @member {string} [discountCodeNotes]
   */
  discountCodeNotes?: string;
  /**
   * @member {string} [siteHost]
   */
  siteHost?: string;
  /**
   * @member {string} [urlSchema]
   */
  urlSchema?: string;
  /**
   * @member {number} [siteState]
   */
  siteState?: number;
  /**
   * @member {number} [siteType]
   */
  siteType?: number;
  /**
   * @member {number} [industryId]
   */
  industryId?: number;
  /**
   * @member {number} [subIndustryId]
   */
  subIndustryId?: number;
  /**
   * @member {boolean} [sendDPA]
   */
  sendDPA?: boolean;
}

/**
 * @interface
 * An interface representing SiteCreateResult.
 */
export interface SiteCreateResult {
  /**
   * @member {string} [siteId]
   */
  siteId?: string;
  /**
   * @member {string} [companyId]
   */
  companyId?: string;
  /**
   * @member {string} [userId]
   */
  userId?: string;
}

/**
 * @interface
 * An interface representing PersistingCssRequestModel.
 */
export interface PersistingCssRequestModel {
  /**
   * @member {string} [cssFileName]
   */
  cssFileName?: string;
  /**
   * @member {string} [color]
   */
  color?: string;
  /**
   * @member {string} [bodyFont]
   */
  bodyFont?: string;
}

/**
 * @interface
 * An interface representing CssGeneratingResponseModel.
 */
export interface CssGeneratingResponseModel {
  /**
   * @member {string} [fileName]
   */
  fileName?: string;
}

/**
 * @interface
 * An interface representing CssGeneratingRequestModel.
 */
export interface CssGeneratingRequestModel {
  /**
   * @member {string} [color]
   */
  color?: string;
  /**
   * @member {string} [bodyFont]
   */
  bodyFont?: string;
}

/**
 * @interface
 * An interface representing ProductItemModelRequest.
 */
export interface ProductItemModelRequest {
  /**
   * @member {string} productNumber
   */
  productNumber: string;
  /**
   * @member {string} productName
   */
  productName: string;
  /**
   * @member {string} [productPrice]
   */
  productPrice?: string;
}

/**
 * @interface
 * An interface representing JobAdLocationsModel.
 */
export interface JobAdLocationsModel {
  /**
   * @member {string} [id]
   */
  id?: string;
  /**
   * @member {string} [jobAdId]
   */
  jobAdId?: string;
  /**
   * @member {string} [locationAddress]
   */
  locationAddress?: string;
  /**
   * @member {string} [locationZipCode]
   */
  locationZipCode?: string;
  /**
   * @member {string} [locationCity]
   */
  locationCity?: string;
  /**
   * @member {string} [locationCountry]
   */
  locationCountry?: string;
  /**
   * @member {string} [area]
   */
  area?: string;
  /**
   * @member {number} [areaId]
   */
  areaId?: number;
  /**
   * @member {boolean} [primary]
   */
  primary?: boolean;
  /**
   * @member {boolean} [useArea]
   */
  useArea?: boolean;
}

/**
 * @interface
 * An interface representing ExtendedJobAdModel.
 */
export interface ExtendedJobAdModel {
  /**
   * @member {string} [regionCode]
   */
  regionCode?: string;
  /**
   * @member {string} [customerName]
   */
  customerName?: string;
  /**
   * @member {string} [customerRelationshipManagementId]
   */
  customerRelationshipManagementId?: string;
  /**
   * @member {string} [companyVatNumber]
   */
  companyVatNumber?: string;
  /**
   * @member {string} [fullDescription]
   */
  fullDescription?: string;
  /**
   * @member {string} [customerType]
   */
  customerType?: string;
  /**
   * @member {string} [logoUrl]
   */
  logoUrl?: string;
  /**
   * @member {string} [originalSource]
   */
  originalSource?: string;
  /**
   * @member {string} [siteURL]
   */
  siteURL?: string;
  /**
   * @member {string} [siteLanguage]
   */
  siteLanguage?: string;
  /**
   * @member {string} [couponCode]
   */
  couponCode?: string;
  /**
   * @member {string} [username]
   */
  username?: string;
  /**
   * @member {string} [userEmail]
   */
  userEmail?: string;
  /**
   * @member {number} [oldStatus]
   */
  oldStatus?: number;
  /**
   * @member {number} [applicationMethodLocal]
   */
  applicationMethodLocal?: number;
  /**
   * @member {string} [requestedPackageOverridePrice]
   */
  requestedPackageOverridePrice?: string;
  /**
   * @member {string} [requestedPackageOverrideProducts]
   */
  requestedPackageOverrideProducts?: string;
  /**
   * @member {string} [orderReference]
   */
  orderReference?: string;
  /**
   * @member {boolean} [publishToOfir]
   */
  publishToOfir?: boolean;
  /**
   * @member {number} [ogImageWidth]
   */
  ogImageWidth?: number;
  /**
   * @member {number} [ogImageHeight]
   */
  ogImageHeight?: number;
  /**
   * @member {string} [authorUserEmail]
   */
  authorUserEmail?: string;
  /**
   * @member {boolean} [authorUserIsSuperAdmin]
   */
  authorUserIsSuperAdmin?: boolean;
  /**
   * @member {number} [updateFlag]
   */
  updateFlag?: number;
  /**
   * @member {string} [backgroundImage]
   */
  backgroundImage?: string;
  /**
   * @member {string} [title]
   */
  title?: string;
  /**
   * @member {Date} [createDate]
   */
  createDate?: Date;
  /**
   * @member {Date} [expiryDate]
   */
  expiryDate?: Date;
  /**
   * @member {string} [id]
   */
  id?: string;
  /**
   * @member {string} [externalID]
   */
  externalID?: string;
  /**
   * @member {string} [externalSource]
   */
  externalSource?: string;
  /**
   * @member {string[]} [category]
   */
  category?: string[];
  /**
   * @member {string[]} [jobType]
   */
  jobType?: string[];
  /**
   * @member {string[]} [position]
   */
  position?: string[];
  /**
   * @member {string[]} [condition]
   */
  condition?: string[];
  /**
   * @member {number} [salaryFrom]
   */
  salaryFrom?: number;
  /**
   * @member {number} [salaryTo]
   */
  salaryTo?: number;
  /**
   * @member {string} [salaryType]
   */
  salaryType?: string;
  /**
   * @member {string} [salaryCurrency]
   */
  salaryCurrency?: string;
  /**
   * @member {string} [description]
   */
  description?: string;
  /**
   * @member {string} [contactName]
   */
  contactName?: string;
  /**
   * @member {string} [contactPhone]
   */
  contactPhone?: string;
  /**
   * @member {string} [contactEmail]
   */
  contactEmail?: string;
  /**
   * @member {string} [commercialContactName]
   */
  commercialContactName?: string;
  /**
   * @member {string} [commercialContact]
   */
  commercialContact?: string;
  /**
   * @member {string} [locationAddress]
   */
  locationAddress?: string;
  /**
   * @member {string} [locationZipCode]
   */
  locationZipCode?: string;
  /**
   * @member {string} [locationCity]
   */
  locationCity?: string;
  /**
   * @member {string} [locationCounty]
   */
  locationCounty?: string;
  /**
   * @member {string} [locationCountry]
   */
  locationCountry?: string;
  /**
   * @member {string} [internalReference]
   */
  internalReference?: string;
  /**
   * @member {number[]} [applicationMethod]
   */
  applicationMethod?: number[];
  /**
   * @member {string[]} [exportOptions]
   */
  exportOptions?: string[];
  /**
   * @member {string} [companyID]
   */
  companyID?: string;
  /**
   * @member {string} [companyName]
   */
  companyName?: string;
  /**
   * @member {string} [companyAddress]
   */
  companyAddress?: string;
  /**
   * @member {string} [companyCity]
   */
  companyCity?: string;
  /**
   * @member {string} [companyZipCode]
   */
  companyZipCode?: string;
  /**
   * @member {string} [companyCounty]
   */
  companyCounty?: string;
  /**
   * @member {string} [companyCountry]
   */
  companyCountry?: string;
  /**
   * @member {string} [companyEmail]
   */
  companyEmail?: string;
  /**
   * @member {string} [companyPhone]
   */
  companyPhone?: string;
  /**
   * @member {string} [companyWWW]
   */
  companyWWW?: string;
  /**
   * @member {string} [companyContactPerson]
   */
  companyContactPerson?: string;
  /**
   * @member {string} [companyCommercialContactName]
   */
  companyCommercialContactName?: string;
  /**
   * @member {string} [companyCommercialContact]
   */
  companyCommercialContact?: string;
  /**
   * @member {string} [companyCommercialVatNumber]
   */
  companyCommercialVatNumber?: string;
  /**
   * @member {string} [companyCommercialExternalId]
   */
  companyCommercialExternalId?: string;
  /**
   * @member {string} siteId
   */
  siteId: string;
  /**
   * @member {number} [status]
   */
  status?: number;
  /**
   * @member {string} [url]
   */
  url?: string;
  /**
   * @member {string} [urlHash]
   */
  urlHash?: string;
  /**
   * @member {string} [applicationUrl]
   */
  applicationUrl?: string;
  /**
   * @member {string} [companyVATIdentifier]
   */
  companyVATIdentifier?: string;
  /**
   * @member {string} [productionUnitIdentifier]
   */
  productionUnitIdentifier?: string;
  /**
   * @member {string} [globalLocationNumber]
   */
  globalLocationNumber?: string;
  /**
   * @member {string} [requestedPackageId]
   */
  requestedPackageId?: string;
  /**
   * @member {string} [requestedPackageName]
   */
  requestedPackageName?: string;
  /**
   * @member {string} [requestedPackagePrice]
   */
  requestedPackagePrice?: string;
  /**
   * @member {number} [requestedPackageDurationDays]
   */
  requestedPackageDurationDays?: number;
  /**
   * @member {ProductItemModelRequest[]} [requestedPackageProducts]
   */
  requestedPackageProducts?: ProductItemModelRequest[];
  /**
   * @member {string} [countryCode]
   */
  countryCode?: string;
  /**
   * @member {Date} [activationDate]
   */
  activationDate?: Date;
  /**
   * @member {string} [correlationId]
   */
  correlationId?: string;
  /**
   * @member {string} [pNumber]
   */
  pNumber?: string;
  /**
   * @member {string} [statisticsRegisterFeedDisplayCountLink]
   */
  statisticsRegisterFeedDisplayCountLink?: string;
  /**
   * @member {string} [statisticsRegisterFeedEntrySearchedCountLink]
   */
  statisticsRegisterFeedEntrySearchedCountLink?: string;
  /**
   * @member {string} [statisticsRegisterFeedEntryDisplayCountLink]
   */
  statisticsRegisterFeedEntryDisplayCountLink?: string;
  /**
   * @member {string} [statisticsRegisterJobApplicationInitiatedCountLink]
   */
  statisticsRegisterJobApplicationInitiatedCountLink?: string;
  /**
   * @member {string} [statisticsRegisterJobApplicationAppliedCountLink]
   */
  statisticsRegisterJobApplicationAppliedCountLink?: string;
  /**
   * @member {number} [numberOfVacancies]
   */
  numberOfVacancies?: number;
  /**
   * @member {number} [applyMethod]
   */
  applyMethod?: number;
  /**
   * @member {string} [author]
   */
  author?: string;
  /**
   * @member {Date} [modifyDate]
   */
  readonly modifyDate?: Date;
  /**
   * @member {string} [logoId]
   */
  logoId?: string;
  /**
   * @member {string} [ogimage]
   */
  ogimage?: string;
  /**
   * @member {string} [orderByName]
   */
  orderByName?: string;
  /**
   * @member {string} [orderByEmail]
   */
  orderByEmail?: string;
  /**
   * @member {boolean} [anonymousLocation]
   */
  anonymousLocation?: boolean;
  /**
   * @member {string} [alternateTextAnonymousLocation]
   */
  alternateTextAnonymousLocation?: string;
  /**
   * @member {JobAdLocationsModel[]} [locations]
   */
  locations?: JobAdLocationsModel[];
  /**
   * @member {string} [locationString]
   */
  locationString?: string;
  /**
   * @member {string} [locationMapUri]
   */
  locationMapUri?: string;
  /**
   * @member {boolean} [isPublished]
   */
  isPublished?: boolean;
  /**
   * @member {boolean} [useEAN]
   */
  useEAN?: boolean;
}

/**
 * @interface
 * An interface representing JobAdResponce.
 */
export interface JobAdResponce {
  /**
   * @member {string} [siteid]
   */
  siteid?: string;
  /**
   * @member {string} [id]
   */
  id?: string;
}

/**
 * @interface
 * An interface representing MsgJobAd.
 */
export interface MsgJobAd {
  /**
   * @member {number} [updateFlag]
   */
  updateFlag?: number;
  /**
   * @member {string} [backgroundImage]
   */
  backgroundImage?: string;
  /**
   * @member {string} [title]
   */
  title?: string;
  /**
   * @member {Date} [createDate]
   */
  createDate?: Date;
  /**
   * @member {Date} [expiryDate]
   */
  expiryDate?: Date;
  /**
   * @member {string} [id]
   */
  id?: string;
  /**
   * @member {string} [externalID]
   */
  externalID?: string;
  /**
   * @member {string} [externalSource]
   */
  externalSource?: string;
  /**
   * @member {string[]} [category]
   */
  category?: string[];
  /**
   * @member {string[]} [jobType]
   */
  jobType?: string[];
  /**
   * @member {string[]} [position]
   */
  position?: string[];
  /**
   * @member {string[]} [condition]
   */
  condition?: string[];
  /**
   * @member {number} [salaryFrom]
   */
  salaryFrom?: number;
  /**
   * @member {number} [salaryTo]
   */
  salaryTo?: number;
  /**
   * @member {string} [salaryType]
   */
  salaryType?: string;
  /**
   * @member {string} [salaryCurrency]
   */
  salaryCurrency?: string;
  /**
   * @member {string} [description]
   */
  description?: string;
  /**
   * @member {string} [contactName]
   */
  contactName?: string;
  /**
   * @member {string} [contactPhone]
   */
  contactPhone?: string;
  /**
   * @member {string} [contactEmail]
   */
  contactEmail?: string;
  /**
   * @member {string} [commercialContactName]
   */
  commercialContactName?: string;
  /**
   * @member {string} [commercialContact]
   */
  commercialContact?: string;
  /**
   * @member {string} [locationAddress]
   */
  locationAddress?: string;
  /**
   * @member {string} [locationZipCode]
   */
  locationZipCode?: string;
  /**
   * @member {string} [locationCity]
   */
  locationCity?: string;
  /**
   * @member {string} [locationCounty]
   */
  locationCounty?: string;
  /**
   * @member {string} [locationCountry]
   */
  locationCountry?: string;
  /**
   * @member {string} [internalReference]
   */
  internalReference?: string;
  /**
   * @member {number[]} [applicationMethod]
   */
  applicationMethod?: number[];
  /**
   * @member {string[]} [exportOptions]
   */
  exportOptions?: string[];
  /**
   * @member {string} [companyID]
   */
  companyID?: string;
  /**
   * @member {string} [companyName]
   */
  companyName?: string;
  /**
   * @member {string} [companyAddress]
   */
  companyAddress?: string;
  /**
   * @member {string} [companyCity]
   */
  companyCity?: string;
  /**
   * @member {string} [companyZipCode]
   */
  companyZipCode?: string;
  /**
   * @member {string} [companyCounty]
   */
  companyCounty?: string;
  /**
   * @member {string} [companyCountry]
   */
  companyCountry?: string;
  /**
   * @member {string} [companyEmail]
   */
  companyEmail?: string;
  /**
   * @member {string} [companyPhone]
   */
  companyPhone?: string;
  /**
   * @member {string} [companyWWW]
   */
  companyWWW?: string;
  /**
   * @member {string} [companyContactPerson]
   */
  companyContactPerson?: string;
  /**
   * @member {string} [companyCommercialContactName]
   */
  companyCommercialContactName?: string;
  /**
   * @member {string} [companyCommercialContact]
   */
  companyCommercialContact?: string;
  /**
   * @member {string} [companyCommercialVatNumber]
   */
  companyCommercialVatNumber?: string;
  /**
   * @member {string} [companyCommercialExternalId]
   */
  companyCommercialExternalId?: string;
  /**
   * @member {string} siteId
   */
  siteId: string;
  /**
   * @member {number} [status]
   */
  status?: number;
  /**
   * @member {string} [url]
   */
  url?: string;
  /**
   * @member {string} [urlHash]
   */
  urlHash?: string;
  /**
   * @member {string} [applicationUrl]
   */
  applicationUrl?: string;
  /**
   * @member {string} [companyVATIdentifier]
   */
  companyVATIdentifier?: string;
  /**
   * @member {string} [productionUnitIdentifier]
   */
  productionUnitIdentifier?: string;
  /**
   * @member {string} [globalLocationNumber]
   */
  globalLocationNumber?: string;
  /**
   * @member {string} [requestedPackageId]
   */
  requestedPackageId?: string;
  /**
   * @member {string} [requestedPackageName]
   */
  requestedPackageName?: string;
  /**
   * @member {string} [requestedPackagePrice]
   */
  requestedPackagePrice?: string;
  /**
   * @member {number} [requestedPackageDurationDays]
   */
  requestedPackageDurationDays?: number;
  /**
   * @member {ProductItemModelRequest[]} [requestedPackageProducts]
   */
  requestedPackageProducts?: ProductItemModelRequest[];
  /**
   * @member {string} [countryCode]
   */
  countryCode?: string;
  /**
   * @member {Date} [activationDate]
   */
  activationDate?: Date;
  /**
   * @member {string} [correlationId]
   */
  correlationId?: string;
  /**
   * @member {string} [pNumber]
   */
  pNumber?: string;
  /**
   * @member {string} [statisticsRegisterFeedDisplayCountLink]
   */
  statisticsRegisterFeedDisplayCountLink?: string;
  /**
   * @member {string} [statisticsRegisterFeedEntrySearchedCountLink]
   */
  statisticsRegisterFeedEntrySearchedCountLink?: string;
  /**
   * @member {string} [statisticsRegisterFeedEntryDisplayCountLink]
   */
  statisticsRegisterFeedEntryDisplayCountLink?: string;
  /**
   * @member {string} [statisticsRegisterJobApplicationInitiatedCountLink]
   */
  statisticsRegisterJobApplicationInitiatedCountLink?: string;
  /**
   * @member {string} [statisticsRegisterJobApplicationAppliedCountLink]
   */
  statisticsRegisterJobApplicationAppliedCountLink?: string;
  /**
   * @member {number} [numberOfVacancies]
   */
  numberOfVacancies?: number;
  /**
   * @member {number} [applyMethod]
   */
  applyMethod?: number;
  /**
   * @member {string} [author]
   */
  author?: string;
  /**
   * @member {Date} [modifyDate]
   */
  readonly modifyDate?: Date;
  /**
   * @member {string} [logoId]
   */
  logoId?: string;
  /**
   * @member {string} [ogimage]
   */
  ogimage?: string;
  /**
   * @member {string} [orderByName]
   */
  orderByName?: string;
  /**
   * @member {string} [orderByEmail]
   */
  orderByEmail?: string;
  /**
   * @member {boolean} [anonymousLocation]
   */
  anonymousLocation?: boolean;
  /**
   * @member {string} [alternateTextAnonymousLocation]
   */
  alternateTextAnonymousLocation?: string;
  /**
   * @member {JobAdLocationsModel[]} [locations]
   */
  locations?: JobAdLocationsModel[];
  /**
   * @member {string} [locationString]
   */
  locationString?: string;
  /**
   * @member {string} [locationMapUri]
   */
  locationMapUri?: string;
  /**
   * @member {boolean} [isPublished]
   */
  isPublished?: boolean;
  /**
   * @member {boolean} [useEAN]
   */
  useEAN?: boolean;
  /**
   * @member {string} [customerName]
   */
  customerName?: string;
}

/**
 * @interface
 * An interface representing SetSuperAdminDeleteStatusResponce.
 */
export interface SetSuperAdminDeleteStatusResponce {
  /**
   * @member {string} [siteId]
   */
  siteId?: string;
  /**
   * @member {string} [jobAdId]
   */
  jobAdId?: string;
  /**
   * @member {number} [status]
   */
  status?: number;
}

/**
 * @interface
 * An interface representing UpdateStatusResponce.
 */
export interface UpdateStatusResponce {
  /**
   * @member {string} [siteid]
   */
  siteid?: string;
  /**
   * @member {string} [id]
   */
  id?: string;
  /**
   * @member {number} [status]
   */
  status?: number;
  /**
   * @member {string} [url]
   */
  url?: string;
  /**
   * @member {string} [urlHash]
   */
  urlHash?: string;
}

/**
 * @interface
 * An interface representing PatchProductModelRequest.
 */
export interface PatchProductModelRequest {
  /**
   * @member {string} [requestedPackageId]
   */
  requestedPackageId?: string;
  /**
   * @member {string} [requestedPackageName]
   */
  requestedPackageName?: string;
  /**
   * @member {string} [requestedPackagePrice]
   */
  requestedPackagePrice?: string;
  /**
   * @member {number} [requestedPackageDurationDays]
   */
  requestedPackageDurationDays?: number;
  /**
   * @member {ProductItemModelRequest[]} [items]
   */
  items?: ProductItemModelRequest[];
}

/**
 * @interface
 * An interface representing EncryptedData.
 */
export interface EncryptedData {
  /**
   * @member {string} [publicKey]
   */
  publicKey?: string;
  /**
   * @member {string} [data]
   */
  data?: string;
}

/**
 * @interface
 * An interface representing PartnerJobAdResponce.
 */
export interface PartnerJobAdResponce {
  /**
   * @member {string} [siteid]
   */
  siteid?: string;
  /**
   * @member {string} [id]
   */
  id?: string;
  /**
   * @member {string} [url]
   */
  url?: string;
}

/**
 * @interface
 * An interface representing CompanyWithSiteTypeModel.
 */
export interface CompanyWithSiteTypeModel {
  /**
   * @member {number} [siteType]
   */
  siteType?: number;
  /**
   * @member {string} [id]
   */
  id?: string;
  /**
   * @member {string} [name]
   */
  name?: string;
  /**
   * @member {string} [address]
   */
  address?: string;
  /**
   * @member {string} [addressExtended]
   */
  addressExtended?: string;
  /**
   * @member {string} [zipCode]
   */
  zipCode?: string;
  /**
   * @member {string} [city]
   */
  city?: string;
  /**
   * @member {string} [stateCounty]
   */
  stateCounty?: string;
  /**
   * @member {string} [country]
   */
  country?: string;
  /**
   * @member {string} [countryCode]
   */
  countryCode?: string;
  /**
   * @member {string} [email]
   */
  email?: string;
  /**
   * @member {string} [commercialContactName]
   */
  commercialContactName?: string;
  /**
   * @member {string} [commercialContact]
   */
  commercialContact?: string;
  /**
   * @member {string} [commercialContactNameExtended]
   */
  commercialContactNameExtended?: string;
  /**
   * @member {string} [commercialContactExtended]
   */
  commercialContactExtended?: string;
  /**
   * @member {string} [commercialVatNumber]
   */
  commercialVatNumber?: string;
  /**
   * @member {string} [commercialExternalId]
   */
  commercialExternalId?: string;
  /**
   * @member {string} [websiteUrl]
   */
  websiteUrl?: string;
  /**
   * @member {string} [vatnumber]
   */
  vatnumber?: string;
  /**
   * @member {string} [pnumber]
   */
  pnumber?: string;
  /**
   * @member {string} [eannumber]
   */
  eannumber?: string;
  /**
   * @member {string} [phone]
   */
  phone?: string;
  /**
   * @member {string} [logoUrl]
   */
  logoUrl?: string;
  /**
   * @member {string} [logoPath]
   */
  logoPath?: string;
  /**
   * @member {string} [industry]
   */
  industry?: string;
  /**
   * @member {string} [subIndustry]
   */
  subIndustry?: string;
  /**
   * @member {number} [numEmployees]
   */
  numEmployees?: number;
  /**
   * @member {string} [facebookUrl]
   */
  facebookUrl?: string;
  /**
   * @member {string} [linkedInUrl]
   */
  linkedInUrl?: string;
  /**
   * @member {string} [twitterUrl]
   */
  twitterUrl?: string;
  /**
   * @member {string} [otherUrl]
   */
  otherUrl?: string;
  /**
   * @member {Date} [dateCreated]
   */
  dateCreated?: Date;
  /**
   * @member {Date} [dateModified]
   */
  dateModified?: Date;
  /**
   * @member {string} [correlationId]
   */
  correlationId?: string;
  /**
   * @member {string} [siteId]
   */
  siteId?: string;
  /**
   * @member {string} [externalId]
   */
  externalId?: string;
  /**
   * @member {string} [displayName]
   */
  displayName?: string;
  /**
   * @member {string} [externalApplyUrl]
   */
  externalApplyUrl?: string;
  /**
   * @member {number} [externalApplyMethod]
   */
  externalApplyMethod?: number;
  /**
   * @member {string} [contactPerson]
   */
  contactPerson?: string;
  /**
   * @member {string} [externalApplyEmail]
   */
  externalApplyEmail?: string;
  /**
   * @member {string} [youtubeUrl]
   */
  youtubeUrl?: string;
  /**
   * @member {string} [instagramUrl]
   */
  instagramUrl?: string;
  /**
   * @member {boolean} [disablePublishAd]
   */
  disablePublishAd?: boolean;
}

/**
 * @interface
 * An interface representing SearchParams.
 */
export interface SearchParams {
  /**
   * @member {string} [searchValue]
   */
  searchValue?: string;
}

/**
 * @interface
 * An interface representing IHaveSiteModel.
 */
export interface IHaveSiteModel {
  /**
   * @member {string} [id]
   */
  readonly id?: string;
  /**
   * @member {string} [name]
   */
  readonly name?: string;
  /**
   * @member {string} [host]
   */
  readonly host?: string;
  /**
   * @member {string} [masterData]
   */
  readonly masterData?: string;
  /**
   * @member {string} [cultureCode]
   */
  readonly cultureCode?: string;
  /**
   * @member {Date} [createdAt]
   */
  readonly createdAt?: Date;
}

/**
 * @interface
 * An interface representing SiteEntity.
 */
export interface SiteEntity {
  /**
   * @member {string} [id]
   */
  id?: string;
  /**
   * @member {string} [name]
   */
  name?: string;
  /**
   * @member {string} [host]
   */
  host?: string;
  /**
   * @member {Date} [createdAt]
   */
  createdAt?: Date;
  /**
   * @member {Date} [modifiedAt]
   */
  modifiedAt?: Date;
  /**
   * @member {string} [correlationId]
   */
  correlationId?: string;
  /**
   * @member {string} [masterData]
   */
  masterData?: string;
  /**
   * @member {string} [cultureCode]
   */
  cultureCode?: string;
  /**
   * @member {string} [companyId]
   */
  companyId?: string;
  /**
   * @member {number} [siteState]
   */
  siteState?: number;
  /**
   * @member {number} [siteType]
   */
  siteType?: number;
  /**
   * @member {string} [metaTitle]
   */
  metaTitle?: string;
  /**
   * @member {string} [metaDescription]
   */
  metaDescription?: string;
}

/**
 * @interface
 * An interface representing IHaveEmailSiteModel.
 */
export interface IHaveEmailSiteModel {
  /**
   * @member {string} [id]
   */
  readonly id?: string;
  /**
   * @member {Date} [createdAt]
   */
  readonly createdAt?: Date;
  /**
   * @member {string} [cultureCode]
   */
  readonly cultureCode?: string;
}

/**
 * @interface
 * An interface representing JobAdListItem.
 */
export interface JobAdListItem {
  /**
   * @member {string} [id]
   */
  id?: string;
  /**
   * @member {string} [siteID]
   */
  siteID?: string;
  /**
   * @member {string} [title]
   */
  title?: string;
  /**
   * @member {Date} [createDate]
   */
  createDate?: Date;
  /**
   * @member {Date} [expiryDate]
   */
  expiryDate?: Date;
  /**
   * @member {number} [status]
   */
  status?: number;
  /**
   * @member {string} [url]
   */
  url?: string;
  /**
   * @member {string[]} [condition]
   */
  condition?: string[];
  /**
   * @member {Date} [activationDate]
   */
  activationDate?: Date;
  /**
   * @member {string[]} [jobType]
   */
  jobType?: string[];
  /**
   * @member {string} [locationCity]
   */
  locationCity?: string;
  /**
   * @member {string} [statisticsRegisterFeedEntrySearchedCountLink]
   */
  statisticsRegisterFeedEntrySearchedCountLink?: string;
}

/**
 * @interface
 * An interface representing JobAdListItemSearchResult.
 */
export interface JobAdListItemSearchResult {
  /**
   * @member {number} [total]
   */
  total?: number;
  /**
   * @member {number} [page]
   */
  page?: number;
  /**
   * @member {number} [pageSize]
   */
  pageSize?: number;
  /**
   * @member {number} [count]
   */
  count?: number;
  /**
   * @member {JobAdListItem[]} [results]
   */
  results?: JobAdListItem[];
}

/**
 * @interface
 * An interface representing ProductItemModel.
 */
export interface ProductItemModel {
  /**
   * @member {string} [productNumber]
   */
  productNumber?: string;
  /**
   * @member {string} [productName]
   */
  productName?: string;
  /**
   * @member {string} [productPrice]
   */
  productPrice?: string;
}

/**
 * @interface
 * An interface representing JobAdModel.
 */
export interface JobAdModel {
  /**
   * @member {string} [backgroundImage]
   */
  backgroundImage?: string;
  /**
   * @member {string} [title]
   */
  title?: string;
  /**
   * @member {Date} [createDate]
   */
  createDate?: Date;
  /**
   * @member {Date} [expiryDate]
   */
  expiryDate?: Date;
  /**
   * @member {string} [id]
   */
  id?: string;
  /**
   * @member {string} [externalID]
   */
  externalID?: string;
  /**
   * @member {string} [externalSource]
   */
  externalSource?: string;
  /**
   * @member {string[]} [category]
   */
  category?: string[];
  /**
   * @member {string[]} [jobType]
   */
  jobType?: string[];
  /**
   * @member {string[]} [position]
   */
  position?: string[];
  /**
   * @member {string[]} [condition]
   */
  condition?: string[];
  /**
   * @member {number} [salaryFrom]
   */
  salaryFrom?: number;
  /**
   * @member {number} [salaryTo]
   */
  salaryTo?: number;
  /**
   * @member {string} [salaryType]
   */
  salaryType?: string;
  /**
   * @member {string} [salaryCurrency]
   */
  salaryCurrency?: string;
  /**
   * @member {string} [description]
   */
  description?: string;
  /**
   * @member {string} [contactName]
   */
  contactName?: string;
  /**
   * @member {string} [contactPhone]
   */
  contactPhone?: string;
  /**
   * @member {string} [contactEmail]
   */
  contactEmail?: string;
  /**
   * @member {string} [commercialContactName]
   */
  commercialContactName?: string;
  /**
   * @member {string} [commercialContact]
   */
  commercialContact?: string;
  /**
   * @member {string} [locationAddress]
   */
  locationAddress?: string;
  /**
   * @member {string} [locationZipCode]
   */
  locationZipCode?: string;
  /**
   * @member {string} [locationCity]
   */
  locationCity?: string;
  /**
   * @member {string} [locationCounty]
   */
  locationCounty?: string;
  /**
   * @member {string} [locationCountry]
   */
  locationCountry?: string;
  /**
   * @member {string} [internalReference]
   */
  internalReference?: string;
  /**
   * @member {number[]} [applicationMethod]
   */
  applicationMethod?: number[];
  /**
   * @member {string[]} [exportOptions]
   */
  exportOptions?: string[];
  /**
   * @member {string} [companyID]
   */
  companyID?: string;
  /**
   * @member {string} [companyName]
   */
  companyName?: string;
  /**
   * @member {string} [companyAddress]
   */
  companyAddress?: string;
  /**
   * @member {string} [companyCity]
   */
  companyCity?: string;
  /**
   * @member {string} [companyZipCode]
   */
  companyZipCode?: string;
  /**
   * @member {string} [companyCounty]
   */
  companyCounty?: string;
  /**
   * @member {string} [companyCountry]
   */
  companyCountry?: string;
  /**
   * @member {string} [companyEmail]
   */
  companyEmail?: string;
  /**
   * @member {string} [companyPhone]
   */
  companyPhone?: string;
  /**
   * @member {string} [companyWWW]
   */
  companyWWW?: string;
  /**
   * @member {string} [companyCommercialContactName]
   */
  companyCommercialContactName?: string;
  /**
   * @member {string} [companyCommercialContact]
   */
  companyCommercialContact?: string;
  /**
   * @member {string} [companyCommercialVatNumber]
   */
  companyCommercialVatNumber?: string;
  /**
   * @member {string} [companyCommercialExternalId]
   */
  companyCommercialExternalId?: string;
  /**
   * @member {string} [companyContactPerson]
   */
  companyContactPerson?: string;
  /**
   * @member {string} [siteId]
   */
  siteId?: string;
  /**
   * @member {number} [status]
   */
  status?: number;
  /**
   * @member {string} [url]
   */
  url?: string;
  /**
   * @member {string} [urlHash]
   */
  urlHash?: string;
  /**
   * @member {string} [applicationUrl]
   */
  applicationUrl?: string;
  /**
   * @member {string} [companyVATIdentifier]
   */
  companyVATIdentifier?: string;
  /**
   * @member {string} [productionUnitIdentifier]
   */
  productionUnitIdentifier?: string;
  /**
   * @member {string} [globalLocationNumber]
   */
  globalLocationNumber?: string;
  /**
   * @member {string} [requestedPackageId]
   */
  requestedPackageId?: string;
  /**
   * @member {string} [requestedPackageName]
   */
  requestedPackageName?: string;
  /**
   * @member {string} [requestedPackagePrice]
   */
  requestedPackagePrice?: string;
  /**
   * @member {number} [requestedPackageDurationDays]
   */
  requestedPackageDurationDays?: number;
  /**
   * @member {ProductItemModel[]} [requestedPackageProducts]
   */
  requestedPackageProducts?: ProductItemModel[];
  /**
   * @member {string} [countryCode]
   */
  countryCode?: string;
  /**
   * @member {Date} [activationDate]
   */
  activationDate?: Date;
  /**
   * @member {number} [updateFlag]
   */
  updateFlag?: number;
  /**
   * @member {string} [correlationId]
   */
  correlationId?: string;
  /**
   * @member {string} [pNumber]
   */
  pNumber?: string;
  /**
   * @member {string} [statisticsRegisterFeedDisplayCountLink]
   */
  statisticsRegisterFeedDisplayCountLink?: string;
  /**
   * @member {string} [statisticsRegisterFeedEntrySearchedCountLink]
   */
  statisticsRegisterFeedEntrySearchedCountLink?: string;
  /**
   * @member {string} [statisticsRegisterFeedEntryDisplayCountLink]
   */
  statisticsRegisterFeedEntryDisplayCountLink?: string;
  /**
   * @member {string} [statisticsRegisterJobApplicationInitiatedCountLink]
   */
  statisticsRegisterJobApplicationInitiatedCountLink?: string;
  /**
   * @member {string} [statisticsRegisterJobApplicationAppliedCountLink]
   */
  statisticsRegisterJobApplicationAppliedCountLink?: string;
  /**
   * @member {number} [candidateCount]
   */
  candidateCount?: number;
  /**
   * @member {number} [numberOfVacancies]
   */
  numberOfVacancies?: number;
  /**
   * @member {number} [applyMethod]
   */
  applyMethod?: number;
  /**
   * @member {string} [author]
   */
  author?: string;
  /**
   * @member {Date} [modifyDate]
   */
  modifyDate?: Date;
  /**
   * @member {string} [logoId]
   */
  logoId?: string;
  /**
   * @member {string} [ogimage]
   */
  ogimage?: string;
  /**
   * @member {string} [orderByName]
   */
  orderByName?: string;
  /**
   * @member {string} [orderByEmail]
   */
  orderByEmail?: string;
  /**
   * @member {boolean} [anonymousLocation]
   */
  anonymousLocation?: boolean;
  /**
   * @member {string} [alternateTextAnonymousLocation]
   */
  alternateTextAnonymousLocation?: string;
  /**
   * @member {JobAdLocationsModel[]} [locations]
   */
  locations?: JobAdLocationsModel[];
  /**
   * @member {string} [locationString]
   */
  locationString?: string;
  /**
   * @member {string} [locationMapUri]
   */
  locationMapUri?: string;
  /**
   * @member {boolean} [isPublished]
   */
  isPublished?: boolean;
  /**
   * @member {boolean} [useEAN]
   */
  useEAN?: boolean;
  /**
   * @member {string} [customerName]
   */
  customerName?: string;
}

/**
 * @interface
 * An interface representing JobAdModelSearchResult.
 */
export interface JobAdModelSearchResult {
  /**
   * @member {number} [total]
   */
  total?: number;
  /**
   * @member {number} [page]
   */
  page?: number;
  /**
   * @member {number} [pageSize]
   */
  pageSize?: number;
  /**
   * @member {number} [count]
   */
  count?: number;
  /**
   * @member {JobAdModel[]} [results]
   */
  results?: JobAdModel[];
}

/**
 * @interface
 * An interface representing CustomerWebAPIGetTokenOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIGetTokenOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {LoginRequest} [body]
   */
  body?: LoginRequest;
}

/**
 * @interface
 * An interface representing CustomerWebAPICompaniesPostAsyncOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPICompaniesPostAsyncOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {CreateCompanyViewModel} [body]
   */
  body?: CreateCompanyViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPICompaniesPutAsyncOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPICompaniesPutAsyncOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {UpdateCompanyViewModel} [body]
   */
  body?: UpdateCompanyViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPIPasswordResetUserPasswordOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIPasswordResetUserPasswordOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {ResetPasswordViewModel} [body]
   */
  body?: ResetPasswordViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPIPasswordSetNewPasswordOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIPasswordSetNewPasswordOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {NewPasswordViewModel} [body]
   */
  body?: NewPasswordViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPIRolesCreateRoleOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIRolesCreateRoleOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {CreateRoleViewModel} [body]
   */
  body?: CreateRoleViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPISharedRolesCreateGlobalRoleOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPISharedRolesCreateGlobalRoleOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {CreateRoleViewModel} [body]
   */
  body?: CreateRoleViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPISharedUsersCreateGlobalUserOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPISharedUsersCreateGlobalUserOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {CreateUserViewModel} [body]
   */
  body?: CreateUserViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPISharedUsersUpdateGlobalUserOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPISharedUsersUpdateGlobalUserOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {UpdateUserViewModel} [body]
   */
  body?: UpdateUserViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPIUsersCreateUserOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIUsersCreateUserOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {CreateUserViewModel} [body]
   */
  body?: CreateUserViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPIUsersInitUserForNewSiteOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIUsersInitUserForNewSiteOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {AddSiteCreationUserViewModel} [body]
   */
  body?: AddSiteCreationUserViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPIUsersChangeUserPasswordOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIUsersChangeUserPasswordOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {ChangePasswordViewModel} [body]
   */
  body?: ChangePasswordViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPIUsersUpdateUserOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIUsersUpdateUserOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {UpdateUserViewModel} [body]
   */
  body?: UpdateUserViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPIUpdateUserByCustomerToolOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIUpdateUserByCustomerToolOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {UpdateUserViewModel} [body]
   */
  body?: UpdateUserViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPICreateSiteOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPICreateSiteOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {PostSiteViewModel} [body]
   */
  body?: PostSiteViewModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPIUpdateSiteOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIUpdateSiteOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {boolean} [isValidateHost] Default value: true .
   */
  isValidateHost?: boolean;
}

/**
 * @interface
 * An interface representing CustomerWebAPISitesMergeSiteOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPISitesMergeSiteOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {string} [targetSiteId]
   */
  targetSiteId?: string;
  /**
   * @member {string} [targetCompanyId]
   */
  targetCompanyId?: string;
}

/**
 * @interface
 * An interface representing CustomerWebAPICssGeneratorGenerateCssAsyncOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPICssGeneratorGenerateCssAsyncOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {CssGeneratingRequestModel} [body]
   */
  body?: CssGeneratingRequestModel;
}

/**
 * @interface
 * An interface representing CustomerWebAPIJobAdPatchDescription1OptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIJobAdPatchDescription1OptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {string} [jobadid]
   */
  jobadid?: string;
}

/**
 * @interface
 * An interface representing CustomerWebAPICreatePartnerJobAdOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPICreatePartnerJobAdOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {EncryptedData} [body]
   */
  body?: EncryptedData;
}

/**
 * @interface
 * An interface representing CustomerWebAPICompaniesGet1OptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPICompaniesGet1OptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {number} [pageSize]
   */
  pageSize?: number;
  /**
   * @member {number} [page] Default value: 1 .
   */
  page?: number;
}

/**
 * @interface
 * An interface representing CustomerWebAPICompaniesFindCompaniesOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPICompaniesFindCompaniesOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {string} [searchValue]
   */
  searchValue?: string;
  /**
   * @member {boolean} [noCrmId] Default value: false .
   */
  noCrmId?: boolean;
}

/**
 * @interface
 * An interface representing CustomerWebAPICompaniesFindCompaniesByVatNumberOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPICompaniesFindCompaniesByVatNumberOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {boolean} [emptyCrmId]
   */
  emptyCrmId?: boolean;
}

/**
 * @interface
 * An interface representing CustomerWebAPIPartnerCompaniesFindCompanyByVatIdOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIPartnerCompaniesFindCompanyByVatIdOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {EncryptedData} [body]
   */
  body?: EncryptedData;
}

/**
 * @interface
 * An interface representing CustomerWebAPIPartnerUsersFindBySiteIdOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIPartnerUsersFindBySiteIdOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {EncryptedData} [body]
   */
  body?: EncryptedData;
}

/**
 * @interface
 * An interface representing CustomerWebAPIUsersGetUsersAsyncOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIUsersGetUsersAsyncOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {string} [searchIn]
   */
  searchIn?: string;
  /**
   * @member {string} [searchFor]
   */
  searchFor?: string;
  /**
   * @member {boolean} [includeRoles]
   */
  includeRoles?: boolean;
  /**
   * @member {boolean} [includePassword]
   */
  includePassword?: boolean;
  /**
   * @member {number} [page]
   */
  page?: number;
  /**
   * @member {number} [pageSize]
   */
  pageSize?: number;
  /**
   * @member {string} [siteId]
   */
  siteId?: string;
}

/**
 * @interface
 * An interface representing CustomerWebAPIUsersGetUserAsyncOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIUsersGetUserAsyncOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {boolean} [includeRoles]
   */
  includeRoles?: boolean;
  /**
   * @member {string} [siteId]
   */
  siteId?: string;
  /**
   * @member {boolean} [includePassword]
   */
  includePassword?: boolean;
}

/**
 * @interface
 * An interface representing CustomerWebAPIUsersGetUserAsync1OptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIUsersGetUserAsync1OptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {boolean} [includeRoles]
   */
  includeRoles?: boolean;
  /**
   * @member {string} [siteId]
   */
  siteId?: string;
  /**
   * @member {boolean} [includePassword]
   */
  includePassword?: boolean;
}

/**
 * @interface
 * An interface representing CustomerWebAPIUsersFindUsersOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIUsersFindUsersOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {SearchParams} [body]
   */
  body?: SearchParams;
}

/**
 * @interface
 * An interface representing CustomerWebAPIGetSitesOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIGetSitesOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {string} [searchIn]
   */
  searchIn?: string;
  /**
   * @member {string} [searchFor]
   */
  searchFor?: string;
  /**
   * @member {number} [pageSize]
   */
  pageSize?: number;
  /**
   * @member {number} [page]
   */
  page?: number;
}

/**
 * @interface
 * An interface representing CustomerWebAPISitesGetSiteByIdsOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPISitesGetSiteByIdsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {string[]} [body]
   */
  body?: string[];
}

/**
 * @interface
 * An interface representing CustomerWebAPIGetJobsContentListOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIGetJobsContentListOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {number} [pageSize]
   */
  pageSize?: number;
  /**
   * @member {number} [page] Default value: 1 .
   */
  page?: number;
  /**
   * @member {number} [status] Default value: 1 .
   */
  status?: number;
}

/**
 * @interface
 * An interface representing CustomerWebAPIGetJobsListOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIGetJobsListOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {number} [pageSize]
   */
  pageSize?: number;
  /**
   * @member {number} [page] Default value: 1 .
   */
  page?: number;
  /**
   * @member {number} [status] Default value: 1 .
   */
  status?: number;
}

/**
 * @interface
 * An interface representing CustomerWebAPIJobAdsGetJAEOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIJobAdsGetJAEOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {number} [pageSize]
   */
  pageSize?: number;
  /**
   * @member {number} [page] Default value: 1 .
   */
  page?: number;
}

/**
 * @interface
 * An interface representing CustomerWebAPIGetJobAdsFilteredOptionalParams.
 * Optional Parameters.
 *
 * @extends RequestOptionsBase
 */
export interface CustomerWebAPIGetJobAdsFilteredOptionalParams extends msRest.RequestOptionsBase {
  /**
   * @member {number} [pageSize]
   */
  pageSize?: number;
  /**
   * @member {number} [page] Default value: 1 .
   */
  page?: number;
  /**
   * @member {number} [status] Default value: 1 .
   */
  status?: number;
  /**
   * @member {string} [sortField] Default value: 'createDate' .
   */
  sortField?: string;
  /**
   * @member {string} [sortOrder] Default value: 'DESC' .
   */
  sortOrder?: string;
}

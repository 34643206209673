import '@babel/polyfill';

export interface Action<P> {
    type: string;
    payload: P;
}

interface IActionCreator<P> {
    type: string;
    (payload?: P): Action<P>;
}

export function actionCreator<P>(type: string): IActionCreator<P> {
    return Object.assign((payload: P) => ({ type, payload }), {
        type,
    });
}
export function isType<P>(action: Action<any>, creator: IActionCreator<P>): action is Action<P> {
    return action.type === creator.type;
}

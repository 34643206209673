import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar, useSnackbar } from 'notistack';
import * as React from 'react';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { IStore } from '..';
import { useEffect } from 'react';
const displayed = [];

export const Notifier = () => {
    const state = useMappedState((s: IStore) => s);
    const snackbars = state.snackbars;
    const dispatch = useDispatch();
    const sn = useSnackbar();
    useEffect(() => {
        snackbars.forEach((x) => {
            if (displayed.indexOf(x.options.key) === -1) {
                sn.enqueueSnackbar(x.message, {
                    ...x.options,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                });
                displayed.push(x.options.key);
            }
        });
    });
    return null;
};

import { CustomerWebAPI } from './autorest/customerWebAPI';
// import { getAuthToken } from '../cms/common/apiConstants';
// tslint:disable-next-line:no-var-requires

import { ServiceClientCredentials, WebResource } from 'ms-rest-js';
import { JwtService } from '../jwt/jwtService';

class Credentials implements ServiceClientCredentials {
    public async signRequest(webResource: WebResource): Promise<WebResource> {
        const jwttoken = JwtService.getToken();
        webResource.headers.set('Authorization', 'Bearer ' + jwttoken);
        return webResource;
    }
}

export class Api extends CustomerWebAPI {
    constructor(url: string) {
        const credentials = new Credentials();
        super(credentials, url, { noRetryPolicy: true });
    }
}

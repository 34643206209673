import { JobAdModel, SiteEntity } from "../../../services/api/autorest/models"
import { UpdateCompanyViewModel } from '../../../services/api/autorest/models';
import { actionCreator, Action, isType } from '../../Action';
import { ApiService } from '../../../services/api/apiService';
import { AsyncHandler } from '../../helpers';
import { enqueueSnackbar } from '../snackBarReducer';
import { JwtService } from '../../../services/jwt/jwtService';
import * as uuidv4 from 'uuid/v4';
import { ActiveJobAdModel } from "../../../Models/ActiveJobAdModel";

export type TCompany = UpdateCompanyViewModel 
    & { siteList: SiteEntity[],
        userList: any[],
        id: string 
        siteId: string,
        customerId: string,
        site: SiteEntity,
        isDisabled: boolean,
        isDeleted: boolean,
        deleteReason: string,
        createdBy: string,
        commercialContactExtended: string,
        commercialContactNameExtended: string,
        commercialVatNumber: string,
        commercialExternalId: string,
        activeAds?: ActiveJobAdModel[],
        inactiveAds?: ActiveJobAdModel[]
    } 
export const actions = {
    replaceCompany: actionCreator<{ company: any }>('UPDATE_COMPANY'),
    replaceSitelist: actionCreator<{ siteList: SiteEntity[] }>('UPDATE_SITE_LIST'),
    replaceSite: actionCreator<{ site: object }>('UPDATE_SITE_FROM_COMPANY'),
    replaceUserlist: actionCreator<{ userList: any[] }>('UPDATE_USER_LIST'),
    updateCompanyProperty: actionCreator<{ value: string | number; propname: string }>('UPDATE_COMPANY_PROPERTY'),
    getActiveAds: actionCreator<{ activeAds: ActiveJobAdModel[] }>('GET_ACTIVE_ADS'),
    getInactiveAds: actionCreator < { inactiveAds: ActiveJobAdModel[] }>('GET_INACTIVE_ADS')
};
export default function reducer(state: any[] = [], action: Action<any>) {
    if (!state) return null;
    if (isType(action, actions.replaceCompany)) {
        return action.payload.company
    } else if (isType(action, actions.updateCompanyProperty)) {
        return { ...state, [action.payload.propname]: action.payload.value };
    } else if (isType(action, actions.replaceSitelist)) {
        return { ...state, siteList: action.payload.siteList };
    } else if (isType(action, actions.replaceSite)) {
        return { ...state, site: action.payload.site };
    } else if (isType(action, actions.replaceUserlist)) {
        return { ...state, userList: action.payload.userList };
    } else if (isType(action, actions.getActiveAds)) {
        return { ...state, activeAds: action.payload.activeAds };
    } else if (isType(action, actions.getInactiveAds)) {
        return { ...state, inactiveAds: action.payload.inactiveAds };
    }
    return state;
}
export const updateCompanyProperty = (value: string, propname: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(actions.updateCompanyProperty({ value, propname }));
    };
};

export const updateCompanySiteList = (siteList) => {
    return async (dispatch: any, getState: any) => {
        dispatch(actions.replaceSitelist({ siteList }));
    };
};

export const updateCompanySite = (site) => {
    return async (dispatch: any, getState: any) => {
        dispatch(actions.replaceSite({ site }));
    };
}

export const getActiveAds = (siteId: string) => {
    return async (dispatch: any, getState: any) => {
        const data = await ApiService.getActiveAds(siteId);

        if (data && data.results) {
            const activeAds = await Promise.all(data.results.map(async (ad): Promise<ActiveJobAdModel> => {
                try {
                    const json = await ApiService.getActiveAdOrderNumber(ad.id);
                    const orderInformation = json.OrderInformation.Order;
                    return {
                        ...ad,
                        orderInformation: {
                            id: orderInformation['@id'],
                            name: json.OrderInformation.Package['@name'],
                            contactName: orderInformation['@contactName'],
                            contactEmail: orderInformation['@contactEmail'],
                            globalLocationNumber: orderInformation['@globalLocationNumber'],
                            valueAddedTaxIdentification: orderInformation['@valueAddedTaxIdentification'],
                            buyerCustomer: orderInformation['@buyerCustomer'],
                            buyerCustomerCrmId: orderInformation['@buyerCustomerCrmId'],
                            accountingCustomer: orderInformation['@accountingCustomer'],
                            accountingCustomerCrmId: orderInformation['@accountingCustomerCrmId']
                        }
                    };
                } catch {
                    return { ...ad };
                }
            }));
            dispatch(actions.getActiveAds({ activeAds }));
        }
    };
}

export const getInactiveAds = (siteId: string) => {
    return async (dispatch: any, getState: any) => {
        const data = await ApiService.getInactiveAds(siteId);
        if (data && data.results) {
            const inactiveAds = await Promise.all(data.results.map(async (ad): Promise<ActiveJobAdModel> => {
                try {
                    const json = await ApiService.getActiveAdOrderNumber(ad.id);
                    const orderInformation = json.OrderInformation.Order;
                    return {
                        ...ad,
                        orderInformation: {
                            id: orderInformation['@id'],
                            name: json.OrderInformation.Package['@name'],
                            contactName: orderInformation['@contactName'],
                            contactEmail: orderInformation['@contactEmail'],
                            globalLocationNumber: orderInformation['@globalLocationNumber'],
                            valueAddedTaxIdentification: orderInformation['@valueAddedTaxIdentification'],
                            buyerCustomer: orderInformation['@buyerCustomer'],
                            buyerCustomerCrmId: orderInformation['@buyerCustomerCrmId'],
                            accountingCustomer: orderInformation['@accountingCustomer'],
                            accountingCustomerCrmId: orderInformation['@accountingCustomerCrmId']
                        }
                    };
                } catch {
                    return { ...ad };
                }
            }));
            dispatch(actions.getInactiveAds({ inactiveAds }))
        }
    }
}

export const updateSite = (siteId: string) => {
    return async (dispatch, getState) => {
        const company = getState().company as TCompany;
        const updatedSite = { ...company.siteList.find(site => site.id === siteId), name: company.name };
        dispatch(updateCompanySite(updatedSite));

        AsyncHandler(
            async () => {
                await ApiService.updateSite(updatedSite);
                dispatch(enqueueSnackbar('Site successfully updated', 'success'));
            },
            dispatch,
            'replaceUser',
            (error) => { dispatch(enqueueSnackbar(`Site update failed: ${error}`, 'error')); }
        );
    }
}

export const updateCompany = (siteId: string, companyId: string) => {
    return async (dispatch: any, getState: any) => {
        const company = getState().company as TCompany;
        dispatch(updateSite(siteId));

        AsyncHandler(
            async () => {
                const modifiedCompany = { ...company, modifiedBy: JwtService.getUsername() };
                await ApiService.updateCompany({ company: modifiedCompany, id: companyId, siteId });
                dispatch(enqueueSnackbar('Company successfully updated', 'success'));
            },
            dispatch,
            'replaceUser',
            (error) => { dispatch(enqueueSnackbar(`Company Update failed: ${error}`, 'error')); }
        );
    };
};

export const getUsersBySiteId = (id: string)=>{

    return async (dispatch: any, getState: any) => {
        AsyncHandler(
            async () => {
                const userList = (await ApiService.getUsersBySiteId(id)) as any;
                dispatch(actions.replaceUserlist({ userList }));
            },
            dispatch,
            'company'
        );
    };
}
export const getCompany = (props: {siteId: string, companyId: string}) => {
    const {companyId, siteId} = props
    return async (dispatch: any, getState: any) => {
        AsyncHandler(
            async () => {
                const companies = (await ApiService.getCompany(siteId)) as any;
                let company = !!companies ? companies[0] : [];
                dispatch(actions.replaceCompany({ company }));
                dispatch(getUsersBySiteId(siteId))
                const siteList = (await ApiService.getSiteListForCompany(companyId)) as any;
                if (siteList) {
                    dispatch(updateCompanySiteList(siteList));
                    dispatch(actions.updateCompanyProperty({ value: siteList[0].host, propname: "host" }))
                }
            },
            dispatch,
            'company'
        );
    };
};
export const getSiteById = (siteId) => {
    return async (dispatch: any, getState: any) => {
        AsyncHandler(
            async () => {
                const site = await ApiService.getSiteById(siteId) as any;                
                dispatch(actions.replaceSite({site }));
            },
            dispatch,
            'company'
        );
    };
};
export const companyDelete = (companyId: string, siteId: string) => {
    return async (dispatch: any,  getState:any) => {
        const company = getState().company;
        company.modifiedBy = JwtService.getUsername();
        AsyncHandler(
            async () => {
                await ApiService.deleteCompany(companyId, siteId);
                dispatch(clearCompany());
                dispatch(enqueueSnackbar(`Company ${company.name} has been deleted`, 'success'));
            },
            dispatch,
            'userDelete',
            () => {
                dispatch(enqueueSnackbar(`Company ${company.name} Delete failed`, 'error'));
            }
        );
        
    }
}

export const clearCompany = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(
            actions.replaceCompany({
                company: {
                    id: uuidv4(),
                    createdBy: JwtService.getUsername(),
                }
            })
        );
    };
};
import {
    Button, Card,
    CardContent,
    CardHeader, Checkbox, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, Icon, IconButton, InputLabel, Link, Menu, MenuItem, Select, TextField, Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dashboard from '@material-ui/icons/Dashboard';
import DesktopWindows from '@material-ui/icons/DesktopWindows';
import { useTranslation } from 'react-i18next';
import { useMappedState, useDispatch } from 'redux-react-hook';
import * as customerReducer from '../../redux/reducers/customerReducer';
import { updateSite } from '../../redux/reducers/sitesReducer';
import { updateCustomerProperty, actions, getPipeDriveOrg, pushCustomerToPipedrive } from '../../redux/reducers/customerReducer';
import { IStore } from '../../redux/reducers';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { yellow } from '@material-ui/core/colors';
import * as React from 'react';
import useReactRouter from 'use-react-router';
import { Form, FormGroup } from '../../components/Form/form';
import { Dawa } from '../../redux/models/dawa';
import { updateCompanySiteList } from '../../redux/reducers/company/companyReducer';
import { createSite } from '../../redux/reducers/company/siteCreateReducer';
import { enqueueSnackbar } from '../../redux/reducers/snackBarReducer';
import { SiteListModalWindow } from './SiteListModalWindow';
import { ApiService } from '../../services/api/apiService';
import { JwtService } from '../../services/jwt/jwtService';
import { validator } from '../../services/validator';
import './Customer.scss';
import { customerObjectValidation } from './validation';
import { Autocomplete } from '@material-ui/lab';
import { Utils } from '../../services/utils';

const counrties = require('..\\..\\..\\config\\countries.json');
const countriesDK = counrties['da-DK'];

export const Customer = () => {
    const classes = makeStyles((theme) => {
        return {
            cssRoot: {
                color: yellow[500],
                '&:hover': {
                    backgroundColor: yellow[700] + '14'
                }
            },
            popper: {
                fontSize: 20
            }
        };
    })({});

    const { t } = useTranslation();
    // const mapState = useCallback((state: any) => state, {} as any);
    const state = useMappedState((s: any) => s) as IStore;
    const dispatch = useDispatch();
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const customer = state.customer;
    const errors = state.metadata.errors;
    const isAsyncRunning = state.metadata.isAsyncRunning;
    const { match, history } = useReactRouter<{ customerId: string }>();
    const isEdit = !!match.params.customerId;
    const isDeleted = customer.isDeleted;
    const isDisabled = customer.isDisabled;
    const siteList = state.company.siteList;
    const [isNotValidCVR, setValidCVR] = React.useState(false);
    const PackagePlace = ['Phoenix', 'Import', 'Integration', 'CareerSite', 'OfirAdvertiser'];
    const [isCreateAdvertiser, setIsCreateAdvertiser] = React.useState(false);
    const [matchedCompaniesWithoutCrmId, setMatchedCompaniesWithoutCrmId] = React.useState([]);
    const [matchedCompaniesLoaded, setMatchedCompaniesLoaded] = React.useState(false);
    const [mergeSiteModalWindowOpened, setMergeSiteModalWindowOpened] = React.useState(false);
    const [siteIdToMerge, setSiteIdToMerge] = React.useState<string>(null);

    const [siteIdToEdit, setSiteIdToEdit] = React.useState<string>(null);
    const [newSiteName, setNewSiteName] = React.useState<string>(null);
    const [editNameSite, setEditNameSite] = React.useState(false);

    useEffect(() => {
        if (isEdit)
            dispatch(customerReducer.getCustomer(match.params.customerId, true));
    }, []);
    useEffect(() => {
        if (customer.cvr && !matchedCompaniesLoaded) {
            const getCompanies = async () => await ApiService.getCompaniesByVatNumber(customer.cvr, true);
            getCompanies()
                .then(foundCompanies => {
                    setMatchedCompaniesWithoutCrmId(foundCompanies);
                    setMatchedCompaniesLoaded(true);
                })
                .catch(error => setMatchedCompaniesWithoutCrmId([]));
        }
    }, [customer.cvr]);
    useEffect(() => {
        customer.customerId && customer.packageProperty && dispatch(customerReducer.getRecommendations(customer.customerId, customer.packageProperty));
    }, [customer.customerId, customer.packageProperty]);
    const changeText = (e: any) => {
        dispatch(updateCustomerProperty(e.target.value, e.target.name));
    };
    const changeBoolean = (e: any) => {
        dispatch(updateCustomerProperty(e.target.checked, e.target.name));
    };

    const changeSiteName = (e: any) => {
        setNewSiteName(e.target.value);
    }

    const [cvrError, setCvrError] = React.useState({ status: false, text: t('min length 8') });
    React.useEffect(() => {
        if (customer.customerValidation?.cvr) {
            setValidCVR(true);
        } else {
            setValidCVR(false);
        }
    }, [customer.customerValidation?.cvr]);
    const searchCvr = (e: any) => {
        if (!e.charCode || e.charCode === 13) {
            if (customer.cvr.length < 8) {
                setValidCVR(true);
            } else {
                setValidCVR(false);
                dispatch(customerReducer.searchCvr(customer.cvr));
            }
        }
    };
    const handlePuNumberChange = (e: any) => {
        const pu = customer.puCollection.filter((x) => x.productionUnitIdentifier === e.target.value)[0];
        dispatch(customerReducer.updateCustomerFromPu(pu));
        dispatch(actions.updateCustomerDisabled({ isDisabled: false }));
    };
    const [validation, setValidation] = React.useState(customerObjectValidation);
    const saveClicked = () => {
        const { errors, isError } = validator(customer, customerObjectValidation);
        setValidation(errors);
        if (!isError) {
            if (match.params.customerId || customer.customerId) {
                dispatch(customerReducer.updateCustomer(customer));
            } else {
                dispatch(customerReducer.createCustomer(customer));
                //sendToNavision("POST");
            }
        }
    };
    const sendToNavision = async (method) => {
        const navisionData = {
            CustomerId: customer.customerId,
            Name: customer.name,
            CVR: customer.cvr,
            EAN: customer.ean,
            PNumber: customer.pNumber,
            Address: customer.address,
            Address2: customer.address2,
            City: customer.city,
            ZipCode: customer.zipCode,
            Country: customer.country,
            Email: customer.email,
            Phone1: customer.phone1
        }

        dispatch(customerReducer.sendToNavision(navisionData, method))
    }

    const deleteCustomerClicked = () => {
        dispatch(customerReducer.deleteCustomer(customer));
        setDeleteModalOpen(false);
    };
    const preUpdateCustomerFromDawa = (e: any) => {
        if (customer.dawa.length === 1) {
            updateCustomerFromDawa(customer.dawa[0]);
        } else {
            setAnchorEl(e.currentTarget);
        }
    };

    const updateSiteName = (siteName) => {
        var count = siteList.length;
        for (var i = 0; i < count; i++) {
            if (siteList[i].id == siteIdToEdit) {
                siteList[i].name = siteName;
                dispatch(updateSite(siteList[i]));
            }
        }
        setEditNameSite(false);
    }

    const updateCustomerFromDawa = (dawa: Dawa) => {
        dispatch(customerReducer.updateCustomerFromDawa(dawa));
    };
    const isAddressDifferent = () => {
        if (customer && customer.dawa) {
            const found = customer.dawa.filter((x) => {
                return x.adressebetegnelse === `${customer.address}, ${customer.zipCode} ${customer.city}`;
            });
            return found.length === 0 && customer.dawa.length > 0;
        }
        return false;
    };
    const getDawaToolTip = () => {
        if (customer.dawa.length > 1) {
            return 'More than one address is found. Click to select';
        } else {
            return customer.dawa[0].adressebetegnelse;
        }
    };
    let cardHeader = t('Customer data');
    if (customer.customerId) {
        cardHeader += ' (' + customer.customerId + ')';
    }
    const editCustomerClicked = () => {
        history.push(`/customer/edit/${customer.exists.id}`);
        dispatch(actions.updateCustomerExists({ exists: null }));
    };
    const editCustomerNegativeClicked = () => {
        dispatch(actions.updateCustomerExists({ exists: null }));
        dispatch(actions.updateCustomerDisabled({ isDisabled: true }));
    };
    const [formState, setFormState] = React.useState({} as any);
    const [initialValuesState, setInitialValuesState] = React.useState({} as any);

    const emailValidate = async () => {
        try {
            const rv = await ApiService.getUserByName(formState?.userEmail ? formState?.userEmail : '');
            if (rv && rv.userName) {
                setInitialValuesState((c) => ({
                    ...c,
                    ...formState,
                    userFirstName: rv.firstName,
                    userLastName: rv.lastName,
                    userPhone: rv.phoneNumber,
                    password: '0000000000',
                    passwordConfirm: '0000000000'
                }));
            } else if (formState?.userEmail) {
                setInitialValuesState((c) => ({ ...c, ...formState, userFirstName: '', userLastName: '', userPhone: '', password: '', passwordConfirm: '' }));
            }
        } catch {

        }
    };

    const changeCountry = (e: object, value: any) => {
        console.log(value);
        if (value)
            dispatch(updateCustomerProperty(value['value'], "country"));
    };


    const handleCreateSite = () => {

        var countryItem = countriesDK.find(x => x.value === formState.country);
        console.log(countryItem);

        try {
            dispatch(
                createSite({
                    ...customer,
                    ...formState,
                    country: formState.country || "Danmark",
                    countryCode: countryItem ? countryItem['key'] : "DK",
                    ofirCRMNumber: formState.customerId,
                    userFirstName: formState.userFirstName,
                    userLastName: formState.userLastName,
                    vatNumber: formState.cvr,
                    companyName: formState.name,
                    userEmail: formState.userEmail
                })
            );
            setIsCreateAdvertiser(false);
        } catch {
            dispatch(enqueueSnackbar('Unable to create user', 'error'))
        }
    }
    return (
        <div className="container">
            <div className="inputs">
                {customer.isDeleted && (
                    <Card classes={{ root: 'card' }}>
                        <CardHeader classes={{ root: 'cardheader' }} title="Delete reason" />
                        <CardContent>
                            <div>{customer.deleteReason}</div>
                        </CardContent>
                    </Card>
                )}
                <Card classes={{ root: 'card' }}>
                    <CardHeader classes={{ root: 'cardheader' }} title={cardHeader} />
                    <CardContent>
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <div className="container" style={{ flexDirection: 'row' }}>
                                    <TextField
                                        disabled={isAsyncRunning || isEdit || isDeleted}
                                        fullWidth={true}
                                        error={errors.cvr || cvrError.status || validation.cvr.error}
                                        name="cvr"
                                        onKeyPress={searchCvr}
                                        onChange={changeText}
                                        label={t('CVR*')}
                                        value={customer.cvr}
                                        helperText={errors.cvr ? t(errors.cvrErrorTextResource) : cvrError.status ? cvrError.text : ''}
                                    />
                                    {!isDeleted && customer.cvr && (
                                        <Button
                                            onClick={searchCvr}
                                            color="primary"
                                            style={{ alignSelf: 'flex-end', height: 27, marginLeft: 3 }}
                                            variant="contained"
                                            size="small"
                                        >
                                            &#10004;
                                        </Button>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                {!isEdit && !!customer.puCollection.length ? (
                                    <FormControl fullWidth={true}>
                                        <InputLabel htmlFor="age-simple">P-number</InputLabel>
                                        <Select fullWidth={true} value={customer.pNumber} onChange={handlePuNumberChange} renderValue={() => customer.pNumber}>
                                            {customer.puCollection.map((pu, i) => {
                                                return (
                                                    <MenuItem style={{ display: 'block', height: 'auto' }} key={i} value={pu.productionUnitIdentifier}>
                                                        <div>{`${pu.address}, ${pu.postalCode} ${pu.postalCity}`}</div>
                                                        <div style={{ fontSize: 14, color: '#aaa' }}>{pu.productionUnitIdentifier}</div>
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <TextField
                                        disabled={isAsyncRunning || isEdit || isDeleted}
                                        fullWidth={true}
                                        name="pNumber"
                                        onChange={changeText}
                                        label={t('P-number')}
                                        value={customer.pNumber}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={isAsyncRunning || isDeleted}
                                    fullWidth={true}
                                    name="ean"
                                    onChange={changeText}
                                    label={t('EAN')}
                                    value={customer.ean}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={isAsyncRunning || isDeleted}
                                    fullWidth={true}
                                    name="industryCode"
                                    onChange={changeText}
                                    label={t('Industry code')}
                                    value={customer.industryCode}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card classes={{ root: 'card company-info' }}>
                    <CardHeader
                        classes={{ root: 'cardheader' }}
                        title={
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{t('Customer contact info')}</span>
                                <FormControlLabel
                                    style={{ marginTop: -6 }}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name="isHq"
                                            disabled={isAsyncRunning || isDeleted || isDisabled}
                                            onChange={changeBoolean}
                                            checked={customer.isHq || false}
                                        />
                                    }
                                    label="HQ"
                                />
                            </div>
                        }
                    />
                    <CardContent>
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled}
                            fullWidth={true}
                            name="name"
                            onChange={changeText}
                            label={t('Name*')}
                            error={validation.name.error}
                            value={customer.name}
                        />

                        <div className="container" style={{ flexDirection: 'row' }}>
                            <TextField
                                disabled={isAsyncRunning || isDeleted || isDisabled}
                                fullWidth={true}
                                name="address"
                                onChange={changeText}
                                error={validation.address.error}
                                label={t('Address*')}
                                value={customer.address}
                            />
                            {!isDeleted && isAddressDifferent() && (
                                <Tooltip title={getDawaToolTip()}>
                                    <IconButton onClick={preUpdateCustomerFromDawa} className={classes.cssRoot} color="secondary" aria-label="Add an alarm">
                                        <Icon>warning</Icon>
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled}
                            fullWidth={true}
                            name="address2"
                            onChange={changeText}
                            label={t('Address 2')}
                            value={customer.address2}
                        />
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled}
                            fullWidth={true}
                            name="zipCode"
                            onChange={changeText}
                            label={t('Postal code*')}
                            error={validation.zipCode.error}
                            value={customer.zipCode}
                        />
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled}
                            fullWidth={true}
                            name="city"
                            onChange={changeText}
                            label={t('City*')}
                            error={validation.city.error}
                            value={customer.city}
                        />
                        <Autocomplete
                            options={countriesDK}
                            getOptionLabel={(option) => option['value']}
                            onChange={changeCountry}
                            value={{ value: customer?.country || '', key: ''}}
                            disabled={isAsyncRunning || isDeleted || isDisabled}
                            renderInput={(params) => <TextField{...params} label="Country" />}
                        />
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled}
                            fullWidth={true}
                            name="webAddress"
                            onChange={changeText}
                            label={t('Web address')}
                            value={customer.webAddress}
                        />
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            <TextField
                                disabled={isAsyncRunning || isDeleted || isDisabled}
                                fullWidth={true}
                                name="email"
                                onChange={changeText}
                                label={t('E-mail')}
                                value={customer.email}
                                style={{ flex: 3 }}
                            />
                            {/* {
                            !isEdit && (<Button onClick={() => emailValidate()}   
                            variant="contained"
                            color="primary"
                            title=""
                            style={{flex: 1}}
                        >
                            Validate E-mail
                        </Button>)
                        } */}
                        </div>

                        <Grid container spacing={8}>
                            <Grid item xs={4}>
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth={true}
                                    name="phone1"
                                    onChange={changeText}
                                    label={t('Phone 1')}
                                    value={customer.phone1}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth={true}
                                    name="phone2"
                                    onChange={changeText}
                                    label={t('Phone 2')}
                                    value={customer.phone2}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    disabled={isAsyncRunning || isDeleted || isDisabled}
                                    fullWidth={true}
                                    name="phone3"
                                    onChange={changeText}
                                    label={t('Phone 3')}
                                    value={customer.phone3}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card classes={{ root: 'card company-info' }}>
                    <CardHeader classes={{ root: 'cardheader' }} title="Data from CVR" />
                    <CardContent>
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled}
                            fullWidth={true}
                            name="industryDescription"
                            label={t('Industry description')}
                            value={customer.cvrData?.industrydesc || ""}
                            inputProps={{ readOnly: true }}
                        />
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled}
                            fullWidth={true}
                            name="companyCode"
                            label={t('Company code')}
                            value={customer.cvrData?.companycode || ""}
                            inputProps={{ readOnly: true }}
                        />
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled}
                            fullWidth={true}
                            name="companyDescription"
                            label={t('Company description')}
                            value={customer.cvrData?.companydesc || ""}
                            inputProps={{ readOnly: true }}
                        />
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled}
                            fullWidth={true}
                            name="employees"
                            label={t('Employees')}
                            value={customer.cvrData?.employees || ""}
                            inputProps={{ readOnly: true }}
                        />
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled}
                            fullWidth={true}
                            name="creditStatus"
                            label={t('Credit status')}
                            value={customer.cvrData?.creditstatus || ""}
                            inputProps={{ readOnly: true }}
                        />
                        <TextField
                            disabled={isAsyncRunning || isDeleted || isDisabled}
                            fullWidth={true}
                            name="pNumberEmployees"
                            label={t('P-Number employees')}
                            value={customer.cvrData?.productionUnits[0]?.employees || ""}
                            inputProps={{ readOnly: true }}
                        />
                    </CardContent>
                </Card>
                {isEdit && (
                    <>
                        <CardHeader classes={{ root: 'cardheader' }} title={`Site List`} />
                        <Card classes={{ root: 'card company-info' }}>
                            {state.metadata.name === 'companyLoading' ? (
                                <CircularProgress />
                            ) : (
                                <CardContent>
                                    {!!siteList && siteList.length ? (
                                        siteList?.map((c) => {
                                            return (
                                                <div key={c.id} className="customer-site-list">
                                                    <Link
                                                        className={isAsyncRunning ? 'disabled' : ''}
                                                        style={{ cursor: 'pointer', width: '50%' }}
                                                        href="javascript:void(0)"
                                                        onClick={() => {
                                                            history.push(`/site/edit/${c.companyId}/${c.id}`);
                                                        }}
                                                        title={c.name}
                                                    >
                                                        {c.name.length > 30 ? `${c.name.slice(0, 30)}...` : c.name}
                                                    </Link>
                                                    <div>{c.siteType === 5 ? <Dashboard /> : <DesktopWindows />}</div>
                                                    <Button onClick={() => {
                                                        setEditNameSite(true);
                                                        setNewSiteName(c.name);
                                                        setSiteIdToEdit(c.id);
                                                    }}
                                                    >
                                                        <EditIcon />
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            setSiteIdToMerge(c.id);
                                                            setMergeSiteModalWindowOpened(true);
                                                        }}
                                                    >
                                                        <DeleteIcon color={'error'} />
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            window.open(`//${Utils.transformHost(c.host)}/Account/LoginAdvertiser`, '_blank');
                                                        }}
                                                        color="primary"
                                                        style={{ marginLeft: 10 }}
                                                        variant="contained"
                                                        title={`${Utils.transformHost(c.host)}/Account/LoginAdvertiser`}
                                                    >
                                                        admin page
                                                    </Button>
                                                </div>
                                            );
                                        })
                                    ) : null}
                                    <Button
                                        style={{ marginTop: '1em' }}
                                        disabled={isAsyncRunning || isDisabled}
                                        onClick={() => setIsCreateAdvertiser(true)}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Create Advertiser
                                    </Button>
                                </CardContent>
                            )}
                        </Card>
                        <SiteListModalWindow
                            isOpened={mergeSiteModalWindowOpened}
                            siteList={siteList?.filter(site => site.id !== siteIdToMerge)}
                            selectedSiteId={siteIdToMerge}
                            onClose={() => setMergeSiteModalWindowOpened(false)}
                            onActionSelected={(siteId) => {
                                setSiteIdToMerge(null);
                                dispatch(updateCompanySiteList(siteList?.filter(site => site.id !== siteId) || []));
                            }}
                        />
                        {
                            !matchedCompaniesWithoutCrmId ?
                                <CircularProgress /> : matchedCompaniesWithoutCrmId.length > 0 ?
                                    <>
                                        <CardHeader classes={{ root: 'cardheader' }} title={`Sites matching CVR, with no CRM id`} />
                                        <Card classes={{ root: 'card company-info' }}>
                                            <CardContent>
                                                {
                                                    matchedCompaniesWithoutCrmId?.map((company) => {
                                                        return (
                                                            <div key={company.id} className="customer-site-list">
                                                                <Link
                                                                    className={isAsyncRunning ? 'disabled' : ''}
                                                                    style={{ cursor: 'pointer' }}
                                                                    href="javascript:void(0)"
                                                                    onClick={() => history.push(`/site/edit/${company.id}/${company.id}`)}
                                                                    title={'Site page'}
                                                                >
                                                                    {company.name.length > 20 ? `${company.name.slice(0, 20)}...` : company.name}
                                                                </Link>
                                                                <div>{company.siteType === 5 ? 'Advertiser' : 'Career Site'}</div>
                                                                <Button
                                                                    onClick={async () => {
                                                                        try {
                                                                            await ApiService.updateCompany({
                                                                                company: { ...company, externalId: customer.customerId.toString() },
                                                                                siteId: company.siteId,
                                                                                id: company.id
                                                                            });
                                                                            const addedSite = await ApiService.getSiteById(company.siteId);
                                                                            dispatch(updateCompanySiteList([...siteList, addedSite]));
                                                                            setMatchedCompaniesWithoutCrmId(matchedCompaniesWithoutCrmId.filter(c => c.id !== company.id));
                                                                            dispatch(enqueueSnackbar("Successfully connected", "success"));
                                                                        } catch (error) {
                                                                            dispatch(enqueueSnackbar(`Connection failed ${error}`, "error"));
                                                                        }
                                                                    }}
                                                                    color="primary"
                                                                    style={{ marginLeft: 10 }}
                                                                    variant="contained"
                                                                >
                                                                    connect to customer
                                                                </Button>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </CardContent>
                                        </Card>
                                    </> : null
                        }

                        <CardHeader classes={{ root: 'cardheader' }} title={`Package List`} />
                        <Card classes={{ root: 'card company-info' }}>
                            <CardContent>
                                <Select
                                    fullWidth={true}
                                    value={customer.packageProperty || ''}
                                    defaultValue={customer.packageProperty || ''}
                                    name="packageProperty"
                                    onChange={(e: any) => {
                                        dispatch(updateCustomerProperty(e.target.value.toString(), e.target.name.toString()));
                                    }}
                                    renderValue={() => customer.packageProperty}
                                >
                                    {PackagePlace.map((place, i) => {
                                        return (
                                            <MenuItem style={{ display: 'block', height: 'auto' }} key={i} value={place}>
                                                <div>{place}</div>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </CardContent>
                        </Card>
                        {customer.recommendation && (
                            <>
                                <Card classes={{ root: 'card company-info' }}>
                                    <CardContent>
                                        <div className="recommendation-scroll">
                                            <div className="recommendation-wrapper">
                                                {customer.recommendation?.map((c, i) => (
                                                    <div className={`item`} key={i}>
                                                        <div className="recommendation-header">
                                                            <h3 title={c.description}>{c.name}</h3>
                                                        </div>
                                                        <div className="recommendation-content">
                                                            {c.productsData.map((j, index) => (
                                                                <div className="product-item" key={index} title={j.description}>
                                                                    <img src={j.icon} />
                                                                    {j.name}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="recommendation-footer">
                                                            <div className="item">
                                                                <h4>{c.price}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </>
                        )}
                    </>
                )}

                {!isDeleted && (
                    <Card classes={{ root: 'card company-info' }}>
                        {isEdit && (
                            <>
                                <Button
                                    onClick={() => {
                                        const { errors, isError } = validator(customer, customerObjectValidation);
                                        setValidation(errors);
                                        !isError && dispatch(pushCustomerToPipedrive(customer));
                                    }}
                                    color="primary"
                                    style={{ float: 'left', margin: 5 }}
                                    variant="contained"
                                >
                                    {t('Send to pipedrive')}
                                </Button>

                                {/*<Button
                                onClick={()=>sendToNavision("PUT")}
                                color="primary"
                                style={{ float: 'left', margin: 5 }}
                                variant="contained"
                            >
                                {t('Send to Navision')}
                            </Button>*/}
                            </>
                        )}

                        <Button
                            disabled={isAsyncRunning || isDisabled}
                            onClick={saveClicked}
                            variant="contained"
                            color="primary"
                            style={{ float: 'right', width: 100, margin: 5 }}
                            title=""
                        >
                            {match.params.customerId ? t('Update') : t('Save')}
                        </Button>
                        {isEdit && (
                            <Button
                                disabled={isAsyncRunning || !JwtService.hasCustomerCMSRole()}
                                onClick={() => setDeleteModalOpen(true)}
                                variant="text"
                                color="secondary"
                                style={{ float: 'right', width: 100, margin: 5 }}
                                title=""
                            >
                                {t('Delete')}
                            </Button>
                        )}
                    </Card>
                )}
                <Dialog
                    open={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{t('Delete')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{t(`Are you sure you want to delete ${customer.name}?`)}</DialogContentText>
                        <TextField
                            name="deleteReason"
                            onChange={changeText}
                            style={{ width: 400 }}
                            value={customer.deleteReason}
                            multiline={true}
                            rows={5}
                            label="Reason for delete"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteModalOpen(false)} autoFocus variant="contained" color="primary">
                            {t('No')}
                        </Button>
                        <Button onClick={() => deleteCustomerClicked()} color="primary">
                            {t('Yes')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={editNameSite}
                    onClose={() => setEditNameSite(false)}
                    aria-labelledby="siteeditname-dialog-title"
                    aria-describeby="siteeditname-dialog-description"
                >
                    <DialogTitle id="siteeditname-dialog-title">{t('Edit name')}</DialogTitle>
                    <DialogContent>
                        <TextField
                            name="newSiteName"
                            style={{ width: 400 }}
                            value={newSiteName}
                            //multiline={false}
                            onChange={changeSiteName}
                        >
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setEditNameSite(false)} autoFocus variant="contained" color="primary">
                            {t('Cancel')}
                        </Button>
                        <Button onClick={() => updateSiteName(newSiteName)}>
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isCreateAdvertiser}
                    onClose={() => setIsCreateAdvertiser(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{t('Insert user for site')}</DialogTitle>
                    <DialogContent>
                        <Form
                            onSubmit={(e) => {
                                // console.log(e);
                            }}
                            initialValues={{ ...customer, ...initialValuesState }}
                            noSubmitBtn
                            getState={(e) => setFormState(e)}
                        >
                            <FormGroup>
                                <Form.Text name="userEmail" title="Email" required style={{ flex: 3 }} />
                                <Button onClick={() => emailValidate()} variant="contained" color="primary" title="">
                                    Validate
                                </Button>
                            </FormGroup>
                            <FormGroup>
                                <Form.Text name="userFirstName" title="First name" required />
                                <Form.Text name="userLastName" title="Last name" required />
                            </FormGroup>

                            <FormGroup>
                                <Form.Text name="password" title="Password" type="password" required />
                                <Form.Text name="passwordConfirm" title="Password Confirm" type="password" required />
                            </FormGroup>
                        </Form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                emailValidate();
                                handleCreateSite();
                            }}
                            color="primary"
                        >
                            {t('Create advertiser')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={!!customer.exists}
                    onClose={() => dispatch(actions.updateCustomerExists({ exists: null }))}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{t('Customer exists')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{t(`Do you wish to edit ${customer.name}?`)}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={editCustomerNegativeClicked} color="primary">
                            {t('No')}
                        </Button>
                        <Button onClick={editCustomerClicked} variant="contained" color="primary" autoFocus>
                            {t('Yes')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={!isEdit && isNotValidCVR}
                    onClose={() => setValidCVR(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{t('Not Valid CVR!')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{t(`The CVR ${customer.cvr}, is not a valid Danish CVR.`)}</DialogContentText>
                        <DialogContentText id="alert-dialog-description">{t(`Verify if the CVR-number is correct.`)}</DialogContentText>
                        <br />
                        <DialogContentText id="alert-dialog-description">
                            {t(`If thc CVR is for a foreign customer, create the customer without CVR validation..`)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setValidCVR(false);
                                setCvrError((c) => ({ ...c, status: false }));
                                dispatch(actions.updateCustomerDisabled({ isDisabled: false }));
                                dispatch(updateCustomerProperty('', 'pNumber'));
                                dispatch(updateCustomerProperty('', 'puCollection'));
                            }}
                            variant="contained"
                            color="primary"
                            autoFocus
                        >
                            {t('Create customer')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Menu open={anchorEl != null} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                    {customer.dawa.map((x, i) => (
                        <MenuItem
                            key={i}
                            onClick={() => {
                                updateCustomerFromDawa(x);
                                setAnchorEl(null);
                            }}
                        >
                            {x.adressebetegnelse}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </div>
    );
};

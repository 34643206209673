import { Button, DialogActions, DialogContent, DialogTitle, Dialog as MaterialDialog, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import * as React from "react";
import { useDispatch } from "redux-react-hook";
import { enqueueSnackbar } from "../../redux/reducers/snackBarReducer";
import { ApiService } from "../../services/api/apiService";
import { SiteEntity } from "../../services/api/autorest/models";

type SiteListModalWindowProps = {
    siteList: SiteEntity[];
    isOpened: boolean;
    selectedSiteId: string;
    onClose: () => void;
    onActionSelected?: (siteId: string) => void;
}


export function SiteListModalWindow({ siteList, isOpened, onClose, selectedSiteId, onActionSelected }: SiteListModalWindowProps) {
    const dispatch = useDispatch();
    return (
        <MaterialDialog
            open={isOpened}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Sites</DialogTitle>
            <DialogContent>
                <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                    {
                        siteList && siteList.length > 0
                            ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {siteList.map((site, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{site.name}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ width: '100%' }}
                                                        onClick={() => {
                                                            ApiService.mergeSite(selectedSiteId, site.id, site.companyId)
                                                                .then(() => {
                                                                    dispatch(enqueueSnackbar("Selected site has been merged successfully", "success", false));
                                                                    onActionSelected && onActionSelected(selectedSiteId);
                                                                    onClose();
                                                                })
                                                        }}
                                                    >
                                                        Merge
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )
                            : null
                    }
                </ul>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    onClick={() => {
                        ApiService.deleteSite(selectedSiteId).then(() => {
                            dispatch(enqueueSnackbar("Selected site has been deleted successfully", "success", false));
                            onActionSelected && onActionSelected(selectedSiteId);
                            onClose();
                        })
                    }}
                    color="secondary"
                >
                    Delete
                </Button>
                <Button onClick={onClose} variant="contained" color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </MaterialDialog>
    )
}
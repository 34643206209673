import { CustomerModel } from '../../services/api/autorest/models';
import { actionCreator, Action, isType } from '../Action';
import { ApiService } from '../../services/api/apiService';
import { setIsAsync } from './metadataReducer';
import { AsyncHandler } from '../helpers';
import { enqueueSnackbar } from './snackBarReducer';
export const actions = {
    search: actionCreator<{ customers: CustomerModel[] }>('SEARCH_CUSTOMERS')
};
export default function reducer(state: CustomerModel[] = [], action: Action<any>) {
    if (!state) {
        return null;
    }
    if (isType(action, actions.search)) {
        return action.payload.customers;
    }

    return state;
}
export const searchCustomers = (text: string, forComapny?: boolean) => {
    return async (dispatch: any, getState: any) => {
        AsyncHandler(
            
            async () => {
                try{
                    const c = await ApiService.searchCustomer(text);
                    
                    !c && dispatch(enqueueSnackbar("Customer not found","error"))
                    dispatch(actions.search({ customers: c.slice(0,25) }));
                }catch(err){
                    // dispatch(enqueueSnackbar(`User ${text} not Found`, 'error'));
                    dispatch(actions.search({ customers: [] }));
                }
            },
            dispatch,
            'searchCustomer'
        );
    };
};
export const clearCustomers = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(actions.search({customers: []}))
    };
};

import * as React from 'react';
import './CustomerSearch.scss';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Divider,Link, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { clearCustomers, searchCustomers } from '../../redux/reducers/customerSearchReducer';
import { IStore } from '../../redux/reducers';
import useReactRouter from 'use-react-router';
import { Search } from "../../components/Search/Search"
//import {PipederiveApi } from "../../services/api/pipedriveApi"
export const CustomerSearch = () => {
    const { t } = useTranslation();
    const { history } = useReactRouter();

    const state = useMappedState((s: IStore) => s);
    const customers = state.customers;
    const dispatch = useDispatch();
    let timer;
    const isAsyncRunning = state.metadata.isAsyncRunning;
    //React.useEffect(()=>{
    
    //    PipederiveApi.getDealById()
    //}, [])
    const searchCustomer = (e: any) => {
        const val = e.target.value;
        if (!val) {
            return;
        }

        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            dispatch(searchCustomers(val));
        }, 300);
    };

    return (
        <div className="container">
            <Paper>
                <Search onSearch={searchCustomer}/>
                <Divider />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Id')}</TableCell>
                            <TableCell>{t('Name')}</TableCell>
                            <TableCell>{t('Address')}</TableCell>
                            <TableCell>{t('City')}</TableCell>
                            <TableCell>{t('E-mail')}</TableCell>
                            <TableCell>{t('Phone 1')}</TableCell>
                            <TableCell>{t('CVR')}</TableCell>
                            <TableCell>{t('P-number')}</TableCell>
                            {/* <TableCell>{t('HQ')}</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customers.map((c, i) => (
                            <TableRow key={i} style={c.isDeleted ? { textDecoration: 'line-through' } : {}}>
                                <TableCell>{c.customerId}</TableCell>
                                <TableCell>
                                    <Link
                                        className={isAsyncRunning ? 'disabled' : ''}
                                        style={{ cursor: 'pointer' }}
                                        href="javascript:void(0)"
                                        onClick={() => {
                                            dispatch(clearCustomers());
                                            history.push(`/customer/edit/${c.id}`)
                                        }}
                                    >
                                        <Tooltip title={c.deleteReason} placement="top">
                                            <span style={c.isHq ? { fontWeight: 'bold' } : {}}>{c.name}</span>
                                        </Tooltip>
                                    </Link>
                                </TableCell>
                                <TableCell>{c.address}</TableCell>
                                <TableCell>{c.zipCode + ' ' + c.city}</TableCell>
                                <TableCell>{c.email}</TableCell>
                                <TableCell>{c.phone1}</TableCell>
                                <TableCell>{c.cvr}</TableCell>
                                <TableCell>{c.pNumber}</TableCell>
                                {/* <TableCell>{c.isHq ? 'X' : ''}</TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
};

import { CustomerModel } from '../../services/api/autorest/models';
import { actionCreator, Action, isType } from '../Action';
import { ApiService } from '../../services/api/apiService';
import { Cvr, ProductionUnit } from '../models/cvr';
import { CustomerModeldefault } from '../../services/api/defaults';
import * as metadataReducer from './metadataReducer';
import * as uuidv4 from 'uuid/v4';
import { AsyncHandler } from '../helpers';
import { JwtService } from '../../services/jwt/jwtService';
import { enqueueSnackbar } from './snackBarReducer';
import { Dawa } from '../models/dawa';
import { PipederiveApi } from '../../services/api/pipedriveApi';
import { PIPEDRIVE_FIELDS_CODES } from '../../constants/pipedrive';
import { showPipedriveActionSelection, showPipedriveOrgSelection, showPipedriveSuccess } from './modalsReducer';
import {actions as companyReducer }  from "./company/companyReducer"
export const actions = {
    updateCustomerProperty: actionCreator<{ value: string | number; propname: string }>('UPDATE_CUSTOMER_PROPERTY'),
    replaceCustomer: actionCreator<{ customer: CustomerModelEx }>('UPDATE_CUSTOMER'),
    clearCustomer: actionCreator<{}>('CLEAR_CUSTOMER'),
    updateCustomerFromPu: actionCreator<{ productionUnit: ProductionUnit }>('UPDATE_CUSTOMER_FROM_PU'),
    getCvrData: actionCreator<{ cvr: Cvr }>('GET_CVR_DATA'),
    updateCustomerFromCvr: actionCreator<{ cvr: Cvr }>('UPDATE_CUSTOMER_FROM_CVR'),
    updateCustomerExists: actionCreator<{ exists: CustomerModel }>('UPDATE_CUSTOMER_EXISTS'),
    updateCustomerDisabled: actionCreator<{ isDisabled: boolean }>('UPDATE_CUSTOMER_DISABLED'),
    updateCustomerCompany: actionCreator<{ company: any[] }>('UPDATE_CUSTOMER_COMPANY'),
    updateCustomerRecommendation: actionCreator<{ recommendation: any[] }>('UPDATE_CUSTOMER_RECOMENDATION'),
    updateCustomerFromDawa: actionCreator<{ dawa: Dawa }>('UPDATE_CUSTOMER_FROM_DAWA'),
    addDawaToCustomer: actionCreator<{ dawa: Dawa[] }>('ADD_DAWA_TO_CUSTOMER'),
    customerValidation: actionCreator<{}>('CUSTOMER_VALIDATION')
};
export interface CustomerModelEx extends CustomerModel {
    puCollection: ProductionUnit[];
    dawa: Dawa[];
    company: any[];
    recommendation: any[];
    exists: CustomerModel;
    package: any;
    isDisabled: boolean;
    customerValidation?: any;
    cvrData?: Cvr;
}

export default function reducer(
    state: CustomerModelEx = {
        ...CustomerModeldefault,
        id: uuidv4(),
        isDisabled: false,
        company: [],
        recommendation:[],
        exists: null,
        createdBy: JwtService.getUsername(),
        puCollection: [],
        dawa: [],
        customerValidation:{},
        package: null, 
    },
    action: Action<any>
) {
    if (!state) {
        return null;
    } else if (isType(action, actions.updateCustomerProperty)) {
        return { ...state, [action.payload.propname]: action.payload.value };
    } else if (isType(action, actions.replaceCustomer)) {
        return action.payload.customer;
    } else if (isType(action, actions.updateCustomerCompany)) {
        return { ...state, company: action.payload.company };
    } else if (isType(action, actions.updateCustomerRecommendation)) {
        return { ...state, recommendation: action.payload.recommendation };
    } else if (isType(action, actions.addDawaToCustomer)) {
        return { ...state, dawa: action.payload.dawa };

    } else if (isType(action, actions.updateCustomerExists)) {
        return { ...state, exists: action.payload.exists };
    } else if (isType(action, actions.updateCustomerDisabled)) {
        return { ...state, isDisabled: action.payload.isDisabled };
    } else if (isType(action, actions.updateCustomerFromDawa)) {
        const dawa = action.payload.dawa;
        let vej = dawa.adgangsadresse.vejstykke.navn + ' ' + dawa.adgangsadresse.husnr;

        if (dawa.etage) {
            vej += ', ' + dawa.etage + '.';
        }
        if (dawa.dør) {
            vej += ' ' + dawa.dør;
        }
        return {
            ...state,
            address: vej,
            zipCode: dawa.adgangsadresse.postnummer.nr,
            city: dawa.adgangsadresse.postnummer.navn
        };
    } else if (isType(action, actions.updateCustomerFromPu)) {
        const pu = action.payload.productionUnit;
        return {
            ...state,
            address: pu.address,
            city: pu.postalCity,
            zipCode: pu.postalCode,
            name: pu.name,
            pNumber: pu.productionUnitIdentifier,
            phone1: pu.phone,
            email: pu.email,
            industryCode: pu.industrycode
        } as CustomerModel;
    } else if (isType(action, actions.updateCustomerFromCvr)) {
        const cvr = action.payload.cvr;
        const pu = cvr.productionUnits[0];
        return {
            ...state,
            //id: uuidv4(),
            address: pu.address,
            city: pu.postalCity,
            zipCode: pu.postalCode,
            name: pu.name,
            pNumber: pu.productionUnitIdentifier,
            cvr: cvr.valueAddedTaxId,
            phone1: pu.phone,
            email: pu.email,
            industryCode: pu.industrycode,
            puCollection: cvr.productionUnits,
        } as CustomerModel;
    } else if (isType(action, actions.clearCustomer)) {
        return { ...CustomerModeldefault };
    } else if (isType(action, actions.customerValidation)) {
        return { ...state, customerValidation: action.payload };
    } else if (isType(action, actions.getCvrData)) {
        return {
            ...state,
            cvrData: action.payload.cvr
        }
    }
    return state;
}

export const updateCustomerProperty = (value: string, propname: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(actions.updateCustomerProperty({ value, propname }));
    };
};

export const clearCustomer = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(
            actions.replaceCustomer({
                customer: {
                    ...CustomerModeldefault,
                    exists: null,
                    isDisabled: false,
                    puCollection: [],
                    company: [],
                    recommendation:[],
                    id: uuidv4(),
                    createdBy: JwtService.getUsername(),
                    dawa: [],
                    package: null,
                }
            })
        );
    };
};
export const getCompany = (customerId: string) =>{
    return async (dispatch: any, getState: any) => {
        const customer = getState().customer
        AsyncHandler(
            async () => { 
                dispatch(metadataReducer.loading(true, "companyLoading"))
                const company = (await ApiService.searchCompanyByCustomerId(customerId)) as any
                let siteList = [];
                if (company?.status !== "NotFound" ) {
                    for (const key in company){
                        const rv = (await ApiService.getSiteListForCompany(company[key].id)) as any
                        if  (rv){
                            const site = rv[0]
                            site["companyId"] = company[key].id
                            siteList.push(site);                
                        }
                    }
                }
                dispatch(companyReducer.replaceSitelist({siteList}));
                dispatch(actions.updateCustomerCompany({ company  }));
                dispatch(metadataReducer.loading(false))
            },
            dispatch,
            'companyByCustomer'
        );
        // dispatch(actions.replaceCustomer({ customer: { ...CustomerModeldefault, id: uuidv4(), createdBy: JwtService.getUsername() } }));
    };
} 

export const getCustomer = (id: string, getCvrData: boolean) => {
    return async (dispatch: any, getState: any) => {
        AsyncHandler(
            async () => {
                const customer = (await ApiService.getCustomer(id)) as CustomerModelEx;
                dispatch(actions.replaceCustomer({ customer: { ...customer, dawa: [] } }));
                dispatch(getCompany(customer.customerId.toString()))
                dispatch(searchDawa());

                if (getCvrData && customer?.cvr) {
                    const cvr = await ApiService.searchCVR(customer.cvr);
                    dispatch(actions.getCvrData({ cvr }));
                }
            },
            dispatch,
            'customer'
        );

        // dispatch(actions.replaceCustomer({ customer: { ...CustomerModeldefault, id: uuidv4(), createdBy: JwtService.getUsername() } }));
    };
};

export const searchCvr = (text: string) => {
    return async (dispatch: any, getState: any) => {
        try {
            const cvr = await ApiService.searchCVR(text) as any;
            dispatch(actions.customerValidation({cvr: false}))
            dispatch(actions.updateCustomerFromCvr({ cvr }));
            dispatch(searchDawa());
            if (cvr.productionUnits.length > 0) {
                dispatch(customerExists(cvr.productionUnits[0]));
            }
        } catch (error) {
            dispatch(actions.customerValidation({cvr: true}))
        }
    };
};

export const searchDawa = () => {
    return async (dispatch: any, getState: any) => {
        AsyncHandler(
            async () => {
                const dawa = await ApiService.searchDawa(getState().customer);

                dispatch(actions.addDawaToCustomer({ dawa }));
            },
            dispatch,
            'dawa'
        );
    };
};

export const createCustomer = (customer: CustomerModel) => {
    return async (dispatch: any, getState: any) => {
        dispatch(metadataReducer.setIsAsync(true));

        let retryCount = 0;
        const exists = customer.pNumber && await ApiService.getCustomerExists(customer.pNumber);
        if (exists) {
            dispatch(enqueueSnackbar('Customer already exists ', 'warning'));
            dispatch(metadataReducer.setIsAsync(false));
            return;
        }
        
        await ApiService.createCustomer(customer);
        const interval = setInterval(async () => {
            try {
                if (retryCount++ === 10) {
                    dispatch(metadataReducer.setIsAsync(false));
                    dispatch(enqueueSnackbar('Customer not created', 'error'));
                    clearInterval(interval);

                    return;
                }
                customer = await ApiService.getCustomer(customer.id);
                clearInterval(interval);
                dispatch(actions.updateCustomerProperty({ value: customer.customerId, propname: 'customerId' }));
                dispatch(metadataReducer.setIsAsync(false));
                // dispatch(pushCustomerToPipedrive(customer));
                dispatch(enqueueSnackbar('Customer with created with id = ' + customer.customerId, 'success'));

                return;
            } catch (error) {
                console.log(error);
            }
        }, 2000);
    };
};

export const updateCustomer = (customer: CustomerModel) => {
    return async (dispatch: any, getState: any) => {
        AsyncHandler(
            async () => {
                customer.modifiedBy = JwtService.getUsername();
                await ApiService.updateCustomer(customer);
                dispatch(enqueueSnackbar('Customer Updated', 'success'));
            },
            dispatch,
            'replaceCustomer',
            () => {
                dispatch(enqueueSnackbar('Customer Update failed', 'error'));
            }
        );
    };
};

export const deleteCustomer = (customer: CustomerModel) => {
    return async (dispatch: any, getState: any) => {
        AsyncHandler(
            async () => {
                customer.modifiedBy = JwtService.getUsername();
                await ApiService.deleteCustomer(customer);
                dispatch(enqueueSnackbar('Customer Deleted', 'success'));
                dispatch(clearCustomer());
            },
            dispatch,
            'deleteCustomer',
            () => {
                dispatch(enqueueSnackbar('Customer Delete failed', 'error'));
            }
        );
    };
};

export const sendToNavision = ( navisionData: any, method: string) => {
    return async(dispatch: any, getState: any) => {
        AsyncHandler(
            async () => {
                await ApiService.sendToNavision(navisionData, method);
                dispatch(enqueueSnackbar('Sent to Navision', 'success'));
            },
            dispatch,
            'sendToNavision',
            ()=>{
                dispatch(enqueueSnackbar('Faild sent to Navision', 'error'));  
            }
        )
    }
}

export const updateCustomerFromPu = (productionUnit: ProductionUnit) => {
    return async (dispatch: any, getState: any) => {
        dispatch(customerExists(productionUnit));
        dispatch(actions.updateCustomerFromPu({ productionUnit }));
    };
};

export const customerExists = (productionUnit: ProductionUnit) => {
    return async (dispatch: any, getState: any) => {
        try {
            const customer = await ApiService.getCustomerByPNumber(productionUnit.productionUnitIdentifier);
            dispatch(actions.updateCustomerExists({ exists: customer }));
        } catch (error) {
            // console.log(error);
            
            dispatch(actions.updateCustomerExists({ exists: null }));
        }
    };
};

export const updateCustomerFromDawa = (dawa: Dawa) => {
    return async (dispatch: any, getState: any) => {
        dispatch(actions.updateCustomerFromDawa({ dawa }));
    };
};

export const createPipedriveOrg = (customer: CustomerModel) => {
    return async (dispatch: (e: any)=>void) => {
        const rv = await PipederiveApi.createOrg(customer) as any;
        rv.success 
            ? dispatch(showPipedriveSuccess({...rv.data, update: false})) 
            : dispatch(enqueueSnackbar(`Organization create failed `, 'error'));
        
    };
};

export const updatePipedriveOrg = (customer: CustomerModel, orgId: number) => {
    return async (dispatch: (e: any)=>void) => {
        const rv = await PipederiveApi.updadeOrg(customer, orgId) as any;
        rv.success 
            ? dispatch(showPipedriveSuccess({...rv.data, update: true}))
            : dispatch(enqueueSnackbar(`Organization ${rv.data.name} update failed `, 'error'));
       
    };
};

export const getPipeDriveOrg = (orgId: string) => {
    return async () => {
        const rv = await PipederiveApi.searchOrgsByFieldValue(orgId);
    };
};

export const pushCustomerToPipedrive = (customer: CustomerModel) => {
    return async (dispatch: (e: any)=>void) => {
        const searchResults = await PipederiveApi.searchOrgsByFieldValue(customer.cvr);
        if (!searchResults.length) {
            dispatch(createPipedriveOrg(customer));
        }

        if (searchResults.length === 1) {
            const org = searchResults[0].item;
            const organization = await PipederiveApi.getOrgById(org.id);
            if (organization[PIPEDRIVE_FIELDS_CODES.P_NUMBER]) {
                dispatch(updatePipedriveOrg(customer, org.id));
            }

            if (!organization[PIPEDRIVE_FIELDS_CODES.P_NUMBER]) {
                dispatch(showPipedriveActionSelection(customer, org.id));
            }
        }

        if (searchResults.length > 1) {
            const orgs = [] as any;
            const normalizeorg = searchResults.map(o => o.item)
            for (const org in normalizeorg){
                const data = normalizeorg[org]
                const orgResponse = await PipederiveApi.getOrgById(data.id);
                orgs.push(orgResponse);
            }            
            dispatch(showPipedriveOrgSelection(customer, orgs));

        }
    };
};


///Customer recommendations
export const getRecommendations = (id: string | number, place: string) => {
    return async (dispatch: (e: any)=>void) => {
        dispatch(metadataReducer.setIsAsync(true));
        const rv = await ApiService.getCustomerRecommendation(id, place);
        const recommendation = normalizeRecommendation(rv)
        dispatch(metadataReducer.setIsAsync(false));
        dispatch(actions.updateCustomerRecommendation({ recommendation }));
    };
}

const normalizeRecommendation = (recommendation: any) => {
    const packagesData = recommendation;
    const _packages = [];
    const packagesRecommendations = packagesData.Recommendations;
    const packages = packagesRecommendations.Recommendation;
    const products = packagesRecommendations.Products.Product;
    let handledPackages = packages;
    if (!Array.isArray(handledPackages)) {
        handledPackages = [handledPackages];
    }
    handledPackages.map((item: any, index: number) => {
        const { SelectionOptions, TotalPrice, Products } = item;
        const price = TotalPrice.PriceAmount;
        const packageProducts = [];
        let counterValue = 0;

        Products.Id.forEach((packageProductId) => {
            const product = products.find((productItem) => {
                return productItem.Item['@id'] === packageProductId;
            });
            packageProducts.push(product);
        });
        const c = {
            name: item['@name'],
            id: item["@packageId"],
            price: price['#text'],
            currency: price['@currencyCode'],
            description: SelectionOptions['@PriceOptionText'],
            counterValue: counterValue,
            productsData: packageProducts.map(c=> ({name: c["Item"].Name, icon: c["Icon"], description: c["Item"].MapsTo.Item.Description})),
            recommendText: SelectionOptions['@RecommendedText'],
            duration: item['@durationInDays'],
        } as any             
        _packages.push(c)
    }); 
    return _packages;
}
// export const getUserByEmail = (userName: string) => {
//     return async (dispatch: any, getState: any) => {
//         try {
//             const customer = await ApiService.getUserByName(userName);
//             dispatch(actions.updateCustomer({ exists: customer }));
//         } catch (error) {
//             console.log(error);
            
//             dispatch(actions.updateCustomerExists({ exists: null }));
//         }
//     };
// };
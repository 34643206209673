import { Metadata, errorsDefault } from '../models/meta';
import { actionCreator, Action, isType } from '../Action';
export const actions = {
    updateIsAsync: actionCreator<{ isAsyncRunning: boolean }>('IS_ASYNC'),
    updateIsLoading: actionCreator<{ loading: boolean, name: string }>('IS_LOADING'),
    updateErrors: actionCreator<{ prop: string; hasError: boolean }>('UPDATE_HAS_ERRORS'),
};

export default function reducer(state: Metadata = { isAsyncRunning: false, errors: errorsDefault}, action: Action<any>) {
    if (!state) {
        return null;
    }
    if (isType(action, actions.updateIsAsync)) {
        return { ...state, isAsyncRunning: action.payload.isAsyncRunning};
    }
    if (isType(action, actions.updateIsLoading)) {
        return { ...state, loading: action.payload.loading, name: action.payload.name};
    }
    if (isType(action, actions.updateErrors)) {
        const errors = { ...state.errors };
        errors[action.payload.prop] = action.payload.hasError;
        return { ...state, errors };
    }
    return state;
}
export const loading = (loading: boolean, name?: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(actions.updateIsLoading({ loading, name}));
    };
};
export const setIsAsync = (isAsyncRunning: boolean, loading?: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(actions.updateIsAsync({ isAsyncRunning}));
    };
};
export const updateErrors = (prop: string, hasError: boolean) => {
    return async (dispatch: any, getState: any) => {
        dispatch(actions.updateErrors({ prop, hasError }));
    };
};

/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "ms-rest-js";
import * as Models from "./models";
import * as Mappers from "./models/mappers";
import { CustomerWebAPIContext } from "./customerWebAPIContext";
const WebResource = msRest.WebResource;

class CustomerWebAPI extends CustomerWebAPIContext {
  serializer = new msRest.Serializer(Mappers);

  /**
   * @class
   * Initializes a new instance of the CustomerWebAPI class.
   * @constructor
   *
   * @param {string} [baseUri] - The base URI of the service.
   *
   * @param {object} [options] - The parameter options
   *
   * @param {Array} [options.filters] - Filters to be added to the request pipeline
   *
   * @param {object} [options.requestOptions] - The request options. Detailed info can be found at
   * {@link https://github.github.io/fetch/#Request Options doc}
   *
   * @param {boolean} [options.noRetryPolicy] - If set to true, turn off default retry policy
   *
   */
  constructor(credentials: msRest.ServiceClientCredentials, baseUri?: string, options?: msRest.ServiceClientOptions) {
    super(credentials, baseUri, options);
  }
  // methods on the client.

  /**
   * @param {CustomerWebAPIGetTokenOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getTokenWithHttpOperationResponse(options?: Models.CustomerWebAPIGetTokenOptionalParams): Promise<msRest.HttpOperationResponse<Models.TokenResponse>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/Login",
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.LoginRequest
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {
              bodyMapper: Mappers.TokenResponse
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.TokenResponse;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {CustomerModel} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async createCustomerWithHttpOperationResponse(body: Models.CustomerModel, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.CustomerModel>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/createcustomer",
          requestBody: {
            parameterPath: "body",
            mapper: {
              ...Mappers.CustomerModel,
              required: true
            }
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {
              bodyMapper: Mappers.CustomerModel
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.CustomerModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {DeleteCustomerMessage} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async deleteCustomerWithHttpOperationResponse(body: Models.DeleteCustomerMessage, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "DELETE",
          baseUrl: this.baseUri,
          path: "api/deletecustomer",
          requestBody: {
            parameterPath: "body",
            mapper: {
              ...Mappers.DeleteCustomerMessage,
              required: true
            }
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {CustomerModel} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async updateCustomerWithHttpOperationResponse(body: Models.CustomerModel, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PUT",
          baseUrl: this.baseUri,
          path: "api/Updatecustomer",
          requestBody: {
            parameterPath: "body",
            mapper: {
              ...Mappers.CustomerModel,
              required: true
            }
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {CustomerWebAPICompaniesPostAsyncOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async companiesPostAsyncWithHttpOperationResponse(siteid: string, options?: Models.CustomerWebAPICompaniesPostAsyncOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/companies",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.CreateCompanyViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {string} siteid
   *
   * @param {CustomerWebAPICompaniesPutAsyncOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async companiesPutAsyncWithHttpOperationResponse(id: string, siteid: string, options?: Models.CustomerWebAPICompaniesPutAsyncOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id,
          siteid,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PUT",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/companies/{id}",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            },
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.UpdateCompanyViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {string} siteid
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async companiesDeleteAsyncWithHttpOperationResponse(id: string, siteid: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id,
          siteid
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "DELETE",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/companies/{id}",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            },
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} userName
   *
   * @param {CustomerWebAPIPasswordResetUserPasswordOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async passwordResetUserPasswordWithHttpOperationResponse(userName: string, options?: Models.CustomerWebAPIPasswordResetUserPasswordOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          userName,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PATCH",
          baseUrl: this.baseUri,
          path: "api/users/by-name/{userName}/password/reset",
          urlParameters: [
            {
              parameterPath: "userName",
              mapper: {
                required: true,
                serializedName: "userName",
                type: {
                  name: "String"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.ResetPasswordViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} userName
   *
   * @param {CustomerWebAPIPasswordSetNewPasswordOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async passwordSetNewPasswordWithHttpOperationResponse(userName: string, options?: Models.CustomerWebAPIPasswordSetNewPasswordOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          userName,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PATCH",
          baseUrl: this.baseUri,
          path: "api/users/by-name/{userName}/password/new",
          urlParameters: [
            {
              parameterPath: "userName",
              mapper: {
                required: true,
                serializedName: "userName",
                type: {
                  name: "String"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.NewPasswordViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteId
   *
   * @param {CustomerWebAPIRolesCreateRoleOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async rolesCreateRoleWithHttpOperationResponse(siteId: string, options?: Models.CustomerWebAPIRolesCreateRoleOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteId,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/sites/{siteId}/roles",
          urlParameters: [
            {
              parameterPath: "siteId",
              mapper: {
                required: true,
                serializedName: "siteId",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.CreateRoleViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteId
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async rolesGetRolesAsyncWithHttpOperationResponse(siteId: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteId
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/{siteId}/roles",
          urlParameters: [
            {
              parameterPath: "siteId",
              mapper: {
                required: true,
                serializedName: "siteId",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {CustomerWebAPISharedRolesCreateGlobalRoleOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async sharedRolesCreateGlobalRoleWithHttpOperationResponse(options?: Models.CustomerWebAPISharedRolesCreateGlobalRoleOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/roles",
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.CreateRoleViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {CustomerWebAPISharedUsersCreateGlobalUserOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async sharedUsersCreateGlobalUserWithHttpOperationResponse(options?: Models.CustomerWebAPISharedUsersCreateGlobalUserOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/users",
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.CreateUserViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {CustomerWebAPISharedUsersUpdateGlobalUserOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async sharedUsersUpdateGlobalUserWithHttpOperationResponse(id: string, options?: Models.CustomerWebAPISharedUsersUpdateGlobalUserOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PUT",
          baseUrl: this.baseUri,
          path: "api/users/{id}",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.UpdateUserViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async sharedUsersDeleteGlobalUserWithHttpOperationResponse(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "DELETE",
          baseUrl: this.baseUri,
          path: "api/users/{id}",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteId
   *
   * @param {CustomerWebAPIUsersCreateUserOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async usersCreateUserWithHttpOperationResponse(siteId: string, options?: Models.CustomerWebAPIUsersCreateUserOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteId,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/sites/{siteId}/users",
          urlParameters: [
            {
              parameterPath: "siteId",
              mapper: {
                required: true,
                serializedName: "siteId",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.CreateUserViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteId
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async usersFindUserBySiteIdWithHttpOperationResponse(siteId: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteId
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/{siteId}/users",
          urlParameters: [
            {
              parameterPath: "siteId",
              mapper: {
                required: true,
                serializedName: "siteId",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteId
   *
   * @param {CustomerWebAPIUsersInitUserForNewSiteOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async usersInitUserForNewSiteWithHttpOperationResponse(siteId: string, options?: Models.CustomerWebAPIUsersInitUserForNewSiteOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteId,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/sites/{siteId}/users/init",
          urlParameters: [
            {
              parameterPath: "siteId",
              mapper: {
                required: true,
                serializedName: "siteId",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.AddSiteCreationUserViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteId
   *
   * @param {string} id
   *
   * @param {CustomerWebAPIUsersChangeUserPasswordOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async usersChangeUserPasswordWithHttpOperationResponse(siteId: string, id: string, options?: Models.CustomerWebAPIUsersChangeUserPasswordOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteId,
          id,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PUT",
          baseUrl: this.baseUri,
          path: "api/sites/{siteId}/users/{id}/changepassword",
          urlParameters: [
            {
              parameterPath: "siteId",
              mapper: {
                required: true,
                serializedName: "siteId",
                type: {
                  name: "Uuid"
                }
              }
            },
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.ChangePasswordViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteId
   *
   * @param {string} id
   *
   * @param {CustomerWebAPIUsersUpdateUserOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async usersUpdateUserWithHttpOperationResponse(siteId: string, id: string, options?: Models.CustomerWebAPIUsersUpdateUserOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteId,
          id,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PUT",
          baseUrl: this.baseUri,
          path: "api/sites/{siteId}/users/{id}",
          urlParameters: [
            {
              parameterPath: "siteId",
              mapper: {
                required: true,
                serializedName: "siteId",
                type: {
                  name: "Uuid"
                }
              }
            },
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.UpdateUserViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteId
   *
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async usersDeleteUserWithHttpOperationResponse(siteId: string, id: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteId,
          id
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "DELETE",
          baseUrl: this.baseUri,
          path: "api/sites/{siteId}/users/{id}",
          urlParameters: [
            {
              parameterPath: "siteId",
              mapper: {
                required: true,
                serializedName: "siteId",
                type: {
                  name: "Uuid"
                }
              }
            },
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {CustomerWebAPIUpdateUserByCustomerToolOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async updateUserByCustomerToolWithHttpOperationResponse(id: string, options?: Models.CustomerWebAPIUpdateUserByCustomerToolOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PUT",
          baseUrl: this.baseUri,
          path: "api/user/{id}",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.UpdateUserViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async usersFindUserByIdWithHttpOperationResponse(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/user/{id}",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {CustomerWebAPICreateSiteOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async createSiteWithHttpOperationResponse(options?: Models.CustomerWebAPICreateSiteOptionalParams): Promise<msRest.HttpOperationResponse<string>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/Sites",
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.PostSiteViewModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {
              bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                  name: "Uuid"
                }
              }
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = {
              serializedName: "parsedResponse",
              type: {
                name: "Uuid"
              }
            };
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {PutSiteViewModel} body
   *
   * @param {CustomerWebAPIUpdateSiteOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async updateSiteWithHttpOperationResponse(id: string, body: Models.PutSiteViewModel, options?: Models.CustomerWebAPIUpdateSiteOptionalParams): Promise<msRest.HttpOperationResponse<string>> {
    let isValidateHost = (options && options.isValidateHost !== undefined) ? options.isValidateHost : true;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id,
          isValidateHost,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PUT",
          baseUrl: this.baseUri,
          path: "api/Sites/{id}",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          queryParameters: [
            {
              parameterPath: "isValidateHost",
              mapper: {
                serializedName: "isValidateHost",
                defaultValue: true,
                type: {
                  name: "Boolean"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: {
              ...Mappers.PutSiteViewModel,
              required: true
            }
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {
              bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                  name: "Uuid"
                }
              }
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = {
              serializedName: "parsedResponse",
              type: {
                name: "Uuid"
              }
            };
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async sitesDeleteSiteWithHttpOperationResponse(siteid: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "DELETE",
          baseUrl: this.baseUri,
          path: "api/Sites/{siteid}",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {CustomerWebAPISitesMergeSiteOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async sitesMergeSiteWithHttpOperationResponse(siteid: string, options?: Models.CustomerWebAPISitesMergeSiteOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let targetSiteId = (options && options.targetSiteId !== undefined) ? options.targetSiteId : undefined;
    let targetCompanyId = (options && options.targetCompanyId !== undefined) ? options.targetCompanyId : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          targetSiteId,
          targetCompanyId
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "DELETE",
          baseUrl: this.baseUri,
          path: "api/Sites/{siteid}/merge",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          queryParameters: [
            {
              parameterPath: "targetSiteId",
              mapper: {
                serializedName: "targetSiteId",
                type: {
                  name: "Uuid"
                }
              }
            },
            {
              parameterPath: "targetCompanyId",
              mapper: {
                serializedName: "targetCompanyId",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {InternalSiteCreationRequest} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async internalSiteCreationV2WithHttpOperationResponse(body: Models.InternalSiteCreationRequest, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.SiteCreateResult>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/sites/new",
          requestBody: {
            parameterPath: "body",
            mapper: {
              ...Mappers.InternalSiteCreationRequest,
              required: true
            }
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {
              bodyMapper: Mappers.SiteCreateResult
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.SiteCreateResult;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteId
   *
   * @param {PersistingCssRequestModel} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async generateCssAndSaveItToSharedFolderWithHttpOperationResponse(siteId: string, body: Models.PersistingCssRequestModel, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.CssGeneratingResponseModel>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteId,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/sites/{siteId}/css",
          urlParameters: [
            {
              parameterPath: "siteId",
              mapper: {
                required: true,
                serializedName: "siteId",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: {
              ...Mappers.PersistingCssRequestModel,
              required: true
            }
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {
              bodyMapper: Mappers.CssGeneratingResponseModel
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.CssGeneratingResponseModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {CustomerWebAPICssGeneratorGenerateCssAsyncOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async cssGeneratorGenerateCssAsyncWithHttpOperationResponse(options?: Models.CustomerWebAPICssGeneratorGenerateCssAsyncOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/sites/css",
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.CssGeneratingRequestModel
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {ExtendedJobAdModel} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async createJobAdWithHttpOperationResponse(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.ExtendedJobAdModel>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          jobadid,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/jobads/{jobadid}",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "jobadid",
              mapper: {
                required: true,
                serializedName: "jobadid",
                type: {
                  name: "String"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: {
              ...Mappers.ExtendedJobAdModel,
              required: true
            }
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            201: {
              bodyMapper: Mappers.ExtendedJobAdModel
            },
            500: {},
            400: {},
            401: {},
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 201) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.ExtendedJobAdModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {ExtendedJobAdModel} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async updateJobAdWithHttpOperationResponse(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.ExtendedJobAdModel>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          jobadid,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PUT",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/jobads/{jobadid}",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "jobadid",
              mapper: {
                required: true,
                serializedName: "jobadid",
                type: {
                  name: "String"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: {
              ...Mappers.ExtendedJobAdModel,
              required: true
            }
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            202: {
              bodyMapper: Mappers.ExtendedJobAdModel
            },
            401: {},
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 202) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.ExtendedJobAdModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async jobAdDeleteWithHttpOperationResponse(siteid: string, jobadid: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.JobAdResponce>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          jobadid
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "DELETE",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/jobads/{jobadid}",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "jobadid",
              mapper: {
                required: true,
                serializedName: "jobadid",
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            202: {
              bodyMapper: Mappers.JobAdResponce
            },
            500: {},
            400: {},
            401: {},
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 202) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.JobAdResponce;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getJobAdWithHttpOperationResponse(siteid: string, jobadid: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.JobAdModel>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          jobadid
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/jobads/{jobadid}",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "jobadid",
              mapper: {
                required: true,
                serializedName: "jobadid",
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.JobAdModel
            },
            500: {},
            404: {},
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.JobAdModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {MsgJobAd} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async setSuperAdminDeleteStatusWithHttpOperationResponse(siteid: string, jobadid: string, body: Models.MsgJobAd, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.SetSuperAdminDeleteStatusResponce>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          jobadid,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PATCH",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/jobads/{jobadid}/setsuperadmindeletestatus",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "jobadid",
              mapper: {
                required: true,
                serializedName: "jobadid",
                type: {
                  name: "String"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: {
              ...Mappers.MsgJobAd,
              required: true
            }
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {
              bodyMapper: Mappers.SetSuperAdminDeleteStatusResponce
            },
            500: {},
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.SetSuperAdminDeleteStatusResponce;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {ExtendedJobAdModel} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async jobAdUpdateStatusWithHttpOperationResponse(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.ExtendedJobAdModel>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          jobadid,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PATCH",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/jobads/{jobadid}/updatestatus",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "jobadid",
              mapper: {
                required: true,
                serializedName: "jobadid",
                type: {
                  name: "String"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: {
              ...Mappers.ExtendedJobAdModel,
              required: true
            }
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            202: {
              bodyMapper: Mappers.ExtendedJobAdModel
            },
            500: {},
            401: {},
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 202) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.ExtendedJobAdModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {MsgJobAd} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async jobAdUpdateStatusForJqueryWithHttpOperationResponse(siteid: string, jobadid: string, body: Models.MsgJobAd, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.UpdateStatusResponce>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          jobadid,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PUT",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/jobads/{jobadid}/updatestatusforjquery",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "jobadid",
              mapper: {
                required: true,
                serializedName: "jobadid",
                type: {
                  name: "String"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: {
              ...Mappers.MsgJobAd,
              required: true
            }
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            202: {
              bodyMapper: Mappers.UpdateStatusResponce
            },
            500: {},
            400: {},
            401: {},
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 202) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.UpdateStatusResponce;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {PatchProductModelRequest} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async jobAdPatchProductsWithHttpOperationResponse(siteid: string, jobadid: string, body: Models.PatchProductModelRequest, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          jobadid,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PATCH",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/jobads/{jobadid}/products",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "jobadid",
              mapper: {
                required: true,
                serializedName: "jobadid",
                type: {
                  name: "String"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: {
              ...Mappers.PatchProductModelRequest,
              required: true
            }
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            400: {},
            401: {},
            500: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @summary PatchDescription
   *
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {number} siteType
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async jobAdPatchDescriptionWithHttpOperationResponse(siteid: string, jobadid: string, siteType: number, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          jobadid,
          siteType
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PATCH",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/jobads/{jobadid}/resendDescription/{siteType}",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "jobadid",
              mapper: {
                required: true,
                serializedName: "jobadid",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "siteType",
              mapper: {
                required: true,
                serializedName: "siteType",
                type: {
                  name: "Number"
                }
              }
            }
          ],
          responses: {
            200: {},
            400: {},
            401: {},
            500: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @summary PatchDescription
   *
   * @param {string} siteid
   *
   * @param {number} siteType
   *
   * @param {CustomerWebAPIJobAdPatchDescription1OptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async jobAdPatchDescription1WithHttpOperationResponse(siteid: string, siteType: number, options?: Models.CustomerWebAPIJobAdPatchDescription1OptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let jobadid = (options && options.jobadid !== undefined) ? options.jobadid : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          jobadid,
          siteType
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "PATCH",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/resendDescription/{siteType}",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "siteType",
              mapper: {
                required: true,
                serializedName: "siteType",
                type: {
                  name: "Number"
                }
              }
            }
          ],
          queryParameters: [
            {
              parameterPath: "jobadid",
              mapper: {
                serializedName: "jobadid",
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            200: {},
            400: {},
            401: {},
            500: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {CustomerWebAPICreatePartnerJobAdOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async createPartnerJobAdWithHttpOperationResponse(siteid: string, options?: Models.CustomerWebAPICreatePartnerJobAdOptionalParams): Promise<msRest.HttpOperationResponse<Models.PartnerJobAdResponce>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/partner/sites/{siteid}/jobads",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            }
          ],
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.EncryptedData
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            201: {
              bodyMapper: Mappers.PartnerJobAdResponce
            },
            400: {},
            401: {},
            500: {},
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 201) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.PartnerJobAdResponce;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} searchText
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async searchCustomerWithHttpOperationResponse(searchText: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.CustomerModel[]>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          searchText
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/SearchCustomer/{searchText}",
          urlParameters: [
            {
              parameterPath: "searchText",
              mapper: {
                required: true,
                serializedName: "searchText",
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                  name: "Sequence",
                  element: {
                    serializedName: "CustomerModelElementType",
                    type: {
                      name: "Composite",
                      className: "CustomerModel"
                    }
                  }
                }
              }
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = {
              serializedName: "parsedResponse",
              type: {
                name: "Sequence",
                element: {
                  serializedName: "CustomerModelElementType",
                  type: {
                    name: "Composite",
                    className: "CustomerModel"
                  }
                }
              }
            };
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} pNumber
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getCustomerExistsWithHttpOperationResponse(pNumber: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<boolean>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          pNumber
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/SearchCustomer/exists/{pNumber}",
          urlParameters: [
            {
              parameterPath: "pNumber",
              mapper: {
                required: true,
                serializedName: "pNumber",
                defaultValue: '',
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                  name: "Boolean"
                }
              }
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = {
              serializedName: "parsedResponse",
              type: {
                name: "Boolean"
              }
            };
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} pNumber
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getCustomerByPnumberWithHttpOperationResponse(pNumber: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.CustomerModel>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          pNumber
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/SearchCustomer/bypnumber/{pNumber}",
          urlParameters: [
            {
              parameterPath: "pNumber",
              mapper: {
                required: true,
                serializedName: "pNumber",
                defaultValue: '',
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.CustomerModel
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.CustomerModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getCustomerWithHttpOperationResponse(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.CustomerModel>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/SearchCustomer/byid/{id}",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.CustomerModel
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.CustomerModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} cvr
   *
   * @param {string} pnumber
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getCustomersByCvrAndPnumberWithHttpOperationResponse(cvr: string, pnumber: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.CustomerModel[]>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          cvr,
          pnumber
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/SearchCustomer/bycvrandpnumber/{cvr}",
          urlParameters: [
            {
              parameterPath: "cvr",
              mapper: {
                required: true,
                serializedName: "cvr",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "pnumber",
              mapper: {
                required: true,
                serializedName: "pnumber",
                defaultValue: '',
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                  name: "Sequence",
                  element: {
                    serializedName: "CustomerModelElementType",
                    type: {
                      name: "Composite",
                      className: "CustomerModel"
                    }
                  }
                }
              }
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = {
              serializedName: "parsedResponse",
              type: {
                name: "Sequence",
                element: {
                  serializedName: "CustomerModelElementType",
                  type: {
                    name: "Composite",
                    className: "CustomerModel"
                  }
                }
              }
            };
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} cvr
   *
   * @param {string} pnumber
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getCustomersByCvrAndPnumber1WithHttpOperationResponse(cvr: string, pnumber: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.CustomerModel[]>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          cvr,
          pnumber
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/SearchCustomer/bycvrandpnumber/{cvr}/{pnumber}",
          urlParameters: [
            {
              parameterPath: "cvr",
              mapper: {
                required: true,
                serializedName: "cvr",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "pnumber",
              mapper: {
                required: true,
                serializedName: "pnumber",
                defaultValue: '',
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                  name: "Sequence",
                  element: {
                    serializedName: "CustomerModelElementType",
                    type: {
                      name: "Composite",
                      className: "CustomerModel"
                    }
                  }
                }
              }
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = {
              serializedName: "parsedResponse",
              type: {
                name: "Sequence",
                element: {
                  serializedName: "CustomerModelElementType",
                  type: {
                    name: "Composite",
                    className: "CustomerModel"
                  }
                }
              }
            };
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getNextCustomerIdWithHttpOperationResponse(options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<number>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments({}, options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/SearchCustomer/nextcustomerid",
          responses: {
            200: {
              bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                  name: "Number"
                }
              }
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = {
              serializedName: "parsedResponse",
              type: {
                name: "Number"
              }
            };
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {number} customerid
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getCustomerByCustomerIdWithHttpOperationResponse(customerid: number, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.CustomerModel>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          customerid
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/SearchCustomer/bycustomerid/{customerid}",
          urlParameters: [
            {
              parameterPath: "customerid",
              mapper: {
                required: true,
                serializedName: "customerid",
                type: {
                  name: "Number"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.CustomerModel
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.CustomerModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async companiesGetWithHttpOperationResponse(options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments({}, options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteId
   *
   * @param {CustomerWebAPICompaniesGet1OptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async companiesGet1WithHttpOperationResponse(siteId: string, options?: Models.CustomerWebAPICompaniesGet1OptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let pageSize = (options && options.pageSize !== undefined) ? options.pageSize : undefined;
    let page = (options && options.page !== undefined) ? options.page : 1;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteId,
          pageSize,
          page
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/{siteId}/companies",
          urlParameters: [
            {
              parameterPath: "siteId",
              mapper: {
                required: true,
                serializedName: "siteId",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          queryParameters: [
            {
              parameterPath: "pageSize",
              mapper: {
                serializedName: "pageSize",
                type: {
                  name: "Number"
                }
              }
            },
            {
              parameterPath: "page",
              mapper: {
                serializedName: "page",
                defaultValue: 1,
                type: {
                  name: "Number"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteId
   *
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async companiesGet2WithHttpOperationResponse(siteId: string, id: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteId,
          id
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/{siteId}/companies/{id}",
          urlParameters: [
            {
              parameterPath: "siteId",
              mapper: {
                required: true,
                serializedName: "siteId",
                type: {
                  name: "Uuid"
                }
              }
            },
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteId
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async companiesGet3WithHttpOperationResponse(siteId: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteId
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/{siteId}/companies/partner",
          urlParameters: [
            {
              parameterPath: "siteId",
              mapper: {
                required: true,
                serializedName: "siteId",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} companyName
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async companiesFindCompanyByNameWithHttpOperationResponse(companyName: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          companyName
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/companies/by-name/{companyName}",
          urlParameters: [
            {
              parameterPath: "companyName",
              mapper: {
                required: true,
                serializedName: "companyName",
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} customerId
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async companiesFindCompanyByCustomerWithHttpOperationResponse(customerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          customerId
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/companies/by-customer/{customerId}",
          urlParameters: [
            {
              parameterPath: "customerId",
              mapper: {
                required: true,
                serializedName: "customerId",
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {CustomerWebAPICompaniesFindCompaniesOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async companiesFindCompaniesWithHttpOperationResponse(options?: Models.CustomerWebAPICompaniesFindCompaniesOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let searchValue = (options && options.searchValue !== undefined) ? options.searchValue : undefined;
    let noCrmId = (options && options.noCrmId !== undefined) ? options.noCrmId : false;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          searchValue,
          noCrmId
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/companies/byparameters",
          queryParameters: [
            {
              parameterPath: "searchValue",
              mapper: {
                serializedName: "searchValue",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "noCrmId",
              mapper: {
                serializedName: "noCrmId",
                defaultValue: false,
                type: {
                  name: "Boolean"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} vatnumber
   *
   * @param {CustomerWebAPICompaniesFindCompaniesByVatNumberOptionalParams} [options] Optional
   * Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async companiesFindCompaniesByVatNumberWithHttpOperationResponse(vatnumber: string, options?: Models.CustomerWebAPICompaniesFindCompaniesByVatNumberOptionalParams): Promise<msRest.HttpOperationResponse<Models.CompanyWithSiteTypeModel[]>> {
    let emptyCrmId = (options && options.emptyCrmId !== undefined) ? options.emptyCrmId : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          vatnumber,
          emptyCrmId
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/companies/byvatnumber/{vatnumber}",
          urlParameters: [
            {
              parameterPath: "vatnumber",
              mapper: {
                required: true,
                serializedName: "vatnumber",
                type: {
                  name: "String"
                }
              }
            }
          ],
          queryParameters: [
            {
              parameterPath: "emptyCrmId",
              mapper: {
                serializedName: "emptyCrmId",
                type: {
                  name: "Boolean"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                  name: "Sequence",
                  element: {
                    serializedName: "CompanyWithSiteTypeModelElementType",
                    type: {
                      name: "Composite",
                      className: "CompanyWithSiteTypeModel"
                    }
                  }
                }
              }
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = {
              serializedName: "parsedResponse",
              type: {
                name: "Sequence",
                element: {
                  serializedName: "CompanyWithSiteTypeModelElementType",
                  type: {
                    name: "Composite",
                    className: "CompanyWithSiteTypeModel"
                  }
                }
              }
            };
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async partnerCompaniesGetWithHttpOperationResponse(options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments({}, options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/partner",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {CustomerWebAPIPartnerCompaniesFindCompanyByVatIdOptionalParams} [options] Optional
   * Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async partnerCompaniesFindCompanyByVatIdWithHttpOperationResponse(options?: Models.CustomerWebAPIPartnerCompaniesFindCompanyByVatIdOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/parner/companies/by-vat",
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.EncryptedData
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async variableCompaniesGetWithHttpOperationResponse(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/companies/{id}/email",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {CustomerWebAPIPartnerUsersFindBySiteIdOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async partnerUsersFindBySiteIdWithHttpOperationResponse(options?: Models.CustomerWebAPIPartnerUsersFindBySiteIdOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/partner/users/bysiteid",
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.EncryptedData
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} userName
   *
   * @param {string} token
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async passwordsValidateResetPasswordTokenAsyncWithHttpOperationResponse(userName: string, token: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          userName,
          token
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/users/by-name/{userName}/password/validate-token/{token}",
          urlParameters: [
            {
              parameterPath: "userName",
              mapper: {
                required: true,
                serializedName: "userName",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "token",
              mapper: {
                required: true,
                serializedName: "token",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {CustomerWebAPIUsersGetUsersAsyncOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async usersGetUsersAsyncWithHttpOperationResponse(options?: Models.CustomerWebAPIUsersGetUsersAsyncOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let searchIn = (options && options.searchIn !== undefined) ? options.searchIn : undefined;
    let searchFor = (options && options.searchFor !== undefined) ? options.searchFor : undefined;
    let includeRoles = (options && options.includeRoles !== undefined) ? options.includeRoles : undefined;
    let includePassword = (options && options.includePassword !== undefined) ? options.includePassword : undefined;
    let page = (options && options.page !== undefined) ? options.page : undefined;
    let pageSize = (options && options.pageSize !== undefined) ? options.pageSize : undefined;
    let siteId = (options && options.siteId !== undefined) ? options.siteId : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          searchIn,
          searchFor,
          includeRoles,
          includePassword,
          page,
          pageSize,
          siteId
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/Users",
          queryParameters: [
            {
              parameterPath: "searchIn",
              mapper: {
                serializedName: "SearchIn",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "searchFor",
              mapper: {
                serializedName: "SearchFor",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "includeRoles",
              mapper: {
                serializedName: "IncludeRoles",
                type: {
                  name: "Boolean"
                }
              }
            },
            {
              parameterPath: "includePassword",
              mapper: {
                serializedName: "IncludePassword",
                type: {
                  name: "Boolean"
                }
              }
            },
            {
              parameterPath: "page",
              mapper: {
                serializedName: "Page",
                type: {
                  name: "Number"
                }
              }
            },
            {
              parameterPath: "pageSize",
              mapper: {
                serializedName: "PageSize",
                type: {
                  name: "Number"
                }
              }
            },
            {
              parameterPath: "siteId",
              mapper: {
                serializedName: "SiteId",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {CustomerWebAPIUsersGetUserAsyncOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async usersGetUserAsyncWithHttpOperationResponse(id: string, options?: Models.CustomerWebAPIUsersGetUserAsyncOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let includeRoles = (options && options.includeRoles !== undefined) ? options.includeRoles : undefined;
    let siteId = (options && options.siteId !== undefined) ? options.siteId : undefined;
    let includePassword = (options && options.includePassword !== undefined) ? options.includePassword : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id,
          includeRoles,
          siteId,
          includePassword
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/Users/{id}",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          queryParameters: [
            {
              parameterPath: "includeRoles",
              mapper: {
                serializedName: "IncludeRoles",
                type: {
                  name: "Boolean"
                }
              }
            },
            {
              parameterPath: "siteId",
              mapper: {
                serializedName: "SiteId",
                type: {
                  name: "Uuid"
                }
              }
            },
            {
              parameterPath: "includePassword",
              mapper: {
                serializedName: "IncludePassword",
                type: {
                  name: "Boolean"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} username
   *
   * @param {CustomerWebAPIUsersGetUserAsync1OptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async usersGetUserAsync1WithHttpOperationResponse(username: string, options?: Models.CustomerWebAPIUsersGetUserAsync1OptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let includeRoles = (options && options.includeRoles !== undefined) ? options.includeRoles : undefined;
    let siteId = (options && options.siteId !== undefined) ? options.siteId : undefined;
    let includePassword = (options && options.includePassword !== undefined) ? options.includePassword : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          username,
          includeRoles,
          siteId,
          includePassword
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/Users/ByName/{username}",
          urlParameters: [
            {
              parameterPath: "username",
              mapper: {
                required: true,
                serializedName: "username",
                type: {
                  name: "String"
                }
              }
            }
          ],
          queryParameters: [
            {
              parameterPath: "includeRoles",
              mapper: {
                serializedName: "IncludeRoles",
                type: {
                  name: "Boolean"
                }
              }
            },
            {
              parameterPath: "siteId",
              mapper: {
                serializedName: "SiteId",
                type: {
                  name: "Uuid"
                }
              }
            },
            {
              parameterPath: "includePassword",
              mapper: {
                serializedName: "IncludePassword",
                type: {
                  name: "Boolean"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {CustomerWebAPIUsersFindUsersOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async usersFindUsersWithHttpOperationResponse(options?: Models.CustomerWebAPIUsersFindUsersOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/users/by-parameters",
          requestBody: {
            parameterPath: "body",
            mapper: Mappers.SearchParams
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async variableUsersGetUserAsyncWithHttpOperationResponse(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<void>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/users/{id}/email",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {CustomerWebAPIGetSitesOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getSitesWithHttpOperationResponse(options?: Models.CustomerWebAPIGetSitesOptionalParams): Promise<msRest.HttpOperationResponse<Models.IHaveSiteModel>> {
    let searchIn = (options && options.searchIn !== undefined) ? options.searchIn : undefined;
    let searchFor = (options && options.searchFor !== undefined) ? options.searchFor : undefined;
    let pageSize = (options && options.pageSize !== undefined) ? options.pageSize : undefined;
    let page = (options && options.page !== undefined) ? options.page : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          searchIn,
          searchFor,
          pageSize,
          page
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites",
          queryParameters: [
            {
              parameterPath: "searchIn",
              mapper: {
                serializedName: "SearchIn",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "searchFor",
              mapper: {
                serializedName: "SearchFor",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "pageSize",
              mapper: {
                serializedName: "PageSize",
                type: {
                  name: "Number"
                }
              }
            },
            {
              parameterPath: "page",
              mapper: {
                serializedName: "Page",
                type: {
                  name: "Number"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.IHaveSiteModel
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.IHaveSiteModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getSiteByIdWithHttpOperationResponse(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.SiteEntity>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/ById/{id}",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.SiteEntity
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.SiteEntity;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async sitesGetSiteByCompanyIdWithHttpOperationResponse(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.SiteEntity>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/ByCompanyId/{id}",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.SiteEntity
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.SiteEntity;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {CustomerWebAPISitesGetSiteByIdsOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async sitesGetSiteByIdsWithHttpOperationResponse(options?: Models.CustomerWebAPISitesGetSiteByIdsOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let body = (options && options.body !== undefined) ? options.body : undefined;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          body
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "POST",
          baseUrl: this.baseUri,
          path: "api/sites/ByIds",
          requestBody: {
            parameterPath: "body",
            mapper: {
              serializedName: "body",
              type: {
                name: "Sequence",
                element: {
                  serializedName: "stringElementType",
                  type: {
                    name: "Uuid"
                  }
                }
              }
            }
          },
          contentType: "application/json-patch+json; charset=utf-8",
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getEmailSiteByIdWithHttpOperationResponse(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.IHaveEmailSiteModel>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          id
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/ById/{id}/email",
          urlParameters: [
            {
              parameterPath: "id",
              mapper: {
                required: true,
                serializedName: "id",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.IHaveEmailSiteModel
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.IHaveEmailSiteModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} host
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getSiteByHostWithHttpOperationResponse(host: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.IHaveSiteModel>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          host
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/ByHost/{host}",
          urlParameters: [
            {
              parameterPath: "host",
              mapper: {
                required: true,
                serializedName: "host",
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.IHaveSiteModel
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.IHaveSiteModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} host
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getSitesByHostWithHttpOperationResponse(host: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.IHaveSiteModel>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          host
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/ByList/ByHost/{host}",
          urlParameters: [
            {
              parameterPath: "host",
              mapper: {
                required: true,
                serializedName: "host",
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.IHaveSiteModel
            },
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.IHaveSiteModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {CustomerWebAPIGetJobsContentListOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getJobsContentListWithHttpOperationResponse(siteid: string, options?: Models.CustomerWebAPIGetJobsContentListOptionalParams): Promise<msRest.HttpOperationResponse<Models.JobAdListItemSearchResult>> {
    let pageSize = (options && options.pageSize !== undefined) ? options.pageSize : undefined;
    let page = (options && options.page !== undefined) ? options.page : 1;
    let status = (options && options.status !== undefined) ? options.status : 1;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          pageSize,
          page,
          status
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/jobads/listcontent",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            }
          ],
          queryParameters: [
            {
              parameterPath: "pageSize",
              mapper: {
                serializedName: "pageSize",
                type: {
                  name: "Number"
                }
              }
            },
            {
              parameterPath: "page",
              mapper: {
                serializedName: "page",
                defaultValue: 1,
                type: {
                  name: "Number"
                }
              }
            },
            {
              parameterPath: "status",
              mapper: {
                serializedName: "status",
                defaultValue: 1,
                type: {
                  name: "Number"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.JobAdListItemSearchResult
            },
            500: {},
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.JobAdListItemSearchResult;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {CustomerWebAPIGetJobsListOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getJobsListWithHttpOperationResponse(siteid: string, options?: Models.CustomerWebAPIGetJobsListOptionalParams): Promise<msRest.HttpOperationResponse<Models.JobAdModelSearchResult>> {
    let pageSize = (options && options.pageSize !== undefined) ? options.pageSize : undefined;
    let page = (options && options.page !== undefined) ? options.page : 1;
    let status = (options && options.status !== undefined) ? options.status : 1;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          pageSize,
          page,
          status
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/jobads",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            }
          ],
          queryParameters: [
            {
              parameterPath: "pageSize",
              mapper: {
                serializedName: "pageSize",
                type: {
                  name: "Number"
                }
              }
            },
            {
              parameterPath: "page",
              mapper: {
                serializedName: "page",
                defaultValue: 1,
                type: {
                  name: "Number"
                }
              }
            },
            {
              parameterPath: "status",
              mapper: {
                serializedName: "status",
                defaultValue: 1,
                type: {
                  name: "Number"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.JobAdModelSearchResult
            },
            500: {},
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.JobAdModelSearchResult;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteId
   *
   * @param {CustomerWebAPIJobAdsGetJAEOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async jobAdsGetJAEWithHttpOperationResponse(siteId: string, options?: Models.CustomerWebAPIJobAdsGetJAEOptionalParams): Promise<msRest.HttpOperationResponse<void>> {
    let pageSize = (options && options.pageSize !== undefined) ? options.pageSize : undefined;
    let page = (options && options.page !== undefined) ? options.page : 1;

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteId,
          pageSize,
          page
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/{siteId}/jobads/jaelist",
          urlParameters: [
            {
              parameterPath: "siteId",
              mapper: {
                required: true,
                serializedName: "siteId",
                type: {
                  name: "Uuid"
                }
              }
            }
          ],
          queryParameters: [
            {
              parameterPath: "pageSize",
              mapper: {
                serializedName: "pageSize",
                type: {
                  name: "Number"
                }
              }
            },
            {
              parameterPath: "page",
              mapper: {
                serializedName: "page",
                defaultValue: 1,
                type: {
                  name: "Number"
                }
              }
            }
          ],
          responses: {
            200: {},
            default: {}
          },
          serializer: this.serializer
        });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {string} urlHash
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getJobAdByUrlHashWithHttpOperationResponse(siteid: string, urlHash: string, options?: msRest.RequestOptionsBase): Promise<msRest.HttpOperationResponse<Models.JobAdModel>> {

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          urlHash
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/jobads/byurl/{urlHash}",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "urlHash",
              mapper: {
                required: true,
                serializedName: "urlHash",
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.JobAdModel
            },
            500: {},
            404: {},
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.JobAdModel;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }
  // methods on the client.

  /**
   * @param {string} siteid
   *
   * @param {CustomerWebAPIGetJobAdsFilteredOptionalParams} [options] Optional Parameters.
   *
   * @returns {Promise} A promise is returned
   *
   * @resolve {HttpOperationResponse} The deserialized result object.
   *
   * @reject {Error|ServiceError} The error object.
   */
  async getJobAdsFilteredWithHttpOperationResponse(siteid: string, options?: Models.CustomerWebAPIGetJobAdsFilteredOptionalParams): Promise<msRest.HttpOperationResponse<Models.JobAdModelSearchResult>> {
    let pageSize = (options && options.pageSize !== undefined) ? options.pageSize : undefined;
    let page = (options && options.page !== undefined) ? options.page : 1;
    let status = (options && options.status !== undefined) ? options.status : 1;
    let sortField = (options && options.sortField !== undefined) ? options.sortField : 'createDate';
    let sortOrder = (options && options.sortOrder !== undefined) ? options.sortOrder : 'DESC';

    // Create HTTP transport objects
    const httpRequest = new WebResource();
    let operationRes: msRest.HttpOperationResponse;
    try {
      const operationArguments: msRest.OperationArguments = msRest.createOperationArguments(
        {
          siteid,
          pageSize,
          page,
          status,
          sortField,
          sortOrder
        },
        options);
      operationRes = await this.sendOperationRequest(
        httpRequest,
        operationArguments,
        {
          httpMethod: "GET",
          baseUrl: this.baseUri,
          path: "api/sites/{siteid}/jobadsfiltered",
          urlParameters: [
            {
              parameterPath: "siteid",
              mapper: {
                required: true,
                serializedName: "siteid",
                type: {
                  name: "String"
                }
              }
            }
          ],
          queryParameters: [
            {
              parameterPath: "pageSize",
              mapper: {
                serializedName: "pageSize",
                type: {
                  name: "Number"
                }
              }
            },
            {
              parameterPath: "page",
              mapper: {
                serializedName: "page",
                defaultValue: 1,
                type: {
                  name: "Number"
                }
              }
            },
            {
              parameterPath: "status",
              mapper: {
                serializedName: "status",
                defaultValue: 1,
                type: {
                  name: "Number"
                }
              }
            },
            {
              parameterPath: "sortField",
              mapper: {
                serializedName: "sortField",
                defaultValue: 'createDate',
                type: {
                  name: "String"
                }
              }
            },
            {
              parameterPath: "sortOrder",
              mapper: {
                serializedName: "sortOrder",
                defaultValue: 'DESC',
                type: {
                  name: "String"
                }
              }
            }
          ],
          responses: {
            200: {
              bodyMapper: Mappers.JobAdModelSearchResult
            },
            500: {},
            default: {}
          },
          serializer: this.serializer
        });
      // Deserialize Response
      let statusCode = operationRes.status;
      if (statusCode === 200) {
        let parsedResponse = operationRes.parsedBody as { [key: string]: any };
        try {
          if (parsedResponse != undefined) {
            const resultMapper = Mappers.JobAdModelSearchResult;
            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
          }
        } catch (error) {
          let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
          deserializationError.request = msRest.stripRequest(httpRequest);
          deserializationError.response = msRest.stripResponse(operationRes);
          return Promise.reject(deserializationError);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(operationRes);
  }

  /**
   * @param {CustomerWebAPIGetTokenOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.TokenResponse} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.TokenResponse} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getToken(): Promise<Models.TokenResponse>;
  getToken(options: Models.CustomerWebAPIGetTokenOptionalParams): Promise<Models.TokenResponse>;
  getToken(callback: msRest.ServiceCallback<Models.TokenResponse>): void;
  getToken(options: Models.CustomerWebAPIGetTokenOptionalParams, callback: msRest.ServiceCallback<Models.TokenResponse>): void;
  getToken(options?: Models.CustomerWebAPIGetTokenOptionalParams, callback?: msRest.ServiceCallback<Models.TokenResponse>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.TokenResponse>;
    if (!callback) {
      return this.getTokenWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.TokenResponse);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getTokenWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.TokenResponse;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {CustomerModel} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.CustomerModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.CustomerModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  createCustomer(body: Models.CustomerModel): Promise<Models.CustomerModel>;
  createCustomer(body: Models.CustomerModel, options: msRest.RequestOptionsBase): Promise<Models.CustomerModel>;
  createCustomer(body: Models.CustomerModel, callback: msRest.ServiceCallback<Models.CustomerModel>): void;
  createCustomer(body: Models.CustomerModel, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CustomerModel>): void;
  createCustomer(body: Models.CustomerModel, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.CustomerModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.CustomerModel>;
    if (!callback) {
      return this.createCustomerWithHttpOperationResponse(body, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.CustomerModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.createCustomerWithHttpOperationResponse(body, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.CustomerModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {DeleteCustomerMessage} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  deleteCustomer(body: Models.DeleteCustomerMessage): Promise<void>;
  deleteCustomer(body: Models.DeleteCustomerMessage, options: msRest.RequestOptionsBase): Promise<void>;
  deleteCustomer(body: Models.DeleteCustomerMessage, callback: msRest.ServiceCallback<void>): void;
  deleteCustomer(body: Models.DeleteCustomerMessage, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteCustomer(body: Models.DeleteCustomerMessage, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.deleteCustomerWithHttpOperationResponse(body, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.deleteCustomerWithHttpOperationResponse(body, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {CustomerModel} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  updateCustomer(body: Models.CustomerModel): Promise<void>;
  updateCustomer(body: Models.CustomerModel, options: msRest.RequestOptionsBase): Promise<void>;
  updateCustomer(body: Models.CustomerModel, callback: msRest.ServiceCallback<void>): void;
  updateCustomer(body: Models.CustomerModel, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateCustomer(body: Models.CustomerModel, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.updateCustomerWithHttpOperationResponse(body, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.updateCustomerWithHttpOperationResponse(body, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {CustomerWebAPICompaniesPostAsyncOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  companiesPostAsync(siteid: string): Promise<void>;
  companiesPostAsync(siteid: string, options: Models.CustomerWebAPICompaniesPostAsyncOptionalParams): Promise<void>;
  companiesPostAsync(siteid: string, callback: msRest.ServiceCallback<void>): void;
  companiesPostAsync(siteid: string, options: Models.CustomerWebAPICompaniesPostAsyncOptionalParams, callback: msRest.ServiceCallback<void>): void;
  companiesPostAsync(siteid: string, options?: Models.CustomerWebAPICompaniesPostAsyncOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.companiesPostAsyncWithHttpOperationResponse(siteid, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.companiesPostAsyncWithHttpOperationResponse(siteid, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {string} siteid
   *
   * @param {CustomerWebAPICompaniesPutAsyncOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  companiesPutAsync(id: string, siteid: string): Promise<void>;
  companiesPutAsync(id: string, siteid: string, options: Models.CustomerWebAPICompaniesPutAsyncOptionalParams): Promise<void>;
  companiesPutAsync(id: string, siteid: string, callback: msRest.ServiceCallback<void>): void;
  companiesPutAsync(id: string, siteid: string, options: Models.CustomerWebAPICompaniesPutAsyncOptionalParams, callback: msRest.ServiceCallback<void>): void;
  companiesPutAsync(id: string, siteid: string, options?: Models.CustomerWebAPICompaniesPutAsyncOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.companiesPutAsyncWithHttpOperationResponse(id, siteid, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.companiesPutAsyncWithHttpOperationResponse(id, siteid, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {string} siteid
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  companiesDeleteAsync(id: string, siteid: string): Promise<void>;
  companiesDeleteAsync(id: string, siteid: string, options: msRest.RequestOptionsBase): Promise<void>;
  companiesDeleteAsync(id: string, siteid: string, callback: msRest.ServiceCallback<void>): void;
  companiesDeleteAsync(id: string, siteid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  companiesDeleteAsync(id: string, siteid: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.companiesDeleteAsyncWithHttpOperationResponse(id, siteid, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.companiesDeleteAsyncWithHttpOperationResponse(id, siteid, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} userName
   *
   * @param {CustomerWebAPIPasswordResetUserPasswordOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  passwordResetUserPassword(userName: string): Promise<void>;
  passwordResetUserPassword(userName: string, options: Models.CustomerWebAPIPasswordResetUserPasswordOptionalParams): Promise<void>;
  passwordResetUserPassword(userName: string, callback: msRest.ServiceCallback<void>): void;
  passwordResetUserPassword(userName: string, options: Models.CustomerWebAPIPasswordResetUserPasswordOptionalParams, callback: msRest.ServiceCallback<void>): void;
  passwordResetUserPassword(userName: string, options?: Models.CustomerWebAPIPasswordResetUserPasswordOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.passwordResetUserPasswordWithHttpOperationResponse(userName, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.passwordResetUserPasswordWithHttpOperationResponse(userName, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} userName
   *
   * @param {CustomerWebAPIPasswordSetNewPasswordOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  passwordSetNewPassword(userName: string): Promise<void>;
  passwordSetNewPassword(userName: string, options: Models.CustomerWebAPIPasswordSetNewPasswordOptionalParams): Promise<void>;
  passwordSetNewPassword(userName: string, callback: msRest.ServiceCallback<void>): void;
  passwordSetNewPassword(userName: string, options: Models.CustomerWebAPIPasswordSetNewPasswordOptionalParams, callback: msRest.ServiceCallback<void>): void;
  passwordSetNewPassword(userName: string, options?: Models.CustomerWebAPIPasswordSetNewPasswordOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.passwordSetNewPasswordWithHttpOperationResponse(userName, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.passwordSetNewPasswordWithHttpOperationResponse(userName, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteId
   *
   * @param {CustomerWebAPIRolesCreateRoleOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  rolesCreateRole(siteId: string): Promise<void>;
  rolesCreateRole(siteId: string, options: Models.CustomerWebAPIRolesCreateRoleOptionalParams): Promise<void>;
  rolesCreateRole(siteId: string, callback: msRest.ServiceCallback<void>): void;
  rolesCreateRole(siteId: string, options: Models.CustomerWebAPIRolesCreateRoleOptionalParams, callback: msRest.ServiceCallback<void>): void;
  rolesCreateRole(siteId: string, options?: Models.CustomerWebAPIRolesCreateRoleOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.rolesCreateRoleWithHttpOperationResponse(siteId, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.rolesCreateRoleWithHttpOperationResponse(siteId, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteId
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  rolesGetRolesAsync(siteId: string): Promise<void>;
  rolesGetRolesAsync(siteId: string, options: msRest.RequestOptionsBase): Promise<void>;
  rolesGetRolesAsync(siteId: string, callback: msRest.ServiceCallback<void>): void;
  rolesGetRolesAsync(siteId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  rolesGetRolesAsync(siteId: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.rolesGetRolesAsyncWithHttpOperationResponse(siteId, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.rolesGetRolesAsyncWithHttpOperationResponse(siteId, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {CustomerWebAPISharedRolesCreateGlobalRoleOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  sharedRolesCreateGlobalRole(): Promise<void>;
  sharedRolesCreateGlobalRole(options: Models.CustomerWebAPISharedRolesCreateGlobalRoleOptionalParams): Promise<void>;
  sharedRolesCreateGlobalRole(callback: msRest.ServiceCallback<void>): void;
  sharedRolesCreateGlobalRole(options: Models.CustomerWebAPISharedRolesCreateGlobalRoleOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sharedRolesCreateGlobalRole(options?: Models.CustomerWebAPISharedRolesCreateGlobalRoleOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.sharedRolesCreateGlobalRoleWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.sharedRolesCreateGlobalRoleWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {CustomerWebAPISharedUsersCreateGlobalUserOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  sharedUsersCreateGlobalUser(): Promise<void>;
  sharedUsersCreateGlobalUser(options: Models.CustomerWebAPISharedUsersCreateGlobalUserOptionalParams): Promise<void>;
  sharedUsersCreateGlobalUser(callback: msRest.ServiceCallback<void>): void;
  sharedUsersCreateGlobalUser(options: Models.CustomerWebAPISharedUsersCreateGlobalUserOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sharedUsersCreateGlobalUser(options?: Models.CustomerWebAPISharedUsersCreateGlobalUserOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.sharedUsersCreateGlobalUserWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.sharedUsersCreateGlobalUserWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {CustomerWebAPISharedUsersUpdateGlobalUserOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  sharedUsersUpdateGlobalUser(id: string): Promise<void>;
  sharedUsersUpdateGlobalUser(id: string, options: Models.CustomerWebAPISharedUsersUpdateGlobalUserOptionalParams): Promise<void>;
  sharedUsersUpdateGlobalUser(id: string, callback: msRest.ServiceCallback<void>): void;
  sharedUsersUpdateGlobalUser(id: string, options: Models.CustomerWebAPISharedUsersUpdateGlobalUserOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sharedUsersUpdateGlobalUser(id: string, options?: Models.CustomerWebAPISharedUsersUpdateGlobalUserOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.sharedUsersUpdateGlobalUserWithHttpOperationResponse(id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.sharedUsersUpdateGlobalUserWithHttpOperationResponse(id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  sharedUsersDeleteGlobalUser(id: string): Promise<void>;
  sharedUsersDeleteGlobalUser(id: string, options: msRest.RequestOptionsBase): Promise<void>;
  sharedUsersDeleteGlobalUser(id: string, callback: msRest.ServiceCallback<void>): void;
  sharedUsersDeleteGlobalUser(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  sharedUsersDeleteGlobalUser(id: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.sharedUsersDeleteGlobalUserWithHttpOperationResponse(id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.sharedUsersDeleteGlobalUserWithHttpOperationResponse(id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteId
   *
   * @param {CustomerWebAPIUsersCreateUserOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  usersCreateUser(siteId: string): Promise<void>;
  usersCreateUser(siteId: string, options: Models.CustomerWebAPIUsersCreateUserOptionalParams): Promise<void>;
  usersCreateUser(siteId: string, callback: msRest.ServiceCallback<void>): void;
  usersCreateUser(siteId: string, options: Models.CustomerWebAPIUsersCreateUserOptionalParams, callback: msRest.ServiceCallback<void>): void;
  usersCreateUser(siteId: string, options?: Models.CustomerWebAPIUsersCreateUserOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.usersCreateUserWithHttpOperationResponse(siteId, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.usersCreateUserWithHttpOperationResponse(siteId, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteId
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  usersFindUserBySiteId(siteId: string): Promise<void>;
  usersFindUserBySiteId(siteId: string, options: msRest.RequestOptionsBase): Promise<void>;
  usersFindUserBySiteId(siteId: string, callback: msRest.ServiceCallback<void>): void;
  usersFindUserBySiteId(siteId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  usersFindUserBySiteId(siteId: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.usersFindUserBySiteIdWithHttpOperationResponse(siteId, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.usersFindUserBySiteIdWithHttpOperationResponse(siteId, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteId
   *
   * @param {CustomerWebAPIUsersInitUserForNewSiteOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  usersInitUserForNewSite(siteId: string): Promise<void>;
  usersInitUserForNewSite(siteId: string, options: Models.CustomerWebAPIUsersInitUserForNewSiteOptionalParams): Promise<void>;
  usersInitUserForNewSite(siteId: string, callback: msRest.ServiceCallback<void>): void;
  usersInitUserForNewSite(siteId: string, options: Models.CustomerWebAPIUsersInitUserForNewSiteOptionalParams, callback: msRest.ServiceCallback<void>): void;
  usersInitUserForNewSite(siteId: string, options?: Models.CustomerWebAPIUsersInitUserForNewSiteOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.usersInitUserForNewSiteWithHttpOperationResponse(siteId, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.usersInitUserForNewSiteWithHttpOperationResponse(siteId, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteId
   *
   * @param {string} id
   *
   * @param {CustomerWebAPIUsersChangeUserPasswordOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  usersChangeUserPassword(siteId: string, id: string): Promise<void>;
  usersChangeUserPassword(siteId: string, id: string, options: Models.CustomerWebAPIUsersChangeUserPasswordOptionalParams): Promise<void>;
  usersChangeUserPassword(siteId: string, id: string, callback: msRest.ServiceCallback<void>): void;
  usersChangeUserPassword(siteId: string, id: string, options: Models.CustomerWebAPIUsersChangeUserPasswordOptionalParams, callback: msRest.ServiceCallback<void>): void;
  usersChangeUserPassword(siteId: string, id: string, options?: Models.CustomerWebAPIUsersChangeUserPasswordOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.usersChangeUserPasswordWithHttpOperationResponse(siteId, id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.usersChangeUserPasswordWithHttpOperationResponse(siteId, id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteId
   *
   * @param {string} id
   *
   * @param {CustomerWebAPIUsersUpdateUserOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  usersUpdateUser(siteId: string, id: string): Promise<void>;
  usersUpdateUser(siteId: string, id: string, options: Models.CustomerWebAPIUsersUpdateUserOptionalParams): Promise<void>;
  usersUpdateUser(siteId: string, id: string, callback: msRest.ServiceCallback<void>): void;
  usersUpdateUser(siteId: string, id: string, options: Models.CustomerWebAPIUsersUpdateUserOptionalParams, callback: msRest.ServiceCallback<void>): void;
  usersUpdateUser(siteId: string, id: string, options?: Models.CustomerWebAPIUsersUpdateUserOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.usersUpdateUserWithHttpOperationResponse(siteId, id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.usersUpdateUserWithHttpOperationResponse(siteId, id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteId
   *
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  usersDeleteUser(siteId: string, id: string): Promise<void>;
  usersDeleteUser(siteId: string, id: string, options: msRest.RequestOptionsBase): Promise<void>;
  usersDeleteUser(siteId: string, id: string, callback: msRest.ServiceCallback<void>): void;
  usersDeleteUser(siteId: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  usersDeleteUser(siteId: string, id: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.usersDeleteUserWithHttpOperationResponse(siteId, id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.usersDeleteUserWithHttpOperationResponse(siteId, id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {CustomerWebAPIUpdateUserByCustomerToolOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  updateUserByCustomerTool(id: string): Promise<void>;
  updateUserByCustomerTool(id: string, options: Models.CustomerWebAPIUpdateUserByCustomerToolOptionalParams): Promise<void>;
  updateUserByCustomerTool(id: string, callback: msRest.ServiceCallback<void>): void;
  updateUserByCustomerTool(id: string, options: Models.CustomerWebAPIUpdateUserByCustomerToolOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateUserByCustomerTool(id: string, options?: Models.CustomerWebAPIUpdateUserByCustomerToolOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.updateUserByCustomerToolWithHttpOperationResponse(id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.updateUserByCustomerToolWithHttpOperationResponse(id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  usersFindUserById(id: string): Promise<void>;
  usersFindUserById(id: string, options: msRest.RequestOptionsBase): Promise<void>;
  usersFindUserById(id: string, callback: msRest.ServiceCallback<void>): void;
  usersFindUserById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  usersFindUserById(id: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.usersFindUserByIdWithHttpOperationResponse(id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.usersFindUserByIdWithHttpOperationResponse(id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {CustomerWebAPICreateSiteOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {string} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  createSite(): Promise<string>;
  createSite(options: Models.CustomerWebAPICreateSiteOptionalParams): Promise<string>;
  createSite(callback: msRest.ServiceCallback<string>): void;
  createSite(options: Models.CustomerWebAPICreateSiteOptionalParams, callback: msRest.ServiceCallback<string>): void;
  createSite(options?: Models.CustomerWebAPICreateSiteOptionalParams, callback?: msRest.ServiceCallback<string>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<string>;
    if (!callback) {
      return this.createSiteWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as string);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.createSiteWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as string;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {PutSiteViewModel} body
   *
   * @param {CustomerWebAPIUpdateSiteOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {string} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  updateSite(id: string, body: Models.PutSiteViewModel): Promise<string>;
  updateSite(id: string, body: Models.PutSiteViewModel, options: Models.CustomerWebAPIUpdateSiteOptionalParams): Promise<string>;
  updateSite(id: string, body: Models.PutSiteViewModel, callback: msRest.ServiceCallback<string>): void;
  updateSite(id: string, body: Models.PutSiteViewModel, options: Models.CustomerWebAPIUpdateSiteOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateSite(id: string, body: Models.PutSiteViewModel, options?: Models.CustomerWebAPIUpdateSiteOptionalParams, callback?: msRest.ServiceCallback<string>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<string>;
    if (!callback) {
      return this.updateSiteWithHttpOperationResponse(id, body, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as string);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.updateSiteWithHttpOperationResponse(id, body, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as string;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  sitesDeleteSite(siteid: string): Promise<void>;
  sitesDeleteSite(siteid: string, options: msRest.RequestOptionsBase): Promise<void>;
  sitesDeleteSite(siteid: string, callback: msRest.ServiceCallback<void>): void;
  sitesDeleteSite(siteid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  sitesDeleteSite(siteid: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.sitesDeleteSiteWithHttpOperationResponse(siteid, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.sitesDeleteSiteWithHttpOperationResponse(siteid, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {CustomerWebAPISitesMergeSiteOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  sitesMergeSite(siteid: string): Promise<void>;
  sitesMergeSite(siteid: string, options: Models.CustomerWebAPISitesMergeSiteOptionalParams): Promise<void>;
  sitesMergeSite(siteid: string, callback: msRest.ServiceCallback<void>): void;
  sitesMergeSite(siteid: string, options: Models.CustomerWebAPISitesMergeSiteOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sitesMergeSite(siteid: string, options?: Models.CustomerWebAPISitesMergeSiteOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.sitesMergeSiteWithHttpOperationResponse(siteid, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.sitesMergeSiteWithHttpOperationResponse(siteid, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {InternalSiteCreationRequest} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.SiteCreateResult} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.SiteCreateResult} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  internalSiteCreationV2(body: Models.InternalSiteCreationRequest): Promise<Models.SiteCreateResult>;
  internalSiteCreationV2(body: Models.InternalSiteCreationRequest, options: msRest.RequestOptionsBase): Promise<Models.SiteCreateResult>;
  internalSiteCreationV2(body: Models.InternalSiteCreationRequest, callback: msRest.ServiceCallback<Models.SiteCreateResult>): void;
  internalSiteCreationV2(body: Models.InternalSiteCreationRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SiteCreateResult>): void;
  internalSiteCreationV2(body: Models.InternalSiteCreationRequest, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.SiteCreateResult>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.SiteCreateResult>;
    if (!callback) {
      return this.internalSiteCreationV2WithHttpOperationResponse(body, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.SiteCreateResult);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.internalSiteCreationV2WithHttpOperationResponse(body, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.SiteCreateResult;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteId
   *
   * @param {PersistingCssRequestModel} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.CssGeneratingResponseModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.CssGeneratingResponseModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  generateCssAndSaveItToSharedFolder(siteId: string, body: Models.PersistingCssRequestModel): Promise<Models.CssGeneratingResponseModel>;
  generateCssAndSaveItToSharedFolder(siteId: string, body: Models.PersistingCssRequestModel, options: msRest.RequestOptionsBase): Promise<Models.CssGeneratingResponseModel>;
  generateCssAndSaveItToSharedFolder(siteId: string, body: Models.PersistingCssRequestModel, callback: msRest.ServiceCallback<Models.CssGeneratingResponseModel>): void;
  generateCssAndSaveItToSharedFolder(siteId: string, body: Models.PersistingCssRequestModel, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CssGeneratingResponseModel>): void;
  generateCssAndSaveItToSharedFolder(siteId: string, body: Models.PersistingCssRequestModel, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.CssGeneratingResponseModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.CssGeneratingResponseModel>;
    if (!callback) {
      return this.generateCssAndSaveItToSharedFolderWithHttpOperationResponse(siteId, body, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.CssGeneratingResponseModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.generateCssAndSaveItToSharedFolderWithHttpOperationResponse(siteId, body, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.CssGeneratingResponseModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {CustomerWebAPICssGeneratorGenerateCssAsyncOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  cssGeneratorGenerateCssAsync(): Promise<void>;
  cssGeneratorGenerateCssAsync(options: Models.CustomerWebAPICssGeneratorGenerateCssAsyncOptionalParams): Promise<void>;
  cssGeneratorGenerateCssAsync(callback: msRest.ServiceCallback<void>): void;
  cssGeneratorGenerateCssAsync(options: Models.CustomerWebAPICssGeneratorGenerateCssAsyncOptionalParams, callback: msRest.ServiceCallback<void>): void;
  cssGeneratorGenerateCssAsync(options?: Models.CustomerWebAPICssGeneratorGenerateCssAsyncOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.cssGeneratorGenerateCssAsyncWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.cssGeneratorGenerateCssAsyncWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {ExtendedJobAdModel} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.ExtendedJobAdModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.ExtendedJobAdModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  createJobAd(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel): Promise<Models.ExtendedJobAdModel>;
  createJobAd(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, options: msRest.RequestOptionsBase): Promise<Models.ExtendedJobAdModel>;
  createJobAd(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, callback: msRest.ServiceCallback<Models.ExtendedJobAdModel>): void;
  createJobAd(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ExtendedJobAdModel>): void;
  createJobAd(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.ExtendedJobAdModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.ExtendedJobAdModel>;
    if (!callback) {
      return this.createJobAdWithHttpOperationResponse(siteid, jobadid, body, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.ExtendedJobAdModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.createJobAdWithHttpOperationResponse(siteid, jobadid, body, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.ExtendedJobAdModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {ExtendedJobAdModel} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.ExtendedJobAdModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.ExtendedJobAdModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  updateJobAd(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel): Promise<Models.ExtendedJobAdModel>;
  updateJobAd(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, options: msRest.RequestOptionsBase): Promise<Models.ExtendedJobAdModel>;
  updateJobAd(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, callback: msRest.ServiceCallback<Models.ExtendedJobAdModel>): void;
  updateJobAd(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ExtendedJobAdModel>): void;
  updateJobAd(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.ExtendedJobAdModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.ExtendedJobAdModel>;
    if (!callback) {
      return this.updateJobAdWithHttpOperationResponse(siteid, jobadid, body, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.ExtendedJobAdModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.updateJobAdWithHttpOperationResponse(siteid, jobadid, body, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.ExtendedJobAdModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.JobAdResponce} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.JobAdResponce} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  jobAdDelete(siteid: string, jobadid: string): Promise<Models.JobAdResponce>;
  jobAdDelete(siteid: string, jobadid: string, options: msRest.RequestOptionsBase): Promise<Models.JobAdResponce>;
  jobAdDelete(siteid: string, jobadid: string, callback: msRest.ServiceCallback<Models.JobAdResponce>): void;
  jobAdDelete(siteid: string, jobadid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.JobAdResponce>): void;
  jobAdDelete(siteid: string, jobadid: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.JobAdResponce>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.JobAdResponce>;
    if (!callback) {
      return this.jobAdDeleteWithHttpOperationResponse(siteid, jobadid, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.JobAdResponce);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.jobAdDeleteWithHttpOperationResponse(siteid, jobadid, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.JobAdResponce;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.JobAdModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.JobAdModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getJobAd(siteid: string, jobadid: string): Promise<Models.JobAdModel>;
  getJobAd(siteid: string, jobadid: string, options: msRest.RequestOptionsBase): Promise<Models.JobAdModel>;
  getJobAd(siteid: string, jobadid: string, callback: msRest.ServiceCallback<Models.JobAdModel>): void;
  getJobAd(siteid: string, jobadid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.JobAdModel>): void;
  getJobAd(siteid: string, jobadid: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.JobAdModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.JobAdModel>;
    if (!callback) {
      return this.getJobAdWithHttpOperationResponse(siteid, jobadid, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.JobAdModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getJobAdWithHttpOperationResponse(siteid, jobadid, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.JobAdModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {MsgJobAd} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.SetSuperAdminDeleteStatusResponce} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.SetSuperAdminDeleteStatusResponce} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  setSuperAdminDeleteStatus(siteid: string, jobadid: string, body: Models.MsgJobAd): Promise<Models.SetSuperAdminDeleteStatusResponce>;
  setSuperAdminDeleteStatus(siteid: string, jobadid: string, body: Models.MsgJobAd, options: msRest.RequestOptionsBase): Promise<Models.SetSuperAdminDeleteStatusResponce>;
  setSuperAdminDeleteStatus(siteid: string, jobadid: string, body: Models.MsgJobAd, callback: msRest.ServiceCallback<Models.SetSuperAdminDeleteStatusResponce>): void;
  setSuperAdminDeleteStatus(siteid: string, jobadid: string, body: Models.MsgJobAd, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SetSuperAdminDeleteStatusResponce>): void;
  setSuperAdminDeleteStatus(siteid: string, jobadid: string, body: Models.MsgJobAd, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.SetSuperAdminDeleteStatusResponce>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.SetSuperAdminDeleteStatusResponce>;
    if (!callback) {
      return this.setSuperAdminDeleteStatusWithHttpOperationResponse(siteid, jobadid, body, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.SetSuperAdminDeleteStatusResponce);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.setSuperAdminDeleteStatusWithHttpOperationResponse(siteid, jobadid, body, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.SetSuperAdminDeleteStatusResponce;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {ExtendedJobAdModel} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.ExtendedJobAdModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.ExtendedJobAdModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  jobAdUpdateStatus(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel): Promise<Models.ExtendedJobAdModel>;
  jobAdUpdateStatus(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, options: msRest.RequestOptionsBase): Promise<Models.ExtendedJobAdModel>;
  jobAdUpdateStatus(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, callback: msRest.ServiceCallback<Models.ExtendedJobAdModel>): void;
  jobAdUpdateStatus(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ExtendedJobAdModel>): void;
  jobAdUpdateStatus(siteid: string, jobadid: string, body: Models.ExtendedJobAdModel, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.ExtendedJobAdModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.ExtendedJobAdModel>;
    if (!callback) {
      return this.jobAdUpdateStatusWithHttpOperationResponse(siteid, jobadid, body, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.ExtendedJobAdModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.jobAdUpdateStatusWithHttpOperationResponse(siteid, jobadid, body, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.ExtendedJobAdModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {MsgJobAd} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.UpdateStatusResponce} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.UpdateStatusResponce} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  jobAdUpdateStatusForJquery(siteid: string, jobadid: string, body: Models.MsgJobAd): Promise<Models.UpdateStatusResponce>;
  jobAdUpdateStatusForJquery(siteid: string, jobadid: string, body: Models.MsgJobAd, options: msRest.RequestOptionsBase): Promise<Models.UpdateStatusResponce>;
  jobAdUpdateStatusForJquery(siteid: string, jobadid: string, body: Models.MsgJobAd, callback: msRest.ServiceCallback<Models.UpdateStatusResponce>): void;
  jobAdUpdateStatusForJquery(siteid: string, jobadid: string, body: Models.MsgJobAd, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.UpdateStatusResponce>): void;
  jobAdUpdateStatusForJquery(siteid: string, jobadid: string, body: Models.MsgJobAd, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.UpdateStatusResponce>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.UpdateStatusResponce>;
    if (!callback) {
      return this.jobAdUpdateStatusForJqueryWithHttpOperationResponse(siteid, jobadid, body, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.UpdateStatusResponce);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.jobAdUpdateStatusForJqueryWithHttpOperationResponse(siteid, jobadid, body, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.UpdateStatusResponce;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {PatchProductModelRequest} body
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  jobAdPatchProducts(siteid: string, jobadid: string, body: Models.PatchProductModelRequest): Promise<void>;
  jobAdPatchProducts(siteid: string, jobadid: string, body: Models.PatchProductModelRequest, options: msRest.RequestOptionsBase): Promise<void>;
  jobAdPatchProducts(siteid: string, jobadid: string, body: Models.PatchProductModelRequest, callback: msRest.ServiceCallback<void>): void;
  jobAdPatchProducts(siteid: string, jobadid: string, body: Models.PatchProductModelRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  jobAdPatchProducts(siteid: string, jobadid: string, body: Models.PatchProductModelRequest, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.jobAdPatchProductsWithHttpOperationResponse(siteid, jobadid, body, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.jobAdPatchProductsWithHttpOperationResponse(siteid, jobadid, body, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @summary PatchDescription
   *
   * @param {string} siteid
   *
   * @param {string} jobadid
   *
   * @param {number} siteType
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  jobAdPatchDescription(siteid: string, jobadid: string, siteType: number): Promise<void>;
  jobAdPatchDescription(siteid: string, jobadid: string, siteType: number, options: msRest.RequestOptionsBase): Promise<void>;
  jobAdPatchDescription(siteid: string, jobadid: string, siteType: number, callback: msRest.ServiceCallback<void>): void;
  jobAdPatchDescription(siteid: string, jobadid: string, siteType: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  jobAdPatchDescription(siteid: string, jobadid: string, siteType: number, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.jobAdPatchDescriptionWithHttpOperationResponse(siteid, jobadid, siteType, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.jobAdPatchDescriptionWithHttpOperationResponse(siteid, jobadid, siteType, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @summary PatchDescription
   *
   * @param {string} siteid
   *
   * @param {number} siteType
   *
   * @param {CustomerWebAPIJobAdPatchDescription1OptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  jobAdPatchDescription1(siteid: string, siteType: number): Promise<void>;
  jobAdPatchDescription1(siteid: string, siteType: number, options: Models.CustomerWebAPIJobAdPatchDescription1OptionalParams): Promise<void>;
  jobAdPatchDescription1(siteid: string, siteType: number, callback: msRest.ServiceCallback<void>): void;
  jobAdPatchDescription1(siteid: string, siteType: number, options: Models.CustomerWebAPIJobAdPatchDescription1OptionalParams, callback: msRest.ServiceCallback<void>): void;
  jobAdPatchDescription1(siteid: string, siteType: number, options?: Models.CustomerWebAPIJobAdPatchDescription1OptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.jobAdPatchDescription1WithHttpOperationResponse(siteid, siteType, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.jobAdPatchDescription1WithHttpOperationResponse(siteid, siteType, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {CustomerWebAPICreatePartnerJobAdOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.PartnerJobAdResponce} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.PartnerJobAdResponce} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  createPartnerJobAd(siteid: string): Promise<Models.PartnerJobAdResponce>;
  createPartnerJobAd(siteid: string, options: Models.CustomerWebAPICreatePartnerJobAdOptionalParams): Promise<Models.PartnerJobAdResponce>;
  createPartnerJobAd(siteid: string, callback: msRest.ServiceCallback<Models.PartnerJobAdResponce>): void;
  createPartnerJobAd(siteid: string, options: Models.CustomerWebAPICreatePartnerJobAdOptionalParams, callback: msRest.ServiceCallback<Models.PartnerJobAdResponce>): void;
  createPartnerJobAd(siteid: string, options?: Models.CustomerWebAPICreatePartnerJobAdOptionalParams, callback?: msRest.ServiceCallback<Models.PartnerJobAdResponce>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.PartnerJobAdResponce>;
    if (!callback) {
      return this.createPartnerJobAdWithHttpOperationResponse(siteid, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.PartnerJobAdResponce);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.createPartnerJobAdWithHttpOperationResponse(siteid, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.PartnerJobAdResponce;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} searchText
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.CustomerModel[]} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  searchCustomer(searchText: string): Promise<Models.CustomerModel[]>;
  searchCustomer(searchText: string, options: msRest.RequestOptionsBase): Promise<Models.CustomerModel[]>;
  searchCustomer(searchText: string, callback: msRest.ServiceCallback<Models.CustomerModel[]>): void;
  searchCustomer(searchText: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CustomerModel[]>): void;
  searchCustomer(searchText: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.CustomerModel[]>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.CustomerModel[]>;
    if (!callback) {
      return this.searchCustomerWithHttpOperationResponse(searchText, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.CustomerModel[]);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.searchCustomerWithHttpOperationResponse(searchText, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.CustomerModel[];
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} pNumber
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {boolean} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getCustomerExists(pNumber: string): Promise<boolean>;
  getCustomerExists(pNumber: string, options: msRest.RequestOptionsBase): Promise<boolean>;
  getCustomerExists(pNumber: string, callback: msRest.ServiceCallback<boolean>): void;
  getCustomerExists(pNumber: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  getCustomerExists(pNumber: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<boolean>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<boolean>;
    if (!callback) {
      return this.getCustomerExistsWithHttpOperationResponse(pNumber, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as boolean);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getCustomerExistsWithHttpOperationResponse(pNumber, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as boolean;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} pNumber
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.CustomerModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.CustomerModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getCustomerByPnumber(pNumber: string): Promise<Models.CustomerModel>;
  getCustomerByPnumber(pNumber: string, options: msRest.RequestOptionsBase): Promise<Models.CustomerModel>;
  getCustomerByPnumber(pNumber: string, callback: msRest.ServiceCallback<Models.CustomerModel>): void;
  getCustomerByPnumber(pNumber: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CustomerModel>): void;
  getCustomerByPnumber(pNumber: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.CustomerModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.CustomerModel>;
    if (!callback) {
      return this.getCustomerByPnumberWithHttpOperationResponse(pNumber, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.CustomerModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getCustomerByPnumberWithHttpOperationResponse(pNumber, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.CustomerModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.CustomerModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.CustomerModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getCustomer(id: string): Promise<Models.CustomerModel>;
  getCustomer(id: string, options: msRest.RequestOptionsBase): Promise<Models.CustomerModel>;
  getCustomer(id: string, callback: msRest.ServiceCallback<Models.CustomerModel>): void;
  getCustomer(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CustomerModel>): void;
  getCustomer(id: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.CustomerModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.CustomerModel>;
    if (!callback) {
      return this.getCustomerWithHttpOperationResponse(id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.CustomerModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getCustomerWithHttpOperationResponse(id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.CustomerModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} cvr
   *
   * @param {string} pnumber
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.CustomerModel[]} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getCustomersByCvrAndPnumber(cvr: string, pnumber: string): Promise<Models.CustomerModel[]>;
  getCustomersByCvrAndPnumber(cvr: string, pnumber: string, options: msRest.RequestOptionsBase): Promise<Models.CustomerModel[]>;
  getCustomersByCvrAndPnumber(cvr: string, pnumber: string, callback: msRest.ServiceCallback<Models.CustomerModel[]>): void;
  getCustomersByCvrAndPnumber(cvr: string, pnumber: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CustomerModel[]>): void;
  getCustomersByCvrAndPnumber(cvr: string, pnumber: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.CustomerModel[]>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.CustomerModel[]>;
    if (!callback) {
      return this.getCustomersByCvrAndPnumberWithHttpOperationResponse(cvr, pnumber, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.CustomerModel[]);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getCustomersByCvrAndPnumberWithHttpOperationResponse(cvr, pnumber, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.CustomerModel[];
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} cvr
   *
   * @param {string} pnumber
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.CustomerModel[]} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getCustomersByCvrAndPnumber1(cvr: string, pnumber: string): Promise<Models.CustomerModel[]>;
  getCustomersByCvrAndPnumber1(cvr: string, pnumber: string, options: msRest.RequestOptionsBase): Promise<Models.CustomerModel[]>;
  getCustomersByCvrAndPnumber1(cvr: string, pnumber: string, callback: msRest.ServiceCallback<Models.CustomerModel[]>): void;
  getCustomersByCvrAndPnumber1(cvr: string, pnumber: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CustomerModel[]>): void;
  getCustomersByCvrAndPnumber1(cvr: string, pnumber: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.CustomerModel[]>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.CustomerModel[]>;
    if (!callback) {
      return this.getCustomersByCvrAndPnumber1WithHttpOperationResponse(cvr, pnumber, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.CustomerModel[]);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getCustomersByCvrAndPnumber1WithHttpOperationResponse(cvr, pnumber, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.CustomerModel[];
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {number} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getNextCustomerId(): Promise<number>;
  getNextCustomerId(options: msRest.RequestOptionsBase): Promise<number>;
  getNextCustomerId(callback: msRest.ServiceCallback<number>): void;
  getNextCustomerId(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<number>): void;
  getNextCustomerId(options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<number>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<number>;
    if (!callback) {
      return this.getNextCustomerIdWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as number);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getNextCustomerIdWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as number;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {number} customerid
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.CustomerModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.CustomerModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getCustomerByCustomerId(customerid: number): Promise<Models.CustomerModel>;
  getCustomerByCustomerId(customerid: number, options: msRest.RequestOptionsBase): Promise<Models.CustomerModel>;
  getCustomerByCustomerId(customerid: number, callback: msRest.ServiceCallback<Models.CustomerModel>): void;
  getCustomerByCustomerId(customerid: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CustomerModel>): void;
  getCustomerByCustomerId(customerid: number, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.CustomerModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.CustomerModel>;
    if (!callback) {
      return this.getCustomerByCustomerIdWithHttpOperationResponse(customerid, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.CustomerModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getCustomerByCustomerIdWithHttpOperationResponse(customerid, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.CustomerModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  companiesGet(): Promise<void>;
  companiesGet(options: msRest.RequestOptionsBase): Promise<void>;
  companiesGet(callback: msRest.ServiceCallback<void>): void;
  companiesGet(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  companiesGet(options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.companiesGetWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.companiesGetWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteId
   *
   * @param {CustomerWebAPICompaniesGet1OptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  companiesGet1(siteId: string): Promise<void>;
  companiesGet1(siteId: string, options: Models.CustomerWebAPICompaniesGet1OptionalParams): Promise<void>;
  companiesGet1(siteId: string, callback: msRest.ServiceCallback<void>): void;
  companiesGet1(siteId: string, options: Models.CustomerWebAPICompaniesGet1OptionalParams, callback: msRest.ServiceCallback<void>): void;
  companiesGet1(siteId: string, options?: Models.CustomerWebAPICompaniesGet1OptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.companiesGet1WithHttpOperationResponse(siteId, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.companiesGet1WithHttpOperationResponse(siteId, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteId
   *
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  companiesGet2(siteId: string, id: string): Promise<void>;
  companiesGet2(siteId: string, id: string, options: msRest.RequestOptionsBase): Promise<void>;
  companiesGet2(siteId: string, id: string, callback: msRest.ServiceCallback<void>): void;
  companiesGet2(siteId: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  companiesGet2(siteId: string, id: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.companiesGet2WithHttpOperationResponse(siteId, id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.companiesGet2WithHttpOperationResponse(siteId, id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteId
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  companiesGet3(siteId: string): Promise<void>;
  companiesGet3(siteId: string, options: msRest.RequestOptionsBase): Promise<void>;
  companiesGet3(siteId: string, callback: msRest.ServiceCallback<void>): void;
  companiesGet3(siteId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  companiesGet3(siteId: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.companiesGet3WithHttpOperationResponse(siteId, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.companiesGet3WithHttpOperationResponse(siteId, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} companyName
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  companiesFindCompanyByName(companyName: string): Promise<void>;
  companiesFindCompanyByName(companyName: string, options: msRest.RequestOptionsBase): Promise<void>;
  companiesFindCompanyByName(companyName: string, callback: msRest.ServiceCallback<void>): void;
  companiesFindCompanyByName(companyName: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  companiesFindCompanyByName(companyName: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.companiesFindCompanyByNameWithHttpOperationResponse(companyName, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.companiesFindCompanyByNameWithHttpOperationResponse(companyName, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} customerId
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  companiesFindCompanyByCustomer(customerId: string): Promise<void>;
  companiesFindCompanyByCustomer(customerId: string, options: msRest.RequestOptionsBase): Promise<void>;
  companiesFindCompanyByCustomer(customerId: string, callback: msRest.ServiceCallback<void>): void;
  companiesFindCompanyByCustomer(customerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  companiesFindCompanyByCustomer(customerId: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.companiesFindCompanyByCustomerWithHttpOperationResponse(customerId, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.companiesFindCompanyByCustomerWithHttpOperationResponse(customerId, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {CustomerWebAPICompaniesFindCompaniesOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  companiesFindCompanies(): Promise<void>;
  companiesFindCompanies(options: Models.CustomerWebAPICompaniesFindCompaniesOptionalParams): Promise<void>;
  companiesFindCompanies(callback: msRest.ServiceCallback<void>): void;
  companiesFindCompanies(options: Models.CustomerWebAPICompaniesFindCompaniesOptionalParams, callback: msRest.ServiceCallback<void>): void;
  companiesFindCompanies(options?: Models.CustomerWebAPICompaniesFindCompaniesOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.companiesFindCompaniesWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.companiesFindCompaniesWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} vatnumber
   *
   * @param {CustomerWebAPICompaniesFindCompaniesByVatNumberOptionalParams} [options] Optional
   * Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.CompanyWithSiteTypeModel[]} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  companiesFindCompaniesByVatNumber(vatnumber: string): Promise<Models.CompanyWithSiteTypeModel[]>;
  companiesFindCompaniesByVatNumber(vatnumber: string, options: Models.CustomerWebAPICompaniesFindCompaniesByVatNumberOptionalParams): Promise<Models.CompanyWithSiteTypeModel[]>;
  companiesFindCompaniesByVatNumber(vatnumber: string, callback: msRest.ServiceCallback<Models.CompanyWithSiteTypeModel[]>): void;
  companiesFindCompaniesByVatNumber(vatnumber: string, options: Models.CustomerWebAPICompaniesFindCompaniesByVatNumberOptionalParams, callback: msRest.ServiceCallback<Models.CompanyWithSiteTypeModel[]>): void;
  companiesFindCompaniesByVatNumber(vatnumber: string, options?: Models.CustomerWebAPICompaniesFindCompaniesByVatNumberOptionalParams, callback?: msRest.ServiceCallback<Models.CompanyWithSiteTypeModel[]>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.CompanyWithSiteTypeModel[]>;
    if (!callback) {
      return this.companiesFindCompaniesByVatNumberWithHttpOperationResponse(vatnumber, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.CompanyWithSiteTypeModel[]);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.companiesFindCompaniesByVatNumberWithHttpOperationResponse(vatnumber, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.CompanyWithSiteTypeModel[];
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  partnerCompaniesGet(): Promise<void>;
  partnerCompaniesGet(options: msRest.RequestOptionsBase): Promise<void>;
  partnerCompaniesGet(callback: msRest.ServiceCallback<void>): void;
  partnerCompaniesGet(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  partnerCompaniesGet(options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.partnerCompaniesGetWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.partnerCompaniesGetWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {CustomerWebAPIPartnerCompaniesFindCompanyByVatIdOptionalParams} [options] Optional
   * Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  partnerCompaniesFindCompanyByVatId(): Promise<void>;
  partnerCompaniesFindCompanyByVatId(options: Models.CustomerWebAPIPartnerCompaniesFindCompanyByVatIdOptionalParams): Promise<void>;
  partnerCompaniesFindCompanyByVatId(callback: msRest.ServiceCallback<void>): void;
  partnerCompaniesFindCompanyByVatId(options: Models.CustomerWebAPIPartnerCompaniesFindCompanyByVatIdOptionalParams, callback: msRest.ServiceCallback<void>): void;
  partnerCompaniesFindCompanyByVatId(options?: Models.CustomerWebAPIPartnerCompaniesFindCompanyByVatIdOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.partnerCompaniesFindCompanyByVatIdWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.partnerCompaniesFindCompanyByVatIdWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  variableCompaniesGet(id: string): Promise<void>;
  variableCompaniesGet(id: string, options: msRest.RequestOptionsBase): Promise<void>;
  variableCompaniesGet(id: string, callback: msRest.ServiceCallback<void>): void;
  variableCompaniesGet(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  variableCompaniesGet(id: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.variableCompaniesGetWithHttpOperationResponse(id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.variableCompaniesGetWithHttpOperationResponse(id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {CustomerWebAPIPartnerUsersFindBySiteIdOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  partnerUsersFindBySiteId(): Promise<void>;
  partnerUsersFindBySiteId(options: Models.CustomerWebAPIPartnerUsersFindBySiteIdOptionalParams): Promise<void>;
  partnerUsersFindBySiteId(callback: msRest.ServiceCallback<void>): void;
  partnerUsersFindBySiteId(options: Models.CustomerWebAPIPartnerUsersFindBySiteIdOptionalParams, callback: msRest.ServiceCallback<void>): void;
  partnerUsersFindBySiteId(options?: Models.CustomerWebAPIPartnerUsersFindBySiteIdOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.partnerUsersFindBySiteIdWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.partnerUsersFindBySiteIdWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} userName
   *
   * @param {string} token
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  passwordsValidateResetPasswordTokenAsync(userName: string, token: string): Promise<void>;
  passwordsValidateResetPasswordTokenAsync(userName: string, token: string, options: msRest.RequestOptionsBase): Promise<void>;
  passwordsValidateResetPasswordTokenAsync(userName: string, token: string, callback: msRest.ServiceCallback<void>): void;
  passwordsValidateResetPasswordTokenAsync(userName: string, token: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  passwordsValidateResetPasswordTokenAsync(userName: string, token: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.passwordsValidateResetPasswordTokenAsyncWithHttpOperationResponse(userName, token, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.passwordsValidateResetPasswordTokenAsyncWithHttpOperationResponse(userName, token, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {CustomerWebAPIUsersGetUsersAsyncOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  usersGetUsersAsync(): Promise<void>;
  usersGetUsersAsync(options: Models.CustomerWebAPIUsersGetUsersAsyncOptionalParams): Promise<void>;
  usersGetUsersAsync(callback: msRest.ServiceCallback<void>): void;
  usersGetUsersAsync(options: Models.CustomerWebAPIUsersGetUsersAsyncOptionalParams, callback: msRest.ServiceCallback<void>): void;
  usersGetUsersAsync(options?: Models.CustomerWebAPIUsersGetUsersAsyncOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.usersGetUsersAsyncWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.usersGetUsersAsyncWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {CustomerWebAPIUsersGetUserAsyncOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  usersGetUserAsync(id: string): Promise<void>;
  usersGetUserAsync(id: string, options: Models.CustomerWebAPIUsersGetUserAsyncOptionalParams): Promise<void>;
  usersGetUserAsync(id: string, callback: msRest.ServiceCallback<void>): void;
  usersGetUserAsync(id: string, options: Models.CustomerWebAPIUsersGetUserAsyncOptionalParams, callback: msRest.ServiceCallback<void>): void;
  usersGetUserAsync(id: string, options?: Models.CustomerWebAPIUsersGetUserAsyncOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.usersGetUserAsyncWithHttpOperationResponse(id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.usersGetUserAsyncWithHttpOperationResponse(id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} username
   *
   * @param {CustomerWebAPIUsersGetUserAsync1OptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  usersGetUserAsync1(username: string): Promise<void>;
  usersGetUserAsync1(username: string, options: Models.CustomerWebAPIUsersGetUserAsync1OptionalParams): Promise<void>;
  usersGetUserAsync1(username: string, callback: msRest.ServiceCallback<void>): void;
  usersGetUserAsync1(username: string, options: Models.CustomerWebAPIUsersGetUserAsync1OptionalParams, callback: msRest.ServiceCallback<void>): void;
  usersGetUserAsync1(username: string, options?: Models.CustomerWebAPIUsersGetUserAsync1OptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.usersGetUserAsync1WithHttpOperationResponse(username, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.usersGetUserAsync1WithHttpOperationResponse(username, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {CustomerWebAPIUsersFindUsersOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  usersFindUsers(): Promise<void>;
  usersFindUsers(options: Models.CustomerWebAPIUsersFindUsersOptionalParams): Promise<void>;
  usersFindUsers(callback: msRest.ServiceCallback<void>): void;
  usersFindUsers(options: Models.CustomerWebAPIUsersFindUsersOptionalParams, callback: msRest.ServiceCallback<void>): void;
  usersFindUsers(options?: Models.CustomerWebAPIUsersFindUsersOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.usersFindUsersWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.usersFindUsersWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  variableUsersGetUserAsync(id: string): Promise<void>;
  variableUsersGetUserAsync(id: string, options: msRest.RequestOptionsBase): Promise<void>;
  variableUsersGetUserAsync(id: string, callback: msRest.ServiceCallback<void>): void;
  variableUsersGetUserAsync(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  variableUsersGetUserAsync(id: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.variableUsersGetUserAsyncWithHttpOperationResponse(id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.variableUsersGetUserAsyncWithHttpOperationResponse(id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {CustomerWebAPIGetSitesOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.IHaveSiteModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.IHaveSiteModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getSites(): Promise<Models.IHaveSiteModel>;
  getSites(options: Models.CustomerWebAPIGetSitesOptionalParams): Promise<Models.IHaveSiteModel>;
  getSites(callback: msRest.ServiceCallback<Models.IHaveSiteModel>): void;
  getSites(options: Models.CustomerWebAPIGetSitesOptionalParams, callback: msRest.ServiceCallback<Models.IHaveSiteModel>): void;
  getSites(options?: Models.CustomerWebAPIGetSitesOptionalParams, callback?: msRest.ServiceCallback<Models.IHaveSiteModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.IHaveSiteModel>;
    if (!callback) {
      return this.getSitesWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.IHaveSiteModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getSitesWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.IHaveSiteModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.SiteEntity} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.SiteEntity} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getSiteById(id: string): Promise<Models.SiteEntity>;
  getSiteById(id: string, options: msRest.RequestOptionsBase): Promise<Models.SiteEntity>;
  getSiteById(id: string, callback: msRest.ServiceCallback<Models.SiteEntity>): void;
  getSiteById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SiteEntity>): void;
  getSiteById(id: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.SiteEntity>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.SiteEntity>;
    if (!callback) {
      return this.getSiteByIdWithHttpOperationResponse(id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.SiteEntity);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getSiteByIdWithHttpOperationResponse(id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.SiteEntity;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.SiteEntity} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.SiteEntity} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  sitesGetSiteByCompanyId(id: string): Promise<Models.SiteEntity>;
  sitesGetSiteByCompanyId(id: string, options: msRest.RequestOptionsBase): Promise<Models.SiteEntity>;
  sitesGetSiteByCompanyId(id: string, callback: msRest.ServiceCallback<Models.SiteEntity>): void;
  sitesGetSiteByCompanyId(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SiteEntity>): void;
  sitesGetSiteByCompanyId(id: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.SiteEntity>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.SiteEntity>;
    if (!callback) {
      return this.sitesGetSiteByCompanyIdWithHttpOperationResponse(id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.SiteEntity);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.sitesGetSiteByCompanyIdWithHttpOperationResponse(id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.SiteEntity;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {CustomerWebAPISitesGetSiteByIdsOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  sitesGetSiteByIds(): Promise<void>;
  sitesGetSiteByIds(options: Models.CustomerWebAPISitesGetSiteByIdsOptionalParams): Promise<void>;
  sitesGetSiteByIds(callback: msRest.ServiceCallback<void>): void;
  sitesGetSiteByIds(options: Models.CustomerWebAPISitesGetSiteByIdsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sitesGetSiteByIds(options?: Models.CustomerWebAPISitesGetSiteByIdsOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.sitesGetSiteByIdsWithHttpOperationResponse(options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.sitesGetSiteByIdsWithHttpOperationResponse(options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} id
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.IHaveEmailSiteModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.IHaveEmailSiteModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getEmailSiteById(id: string): Promise<Models.IHaveEmailSiteModel>;
  getEmailSiteById(id: string, options: msRest.RequestOptionsBase): Promise<Models.IHaveEmailSiteModel>;
  getEmailSiteById(id: string, callback: msRest.ServiceCallback<Models.IHaveEmailSiteModel>): void;
  getEmailSiteById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.IHaveEmailSiteModel>): void;
  getEmailSiteById(id: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.IHaveEmailSiteModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.IHaveEmailSiteModel>;
    if (!callback) {
      return this.getEmailSiteByIdWithHttpOperationResponse(id, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.IHaveEmailSiteModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getEmailSiteByIdWithHttpOperationResponse(id, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.IHaveEmailSiteModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} host
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.IHaveSiteModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.IHaveSiteModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getSiteByHost(host: string): Promise<Models.IHaveSiteModel>;
  getSiteByHost(host: string, options: msRest.RequestOptionsBase): Promise<Models.IHaveSiteModel>;
  getSiteByHost(host: string, callback: msRest.ServiceCallback<Models.IHaveSiteModel>): void;
  getSiteByHost(host: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.IHaveSiteModel>): void;
  getSiteByHost(host: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.IHaveSiteModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.IHaveSiteModel>;
    if (!callback) {
      return this.getSiteByHostWithHttpOperationResponse(host, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.IHaveSiteModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getSiteByHostWithHttpOperationResponse(host, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.IHaveSiteModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} host
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.IHaveSiteModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.IHaveSiteModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getSitesByHost(host: string): Promise<Models.IHaveSiteModel>;
  getSitesByHost(host: string, options: msRest.RequestOptionsBase): Promise<Models.IHaveSiteModel>;
  getSitesByHost(host: string, callback: msRest.ServiceCallback<Models.IHaveSiteModel>): void;
  getSitesByHost(host: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.IHaveSiteModel>): void;
  getSitesByHost(host: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.IHaveSiteModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.IHaveSiteModel>;
    if (!callback) {
      return this.getSitesByHostWithHttpOperationResponse(host, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.IHaveSiteModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getSitesByHostWithHttpOperationResponse(host, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.IHaveSiteModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {CustomerWebAPIGetJobsContentListOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.JobAdListItemSearchResult} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.JobAdListItemSearchResult} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getJobsContentList(siteid: string): Promise<Models.JobAdListItemSearchResult>;
  getJobsContentList(siteid: string, options: Models.CustomerWebAPIGetJobsContentListOptionalParams): Promise<Models.JobAdListItemSearchResult>;
  getJobsContentList(siteid: string, callback: msRest.ServiceCallback<Models.JobAdListItemSearchResult>): void;
  getJobsContentList(siteid: string, options: Models.CustomerWebAPIGetJobsContentListOptionalParams, callback: msRest.ServiceCallback<Models.JobAdListItemSearchResult>): void;
  getJobsContentList(siteid: string, options?: Models.CustomerWebAPIGetJobsContentListOptionalParams, callback?: msRest.ServiceCallback<Models.JobAdListItemSearchResult>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.JobAdListItemSearchResult>;
    if (!callback) {
      return this.getJobsContentListWithHttpOperationResponse(siteid, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.JobAdListItemSearchResult);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getJobsContentListWithHttpOperationResponse(siteid, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.JobAdListItemSearchResult;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {CustomerWebAPIGetJobsListOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.JobAdModelSearchResult} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.JobAdModelSearchResult} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getJobsList(siteid: string): Promise<Models.JobAdModelSearchResult>;
  getJobsList(siteid: string, options: Models.CustomerWebAPIGetJobsListOptionalParams): Promise<Models.JobAdModelSearchResult>;
  getJobsList(siteid: string, callback: msRest.ServiceCallback<Models.JobAdModelSearchResult>): void;
  getJobsList(siteid: string, options: Models.CustomerWebAPIGetJobsListOptionalParams, callback: msRest.ServiceCallback<Models.JobAdModelSearchResult>): void;
  getJobsList(siteid: string, options?: Models.CustomerWebAPIGetJobsListOptionalParams, callback?: msRest.ServiceCallback<Models.JobAdModelSearchResult>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.JobAdModelSearchResult>;
    if (!callback) {
      return this.getJobsListWithHttpOperationResponse(siteid, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.JobAdModelSearchResult);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getJobsListWithHttpOperationResponse(siteid, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.JobAdModelSearchResult;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteId
   *
   * @param {CustomerWebAPIJobAdsGetJAEOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {void} [result]   - The deserialized result object if an error did not occur.
   *
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  jobAdsGetJAE(siteId: string): Promise<void>;
  jobAdsGetJAE(siteId: string, options: Models.CustomerWebAPIJobAdsGetJAEOptionalParams): Promise<void>;
  jobAdsGetJAE(siteId: string, callback: msRest.ServiceCallback<void>): void;
  jobAdsGetJAE(siteId: string, options: Models.CustomerWebAPIJobAdsGetJAEOptionalParams, callback: msRest.ServiceCallback<void>): void;
  jobAdsGetJAE(siteId: string, options?: Models.CustomerWebAPIJobAdsGetJAEOptionalParams, callback?: msRest.ServiceCallback<void>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<void>;
    if (!callback) {
      return this.jobAdsGetJAEWithHttpOperationResponse(siteId, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as void);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.jobAdsGetJAEWithHttpOperationResponse(siteId, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as void;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {string} urlHash
   *
   * @param {RequestOptionsBase} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.JobAdModel} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.JobAdModel} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getJobAdByUrlHash(siteid: string, urlHash: string): Promise<Models.JobAdModel>;
  getJobAdByUrlHash(siteid: string, urlHash: string, options: msRest.RequestOptionsBase): Promise<Models.JobAdModel>;
  getJobAdByUrlHash(siteid: string, urlHash: string, callback: msRest.ServiceCallback<Models.JobAdModel>): void;
  getJobAdByUrlHash(siteid: string, urlHash: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.JobAdModel>): void;
  getJobAdByUrlHash(siteid: string, urlHash: string, options?: msRest.RequestOptionsBase, callback?: msRest.ServiceCallback<Models.JobAdModel>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.JobAdModel>;
    if (!callback) {
      return this.getJobAdByUrlHashWithHttpOperationResponse(siteid, urlHash, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.JobAdModel);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getJobAdByUrlHashWithHttpOperationResponse(siteid, urlHash, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.JobAdModel;
        return cb(err, result, data.request, data);
      });
    }
  }

  /**
   * @param {string} siteid
   *
   * @param {CustomerWebAPIGetJobAdsFilteredOptionalParams} [options] Optional Parameters.
   *
   * @param {ServiceCallback} callback The callback.
   *
   * @returns {ServiceCallback} callback(err, result, request, operationRes)
   *                      {Error|ServiceError}  err        - The Error object if an error occurred, null otherwise.
   *                      {Models.JobAdModelSearchResult} [result]   - The deserialized result object if an error did not occur.
   *                      See {@link Models.JobAdModelSearchResult} for more information.
   *                      {WebResource} [request]  - The HTTP Request object if an error did not occur.
   *                      {HttpOperationResponse} [response] - The HTTP Response stream if an error did not occur.
   */
  getJobAdsFiltered(siteid: string): Promise<Models.JobAdModelSearchResult>;
  getJobAdsFiltered(siteid: string, options: Models.CustomerWebAPIGetJobAdsFilteredOptionalParams): Promise<Models.JobAdModelSearchResult>;
  getJobAdsFiltered(siteid: string, callback: msRest.ServiceCallback<Models.JobAdModelSearchResult>): void;
  getJobAdsFiltered(siteid: string, options: Models.CustomerWebAPIGetJobAdsFilteredOptionalParams, callback: msRest.ServiceCallback<Models.JobAdModelSearchResult>): void;
  getJobAdsFiltered(siteid: string, options?: Models.CustomerWebAPIGetJobAdsFilteredOptionalParams, callback?: msRest.ServiceCallback<Models.JobAdModelSearchResult>): any {
    if (!callback && typeof options === 'function') {
      callback = options;
      options = undefined;
    }
    let cb = callback as msRest.ServiceCallback<Models.JobAdModelSearchResult>;
    if (!callback) {
      return this.getJobAdsFilteredWithHttpOperationResponse(siteid, options).then((operationRes: msRest.HttpOperationResponse) => {
        return Promise.resolve(operationRes.parsedBody as Models.JobAdModelSearchResult);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      msRest.promiseToCallback(this.getJobAdsFilteredWithHttpOperationResponse(siteid, options))((err: Error, data: msRest.HttpOperationResponse) => {
        if (err) {
          return cb(err);
        }
        let result = data.parsedBody as Models.JobAdModelSearchResult;
        return cb(err, result, data.request, data);
      });
    }
  }
}

export { CustomerWebAPI, Models as CustomerWebAPIModels, Mappers as CustomerWebAPIMappers };

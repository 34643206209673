import { ChangePasswordViewModel, IHaveUserResponse, SiteEntity} from "../../../services/api/autorest/models"
import { IHaveUserResponsedefault } from "../../../services/api/defaults"
import { actionCreator, Action, isType } from '../../Action';
import { ApiService } from '../../../services/api/apiService';
import { AsyncHandler } from '../../helpers';
import { enqueueSnackbar } from '../snackBarReducer';
import { JwtService } from '../../../services/jwt/jwtService';
import * as uuidv4 from 'uuid/v4';
import * as metadataReducer from '../metadataReducer';
import { EmptyGuid } from "../../../services/utils";

export type TUser = IHaveUserResponse & {
    claims: any[]
    createdAt: string
    email: string
    id: string
    isEmailConfirmed: boolean
    isLocked: boolean
    modifiedAt: string
    notes: string
    passwordHash: string
    resetPasswordToken: null
    resetPasswordTokenExpiredAt: null
    roles: TRoles[]
    siteList: SiteEntity[]
    deleteReason: string
    isDeleted: boolean
    isDisabled: boolean
    createdBy: string
    password: string
    phone: string
} 

type TRoles = {
    createdAt: string
    id: string
    modifiedAt:string
    role: TRole
    roleId: string
    user: TUser
    userId: string
}
type TRole = {
    createdAt:string
    id: string
    modifiedAt: string
    name: string
    siteId: string
    users: TUser[]
}


export const actions = {
    replaceUser: actionCreator<{ user: any }>('UPDATE_USER'),
    updateUserSiteList: actionCreator<{ siteList: SiteEntity[] }>('UPDATE_USER_SITELIST'),
    updateUserProperty: actionCreator<{ value: string | number; propname: string }>('UPDATE_USER_PROPERTY'),

};
export default function reducer(state: any[] = [], action: Action<any>) {
    if (!state) return null;
    if (isType(action, actions.replaceUser)) {
        return action.payload.user
    }else if (isType(action, actions.updateUserProperty)) {
        return { ...state, [action.payload.propname]: action.payload.value };
    }else if (isType(action, actions.updateUserSiteList)) {
        return { ...state, siteList: action.payload.siteList};
    };
    return state;
}

export const createUser = (siteId: string, roles:string[]) => {
    return async (dispatch: any, getState: any) => {
        dispatch(metadataReducer.setIsAsync(true));
        const user = getState().user;
        try {
            const userExist = await ApiService.userExist(user.email, siteId) as any;
            if (userExist?.userId) {
                dispatch(enqueueSnackbar(`User exists please create new user or restore your password`, 'error'));
                dispatch(metadataReducer.setIsAsync(false));
                return;
            }
        } catch (error) {
        }

        try {
            const rv = await ApiService.createUser({...user, roles}, siteId) as any;
            dispatch(actions.updateUserProperty({ value: rv, propname: 'id' }));
            dispatch(metadataReducer.setIsAsync(false));
            dispatch(enqueueSnackbar('User created with id = ' + rv, 'success'));
            return;
        } catch (error) {
            dispatch(enqueueSnackbar(`User Create ${error}`, 'error'));
            dispatch(metadataReducer.setIsAsync(false));
        }
    };
};

export const updateUserProperty = (value: string, propname: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(actions.updateUserProperty({ value, propname }));
    };
};
export const updateUser = (roles:string[]) => {
    return async (dispatch: any, getState: any) => {
        const user = getState().user
        console.log("user: ", user);
        AsyncHandler(
            async () => {
                user.modifiedBy = JwtService.getUsername();
                delete user["siteList"];
                delete user["roles"];
                await ApiService.updateUser({ ...user, roles });
                dispatch(enqueueSnackbar('User Updated', 'success'));
            },
            dispatch,
            'replaceUser',
            () => {
                dispatch(enqueueSnackbar('User Update failed', 'error'));
            }
        );
    };
};
export const changeUserPassword = (changePasswordViewModel: ChangePasswordViewModel, userSiteId) => {
    return async (dispatch: any, getState: any) => {
        const user = getState().user;
        AsyncHandler(
            async () => {
                user.modifiedBy = JwtService.getUsername();
                await ApiService.changeUserPassword(userSiteId, user.id, changePasswordViewModel);
                dispatch(enqueueSnackbar('Password changed', 'success'));
            },
            dispatch,
            'replaceUser',
            (error) => { dispatch(enqueueSnackbar(`Change password failed ${error}`, 'error')) }
        );
    };
};
export const getUser = (props: { userId: string }) => {
    const {userId} = props
    return async (dispatch: any, getState: any) => {
        AsyncHandler(
            async () => {
                const user = (await ApiService.getUser(userId)) as any;
                dispatch(actions.replaceUser({ user: user }));
                const siteList = [] as any
                for (const role of user.roles){
                    const siteId = role.role.siteId;
                    const site = siteId && (await ApiService.getUserSiteList(siteId));
                    const company = siteId && (await ApiService.getCompany(siteId));
                    if (site) {
                        site["companies"] = company  || [];
                        siteList.push(site)
                    }
                }
                dispatch(actions.updateUserSiteList({ siteList }));
            },
            dispatch,
            'user'
        );
    };
};
export const userDelete = (userId: string, siteId: string) => {
    return async (dispatch: any,  getState:any) => {
        const user = getState().user;
        user.modifiedBy = JwtService.getUsername();
        AsyncHandler(
            async () => {
                await ApiService.deleteUser(siteId, userId);
                const removeCompletely = siteId === EmptyGuid || new Set(user.roles.map(r => r.role.siteId)).size === 1;

                if (removeCompletely)
                    dispatch(actions.replaceUser({
                        user: {
                            ...IHaveUserResponsedefault,
                            id: userId,
                        }
                    }));
                
                dispatch(enqueueSnackbar(`User ${userId} has been deleted`, 'success', !removeCompletely));
            },
            dispatch,
            'userDelete',
            () => {
                dispatch(enqueueSnackbar(`User ${user.userId} Delete failed`, 'error'));
            }
        );
    }
}

export const clearUser = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(
            actions.replaceUser({
                user: {
                    ...IHaveUserResponsedefault,
                    isDisabled: false,
                    id: uuidv4(),
                    createdBy: JwtService.getUsername(),
                }
            })
        );

    };
};